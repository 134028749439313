import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, companyAvatarStyle } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Avatar from 'components/Avatar';

const SPEC_SMILE_ICON = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7412 0V0.000395298L10.7412 0.00299562C4.78563 0.140471 0 5.01136 0 11C0 17.0751 4.92486 22 11 22C17.0751 22 22 17.0751 22 11C22 8.51176 21.1738 6.21648 19.7808 4.37351C21.0695 3.35188 21.8961 1.77267 21.8961 0.000395298V0H10.7412ZM10.9423 16.5253C7.90781 16.5253 5.4446 14.0793 5.41675 11.0514H16.4679C16.44 14.0793 13.9768 16.5253 10.9423 16.5253Z"
      fill="white"
    />
  </svg>
);

const LogoMark = ({ size = '22' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7412 0V0.000395298L10.7412 0.00299562C4.78563 0.140471 0 5.01136 0 11C0 17.0751 4.92486 22 11 22C17.0751 22 22 17.0751 22 11C22 8.51176 21.1738 6.21648 19.7808 4.37351C21.0695 3.35188 21.8961 1.77267 21.8961 0.000395298V0H10.7412ZM10.9423 16.5253C7.90781 16.5253 5.4446 14.0793 5.41675 11.0514H16.4679C16.44 14.0793 13.9768 16.5253 10.9423 16.5253Z"
        fill="white"
      />
    </svg>
  );
};

const FullLogo = ({ width = '86', height = '30' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 86 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.8975 10.7057C46.5627 8.8535 45.0676 6.48801 41.1177 6.48801C38.0827 6.48801 35.4494 8.58571 35.4494 11.576C35.4494 13.9192 37.0785 15.7045 39.7564 16.2177L41.921 16.6194C42.9699 16.8203 43.617 17.4005 43.617 18.2039C43.617 19.1858 42.769 19.8552 41.3855 19.8552C39.2208 19.8552 38.2389 18.5832 38.105 17.1327L34.8692 17.9138C35.0924 20.1453 36.9669 22.9795 41.3408 22.9795C45.1122 22.9795 47.1876 20.4578 47.1876 17.9807C47.1876 15.7268 45.6255 13.7853 42.6128 13.2274L40.4928 12.8257C39.3993 12.6249 38.9307 12.067 38.9307 11.3082C38.9307 10.4379 39.7787 9.61224 41.0954 9.61224C43.0145 9.61224 43.617 10.8619 43.7286 11.5984L46.8975 10.7057Z"
        fill="#3C4257"
      />
      <path
        d="M52.6006 26.8847V21.7744C53.1139 22.3992 54.185 22.8902 55.6132 22.8902C58.7151 22.8902 60.9021 20.4578 60.9021 17.0657C60.9021 13.7407 58.9606 11.2859 55.7471 11.2859C54.1627 11.2859 52.9576 11.9777 52.489 12.6918V11.5537H49.2086V26.8847H52.6006ZM57.577 17.0881C57.577 18.9403 56.3943 19.8552 55.0553 19.8552C53.7387 19.8552 52.556 18.918 52.556 17.0881C52.556 15.2358 53.7387 14.3432 55.0553 14.3432C56.3943 14.3432 57.577 15.2358 57.577 17.0881Z"
        fill="#3C4257"
      />
      <path
        d="M65.657 15.7714C65.7239 14.9457 66.438 13.8969 67.8662 13.8969C69.473 13.8969 70.0532 14.9234 70.0978 15.7714H65.657ZM70.3433 18.6502C70.0309 19.5205 69.3391 20.1007 68.1117 20.1007C66.7951 20.1007 65.657 19.2081 65.59 17.9584H73.3113C73.3336 17.8914 73.3783 17.3782 73.3783 16.9095C73.3783 13.339 71.2583 11.219 67.8216 11.219C64.9429 11.219 62.2873 13.4952 62.2873 17.0657C62.2873 20.7925 65.0098 22.9795 68.0671 22.9795C70.8789 22.9795 72.6418 21.3727 73.1774 19.4535L70.3433 18.6502Z"
        fill="#3C4257"
      />
      <path
        d="M80.6665 14.3432C81.9831 14.3432 82.6526 15.1466 82.8981 15.9946L85.9331 15.0796C85.4421 13.0935 83.6568 11.219 80.5996 11.219C77.3414 11.219 74.7528 13.6514 74.7528 17.1104C74.7528 20.547 77.4084 22.9795 80.7111 22.9795C83.6791 22.9795 85.5091 21.0603 86 19.1188L83.0097 18.2039C82.7642 19.0295 82.0278 19.8552 80.7111 19.8552C79.3052 19.8552 78.1225 18.8733 78.1225 17.1104C78.1225 15.3251 79.2606 14.3432 80.6665 14.3432Z"
        fill="#3C4257"
      />
      <path
        d="M0 14.5963C0 3.64904 3.64904 0 14.5963 0C25.5435 0 29.1926 3.64904 29.1926 14.5963C29.1926 25.5435 25.5435 29.1925 14.5963 29.1925C3.64904 29.1925 0 25.5435 0 14.5963Z"
        fill="#EA4C89"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.405 6.48729L14.405 6.49021C10.0147 6.59159 6.48683 10.1823 6.48683 14.597C6.48683 19.0755 10.1173 22.706 14.5958 22.706C19.0743 22.706 22.7048 19.0755 22.7048 14.597C22.7048 12.7624 22.0956 11.0701 21.0684 9.71142C22.0187 8.95831 22.6282 7.79398 22.6282 6.48729H14.405ZM14.5575 18.669C12.3205 18.669 10.5046 16.8658 10.4841 14.6337H18.6308C18.6103 16.8658 16.7945 18.669 14.5575 18.669Z"
        fill="white"
      />
    </svg>
  );
};

const FullWhiteLogo = ({ width = '86', height = '30' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 71 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.5558 8.80148C38.2806 7.27872 37.0514 5.33398 33.804 5.33398C31.3089 5.33398 29.144 7.05856 29.144 9.517C29.144 11.4434 30.4833 12.9111 32.6849 13.3331L34.4645 13.6633C35.3268 13.8284 35.8588 14.3054 35.8588 14.9659C35.8588 15.7732 35.1617 16.3236 34.0242 16.3236C32.2446 16.3236 31.4373 15.2778 31.3272 14.0853L28.667 14.7274C28.8505 16.5621 30.3916 18.8921 33.9875 18.8921C37.0881 18.8921 38.7943 16.8189 38.7943 14.7825C38.7943 12.9295 37.51 11.3333 35.0332 10.8746L33.2903 10.5444C32.3913 10.3793 32.0061 9.92062 32.0061 9.29684C32.0061 8.58132 32.7032 7.9025 33.7857 7.9025C35.3635 7.9025 35.8588 8.92991 35.9506 9.53534L38.5558 8.80148Z"
        fill="white"
      />
      <path
        d="M43.2445 22.1027V17.9014C43.6664 18.4151 44.5471 18.8187 45.7213 18.8187C48.2714 18.8187 50.0694 16.8189 50.0694 14.0302C50.0694 11.2966 48.4732 9.27849 45.8313 9.27849C44.5287 9.27849 43.538 9.84724 43.1527 10.4343V9.49865H40.4558V22.1027H43.2445ZM47.3358 14.0486C47.3358 15.5714 46.3634 16.3236 45.2626 16.3236C44.1801 16.3236 43.2078 15.553 43.2078 14.0486C43.2078 12.5258 44.1801 11.792 45.2626 11.792C46.3634 11.792 47.3358 12.5258 47.3358 14.0486Z"
        fill="white"
      />
      <path
        d="M53.9785 12.9661C54.0335 12.2873 54.6206 11.425 55.7948 11.425C57.1158 11.425 57.5928 12.269 57.6295 12.9661H53.9785ZM57.8313 15.3329C57.5744 16.0484 57.0057 16.5254 55.9966 16.5254C54.9142 16.5254 53.9785 15.7915 53.9235 14.7641H60.2714C60.2897 14.7091 60.3264 14.2871 60.3264 13.9018C60.3264 10.9664 58.5835 9.22345 55.7581 9.22345C53.3914 9.22345 51.2082 11.0948 51.2082 14.0302C51.2082 17.0941 53.4464 18.8921 55.9599 18.8921C58.2716 18.8921 59.721 17.5711 60.1613 15.9933L57.8313 15.3329Z"
        fill="white"
      />
      <path
        d="M66.3183 11.792C67.4007 11.792 67.9511 12.4524 68.1529 13.1496L70.6481 12.3974C70.2444 10.7646 68.7767 9.22345 66.2632 9.22345C63.5846 9.22345 61.4564 11.2232 61.4564 14.0669C61.4564 16.8923 63.6397 18.8921 66.355 18.8921C68.7951 18.8921 70.2995 17.3143 70.7031 15.7181L68.2447 14.9659C68.0428 15.6447 67.4374 16.3236 66.355 16.3236C65.1991 16.3236 64.2268 15.5163 64.2268 14.0669C64.2268 12.5992 65.1624 11.792 66.3183 11.792Z"
        fill="white"
      />
      <path
        d="M0 12C0 2.99998 2.99998 0 12 0C21 0 24.0001 2.99998 24.0001 12C24.0001 21 21 24 12 24C2.99998 24 0 21 0 12Z"
        fill="#EA4C89"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8428 5.33325V5.33345L11.8428 5.33585C8.23336 5.41919 5.33301 8.37123 5.33301 12.0007C5.33301 15.6826 8.31776 18.6673 11.9996 18.6673C15.6815 18.6673 18.6663 15.6826 18.6663 12.0007C18.6663 10.4924 18.1654 9.10114 17.3209 7.9841C18.1022 7.36494 18.6033 6.40771 18.6033 5.33345V5.33325H11.8428ZM11.9681 15.3484C10.129 15.3484 8.63614 13.8659 8.61928 12.0308H15.3169C15.3001 13.8659 13.8072 15.3484 11.9681 15.3484Z"
        fill="white"
      />
    </svg>
  );
};

const FullGrayLogo = () => {
  return (
    <svg width="71" height="24" viewBox="0 0 71 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.556 8.80173C38.2808 7.27896 37.0516 5.33423 33.8043 5.33423C31.3091 5.33423 29.1442 7.0588 29.1442 9.51724C29.1442 11.4436 30.4835 12.9114 32.6851 13.3333L34.4647 13.6636C35.327 13.8287 35.8591 14.3057 35.8591 14.9662C35.8591 15.7734 35.1619 16.3238 34.0244 16.3238C32.2448 16.3238 31.4376 15.2781 31.3275 14.0855L28.6672 14.7277C28.8507 16.5623 30.3918 18.8923 33.9877 18.8923C37.0883 18.8923 38.7945 16.8192 38.7945 14.7827C38.7945 12.9297 37.5103 11.3335 35.0335 10.8749L33.2906 10.5446C32.3916 10.3795 32.0063 9.92087 32.0063 9.29708C32.0063 8.58157 32.7035 7.90274 33.7859 7.90274C35.3637 7.90274 35.8591 8.93015 35.9508 9.53559L38.556 8.80173Z"
        fill="#BDC4CF"
      />
      <path
        d="M43.2447 22.103V17.9016C43.6667 18.4153 44.5473 18.8189 45.7215 18.8189C48.2717 18.8189 50.0696 16.8192 50.0696 14.0305C50.0696 11.2969 48.4735 9.27874 45.8316 9.27874C44.529 9.27874 43.5383 9.84748 43.153 10.4346V9.4989H40.456V22.103H43.2447ZM47.336 14.0488C47.336 15.5716 46.3636 16.3238 45.2628 16.3238C44.1804 16.3238 43.208 15.5533 43.208 14.0488C43.208 12.5261 44.1804 11.7922 45.2628 11.7922C46.3636 11.7922 47.336 12.5261 47.336 14.0488Z"
        fill="#BDC4CF"
      />
      <path
        d="M53.9787 12.9664C54.0338 12.2876 54.6209 11.4253 55.7951 11.4253C57.116 11.4253 57.593 12.2692 57.6297 12.9664H53.9787ZM57.8315 15.3331C57.5747 16.0486 57.0059 16.5256 55.9969 16.5256C54.9144 16.5256 53.9787 15.7918 53.9237 14.7644H60.2716C60.29 14.7093 60.3266 14.2873 60.3266 13.9021C60.3266 10.9666 58.5837 9.2237 55.7584 9.2237C53.3917 9.2237 51.2084 11.095 51.2084 14.0305C51.2084 17.0944 53.4467 18.8923 55.9602 18.8923C58.2718 18.8923 59.7212 17.5714 60.1615 15.9936L57.8315 15.3331Z"
        fill="#BDC4CF"
      />
      <path
        d="M66.3185 11.7922C67.401 11.7922 67.9514 12.4527 68.1532 13.1499L70.6483 12.3976C70.2447 10.7648 68.777 9.2237 66.2635 9.2237C63.5849 9.2237 61.4567 11.2235 61.4567 14.0672C61.4567 16.8926 63.6399 18.8923 66.3552 18.8923C68.7953 18.8923 70.2997 17.3145 70.7033 15.7184L68.2449 14.9662C68.0431 15.645 67.4377 16.3238 66.3552 16.3238C65.1994 16.3238 64.227 15.5166 64.227 14.0672C64.227 12.5995 65.1627 11.7922 66.3185 11.7922Z"
        fill="#BDC4CF"
      />
      <path
        d="M0 12C0 2.99998 2.99998 0 12 0C21 0 24.0001 2.99998 24.0001 12C24.0001 21 21 24 12 24C2.99998 24 0 21 0 12Z"
        fill="#BDC4CF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8428 5.33325V5.33359L11.8428 5.33581C8.23339 5.41912 5.33301 8.37117 5.33301 12.0006C5.33301 15.6825 8.31776 18.6673 11.9996 18.6673C15.6815 18.6673 18.6663 15.6825 18.6663 12.0006C18.6663 10.4924 18.1655 9.10122 17.321 7.98421C18.1023 7.36505 18.6034 6.40784 18.6034 5.33359V5.33325H11.8428ZM11.9682 15.3485C10.129 15.3485 8.63613 13.866 8.61935 12.0308H15.317C15.3002 13.866 13.8073 15.3485 11.9682 15.3485Z"
        fill="white"
      />
    </svg>
  );
};

const SpecLogo = ({ variant, logoWidth, logoHeight, size, ...props }) => {
  if (variant === 'avatar') {
    return <Avatar bg={theme.colors.primaryBrand} icon={SPEC_SMILE_ICON} css={companyAvatarStyle} {...props} />;
  } else if (variant === 'logo_mark') {
    return (
      <Box {...props}>
        <LogoMark size={size} />
      </Box>
    );
  } else if (variant === 'full_gray') {
    return (
      <Box {...props}>
        <FullGrayLogo />
      </Box>
    );
  } else if (variant === 'full_white') {
    return (
      <Box {...props}>
        <FullWhiteLogo width={logoWidth} height={logoHeight} />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <FullLogo width={logoWidth} height={logoHeight} />
    </Box>
  );
};

export default SpecLogo;
