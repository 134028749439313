import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Text from 'components/Text';
import Avatar from 'components/Avatar';

// <InfoGroup title="Title" subtitle="Subtitle" noSpaceBetween />

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  text-align: ${props => props.textAlign};
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
`;

const TextGroup = styled.div``;

const SpaceBetween = styled.div`
  ${({ direction, spaceBetween }) =>
    direction === 'row' &&
    css`
      width: ${spaceBetween};
    `};
  ${({ direction, spaceBetween }) =>
    direction === 'column' &&
    css`
      height: ${spaceBetween};
    `};
`;

const Emoji = styled.div`
  font-size: 25px;
  height: 32px;
`;

const InfoGroup = ({
  avatarSize = 'md',
  emoji,
  avatarSrc,
  icon,
  title,
  subtitle,
  titleFontSize = theme.fontSizes.base,
  titleFontColor = theme.colors.primaryText,
  titleFontFamily = theme.fonts.semiBold,
  subtitleFontSize = 'md',
  subtitleFontColor = theme.colors.paragraphText,
  subtitleFontFamily = theme.fonts.light,
  subtitleLineHeight = theme.lineHeights.md,
  spaceBetween = '12px',
  noSpaceBetween = true,
  align = 'center',
  direction = 'row',
  textAlign = 'left',
  showAvatarBadge,
  customAvatarBadgeIcon,
  avatarBadgeBg,
  avatarBadgeBoxSize,
  avatarBorderRadius,
  subtitleAs,
  subtitleCss,
  ...props
}) => {
  return (
    <StyledBox align={align} direction={direction} textAlign={textAlign} {...props}>
      {emoji && <Emoji>{emoji}</Emoji>}
      {avatarSrc && (
        <Avatar
          size={avatarSize}
          src={avatarSrc}
          showBadge={showAvatarBadge}
          customBadgeIcon={customAvatarBadgeIcon}
          borderRadius={avatarBorderRadius}
          badgeBg={avatarBadgeBg}
          badgeBoxSize={avatarBadgeBoxSize}
        />
      )}
      {icon && icon}
      {!noSpaceBetween && <SpaceBetween direction={direction} spaceBetween={spaceBetween} className="space-between" />}
      <TextGroup>
        <Text fontSize={titleFontSize} color={titleFontColor} fontFamily={titleFontFamily} align={textAlign}>
          {title}
        </Text>
        <Text
          fontSize={subtitleFontSize}
          color={subtitleFontColor}
          fontFamily={subtitleFontFamily}
          align={textAlign}
          lineHeight={subtitleLineHeight}
          as={subtitleAs}
          css={subtitleCss}>
          {subtitle}
        </Text>
      </TextGroup>
    </StyledBox>
  );
};

export default InfoGroup;
