import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ArrowRightIcon } from 'assets/icons';
import { THEMES } from 'data/strengthsfinderData';
import update from 'immutability-helper';
import Flex from 'components/Flex';
import IconButton from 'components/IconButton';
import InfoGroup from 'components/InfoGroup';
import InputGroup from 'components/InputGroup';
import LiveOutline from './LiveOutline';

const CreateContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radii.md};
  padding: ${theme.padding.lg};
  .chakra-input__left-addon {
    background: white;
  }
  .input-element {
    border-radius: 0 ${theme.radii.md} ${theme.radii.md} 0;
  }
`;

const Strengthsfinder = ({ section, sectionIndex, onSectionChange, triggerToast, isEditMode }) => {
  const [answers, setAnswers] = useState(section.answers);

  const onGenerate = () => {
    let doesPassValidation = true;
    answers.forEach((answer, i) => {
      if (!answer.answer) doesPassValidation = false;
    });
    if (doesPassValidation) {
      onSectionChange(sectionIndex, 'answers', answers);
    } else {
      triggerToast({ title: `Please select all 5 themes`, status: 'error' });
    }
  };

  const onAnswerChange = (answerIndex, field, value) => {
    const newAnswers = update(answers, { [answerIndex]: { [field]: { $set: value } } });
    setAnswers(newAnswers);
  };

  const onDelete = () => {
    let newAnswers = section.answers;
    newAnswers.forEach((answer, i) => {
      answer.answer = '';
    });
    onSectionChange(sectionIndex, 'answers', newAnswers);
  };

  const hasAnswers = section.answers[0].answer;
  return (
    <React.Fragment>
      {hasAnswers ? (
        <LiveOutline answers={section.answers} isEditMode={isEditMode} onDelete={onDelete} sectionTitle={section.title} />
      ) : (
        <CreateContainer gap="8px">
          <InfoGroup
            title="Enter your top 5 Strengthsfinder themes below"
            subtitle={
              <span>
                Haven't taken the test? Take one free{' '}
                <a href="https://high5test.com/test" target="_blank" className="brand-link">
                  here
                </a>
              </span>
            }
            direction="column"
            align="center"
            textAlign="center"
          />

          <Flex direction="column" align="flex-end" gap="10px">
            {answers.map((answer, answerIndex) => {
              return (
                <InputGroup
                  key={answerIndex}
                  type="select"
                  inputLeftAddon={answerIndex + 1}
                  value={answer.answer}
                  placeholder="Select theme"
                  field="answer"
                  onChange={(field, value) => onAnswerChange(answerIndex, field, value)}
                  variant="outline"
                  width="225px"
                  selectOptions={THEMES.map((theme, index) => {
                    const isDisabled = answers.findIndex(answer => answer.answer === theme.theme) !== -1;
                    return { label: theme.theme, value: theme.theme, disabled: isDisabled };
                  })}
                />
              );
            })}
            <IconButton colorScheme="brand" variant="solid" icon={<ArrowRightIcon color="white" />} onClick={onGenerate} />
          </Flex>
        </CreateContainer>
      )}
    </React.Fragment>
  );
};

export default React.memo(Strengthsfinder, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
