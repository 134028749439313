import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Box, Flex } from '@chakra-ui/react';
import InfoGroup from 'components/InfoGroup';

// <Section>
//  <Section.Header title="" subtitle="" />
//  <Section.Body>
//    <div />
//  </Section.Body>
//  <Section.Footer>
//    <div />
//  </Section.Footer>
// </Section>

const StyledBox = styled(Box)`
  ${containerStyle};
  padding: 0px;
`;

const Section = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>;
};

Section.Header = ({
  title,
  subtitle,
  align = 'center',
  justify = 'space-between',
  borderBottom = theme.layout.border,
  p = theme.padding.lg,
  children,
  ...props
}) => {
  return (
    <Flex align={align} justify={justify} borderBottom={(title || subtitle) && borderBottom} p={p} {...props}>
      <InfoGroup title={title} subtitle={subtitle} />
    </Flex>
  );
};

Section.Body = ({ p = theme.padding.lg, children, ...props }) => {
  return (
    <Box p={p} {...props}>
      {children}
    </Box>
  );
};

const StyledFooter = styled(Flex)`
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      bottom: 0;
      z-index: 3;
      background: white;
      border-radius: 0 0 ${theme.radii.md} ${theme.radii.md};
    `};
`;

Section.Footer = ({
  align = 'center',
  justify = 'flex-end',
  borderTop = theme.layout.border,
  p = theme.padding.lg,
  gap = '12px',
  isSticky,
  children,
  ...props
}) => {
  return (
    <StyledFooter align={align} justify={justify} borderTop={borderTop} p={p} gap={gap} isSticky={isSticky} {...props}>
      {children}
    </StyledFooter>
  );
};

export default Section;
