import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Api } from 'helpers/utils';
import { Image } from '@chakra-ui/react';
import Flex from 'components/Flex';
import BarChart from 'componentsCustom/BarChart';
import Directory from 'pages/Directory';
import OverviewStats from './OverviewStats';
import Loading from 'components/Loading';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';

const SectionContainer = styled.div`
  ${containerStyle};
  padding: 0px;
`;

const Analytics = ({ triggerToast, onCopyShareLink, isFeatureGated }) => {
  const [overviewStats, setOverviewStats] = useState(null);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    if (isFeatureGated) {
      setDidLoad(true);
    } else {
      Api.get({ url: `/api/v1/admin/analytics/get_overview_stats` }).then(data => {
        if (data.success) {
          setOverviewStats(data.overview_stats);
          setDidLoad(true);
        } else {
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    }
  }, []);

  if (!didLoad) return <Loading />;

  if (isFeatureGated) {
    return (
      <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
        <UpgradeCTA
          title="Upgrade to Pro to access Analytics"
          subtitle="Once upgraded, you can keep track of Spec activity over time, see which Specs receive the most engagement, see who engages the most with others, and more."
          ctaType="pageHeader"
        />
        <Image
          src="https://res.cloudinary.com/teamspec/image/upload/v1663857816/Feature_Gated_Analytics_gt0yr6.png"
          alt="Spec Analytics Preview"
          loading="lazy"
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
      <OverviewStats overviewStats={overviewStats} />
      <Flex direction="row" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
        <BarChart
          title="Specs Published"
          dataKey="specs_published"
          timeframes={[{ label: 'Monthly', value: 'monthly' }, { label: 'Weekly', value: 'weekly' }]}
        />
        <BarChart
          title="Spec Views"
          dataKey="spec_views"
          timeframes={[{ label: 'Monthly', value: 'monthly' }, { label: 'Weekly', value: 'weekly' }]}
        />
      </Flex>
      <SectionContainer>
        <Directory
          headerTitle="Most Viewed Specs"
          isWithinPanel
          showHeaderTitle
          initialSearchQueries={[
            {
              display: '',
              query_type: 'most_active_specs',
              query_value: '',
            },
            {
              display: 'Title',
              query_type: '',
              query_value: '',
            },
            {
              display: 'Views',
              query_type: 'views_received',
              query_value: '',
            },
            {
              display: 'Comments',
              query_type: 'comments_received',
              query_value: '',
            },
            {
              display: 'Reactions',
              query_type: 'reactions_received',
              query_value: '',
            },
          ]}
        />
      </SectionContainer>

      <SectionContainer>
        <Directory
          headerTitle="Most Active Users"
          isWithinPanel
          showHeaderTitle
          initialSearchQueries={[
            {
              display: '',
              query_type: 'most_active_users',
              query_value: '',
            },
            {
              display: 'Title',
              query_type: '',
              query_value: '',
            },
            {
              display: 'Specs Viewed',
              query_type: 'views_made',
              query_value: '',
            },
            {
              display: 'Comments Sent',
              query_type: 'comments_made',
              query_value: '',
            },
            {
              display: 'Reactions Sent',
              query_type: 'reactions_made',
              query_value: '',
            },
          ]}
        />
      </SectionContainer>
    </Flex>
  );
};

export default Analytics;
