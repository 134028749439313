import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, containerStyleMobileConstrainedWidth } from 'styles/theme';
import Flex from 'components/Flex';
import Text from 'components/Text';

const StyledContainer = styled.div`
  ${containerStyle};
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
  min-height: 400px;
  ${({ pageWithin }) =>
    pageWithin === 'general_signup' &&
    css`
      min-height: 150px;
      ${containerStyleMobileConstrainedWidth};
    `};
  ${({ isModal }) =>
    isModal &&
    css`
      min-height: 150px;
      border-radius: ${theme.radii.md};
      @media screen and (max-width: ${theme.breakpoints.md}) {
        border-radius: ${theme.radii.md};
      }
    `};
`;

const OnboardingFlow = ({ pageWithin, isModal, children }) => {
  return (
    <StyledContainer pageWithin={pageWithin} isModal={isModal}>
      {children}
    </StyledContainer>
  );
};

OnboardingFlow.Inner = ({ children }) => {
  return (
    <Flex direction="column" align="stretch" gap="16px" width="100%">
      {children}
    </Flex>
  );
};

OnboardingFlow.HeaderEmoji = ({ emoji }) => {
  return (
    <Text fontSize="28px" as="div" mb="-16px">
      {emoji}
    </Text>
  );
};

OnboardingFlow.Header = ({ title, subtitle }) => {
  return (
    <Flex direction="column" align="stretch">
      <Text fontSize={theme.fontSizes.xxl} fontFamily={theme.fonts.bold} color={theme.colors.primaryText} as="h2">
        {title}
      </Text>
      <Text fontFamily={theme.fonts.light}>{subtitle}</Text>
    </Flex>
  );
};

OnboardingFlow.Body = ({ children }) => {
  return (
    <Flex direction="column" align="stretch" gap="16px">
      {children}
    </Flex>
  );
};

OnboardingFlow.Footer = ({ children }) => {
  return (
    <Flex direction="column" align="stretch">
      {children}
    </Flex>
  );
};

const StyledPostText = styled(Text)`
  color: ${theme.colors.accentText};
  span {
    cursor: pointer;
    color: ${theme.colors.primaryText};
    &:hover {
      text-decoration: underline;
    }
  }
  ${({ hasClick }) =>
    hasClick &&
    css`
      cursor: pointer;
      &:hover {
        color: ${theme.colors.primaryText};
        text-decoration: underline;
      }
    `};
`;

OnboardingFlow.PostText = ({ onClick, children }) => {
  return (
    <StyledPostText width="100%" textAlign="center" as={onClick ? 'button' : 'div'} hasClick={!!onClick} onClick={onClick}>
      {children}
    </StyledPostText>
  );
};

export default OnboardingFlow;
