const BigFive = () => {
  return (
    <svg width="328" height="32" viewBox="0 0 328 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="185" height="8" rx="4" fill="#E0E6ED" />
      <rect x="298" width="30" height="8" rx="4" fill="#E0E6ED" />
      <rect x="298" y="12" width="30" height="8" rx="4" fill="#E0E6ED" />
      <rect x="298" y="24" width="30" height="8" rx="4" fill="#E0E6ED" />
      <rect y="12" width="223" height="8" rx="4" fill="#E0E6ED" />
      <rect y="24" width="154" height="8" rx="4" fill="#E0E6ED" />
    </svg>
  );
};

export default BigFive;
