import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ArrowRightIcon } from 'assets/icons';
import { INDICATORS } from 'data/myersBriggsData';
import Flex from 'components/Flex';
import IconButton from 'components/IconButton';
import InfoGroup from 'components/InfoGroup';
import InputGroup from 'components/InputGroup';
import LiveOutline from './LiveOutline';

const CreateContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radii.md};
  padding: ${theme.padding.lg};
`;

const MyersBriggs = ({ section, sectionIndex, onSectionAnswerChange, triggerToast, isEditMode }) => {
  const answer = section.answers[0];
  const [typeIndicator, setTypeIndicator] = useState(answer.answer);
  const answerIndex = 0;
  const hasAnswer = answer.answer && answer.answer.length === 4;

  const onGenerate = () => {
    if (typeIndicator && typeIndicator.length === 4) {
      onSectionAnswerChange(sectionIndex, answerIndex, 'answer', typeIndicator);
    } else {
      triggerToast({ title: 'Please select an indicator', status: 'error' });
    }
  };

  const onDelete = () => {
    setTypeIndicator('');
    onSectionAnswerChange(sectionIndex, answerIndex, 'answer', '');
  };

  return (
    <React.Fragment>
      {hasAnswer ? (
        <LiveOutline answer={answer} isEditMode={isEditMode} onDelete={onDelete} />
      ) : (
        <CreateContainer gap="8px">
          <InfoGroup
            title="Enter your Myers-Briggs type indicator below"
            subtitle={
              <span>
                Haven't taken the test? Take one free{' '}
                <a href="https://www.16personalities.com/free-personality-test" target="_blank" className="brand-link">
                  here
                </a>
              </span>
            }
            direction="column"
            align="center"
            textAlign="center"
          />
          <form
            onSubmit={e => {
              e.preventDefault();
              onGenerate();
            }}>
            <Flex gap="10px">
              <InputGroup
                type="select"
                value={typeIndicator}
                placeholder="Select Indicator"
                field="answer"
                onChange={(field, value) => setTypeIndicator(value)}
                width="210px"
                variant="outline"
                selectOptions={INDICATORS.map((indicator, index) => {
                  return { label: indicator.indicator, value: indicator.indicator };
                })}
              />
              <IconButton colorScheme="brand" variant="solid" icon={<ArrowRightIcon color="white" />} type="submit" />
            </Flex>
          </form>
        </CreateContainer>
      )}
    </React.Fragment>
  );
};

export default React.memo(MyersBriggs, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
