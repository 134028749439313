import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle } from 'styles/theme';
import { YEARS } from 'data/constants';
import { Tooltip } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Text from 'components/Text';
import InputGroup from 'components/InputGroup';
import AnswerHandleGroup from 'componentsCustom/AnswerHandleGroup';

const StyledFlex = styled(Flex)`
  ${editTileStyle};
  flex-direction: row;
  flex-wrap: wrap;
`;

const ElementWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid ${theme.colors.border};
  padding: ${({ padding }) => padding && padding};
  .input-element {
    padding: 10px 16px;
    height: 40px;
  }
  width: 100%;
  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${theme.colors.border};
    `};
  ${({ halfWidth }) =>
    halfWidth &&
    css`
      width: 50%;
    `};
`;

const EditTile = ({ answer, onSectionAnswerChange, sectionIndex, answerIndex, sortableHandleClassName, answerElementID }) => {
  const [jsonAnswer, setJSONAnswer] = useState(answer.json_answer);

  const onAnswerChange = (field, value) => {
    let newJsonAnswer = jsonAnswer;
    newJsonAnswer[field] = value;
    setJSONAnswer(newJsonAnswer);
    onSectionAnswerChange(sectionIndex, answerIndex, 'json_answer', newJsonAnswer);
  };

  return (
    <StyledFlex id={answerElementID}>
      <ElementWrapper padding="10px 0 10px 16px">
        <Text>Edit Workplace</Text>
        <AnswerHandleGroup
          onDeleteClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
          dragHandleClassName={sortableHandleClassName}
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth borderRight>
        <InputGroup
          type="select"
          value={jsonAnswer.start_year}
          placeholder="From"
          field="start_year"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          selectOptions={YEARS.map(year => {
            return { label: year, value: year };
          })}
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth>
        <InputGroup
          type="select"
          value={jsonAnswer.end_year}
          placeholder="To"
          field="end_year"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          selectOptions={['Present'].concat(YEARS).map(year => {
            let disabled = false;
            if (typeof +year === 'number' && typeof +jsonAnswer.start_year === 'number' && +year < +jsonAnswer.start_year) disabled = true;
            return { label: year, value: year, disabled: disabled };
          })}
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth borderRight>
        <InputGroup
          type="input"
          value={jsonAnswer.job_title}
          placeholder="Job title"
          field="job_title"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          autoComplete="off"
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth>
        <InputGroup
          type="input"
          value={jsonAnswer.location}
          placeholder="Location"
          field="location"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          autoComplete="off"
          autoComplete="off"
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth borderRight>
        <InputGroup
          type="input"
          value={jsonAnswer.company}
          placeholder="Company"
          field="company"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          autoComplete="off"
        />
      </ElementWrapper>
      <ElementWrapper padding="0px" halfWidth>
        <InputGroup
          type="input"
          value={jsonAnswer.company_url}
          placeholder="Company URL"
          field="company_url"
          onChange={(field, value) => onAnswerChange(field, value)}
          variant="unstyled"
          autoComplete="off"
        />
      </ElementWrapper>
      <InputGroup
        type="textarea"
        value={jsonAnswer.description}
        placeholder="Description"
        field="description"
        onChange={(field, value) => onAnswerChange(field, value)}
        variant="unstyled"
        minRows="4"
      />
    </StyledFlex>
  );
};

export default EditTile;
