import React, { useState, useRef } from 'react';
import { theme } from 'styles/theme';
import { Editable, EditableInput, EditableTextarea, EditablePreview } from '@chakra-ui/react';
import autosize from 'autosize';
import Button from 'components/Button';

const EditableComponent = ({
  value,
  field,
  onChange,
  placeholder,
  isDisabled,
  inputType = 'input',
  minRows = 1,
  showCompleteButton,
  inputStyle,
}) => {
  const [didSet, setDidSet] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef();
  return (
    <Editable defaultValue={value} placeholder={placeholder} isDisabled={isDisabled}>
      <EditablePreview padding="0px" cursor="inherit">
        {value}
      </EditablePreview>
      {inputType === 'input' && <EditableInput value={value} onChange={e => onChange(field, e.target.value)} style={inputStyle} />}
      {inputType === 'textarea' && (
        <EditableTextarea
          value={value}
          onChange={e => onChange(field, e.target.value)}
          ref={inputRef}
          minRows={minRows}
          style={inputStyle}
          onFocus={() => {
            autosize(inputRef.current);
            setIsEditing(true);
          }}
          onBlur={() => setIsEditing(false)}
          rows="1"
        />
      )}
      {showCompleteButton &&
        isEditing && (
          <Button size="xs" colorScheme="brand" variant="solid" onClick={() => setIsEditing(false)}>
            Done
          </Button>
        )}
    </Editable>
  );
};

export default EditableComponent;
