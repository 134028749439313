import React, { useState, useCallback } from 'react';
import { theme } from 'styles/theme';
import { useDropzone } from 'react-dropzone';
import { Center, useColorModeValue, Icon } from '@chakra-ui/react';
import { HiCloudUpload } from 'react-icons/hi';
import Papa from 'papaparse';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Button from 'components/Button';

export default function Dropzone({ onFileAccepted, acceptedFileTypes = '.csv' }) {
  const [dropText, setDropText] = useState(`Drag & drop ${acceptedFileTypes} file here, or click to select a file`);
  const [hasFileUpload, setHasFileUploaded] = useState(false);

  const onDrop = useCallback(
    acceptedFiles => {
      Papa.parse(acceptedFiles[0], {
        complete: results => {
          setDropText(`${acceptedFiles[0].name} – ${results.data.length - 1} results`);
          setHasFileUploaded(true);
          onFileAccepted(results);
        },
      });
    },
    [onFileAccepted],
  );

  const onRemoveFile = () => {
    setDropText(`Drag & drop ${acceptedFileTypes} file here, or click to select a file`);
    setHasFileUploaded(false);
    onFileAccepted(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1,
    multiple: false,
  });

  const activeBg = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue(isDragActive ? 'teal.300' : 'gray.300', isDragActive ? 'teal.500' : 'gray.500');

  return (
    <Flex direction="column" width="100%" align="flex-start">
      <Center
        p={10}
        width="100%"
        cursor="pointer"
        bg={isDragActive ? activeBg : 'transparent'}
        _hover={{ bg: activeBg }}
        transition="background-color 0.2s ease"
        borderRadius={theme.radii.md}
        border="2px dashed"
        borderColor={borderColor}
        {...getRootProps()}>
        <input {...getInputProps()} />
        {!hasFileUpload && <Icon as={HiCloudUpload} mr={2} />}
        <Text>{dropText}</Text>
      </Center>
      {hasFileUpload && (
        <Button variant="link" color={theme.colors.redText} mt="8px" onClick={onRemoveFile}>
          Remove File
        </Button>
      )}
    </Flex>
  );
}
