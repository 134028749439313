import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Flex from 'components/Flex';
import Button from 'components/Button';

const Footer = ({ onLoadMore }) => {
  return (
    <Flex p={theme.padding.lg} borderTop={theme.layout.border}>
      <Button width="100%" onClick={onLoadMore}>
        See More
      </Button>
    </Flex>
  );
};

export default Footer;
