import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { LockedIconFilled, EarthIconFilled } from 'assets/icons';
import { RiArrowDropDownLine } from 'react-icons/ri';
import InfoGroup from 'components/InfoGroup';
import Text from 'components/Text';
import Flex from 'components/Flex';

const Container = styled.div`
  z-index: 2;
  width: 100%;
`;

const Styled = styled.div`
  background: ${theme.colors.grayFill};
  border-radius: ${theme.radii.md};
  cursor: pointer;
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 2px solid ${theme.colors.lightAccentText};
      background: white;
      padding: 8px;
    `};
  ${({ isOpen }) =>
    !isOpen &&
    css`
      transition: background 200ms ease;
      &:hover {
        background: var(--chakra-colors-gray-200);
      }
    `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  flex-shrink: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.border};
`;

const OverlayExit = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: -1;
`;

const ArrowWrapper = styled.div`
  width: 1.5rem;
  height: 100%;
  right: 0.5rem;
  position: absolute;
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Option = styled(Flex)`
  position: relative;
  padding: 12px 34px 12px 16px;
  justify-content: space-between;
  border-radius: ${theme.radii.sm};
  &:hover {
    background: ${theme.colors.grayFill};
  }
  ${({ isOpen }) =>
    !isOpen &&
    css`
      &:hover {
        background: transparent;
      }
    `};
  ${({ isSelected, isOpen }) =>
    isSelected &&
    isOpen &&
    css`
      &:hover {
        background: ${theme.colors.primary};
      }
    `};
`;

const ShareControls = ({ isPublic, onUpdateSharePermissions, showLabel = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      {showLabel && (
        <Text color={theme.colors.primaryText} mb="4px">
          Spec Visibility
        </Text>
      )}
      <Styled onClick={() => setIsOpen(true)} isOpen={isOpen}>
        {(isPublic || isOpen) && (
          <Option bg={isPublic && isOpen && theme.colors.primary} isSelected={isPublic} isOpen={isOpen}>
            <InfoGroup
              icon={
                <IconWrapper>
                  <EarthIconFilled color={isPublic && isOpen && 'white'} />
                </IconWrapper>
              }
              title="Public"
              subtitle="Your Spec is visible to anyone with the link"
              spaceBetween="4px"
              noSpaceBetween={false}
              align="flex-start"
              titleFontColor={isPublic && isOpen && 'white'}
              subtitleFontColor={isPublic && isOpen && 'white'}
              subtitleFontSize={theme.fontSizes.sm}
              onClick={() => isOpen && onUpdateSharePermissions(true)}
            />
            {!isOpen && (
              <ArrowWrapper>
                <RiArrowDropDownLine />
              </ArrowWrapper>
            )}
          </Option>
        )}
        {(!isPublic || isOpen) && (
          <Option bg={!isPublic && isOpen && theme.colors.primary} isSelected={!isPublic} isOpen={isOpen}>
            <InfoGroup
              icon={
                <IconWrapper>
                  <LockedIconFilled color={!isPublic && isOpen && 'white'} />
                </IconWrapper>
              }
              title="Restricted Visibility"
              subtitle="Your Spec is only visible to people within your company who are signed in"
              spaceBetween="4px"
              noSpaceBetween={false}
              align="flex-start"
              titleFontColor={!isPublic && isOpen && 'white'}
              subtitleFontColor={!isPublic && isOpen && 'white'}
              subtitleFontSize={theme.fontSizes.sm}
              onClick={() => isOpen && onUpdateSharePermissions(false)}
            />
            {!isOpen && (
              <ArrowWrapper>
                <RiArrowDropDownLine />
              </ArrowWrapper>
            )}
          </Option>
        )}
      </Styled>
      {isOpen && <OverlayExit onClick={() => setIsOpen(false)} />}
    </Container>
  );
};

export default ShareControls;
