import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle, multiLineText } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Text from 'components/Text';
import HTMLText from 'components/HTMLText';

const LiveAnswer = ({ answer }) => {
  return (
    <Box css={editTileStyle} p="10px 16px">
      <Text fontFamily={theme.fonts.light} color={theme.colors.paragraphText} css={multiLineText}>
        {answer.answer}
      </Text>
    </Box>
  );
};

export default LiveAnswer;
