import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Spinner } from '@chakra-ui/react';

const Styled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const Loading = ({ showImmediately = false }) => {
  const [showSpinner, setShowSpinner] = useState(showImmediately);

  useEffect(() => {
    const displayTimeout = setTimeout(() => {
      setShowSpinner(true);
    }, 750);
    return () => clearTimeout(displayTimeout);
  }, []);

  if (!showSpinner) return null;
  return (
    <Styled>
      <Spinner size="md" />
    </Styled>
  );
};

export default Loading;
