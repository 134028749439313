import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';
import { Box } from '@chakra-ui/react';
import Slider from 'react-slick';
import IconButton from 'components/IconButton';

const Carousel = ({ slidesToShow = 4, children }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 350,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: 'slick-slides',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box m="-6px">
      <Slider {...settings}>
        {children.map((child, index) => {
          if (child === null) return null;
          return (
            <Box key={index} p="6px">
              {child}
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px 0 6px 6px;
  transform: translateX(-50%);
  z-index: 1;
  ${({ isRight }) =>
    isRight &&
    css`
      right: 0;
      left: auto;
      transform: translateX(50%);
      margin: -12px 6px 6px 0;
    `};
`;

const PrevArrow = ({ onClick }) => {
  return (
    <ButtonWrapper>
      <IconButton variant="outline" bg="white" isRound size="sm" icon={<ArrowLeftIcon size={16} />} onClick={onClick} />
    </ButtonWrapper>
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <ButtonWrapper isRight>
      <IconButton variant="outline" bg="white" isRound size="sm" icon={<ArrowRightIcon size={16} />} onClick={onClick} />
    </ButtonWrapper>
  );
};

export default Carousel;
