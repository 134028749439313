import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api } from 'helpers/utils';
import { isLoginEmailValid } from 'helpers/authUtils';
import { createStandaloneToast } from '@chakra-ui/toast';
import { MailIconFilled } from 'assets/icons';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InputGroup from 'components/InputGroup';
import ConfirmationModal from 'components/ConfirmationModal';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const ChangeEmailModal = ({ isOpen, onClose, currentEmail, emailField, onSavedCallback }) => {
  const [currentEmailCode, setCurrentEmailCode] = useState('');
  const [newEmailCode, setNewEmailCode] = useState('');
  const [didVerifyCurrentEmail, setDidVerifyCurrentEmail] = useState(!currentEmail);
  const [didVerifyNewEmail, setDidVerifyNewEmail] = useState(false);

  const [newEmail, setNewEmail] = useState('');
  const [didSendCurrentEmailCode, setDidSendCurrentEmailCode] = useState(false);
  const [didSendNewEmailCode, setDidSendNewEmailCode] = useState(false);

  useEffect(() => {
    if (!!currentEmail) onSendVerficiationCode('current');
  }, []);

  const triggerToast = ({ title, status = 'error', duration = 3000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  const onSendVerficiationCode = typeOfEmail => {
    const email = typeOfEmail === 'current' ? currentEmail : newEmail;
    const validation = isLoginEmailValid(email);
    if (validation.success) {
      Api.post({
        url: `/api/v1/auth/send_change_email_access_code`,
        body: {
          type_of_email: typeOfEmail,
          email_field: emailField,
          send_to_email: email,
        },
      }).then(data => {
        if (data.success) {
          typeOfEmail === 'current' ? setDidSendCurrentEmailCode(true) : setDidSendNewEmailCode(true);
        } else {
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    } else {
      triggerToast({ title: validation.notice, status: 'error' });
    }
  };

  const onVerifyCode = typeOfEmail => {
    const code = typeOfEmail === 'current' ? currentEmailCode : newEmailCode;
    const email = typeOfEmail === 'current' ? currentEmail : newEmail;
    if (code.length === 4) {
      Api.post({
        url: `/api/v1/auth/submit_change_email_access_code`,
        body: {
          type_of_email: typeOfEmail,
          email_field: emailField,
          login_access_code: code,
          email: email,
        },
      }).then(data => {
        if (data.success) {
          if (typeOfEmail === 'current') {
            setDidVerifyCurrentEmail(true);
          } else {
            setDidVerifyNewEmail(true);
            onSavedCallback(email);
          }
        } else {
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    } else {
      triggerToast({ title: 'Please enter the 4-digit code', status: 'error' });
    }
  };

  if (didVerifyNewEmail) {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        iconButton={<MailIconFilled size="16px" />}
        title="Your email has been updated"
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm" constrainWidthMobile>
      <ToastContainer />

      <Modal.Body>
        <Flex gap="16px" direction="column" align="stretch">
          {currentEmail && (
            <React.Fragment>
              <InputGroup
                label={`Your current email is ${currentEmail}. We've sent a temporary verification code to this email.`}
                type="input"
                value={currentEmailCode}
                placeholder="4-digit code"
                field="currentEmailCode"
                onChange={(field, value) => value.length < 5 && setCurrentEmailCode(value)}
                isDisabled={didVerifyCurrentEmail}
              />
              {!didVerifyCurrentEmail && (
                <Button colorScheme="brand" variant="solid" onClick={() => onVerifyCode('current')} alignSelf="flex-start">
                  Verify
                </Button>
              )}
            </React.Fragment>
          )}

          {didVerifyCurrentEmail && (
            <React.Fragment>
              <InputGroup
                label="Please enter a new email and we will send you a verification code."
                type="input"
                value={newEmail}
                placeholder="Email"
                field="newEmail"
                onChange={(field, value) => setNewEmail(value)}
                isDisabled={didSendNewEmailCode}
              />
              {!didSendNewEmailCode && (
                <Button colorScheme="brand" variant="solid" onClick={() => onSendVerficiationCode('new')} alignSelf="flex-start">
                  Send Verification Code
                </Button>
              )}
            </React.Fragment>
          )}

          {didSendNewEmailCode && (
            <React.Fragment>
              <InputGroup
                label={`We just sent you a temporary verification code to ${newEmail}`}
                type="input"
                value={newEmailCode}
                placeholder="4-digit code"
                field="newEmailCode"
                onChange={(field, value) => value.length < 5 && setNewEmailCode(value)}
                isDisabled={didVerifyNewEmail}
              />
              <Button
                colorScheme="brand"
                variant="solid"
                onClick={() => onVerifyCode('new')}
                alignSelf="flex-start"
                isDisabled={didVerifyNewEmail}>
                Change Email
              </Button>
            </React.Fragment>
          )}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeEmailModal;
