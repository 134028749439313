import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import CarouselSection from 'componentsCustom/CarouselSection';

const returnSectionAddButtonText = sectionType => {
  if (sectionType === 'images') return 'Add Image';
  if (sectionType === 'movies') return 'Add Movie';
  if (sectionType === 'tv') return 'Add TV Show';
  if (sectionType === 'books') return 'Add Book';
  return null;
};

const returnApiSearchPlaceholder = sectionType => {
  if (sectionType === 'movies') return 'Search Movies';
  if (sectionType === 'tv') return 'Search TV Shows';
  if (sectionType === 'books') return 'Search Books';
  return null;
};

const MediaCarousel = ({
  section,
  sectionIndex,
  onSectionAnswerChange,
  isEditMode,
  onAnswerSelect,
  onDragSectionAnswerComplete,
  sortableContainerID,
  sortableHandleClassName,
}) => {
  const [isDraggingMode, setIsDraggingMode] = useState(false);
  return (
    <CarouselSection
      section={section}
      isEditMode={isEditMode}
      sectionIndex={sectionIndex}
      onSectionAnswerChange={onSectionAnswerChange}
      onAnswerSelect={onAnswerSelect}
      onDragSectionAnswerComplete={onDragSectionAnswerComplete}
      sortableContainerID={sortableContainerID}
      sortableHandleClassName={sortableHandleClassName}
      isDraggingMode={isDraggingMode}
      setIsDraggingMode={setIsDraggingMode}
      addButtonText={returnSectionAddButtonText(section.section_type)}
      apiSearchPlaceholder={returnApiSearchPlaceholder(section.section_type)}
      isCaptionEditable
      imageAspectRatio={section.section_type === 'images' ? 'square' : 'poster'}
      isViaAPI={['movies', 'tv', 'books'].includes(section.section_type)}
    />
  );
};

export default React.memo(MediaCarousel, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
