const Topics = () => {
  return (
    <svg width="324" height="32" viewBox="0 0 324 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="12" width="77" height="8" rx="4" fill="#E0E6ED" />
      <rect x="81" y="12" width="58" height="8" rx="4" fill="#E0E6ED" />
      <rect x="143" y="12" width="89" height="8" rx="4" fill="#E0E6ED" />
      <rect x="236" y="12" width="64" height="8" rx="4" fill="#E0E6ED" />
      <rect y="24" width="64" height="8" rx="4" fill="#E0E6ED" />
      <rect x="68" y="24" width="39" height="8" rx="4" fill="#E0E6ED" />
      <rect x="111" y="24" width="89" height="8" rx="4" fill="#E0E6ED" />
      <rect width="51" height="8" rx="4" fill="#E0E6ED" />
      <rect x="55" width="71" height="8" rx="4" fill="#E0E6ED" />
      <rect x="130" width="58" height="8" rx="4" fill="#E0E6ED" />
      <rect x="192" width="77" height="8" rx="4" fill="#E0E6ED" />
      <rect x="273" width="51" height="8" rx="4" fill="#E0E6ED" />
    </svg>
  );
};

export default Topics;
