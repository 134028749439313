import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, returnQuery } from 'helpers/utils';
import { ButtonGroup } from '@chakra-ui/react';
import { getCurrent } from 'reduxx/current';
import PageHeader from 'componentsCustom/PageHeader';
import Button from 'components/Button';
import Flex from 'components/Flex';
import PlanTile from './PlanTile';
import Loading from 'components/Loading';

const PLANS = [
  {
    tier: 1,
    title: 'Starter',
    subtitle: 'For individuals and small teams',
    price: '$0',
    price_unit_label: '',
    price_subtitle: 'Free forever',
    unselected_button_text: 'Choose Plan',
    unselected_button_bg: 'white',
    unselected_button_border: `1px solid ${theme.colors.border}`,
    unselected_button_text_color: theme.colors.primaryText,
    features_pre_title: '',
    features: [
      'Individual or team account',
      'Up to 6 Specs within team',
      '1 template',
      '7 day team activity feed history',
      'Company directory of Specs',
      'Slack integration',
    ],
  },
  {
    tier: 2,
    title: 'Professional',
    subtitle: 'For teams',
    price: '$2',
    price_unit_label: 'per user / month',
    price_subtitle: 'Minimum $24 / month',
    unselected_button_text: 'Choose Plan',
    unselected_button_bg: theme.colors.accent,
    unselected_button_border: `1px solid ${theme.colors.accent}`,
    unselected_button_text_color: 'white',
    features_pre_title: 'Everything in Starter, plus',
    features: [
      'Unlimited Specs within team',
      'Unlimited templates',
      'Unlimited sections per Spec',
      'Unlimited activity feed history',
      'Unlimited section types',
      'Admin roles',
      'Company-wide analytics',
      'Auto-invite onboarding',
    ],
  },
  {
    tier: 3,
    title: 'Enterprise',
    subtitle: 'For teams',
    price: "Let's talk",
    price_unit_label: '',
    price_subtitle: '',
    unselected_button_text: 'Contact Us',
    unselected_button_bg: 'white',
    unselected_button_border: `1px solid ${theme.colors.primaryText}`,
    unselected_button_text_color: theme.colors.primaryText,
    features_pre_title: 'Everything in Pro, plus',
    features: ['SSO', 'HRIS integration', 'Custom contract', 'Concierge onboarding', 'Dedicated CSM'],
  },
];

const returnTierName = selectedTier => {
  switch (selectedTier) {
    case 1:
      return 'Starter';
    case 2:
      return 'Professional';
    case 3:
      return 'Enterprise';
  }
};

const returnSeatsUsedText = (selectedTier, numberOfSeatsUsed) => {
  switch (selectedTier) {
    case 1:
      return `${numberOfSeatsUsed}/6 Specs created`;
    case 2:
      let chargeAmount = numberOfSeatsUsed * 2;
      if (chargeAmount < 48) chargeAmount = 48;
      return `${numberOfSeatsUsed} Spec${numberOfSeatsUsed === 1 ? '' : 's'} created: $${chargeAmount}/month`;
    case 3:
      return `${numberOfSeatsUsed} Specs created`;
  }
};

class Billing extends React.Component {
  state = {
    billingInfo: {},
  };

  componentDidMount() {
    const parsed = returnQuery();
    if (parsed.session_id) {
      this.handleSuccessfulCheckoutSession(parsed.session_id);
    } else {
      this.getBillingInfo();
    }
  }

  getBillingInfo() {
    Api.get({ url: `/api/v1/admin/billings/get_billing_info` }).then(data => {
      if (data.success) {
        this.setState({ billingInfo: data.billing_info, didLoad: true });
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  onPlanSelect = selectedTier => {
    const currentTier = this.state.billingInfo.tier;
    switch (selectedTier) {
      case 1:
        this.createStripePortalSession();
        break;
      case 2:
        if (currentTier === 3) {
          this.createStripePortalSession();
        } else {
          this.createCheckoutSession(2);
        }

        break;
      case 3:
        // if (currentTier === 2) {
        //   this.createStripePortalSession();
        // } else {
        //   this.createCheckoutSession(3);
        // }
        window.open('https://welcome.spec.me/get-demo', '_blank');
        break;
    }
  };

  createCheckoutSession(selectedTier) {
    Api.get({ url: `/api/v1/admin/billings/get_stripe_checkout_session_url/${selectedTier}` }).then(data => {
      if (data.success) {
        window.location.href = data.session_url;
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  handleSuccessfulCheckoutSession(sessionID) {
    Api.post({
      url: `/api/v1/admin/billings/handle_successful_checkout_session`,
      body: { session_id: sessionID },
    }).then(data => {
      if (data.success) {
        this.setState({ billingInfo: data.billing_info, didLoad: true });
        this.props.dispatch(getCurrent(false));
      }
    });
  }

  createStripePortalSession = () => {
    Api.get({ url: `/api/v1/admin/billings/get_stripe_portal_session_url` }).then(data => {
      if (data.success) {
        window.location.href = data.session_url;
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  render() {
    const { billingInfo, didLoad } = this.state;
    if (!didLoad) return <Loading />;
    const selectedTier = billingInfo.tier;
    return (
      <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
        <PageHeader
          title={`Current Plan: ${returnTierName(selectedTier)}`}
          subtitle={returnSeatsUsedText(selectedTier, billingInfo.number_of_seats_used)}
          button={
            <ButtonGroup isAttached isDisabled={!billingInfo.is_stripe_customer}>
              <Button onClick={this.createStripePortalSession}>Billing History</Button>
              <Button onClick={this.createStripePortalSession}>Manage Plan</Button>
            </ButtonGroup>
          }
          direction={['column', 'column', 'row']}
          align={['flex-start', 'flex-start', 'center']}
          justify="space-between"
        />
        <Flex gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]} align="stretch" direction={['column', 'column', 'row']}>
          {PLANS.map(plan => {
            return <PlanTile key={plan.tier} plan={plan} selectedTier={selectedTier} onPlanSelect={this.onPlanSelect} />;
          })}
        </Flex>
      </Flex>
    );
  }
}

export default Billing;
