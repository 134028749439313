import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/overlay/popover
const PopoverComponent = ({ children, ...props }) => {
  return <Popover {...props}>{children}</Popover>;
};

PopoverComponent.PopoverTrigger = ({ children, ...props }) => {
  return <PopoverTrigger {...props}>{children}</PopoverTrigger>;
};

PopoverComponent.PopoverContent = ({ children, ...props }) => {
  return <PopoverContent {...props}>{children}</PopoverContent>;
};

PopoverComponent.PopoverBody = ({ children, ...props }) => {
  return <PopoverBody {...props}>{children}</PopoverBody>;
};

PopoverComponent.PopoverArrow = ({ ...props }) => {
  return <PopoverArrow {...props} />;
};

export default PopoverComponent;
