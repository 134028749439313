import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Stack, Text, Flex } from '@chakra-ui/react';
import Button from 'components/Button';

const EmptyState = ({
  title,
  subtitle,
  fadedText,
  illustration,
  icon,
  emoji,
  titleFontSize = theme.fontSizes.lg,
  titleFontColor = theme.colors.primaryText,
  titleFontFamily = theme.fonts.semiBold,
  subtitleFontSize = theme.fontSizes.base,
  subtitleFontColor = theme.colors.paragraphText,
  subtitleFontFamily = theme.fonts.light,
  bg = theme.colors.grayFill,
  border = `1px solid ${theme.colors.border}`,
  borderRadius = 'md',
  p = theme.spacing.lg,
  spacing = '2px',
  align = 'center',
  textAlign = 'center',
  buttonText,
  secondButtonText,
  buttonVariant = 'solid',
  secondButtonVariant = 'solid',
  buttonColorScheme = 'brand',
  secondButtonColorScheme = 'brand',
  onButtonClick,
  onSecondButtonClick,
  isButtonLoading,
  showButtons = true,
  ...props
}) => {
  return (
    <Stack {...props} bg={bg} border={border} borderRadius={borderRadius} p={p} spacing={spacing} align={align}>
      {illustration && <Flex mb="6px">{illustration}</Flex>}
      {icon && icon}
      {emoji && <Text fontSize="32px">{emoji}</Text>}
      {title && (
        <Text fontSize={titleFontSize} color={titleFontColor} fontFamily={titleFontFamily} align={textAlign}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text fontSize={subtitleFontSize} color={subtitleFontColor} fontFamily={subtitleFontFamily} align={textAlign}>
          {subtitle}
        </Text>
      )}
      {fadedText && (
        <Text color={theme.colors.placeholderText} fontSize={subtitleFontSize}>
          {fadedText}
        </Text>
      )}
      {(buttonText || secondButtonText) &&
        showButtons && (
          <Flex align="center" style={{ marginTop: 12 }} gap="16px">
            {buttonText && (
              <Button
                variant={buttonVariant}
                colorScheme={buttonColorScheme}
                onClick={onButtonClick}
                isLoading={isButtonLoading}
                isDisbaled={isButtonLoading}>
                {buttonText}
              </Button>
            )}
            {secondButtonText && (
              <Button variant={secondButtonVariant} colorScheme={secondButtonColorScheme} onClick={onSecondButtonClick}>
                {secondButtonText}
              </Button>
            )}
          </Flex>
        )}
    </Stack>
  );
};

export default EmptyState;
