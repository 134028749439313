import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { returnIfAnswerValid } from 'helpers/specUtils';
import TextEditor from 'components/TextEditor';
import HTMLText from 'components/HTMLText';
import SectionDirectoryLink from 'componentsCustom/SectionDirectoryLink';

const Styled = styled.div`
  font: 14px;
`;

const Freeform = ({ section, sectionIndex, onSectionAnswerChange, isEditMode }) => {
  return (
    <Styled>
      {section.answers.map((answer, answerIndex) => {
        if (isEditMode) {
          return (
            <TextEditor
              key={answer.front_id}
              placeholder="Type here..."
              allowHeaders
              isStandalone
              value={answer.node_answer}
              editorID={`editor-${sectionIndex}-${answerIndex}`}
              onHTMLChange={value => onSectionAnswerChange(sectionIndex, answerIndex, 'answer', value)}
              onNodeChange={value => onSectionAnswerChange(sectionIndex, answerIndex, 'node_answer', value)}
            />
          );
        }

        return <HTMLText key={answer.front_id} html={answer.answer} />;
      })}
      {!isEditMode &&
        !section.is_custom && (
          <SectionDirectoryLink
            label="See how others responded"
            path={`directory?query=true&display=${
              section.title
            }&query_type=spec_section_answer&query_field=spec_section_title&query_value=${section.title}`}
            pt="12px"
          />
        )}
    </Styled>
  );
};

export default React.memo(Freeform, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
