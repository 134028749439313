import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api } from 'helpers/utils';
import { Box } from '@chakra-ui/react';
import update from 'immutability-helper';
import SwitchRow from 'components/SwitchRow';
import InputGroup from 'components/InputGroup';
import Flex from 'components/Flex';

const InputWrap = styled(Box)`
  padding: 8px;
`;

class AutoInviteSettings extends React.Component {
  state = {
    inviteSettings: [],
  };

  componentDidMount() {
    this.getInviteSettings();
  }

  getInviteSettings() {
    Api.get({ url: `/api/v1/admin/settings/get_settings_by_category/invites` }).then(data => {
      if (data.success) {
        this.setState({ inviteSettings: data.settings });
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  onSettingChange = (settingKey, settingValue) => {
    const settingIndex = this.state.inviteSettings.findIndex(inviteSetting => inviteSetting.setting_key === settingKey);
    this.setState({ inviteSettings: update(this.state.inviteSettings, { [settingIndex]: { setting_value: { $set: settingValue } } }) });
    Api.post({
      url: `/api/v1/admin/settings/update_individual_setting`,
      body: { setting_key: settingKey, setting_value: settingValue },
    }).then(data => {
      if (!data.success) this.props.triggerToast({ title: data.error, status: 'error' });
    });
  };

  onInviteMessageChange = (settingValue, inviteSettingIndex) => {
    this.setState({
      inviteSettings: update(this.state.inviteSettings, { [inviteSettingIndex]: { setting_value: { $set: settingValue } } }),
    });
  };

  render() {
    const { inviteSettings } = this.state;
    const { currentCompany, specTemplatesData } = this.props;
    const isAutoInviteEnabled =
      inviteSettings.find(inviteSetting => inviteSetting.setting_key === 'should_auto_invite_new_hires')?.setting_value === 'on' &&
      currentCompany.is_slack_enabled;
    const newHireInviteMethod = inviteSettings.find(inviteSetting => inviteSetting.setting_key === 'new_hire_invite_method')?.setting_value;
    return (
      <React.Fragment>
        <Flex p={theme.padding.lg} borderBottom={theme.layout.border} align="stretch">
          <SwitchRow
            title="Automatically send invites to new hires"
            subtitle="Automate new hire onboarding to maximize adoption and engagement"
            isChecked={isAutoInviteEnabled}
            onChange={isTurnedOn => this.onSettingChange('should_auto_invite_new_hires', isTurnedOn ? 'on' : 'off')}
            isDisabled={!currentCompany.is_slack_enabled}
          />
        </Flex>

        <Flex flexWrap="wrap" p={theme.padding.lg} opacity={isAutoInviteEnabled ? '1.0' : '0.4'} m="-8px">
          {inviteSettings.map((inviteSetting, inviteSettingIndex) => {
            switch (inviteSetting.setting_key) {
              case 'new_hire_detection_method':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="100%">
                    <InputGroup
                      label="How should we detect new hires?"
                      type="select"
                      value={inviteSetting.setting_value}
                      field="new_hire_detection_method"
                      onChange={(field, value) => this.onSettingChange(field, value)}
                      isDisabled={!isAutoInviteEnabled}
                      selectOptions={[
                        {
                          label: 'When they join our Slack workspace',
                          value: 'joined_slack_workspace',
                          disabled: !currentCompany.is_slack_enabled,
                        },
                        {
                          label: 'When they get added to our HRIS system',
                          value: 'added_to_hris_system',
                          disabled: true,
                        },
                      ]}
                    />
                  </InputWrap>
                );
                break;
              case 'new_hire_invite_method':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="50%">
                    <InputGroup
                      label="Invite Method"
                      type="select"
                      value={inviteSetting.setting_value}
                      field="new_hire_invite_method"
                      onChange={(field, value) => this.onSettingChange(field, value)}
                      isDisabled={!isAutoInviteEnabled}
                      selectOptions={[
                        { label: 'Send invites via email', value: 'email' },
                        { label: 'Send invites via Slack DM', value: 'slack', disabled: !currentCompany.is_slack_enabled },
                      ]}
                    />
                  </InputWrap>
                );
                break;
              case 'new_hire_spec_template_id':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="50%">
                    <InputGroup
                      label="Spec Template"
                      type="select"
                      value={inviteSetting.setting_value}
                      field="new_hire_spec_template_id"
                      onChange={(field, value) => this.onSettingChange(field, value)}
                      isDisabled={!isAutoInviteEnabled}
                      selectOptions={specTemplatesData.map(template => {
                        return { label: template.title, value: template.id };
                      })}
                    />
                  </InputWrap>
                );
                break;
              case 'new_hire_invite_message':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="100%">
                    <InputGroup
                      label={newHireInviteMethod === 'slack' ? 'Invite Slack DM' : 'Invite Message'}
                      type="textarea"
                      placeholder="Invite message"
                      value={inviteSetting.setting_value}
                      field="new_hire_invite_message"
                      onBlur={() => this.onSettingChange('new_hire_invite_message', inviteSetting.setting_value)}
                      onChange={(field, value) => this.onInviteMessageChange(value, inviteSettingIndex)}
                      isDisabled={!isAutoInviteEnabled}
                    />
                  </InputWrap>
                );
                break;
              case 'send_new_hire_invites_after_x_days':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="50%">
                    <InputGroup
                      label="Send Date"
                      type="select"
                      value={inviteSetting.setting_value}
                      field="send_new_hire_invites_after_x_days"
                      onChange={(field, value) => this.onSettingChange(field, value)}
                      isDisabled={!isAutoInviteEnabled}
                      selectOptions={[
                        { label: 'The day they join', value: '0' },
                        { label: '1 day after joining our Slack', value: '1' },
                        { label: '2 days after joining our Slack', value: '2' },
                        { label: '3 days after joining our Slack', value: '3' },
                        { label: '4 days after joining our Slack', value: '4' },
                        { label: '5 days after joining our Slack', value: '5' },
                        { label: '6 days after joining our Slack', value: '6' },
                        { label: '1 week after joining our Slack', value: '7' },
                        { label: '10 days after joining our Slack', value: '10' },
                        { label: '2 weeks after joining our Slack', value: '14' },
                        { label: '3 weeks after joining our Slack', value: '21' },
                        { label: '1 month after joining our Slack', value: '30' },
                      ]}
                    />
                  </InputWrap>
                );
                break;
              case 'new_hire_spec_publish_due_date_after_x_days':
                return (
                  <InputWrap key={inviteSetting.setting_key} width="50%">
                    <InputGroup
                      label="Due Date"
                      type="select"
                      value={inviteSetting.setting_value}
                      field="new_hire_spec_publish_due_date_after_x_days"
                      onChange={(field, value) => this.onSettingChange(field, value)}
                      isDisabled={!isAutoInviteEnabled}
                      selectOptions={[
                        { label: 'No due date', value: 0 },
                        { label: '2 days after invites sent', value: 2 },
                        { label: '4 days after invites sent', value: 4 },
                        { label: '1 week after invites sent', value: 7 },
                        { label: '10 days after invites sent', value: 10 },
                        { label: '2 weeks after invites sent', value: 14 },
                        { label: '3 weeks after invites sent', value: 21 },
                        { label: '1 month after invites sent', value: 30 },
                      ]}
                    />
                  </InputWrap>
                );
                break;
              default:
                return null;
            }
          })}
        </Flex>
      </React.Fragment>
    );
  }
}

export default AutoInviteSettings;
