import React, { useEffect, useState, useRef, useCallback } from 'react';
import { render } from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Picker } from 'emoji-mart';
import data from '@emoji-mart/data';
import Modal from 'components/Modal';

const OpenButton = styled.button``;

const EmojiPicker = (props = {}) => {
  const ref = useRef();

  useEffect(() => {
    new Picker({ ...props, data, ref });
  }, []);

  return <div ref={ref} />;
};

const EmojiPickerComponent = ({ onEmojiSelect, children }) => {
  const [isEmojiPickerVisible, setEmojiPickerVisibility] = useState(false);
  return (
    <React.Fragment>
      <OpenButton onClick={() => setEmojiPickerVisibility(true)}>{children}</OpenButton>

      <Modal
        isOpen={isEmojiPickerVisible}
        onClose={() => setEmojiPickerVisibility(false)}
        size="xs"
        blockScrollOnMount={false}
        isCentered
        returnFocusOnClose={false}>
        <EmojiPicker
          onEmojiSelect={e => {
            onEmojiSelect(e.native);
            setEmojiPickerVisibility(false);
          }}
          data={data}
          theme="light"
        />
      </Modal>
    </React.Fragment>
  );
};

export default EmojiPickerComponent;
