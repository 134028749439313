import React from 'react';
import {
  RiSettings3Fill,
  RiMapPin2Fill,
  RiAddFill,
  RiCloseFill,
  RiLockFill,
  RiLockUnlockFill,
  RiEditFill,
  RiEyeFill,
  RiEyeLine,
  RiEyeOffLine,
  RiLayout2Line,
  RiMoreFill,
  RiEmotionLine,
  RiChat1Line,
  RiDeleteBinLine,
  RiFileCopyLine,
  RiLinksFill,
  RiSearchLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiAddCircleLine,
  RiEarthFill,
  RiArrowRightUpLine,
  RiExternalLinkLine,
  RiPlayFill,
  RiVideoAddFill,
  RiVideoUploadFill,
  RiAlignLeft,
  RiCheckFill,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiInformationFill,
  RiMailCheckLine,
  RiMailCheckFill,
  RiFilterLine,
  RiSlackFill,
  RiImageAddFill,
} from 'react-icons/ri';
import { FcGoogle } from 'react-icons/fc';
import { HiLightningBolt } from 'react-icons/hi';

export const SettingsIconFilled = props => <RiSettings3Fill {...props} />;

export const LocationIconFilled = props => <RiMapPin2Fill {...props} />;

export const PlusIcon = props => <RiAddFill {...props} />;

export const CloseIcon = props => <RiCloseFill {...props} />;

export const LockedIconFilled = props => <RiLockFill {...props} />;

export const UnlockedIconFilled = props => <RiLockUnlockFill {...props} />;

export const EditIconFilled = props => <RiEditFill {...props} />;

export const EyeIconFilled = props => <RiEyeFill {...props} />;

export const EyeIconOutline = props => <RiEyeLine {...props} />;

export const EyeOffIconOutline = props => <RiEyeOffLine {...props} />;

export const LayoutIconOutline = props => <RiLayout2Line {...props} />;

export const HorizontalDotsIcon = props => <RiMoreFill {...props} />;

export const SmileIconOutline = props => <RiEmotionLine {...props} />;

export const ChatIconOutline = props => <RiChat1Line {...props} />;

export const TrashIconOutline = props => <RiDeleteBinLine {...props} />;

export const CopyIconOutline = props => <RiFileCopyLine {...props} />;

export const LinkIcon = props => <RiLinksFill {...props} />;

export const SearchIcon = props => <RiSearchLine {...props} />;

export const ArrowLeftIcon = props => <RiArrowLeftLine {...props} />;

export const ArrowRightIcon = props => <RiArrowRightLine {...props} />;

export const AddCircleIconOutline = props => <RiAddCircleLine {...props} />;

export const EarthIconFilled = props => <RiEarthFill {...props} />;

export const RightArrowUpOutline = props => <RiArrowRightUpLine {...props} />;

export const ExternalLinkIconOutline = props => <RiExternalLinkLine {...props} />;

export const PlayIconFilled = props => <RiPlayFill {...props} />;

export const VideoRecordIconFilled = props => <RiVideoAddFill {...props} />;

export const VideoUploadIconFilled = props => <RiVideoUploadFill {...props} />;

export const LeftAlignIconOutline = props => <RiAlignLeft {...props} />;

export const CheckIcon = props => <RiCheckFill {...props} />;

export const GoogleIcon = props => <FcGoogle {...props} />;

export const ChevronDownIcon = props => <RiArrowDownSLine {...props} />;

export const ChevronUpIcon = props => <RiArrowUpSLine {...props} />;

export const InfoIconFilled = props => <RiInformationFill {...props} />;

export const MailIconOutline = props => <RiMailCheckLine {...props} />;

export const MailIconFilled = props => <RiMailCheckFill {...props} />;

export const FilterIconOutline = props => <RiFilterLine {...props} />;

export const LightningIconFilled = props => <HiLightningBolt {...props} />;

export const SlackIconFilled = props => <RiSlackFill {...props} />;

export const ImageAddIconFilled = props => <RiImageAddFill {...props} />;
