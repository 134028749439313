import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Text from 'components/Text';
import HTMLText from 'components/HTMLText';
import SectionDirectoryLink from 'componentsCustom/SectionDirectoryLink';

const LiveTile = ({ answer }) => {
  return (
    <Box>
      <Text fontFamily={theme.fonts.semiBold} color={theme.colors.primaryText}>
        {answer.option}
      </Text>
      <HTMLText html={answer.answer} />
      {!answer.is_custom && (
        <SectionDirectoryLink
          label="See how others responded"
          path={`directory?query=true&display=${
            answer.option
          }&query_type=spec_section_answer&query_field=spec_section_answer_option&query_value=${answer.option}`}
        />
      )}
    </Box>
  );
};

export default LiveTile;
