import React from 'react';
import { theme } from 'styles/theme';
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/feedback/alert
const AlertComponent = ({ title, subtitle, status = 'info', borderRadius = [0, 0, theme.radii.lg], ...props }) => {
  return (
    <Alert status={status} borderRadius={borderRadius} {...props}>
      <AlertIcon size={theme.fontSizes.base} />
      <AlertTitle fontSize={theme.fontSizes.base}>{title}</AlertTitle>
      <AlertDescription>{subtitle}</AlertDescription>
    </Alert>
  );
};

export default AlertComponent;
