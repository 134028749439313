import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Link } from 'react-router-dom';
import Flex from 'components/Flex';
import Button from 'components/Button';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  z-index: 1;
  margin-top: -23px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    overflow-x: auto;
    margin-top: -9px;
  }
  border-radius: 0 0 ${theme.radii.lg} ${theme.radii.lg};
  ${({ wereEditsMade }) =>
    wereEditsMade &&
    css`
      position: sticky;
      bottom: 0px;
    `};
`;

const SaveFooter = ({ wereEditsMade, isCreateMode, isSaveLoading, onSave, currentCompanySlug }) => {
  return (
    <StyledFlex justify="flex-end" wereEditsMade={wereEditsMade} gap="0px">
      {isCreateMode && (
        <Link to={`/org/${currentCompanySlug}/admin/templates`}>
          <Button as="div" padding={theme.padding.smButton} height="auto" variant="ghost">
            Cancel
          </Button>
        </Link>
      )}
      <Button
        variant="solid"
        colorScheme="brand"
        padding={theme.padding.smButton}
        height="auto"
        isDisabled={!wereEditsMade || isSaveLoading}
        isLoading={isSaveLoading}
        onClick={onSave}>
        {isCreateMode ? 'Create Template...' : 'Save Changes'}
      </Button>
    </StyledFlex>
  );
};

export default SaveFooter;
