import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { LinkBox, LinkOverlay, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import HTMLText from 'components/HTMLText';
import Button from 'components/Button';

const replaceMarkdownBrackets = (text, creator, receiver, currentUserSlug = null) => {
  if (!text || !creator) return '';
  if (!!receiver && currentUserSlug && currentUserSlug === receiver.slug) {
    text = text.replace('{{receiver_name_possessive}}', 'your');
    text = text.replace('{{receiver_name}}', 'you');
    text = text.replace('{{receiver_first_name_possessive}}', 'your');
    text = text.replace('{{receiver_first_name}}', 'you');
  }

  if (!!receiver && creator.slug === receiver.slug) {
    text = text.replace('{{receiver_name_possessive}}', 'their');
  }

  if (!receiver && creator.slug === currentUserSlug) {
    text = text.replace('their', 'your');
  }

  text = text
    .replace('{{receiver_name}}', receiver?.name)
    .replace('{{creator_name}}', creator?.name)
    .replace('{{receiver_first_name}}', receiver?.first_name)
    .replace('{{creator_first_name}}', creator?.first_name)
    .replace('{{receiver_name_possessive}}', `${receiver?.name}'s`)
    .replace('{{creator_name_possessive}}', `${creator?.name}'s`)
    .replace('{{receiver_first_name_possessive}}', `${receiver?.first_name}'s`)
    .replace('{{creator_first_name_possessive}}', `${creator?.first_name}'s`)
    .replace('{{receiver_slug}}', receiver?.slug)
    .replace('{{creator_slug}}', creator?.slug);
  return text;
};

const HoverStyle = styled.div`
  ${({ hasActionLink }) =>
    hasActionLink &&
    css`
      cursor: pointer;
      transition: background 0.5s ease;
      &:hover {
        background: ${theme.colors.grayBackground};
      }
    `};
  border-bottom: 1px solid ${theme.colors.border};
  ${({ isLastTile }) =>
    isLastTile &&
    css`
      border-bottom: 0px;
    `};
`;

const OuterLink = styled.a``;
const OuterDiv = styled.div``;

const StyledFlex = styled(Flex)``;

const CreatorName = styled.span`
  font-family: ${theme.fonts.semiBold};
  margin-right: 3px;
`;

const ActivityTile = ({ activity, isLastTile, currentUserSlug }) => {
  const hasActionLink = !!activity.action_link;
  const Outer = hasActionLink ? LinkOverlay : OuterDiv;
  let creatorName = activity.creator.name;
  if (activity.creator.slug === currentUserSlug) creatorName = 'You';
  return (
    <HoverStyle hasActionLink={hasActionLink} isLastTile={isLastTile}>
      <LinkBox>
        <Outer href={replaceMarkdownBrackets(activity.action_link, activity.creator, activity.receiver)}>
          <StyledFlex p={theme.padding.lg} gap="12px" align="flex-start">
            <ChakraLink to={`/${activity.creator.slug}`} as={Link}>
              <Avatar src={activity.creator.image} />
            </ChakraLink>
            <Flex direction="column" align="flex-start" justify="center" minHeight="48px">
              <Text fontFamily={theme.fonts.light}>
                <ChakraLink to={`/${activity.creator.slug}`} as={Link}>
                  <CreatorName>{creatorName}</CreatorName>
                </ChakraLink>
                <HTMLText
                  html={replaceMarkdownBrackets(activity.headline_html, activity.creator, activity.receiver, currentUserSlug)}
                  shouldDisplayInline
                />
              </Text>
              <Text fontFamily={theme.fonts.light} color={theme.colors.lightAccentText}>
                {activity.creator.job_title && `${activity.creator.job_title} – `}
                {moment(activity.created_at).fromNow()}
              </Text>
              {activity.body_html && (
                <Text
                  bg={theme.colors.grayFill}
                  p={`${theme.padding.sm} ${theme.padding.md}`}
                  borderRadius={`0 ${theme.radii.md} ${theme.radii.md} ${theme.radii.md}`}
                  mt="6px">
                  <HTMLText html={replaceMarkdownBrackets(activity.body_html, activity.creator, activity.receiver)} />
                </Text>
              )}
              {!activity.body_html &&
                activity.action_text &&
                hasActionLink && (
                  <Button mt="6px">{replaceMarkdownBrackets(activity.action_text, activity.creator, activity.receiver)}</Button>
                )}
            </Flex>
          </StyledFlex>
        </Outer>
      </LinkBox>
    </HoverStyle>
  );
};

export default ActivityTile;
