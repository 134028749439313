import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';

const Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  cursor: pointer;
`;

const HiddenBackdrop = ({ onClick }) => {
  return <Styled onClick={onClick} />;
};

export default HiddenBackdrop;
