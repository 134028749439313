import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, disabledOverlay } from 'styles/theme';
import { Api } from 'helpers/utils';
import { Stack, StackDivider, Box, Tooltip } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import Flex from 'components/Flex';
import PageHeader from 'componentsCustom/PageHeader';
import ConnectSlackButton from 'componentsCustom/ConnectSlackButton';
import ConnectionManager from './ConnectionManager';
import Loading from 'components/Loading';
import SwitchRow from 'components/SwitchRow';
import Button from 'components/Button';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';
import Tag from 'components/Tag';

const Styled = styled.div``;

const Slack = ({ triggerToast, currentCompanySlug, companyTier }) => {
  const [slackWorkspace, setSlackWorkspace] = useState(null);
  const [slackSettings, setSlackSettings] = useState(null);
  const [isSlackEnabled, setIsSlackEnabled] = useState(false);
  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    getSlackWorkspace();
  }, []);

  const getSlackWorkspace = () => {
    Api.get({ url: `/api/v1/admin/slack_workspaces/get_slack_workspace` }).then(data => {
      if (data.success) {
        setSlackWorkspace(data.slack_workspace);
        setSlackSettings(data.slack_settings);
        setIsSlackEnabled(data.is_slack_enabled);
        setDidLoad(true);
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  const onDisconnectSlackWorkspace = () => {
    const didConfirm = window.confirm('Are you sure you want to disconnect your Slack workspace? This action cannot be undone.');
    if (didConfirm) {
      setIsDisconnectLoading(true);
      Api.delete({ url: `/api/v1/admin/slack_workspaces/disconnect_slack_workspace` }).then(data => {
        if (data.success) {
          window.location.reload();
        } else {
          setIsDisconnectLoading(false);
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    }
  };

  const onSettingChange = (settingKey, settingValue) => {
    const settingIndex = slackSettings.findIndex(slackSetting => slackSetting.setting_key === settingKey);
    setSlackSettings(update(slackSettings, { [settingIndex]: { setting_value: { $set: settingValue } } }));
    Api.post({
      url: `/api/v1/admin/settings/update_individual_setting`,
      body: { setting_key: settingKey, setting_value: settingValue },
    }).then(data => {
      if (!data.success) triggerToast({ title: data.error, status: 'error' });
    });
  };

  if (!didLoad) return <Loading />;
  // const isFeatureGated = companyTier === 1;
  const isFeatureGated = false;
  return (
    <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
      {isFeatureGated && (
        <UpgradeCTA
          title="Upgrade to integrate Slack"
          subtitle="Once upgraded, you can sync your Slack and access the features below"
          ctaType="pageHeader"
        />
      )}

      {isSlackEnabled ? (
        <ConnectionManager
          slackWorkspace={slackWorkspace}
          onDisconnectSlackWorkspace={onDisconnectSlackWorkspace}
          isDisconnectLoading={isDisconnectLoading}
          isDisabled={isFeatureGated}
        />
      ) : (
        <PageHeader
          title="Add to Slack"
          subtitle="Bring your Spec directory into Slack, automatically invite new hires, remove deactivated members, autofill profiles, send invites by Slack channel, and more."
          button={<ConnectSlackButton currentCompanySlug={currentCompanySlug} />}
          isDisabled={isFeatureGated}
        />
      )}
      <Tooltip label={!isSlackEnabled && 'These settings can only be turned on once you add to Slack above'} placement="top-end">
        <Box css={isFeatureGated && disabledOverlay}>
          <Stack spacing="16px" align="start" divider={<StackDivider borderColor={theme.colors.border} />} css={containerStyle}>
            {slackSettings.map(slackSetting => {
              let title = slackSetting.title;
              let isDisabled = false;
              if (slackSetting.setting_key === 'should_add_directory_slack_workspace') {
                title = (
                  <>
                    {title} <Tag label="Coming Soon" size="sm" variant="subtle" textTransform="capitalize" />
                  </>
                );
                isDisabled = true;
              }
              return (
                <React.Fragment>
                  <SwitchRow
                    key={slackSetting.setting_key}
                    title={title}
                    subtitle={slackSetting.subtitle}
                    isChecked={slackSetting.setting_value === 'on'}
                    onChange={isTurnedOn => onSettingChange(slackSetting.setting_key, isTurnedOn ? 'on' : 'off')}
                    showButton={slackSetting.setting_key === 'should_send_invites_to_new_slack_members' ? false : true}
                    isDisabled={isDisabled || !isSlackEnabled}
                  />
                  {slackSetting.setting_key === 'should_send_invites_to_new_slack_members' && (
                    <Link to={`/org/${currentCompanySlug}/admin/invites?tabIndex=1`}>
                      <Button mt="8px" rightIcon={<HiExternalLink />} as="div">
                        Auto Invite Settings
                      </Button>
                    </Link>
                  )}
                </React.Fragment>
              );
            })}
          </Stack>
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default Slack;
