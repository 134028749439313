import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { cleanHTML } from 'helpers/utils';
import { returnUserListLinks } from 'helpers/specUtils';
import { Box } from '@chakra-ui/react';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import Popover from 'components/Popover';

const ReactionsList = ({ reactions, onSelectReaction, isSignedIn, currentUserSlug, sectionIndex, isCommentReaction }) => {
  let height = null;
  let wrapperPadding = '4px';
  let iconPadding = '8px';
  if (isCommentReaction) {
    height = 'auto';
    wrapperPadding = '0px';
    iconPadding = '5px 8px';
  }

  return (
    <React.Fragment>
      {reactions.map(reaction => {
        const isSelected = isSignedIn && reaction.users.includes(currentUserSlug);
        return (
          <Box key={reaction.emoji} p={wrapperPadding}>
            <Popover isLazy trigger="hover" placement="top">
              <Popover.PopoverTrigger>
                <IconButton
                  variant={isSelected ? 'solid' : 'outline'}
                  colorScheme={isSelected ? 'blue' : 'gray'}
                  isRound
                  size="sm"
                  width="auto"
                  height={height}
                  padding={iconPadding}
                  onClick={() => onSelectReaction(sectionIndex, reaction.emoji)}
                  icon={
                    <Text fontSize="12px" color={isSelected ? 'white' : theme.colors.paragraphText}>
                      {reaction.emoji} {reaction.count}
                    </Text>
                  }
                />
              </Popover.PopoverTrigger>
              <Popover.PopoverContent width="auto" maxWidth="350px">
                <Popover.PopoverArrow />
                <Popover.PopoverBody>
                  <Text>
                    <span dangerouslySetInnerHTML={{ __html: cleanHTML(returnUserListLinks({ userSlugs: reaction.users })) }} /> reacted
                    with {reaction.emoji}
                  </Text>
                </Popover.PopoverBody>
              </Popover.PopoverContent>
            </Popover>
          </Box>
        );
      })}
    </React.Fragment>
  );
};

export default React.memo(ReactionsList);
