import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, columnLayoutStyle } from 'styles/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Api, returnQuery } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { SpecSearchIllustration } from 'assets/illustrations/all';
import Text from 'components/Text';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Loading from 'components/Loading';
import TermsFooter from 'componentsCustom/TermsFooter';
import HTMLText from 'components/HTMLText';
import InfoGroup from 'components/InfoGroup';
import EmptyState from 'components/EmptyState';

const StyledFlexContainer = styled(Flex)`
  ${containerStyle};
  ${columnLayoutStyle};
  width: 450px;
  padding: 0px;
  min-height: 400px;
`;

const SpecTemplateLanding = ({ isSignedIn, currentUser, currentCompany }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({});
  const [didLoad, setDidLoad] = useState(false);
  const [wasNotFound, setWasNotFound] = useState(false);

  useEffect(() => {
    Api.get({ url: `/api/v1/specs/get_template/${params.slug}` }).then(data => {
      if (data.success) {
        setTemplate(data.spec_template);
        setDidLoad(true);
      } else {
        setWasNotFound(true);
        setDidLoad(true);
      }
    });
  }, []);

  const onCreateClick = () => {
    if (isSignedIn && currentUser.has_spec) {
      navigate(`/${currentUser.slug}`);
    } else {
      const parsed = returnQuery();
      if (parsed.email) {
        navigate(`/org/${currentCompany.slug}/auth?template=${params.slug}&email=${parsed.email}`);
      } else {
        navigate(`/org/${currentCompany.slug}/auth?template=${params.slug}`);
      }
    }
  };

  if (!didLoad) return <Loading />;

  if (wasNotFound) {
    return (
      <StyledFlexContainer direction="column" align="center" justify="center">
        <EmptyState
          illustration={<SpecSearchIllustration />}
          title="This template doesn't exist"
          subtitle="Check to make sure you have the correct URL"
          bg="white"
          border="none"
        />
      </StyledFlexContainer>
    );
  }
  return (
    <React.Fragment>
      <StyledFlexContainer direction="column" align="stretch">
        <Flex direction="column" align="stretch" gap="16px" p={theme.padding.lg}>
          <Flex direction="column" align="flex-start">
            <Text fontSize={theme.fontSizes.headerOne} fontFamily={theme.fonts.bold} color={theme.colors.primaryText}>
              You're invited to create your Spec
            </Text>
            <Text>
              Outline your working style, preferences, quirks, and everything else about how you operate as a teammate or leader – all in
              your Spec.
            </Text>
          </Flex>
          <Button variant="solid" colorScheme="brand" onClick={onCreateClick}>
            Create My Spec
          </Button>
          {!isSignedIn && (
            <Link to={`/org/${currentCompany.slug}/auth/login?redirect=${window.location.href}`}>
              <Button variant="link" colorScheme="brand" onClick={onCreateClick} as="div">
                Been here before? Login
              </Button>
            </Link>
          )}
        </Flex>

        {template.html_landing_page_message && (
          <Flex p={theme.padding.lg} borderTop={theme.layout.border}>
            <HTMLText html={template.html_landing_page_message} />
          </Flex>
        )}

        {(template.creator || (isSignedIn && currentUser.is_admin)) && (
          <Flex align="center" justify="space-between" p={theme.padding.lg} borderTop={theme.layout.border}>
            {template.creator && (
              <ChakraLink as={Link} to={`/${template.creator.slug}`}>
                <InfoGroup
                  avatarSrc={template.creator.image}
                  title={template.creator.name}
                  subtitle={template.creator.job_title}
                  noSpaceBetween={false}
                />
              </ChakraLink>
            )}
            {isSignedIn &&
              currentUser.is_admin && (
                <Link to={`/org/${currentCompany.slug}/admin/templates/${params.slug}`}>
                  <Button variant="outline" as="div">
                    Edit
                  </Button>
                </Link>
              )}
          </Flex>
        )}
      </StyledFlexContainer>
      <TermsFooter />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(SpecTemplateLanding);
