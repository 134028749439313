import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, multiLineText } from 'styles/theme';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from 'helpers/utils';
import { ArrowLeftIcon } from 'assets/icons';
import moment from 'moment';
import Tabs from 'components/Tabs';
import Section from 'components/Section';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import RecipientsList from './RecipientsList';
import Alert from 'components/Alert';

const InviteBatchDetails = ({ triggerToast, currentCompanySlug }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [inviteBatch, setInviteBatch] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [recipientsPageNumber, setRecipientsPageNumber] = useState(1);
  const [numberOfTotalRecipients, setNumberOfTotalRecipients] = useState(0);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    getInviteBatch();
    getInviteBatchRecipients(1);
  }, []);

  const getInviteBatch = () => {
    Api.get({ url: `/api/v1/admin/invites/get_invite_batch/${params.id}` }).then(data => {
      if (data.success) {
        setInviteBatch(data.invite_batch);
        setDidLoad(true);
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  const getInviteBatchRecipients = pageNumber => {
    setRecipientsPageNumber(pageNumber);
    Api.get({ url: `/api/v1/admin/invites/get_invite_batch_recipients/${params.id}/${pageNumber}` }).then(data => {
      if (data.success) {
        setRecipients([].concat(data.invite_batch_recipients));
        setNumberOfTotalRecipients(data.number_of_total_recipients);
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  if (!didLoad) return null;
  const inviteMethod = inviteBatch.invite_method;
  return (
    <Flex direction="column" align="stretch" gap={theme.padding.lg}>
      <Section>
        <Section.Body>
          <Button
            variant="link"
            colorScheme="brand"
            leftIcon={<ArrowLeftIcon />}
            onClick={() => navigate(`/org/${currentCompanySlug}/admin/invites`)}>
            All Invites
          </Button>
        </Section.Body>
      </Section>
      <Section>
        <Tabs tabs={['Details', 'Users']}>
          <Tabs.TabPanels>
            <Tabs.TabPanel padding={theme.padding.lg}>
              <Flex flexWrap="wrap">
                <InfoGroup
                  title="Invite Method"
                  subtitle={inviteMethod === 'slack' ? 'Via Slack DM' : 'Via Email'}
                  width="50%"
                  mb={theme.margin.md}
                />
                <InfoGroup title="Spec Template" subtitle={inviteBatch.spec_template_title} width="50%" mb={theme.margin.md} />
                <InfoGroup
                  title="Send Date"
                  subtitle={moment(inviteBatch.should_send_at).format('dddd, MMMM Do, h:mm a')}
                  width="50%"
                  mb={theme.margin.md}
                />
                <InfoGroup
                  title="Due Date"
                  subtitle={
                    inviteBatch.spec_publish_due_date ? moment(inviteBatch.spec_publish_due_date).format('dddd, MMMM Do') : 'No due date'
                  }
                  width="50%"
                  mb={theme.margin.md}
                />
                <InfoGroup
                  title={inviteMethod === 'slack' ? 'Invite Slack DM' : 'Invite Email'}
                  subtitle={inviteBatch.invite_message}
                  width="100%"
                  mb={theme.margin.md}
                  subtitleCss={multiLineText}
                />
                <InfoGroup title="Creator" subtitle={inviteBatch.creator.name} width="100%" mb={theme.margin.md} />
                <Alert title="If an invite hasn't been accepted after 7 days, we'll send them a reminder" status="info" />
              </Flex>
            </Tabs.TabPanel>

            <Tabs.TabPanel>
              <RecipientsList
                recipients={recipients}
                numberOfTotalRecipients={numberOfTotalRecipients}
                getInviteBatchRecipients={getInviteBatchRecipients}
              />
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs>
      </Section>
    </Flex>
  );
};

export default InviteBatchDetails;
