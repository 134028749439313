import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, columnLayoutStyle, containerStyle } from 'styles/theme';
import { withParams } from 'hooks/router';
import { Api } from 'helpers/utils';
import { createStandaloneToast } from '@chakra-ui/toast';
import update from 'immutability-helper';
import NotificationSettings from 'containers/UserAccountModal/NotificationSettings';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import TermsFooter from 'componentsCustom/TermsFooter';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const Styled = styled.div`
  ${containerStyle};
  ${columnLayoutStyle};
`;

class NotificationsHub extends React.Component {
  state = {
    notificationSettings: [],
  };

  componentDidMount() {
    this.getNotificationsSettings();
  }

  getNotificationsSettings() {
    const { params } = this.props;
    Api.get({
      url: `/api/v1/notifications/${params.notifications_token}/get_notifications_settings`,
    }).then(data => {
      if (data.success) {
        this.setState({ notificationSettings: data.notification_settings, didLoad: true });
      } else {
      }
    });
  }

  onSettingsChange = (settingKey, settingValue) => {
    const existingIndex = this.state.notificationSettings.findIndex(notificationSetting => notificationSetting.setting_key === settingKey);
    this.setState({
      notificationSettings: update(this.state.notificationSettings, { [existingIndex]: { setting_value: { $set: settingValue } } }),
    });
  };

  onSave = () => {
    const { notificationSettings } = this.state;
    const { params } = this.props;
    this.setState({ isSaving: true });
    Api.post({
      url: `/api/v1/notifications/${params.notifications_token}/update_notifications_settings`,
      body: { notification_settings: notificationSettings },
    }).then(data => {
      if (data.success) {
        this.setState({ isSaving: false });
        toast({
          title: 'Your notification settings have been updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  render() {
    const { notificationSettings, isSaving, didLoad } = this.state;
    if (!didLoad) return null;
    return (
      <>
        <Styled>
          <ToastContainer />
          <Flex mb="16px" pb="16px" borderBottom={theme.layout.border}>
            <InfoGroup title="Edit Notification Preferences" />
          </Flex>
          <NotificationSettings notificationSettings={notificationSettings} onSettingsChange={this.onSettingsChange} />
          <Flex mt="16px" pt="16px" borderTop={theme.layout.border} justify="flex-end">
            <Button variant="solid" colorScheme="brand" isLoading={isSaving} isDisabled={isSaving} onClick={this.onSave}>
              Save
            </Button>
          </Flex>
        </Styled>
        <TermsFooter showLogo={false} />
      </>
    );
  }
}

export default withParams(NotificationsHub);
