import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Api, copyToClipboard } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { PlusIcon } from 'assets/icons';
import { Link as ChakraLink, Tooltip } from '@chakra-ui/react';
import PageHeader from 'componentsCustom/PageHeader';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';

const SpecTemplates = ({ triggerToast, onCopyShareLink, isFeatureGated }) => {
  const [templates, setTemplates] = useState([]);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    Api.get({ url: `/api/v1/admin/spec_templates/get_templates` }).then(data => {
      if (data.success) {
        setTemplates(data.spec_templates);
        setDidLoad(true);
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  }, []);

  if (!didLoad) return <Loading />;
  return (
    <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
      <PageHeader
        title="Spec Templates"
        subtitle="Employees who create their Spec through general signup will use the primary template below. If you want specific roles or teams to use different templates they’ll have to be invited directly."
        button={
          <Tooltip label={isFeatureGated && 'The free plan is limited to 1 template'} placement="top">
            <Link to={isFeatureGated ? '' : 'create'}>
              <Button
                variant="solid"
                colorScheme="brand"
                leftIcon={<PlusIcon />}
                padding={theme.padding.smButton}
                height="auto"
                as="div"
                isDisabled={isFeatureGated}>
                Create New
              </Button>
            </Link>
          </Tooltip>
        }
      />
      <TemplateGroup
        title="Primary Template"
        templates={templates.filter(template => template.is_primary_template)}
        onCopyShareLink={onCopyShareLink}
      />
      <TemplateGroup
        title="Other Templates"
        templates={templates.filter(template => !template.is_primary_template)}
        onCopyShareLink={onCopyShareLink}
      />
      {isFeatureGated && (
        <UpgradeCTA
          title="Your plan is limited to 1 template"
          subtitle="Upgrade to Pro to create as many templates as you'd like for different roles or departments"
          ctaType="pageHeader"
        />
      )}
    </Flex>
  );
};

const TemplateGroup = ({ title, templates, onCopyShareLink }) => {
  return (
    <Flex direction="column" align="stretch" gap="5px">
      <InfoGroup title={title} className="desktop" />
      <Flex direction="column" align="stretch" gap={['8px', '8px', '16px']}>
        {templates.length === 0 && <EmptyState fadedText="No other templates" width="100%" />}
        {templates.map(template => {
          return <TemplateTile key={template.slug} template={template} onCopyShareLink={onCopyShareLink} />;
        })}
      </Flex>
    </Flex>
  );
};

const StyledTemplateTile = styled(Flex)`
  ${containerStyle};
`;

const TemplateTile = ({ template, onCopyShareLink }) => {
  return (
    <StyledTemplateTile justify="space-between">
      <ChakraLink as={Link} to={template.slug} flexGrow={1}>
        <InfoGroup
          title={template.title}
          subtitle={`${template.number_of_spec_sections} section${template.number_of_spec_sections === 1 ? '' : 's'} – ${
            template.number_of_specs_created
          } Spec${template.number_of_specs_created === 1 ? '' : 's'}`}
        />
      </ChakraLink>
      <Flex gap="16px">
        <Button
          variant="link"
          onClick={() => onCopyShareLink(`https://spec.me/org/${template.company_slug}/template/${template.slug}`)}
          className="desktop">
          Copy Invite Link
        </Button>
        <Link to={template.slug}>
          <Button variant="link" color={theme.colors.accent} as="div">
            Edit Template
          </Button>
        </Link>
      </Flex>
    </StyledTemplateTile>
  );
};

export default SpecTemplates;
