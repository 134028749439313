import React from 'react';
import { theme } from 'styles/theme';
import { Checkbox } from '@chakra-ui/react';

const CheckboxComponent = ({ isChecked, onChange, size = 'lg', colorScheme = 'brand', readOnly = false, ...props }) => {
  return (
    <Checkbox
      {...props}
      isChecked={isChecked}
      onChange={e => !readOnly && onChange(e.target.checked)}
      size={size}
      colorScheme={colorScheme}
    />
  );
};

export default CheckboxComponent;
