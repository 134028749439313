import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import { HiHome, HiTemplate, HiChartBar, HiUsers, HiMail, HiCog } from 'react-icons/hi';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';

const PRIMARY_ROUTES = [
  {
    title: 'Edit Templates',
    subtitle: 'Edit the default template, create new role or department specific templates, and send invites',
    path: 'templates',
    icon: <HiTemplate size={18} style={{ flexShrink: 0 }} />,
  },
  {
    title: 'View Analytics',
    subtitle: 'Keep track of Spec activity over time, see who the most engaged users are, and more',
    path: 'analytics',
    icon: <HiChartBar size={18} style={{ flexShrink: 0 }} />,
  },
  {
    title: 'Manage Users',
    subtitle: 'View all users, see who has published their Spec, deactivate users, select new admins, and more',
    path: 'users',
    icon: <HiUsers size={18} style={{ flexShrink: 0 }} />,
  },
  {
    title: 'Send Invites',
    subtitle: 'Send new invites via email or Slack, track Specs created, and choose auto-invite settings to maximize adoption',
    path: 'invites',
    icon: <HiMail size={18} style={{ flexShrink: 0 }} />,
  },
];

const SETTINGS_ROUTES = [
  { title: 'Company Info', subtitle: "Edit your company's name, logo, signup domain, and directory URL", path: 'settings' },
  { title: 'Slack Integration', subtitle: 'Bring your Spec directory into Slack and automate user management', path: 'settings/slack' },
  {
    title: 'HRIS',
    subtitle: 'Choose your HRIS provider, send invites based on department, and autofill profile info',
    path: 'settings/hris',
  },
  {
    title: 'SSO',
    subtitle: 'Choose your HRIS provider, send invites based on department, and autofill profile info',
    path: 'settings/sso',
    should_hide: true,
  },
  { title: 'Admins', subtitle: "Manage who can access the company's admin dashboard", path: 'settings/admins' },
  { title: 'Billing', subtitle: 'Change subscription plans and view billing history', path: 'settings/billing' },
];

const Styled = styled.div`
  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: ${theme.padding.lg};
  }
`;

const Overview = ({ currentUserFirstName }) => {
  return (
    <Styled>
      <InfoGroup title={`Hey ${currentUserFirstName}!`} subtitle="Here are some actions you can take" titleFontSize={theme.fontSizes.lg} />
      <RoutesCluster routes={PRIMARY_ROUTES} />
      <InfoGroup title="Settings" />
      <RoutesCluster routes={SETTINGS_ROUTES} />
    </Styled>
  );
};

const RoutesCluster = ({ routes }) => {
  return (
    <Flex flexWrap="wrap" gap="16px" m="8px 0 28px 0" align="flex-start">
      {routes.map(route => {
        if (route.should_hide) return null;
        return (
          <LinkBox key={route.title} width="calc(50% - 8px)">
            <LinkOverlay to={route.path} as={Link}>
              <Button variant="outline" height="auto" as="div" padding="16px" whiteSpace="normal" bg="white">
                <Flex gap="8px" align="flex-start">
                  {route.icon}
                  <InfoGroup title={route.title} subtitle={route.subtitle} />
                </Flex>
              </Button>
            </LinkOverlay>
          </LinkBox>
        );
      })}
    </Flex>
  );
};

export default Overview;
