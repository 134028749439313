import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Tooltip } from '@chakra-ui/react';
import { isLoginEmailValid } from 'helpers/authUtils';
import { Api } from 'helpers/utils';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Button from 'components/Button';
import InputGroup from 'components/InputGroup';
import Flex from 'components/Flex';

class NewAdminModal extends React.Component {
  state = {
    newAdminEmail: '',
    newAdminType: 'owner',
  };

  onNewAdminChange = (field, value) => {
    this.setState({ [field]: value });
  };

  returnValidation() {
    const { newAdminEmail, newAdminType } = this.state;
    const { companyDomain } = this.props;
    const emailValidation = isLoginEmailValid(newAdminEmail, companyDomain, true);
    if (!emailValidation.success) return emailValidation;
    return { success: true };
  }

  onSendAdminInvite = () => {
    const { newAdminEmail, newAdminType } = this.state;
    const { triggerToast, onCreateNewAdminCallback } = this.props;
    const validation = this.returnValidation();
    if (validation.success) {
      this.setState({ isSending: true });
      Api.post({
        url: `/api/v1/admin/admins/invite_admin`,
        body: {
          admin_email: newAdminEmail,
          admin_type: newAdminType,
        },
      }).then(data => {
        if (data.success) {
          triggerToast({ title: 'Invite has been sent' });
          onCreateNewAdminCallback();
        } else {
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    } else {
      triggerToast({ title: validation.notice, status: 'error' });
    }
  };

  render() {
    const { newAdminEmail, newAdminType, isSending } = this.state;
    const { isOpen, onClose, companyDomain } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Section>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.onSendAdminInvite();
            }}>
            <Section.Body>
              <Flex gap="16px" align="flex-end">
                <InputGroup
                  label="Admin Invite"
                  type="input"
                  value={newAdminEmail}
                  field="newAdminEmail"
                  placeholder={companyDomain ? `name@${companyDomain}` : 'name@website.com'}
                  onChange={this.onNewAdminChange}
                />
                <Tooltip
                  label="Note: The only difference between 'admins' and 'owners' is that owners can invite and remove other admins"
                  placement="top"
                  hasArrow>
                  <div style={{ width: 150 }}>
                    <InputGroup
                      type="select"
                      value={newAdminType}
                      field="newAdminType"
                      onChange={this.onNewAdminChange}
                      selectOptions={[{ label: 'Admin', value: 'admin' }, { label: 'Owner', value: 'owner' }]}
                    />
                  </div>
                </Tooltip>
              </Flex>
            </Section.Body>
            <Section.Footer>
              <Button variant="solid" colorScheme="brand" onClick={this.onSendAdminInvite} isDisabled={isSending} isLoading={isSending}>
                Send Invite
              </Button>
            </Section.Footer>
          </form>
        </Section>
      </Modal>
    );
  }
}

export default NewAdminModal;
