import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import CompanyInfo from './CompanyInfo';
import Slack from './Slack';
import HRIS from './HRIS';
import Billing from './Billing';
import Admins from './Admins';

const Settings = ({ triggerToast, currentCompany, currentCompanySlug, currentUser, dispatch }) => {
  const companyTier = currentCompany.tier;
  return (
    <Routes>
      <Route path="/" element={<CompanyInfo triggerToast={triggerToast} dispatch={dispatch} />} />
      <Route
        path="/slack"
        element={<Slack triggerToast={triggerToast} currentCompanySlug={currentCompanySlug} companyTier={companyTier} />}
      />
      <Route
        path="/hris"
        element={<HRIS triggerToast={triggerToast} currentCompanySlug={currentCompanySlug} companyTier={companyTier} />}
      />
      <Route path="/billing" element={<Billing triggerToast={triggerToast} dispatch={dispatch} />} />
      <Route
        path="/admins"
        element={
          <Admins
            triggerToast={triggerToast}
            currentUserAdminType={currentUser.admin_type}
            dispatch={dispatch}
            companyDomain={currentCompany.domain}
            companyTier={companyTier}
          />
        }
      />
    </Routes>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(Settings);
