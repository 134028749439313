import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, multiLineText } from 'styles/theme';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import Text from 'components/Text';

// https://chakra-ui.com/docs/components/disclosure/accordion
const AccordionComponent = ({ children, ...props }) => {
  return (
    <Accordion allowMultiple allowToggle {...props}>
      {children}
    </Accordion>
  );
};

AccordionComponent.Item = ({ children, ...props }) => {
  return <AccordionItem {...props}>{children}</AccordionItem>;
};

AccordionComponent.Button = ({ text, ...props }) => {
  return (
    <AccordionButton padding="10px 12px" justifyContent="space-between" _hover={{ background: theme.colors.grayFill }} {...props}>
      <Box flex="1" textAlign="left" fontFamily={theme.fonts.medium} fontSize={theme.fontSizes.base}>
        {text}
      </Box>
      <AccordionIcon color={theme.colors.paragraphText} size={theme.fontSizes.base} />
    </AccordionButton>
  );
};

AccordionComponent.Panel = ({ children, ...props }) => {
  return (
    <AccordionPanel padding="10px 12px 12px 12px" {...props}>
      <Text fontFamily={theme.fonts.light} color={theme.colors.paragraphText} css={multiLineText}>
        {children}
      </Text>
    </AccordionPanel>
  );
};

export default AccordionComponent;
