import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import SectionTitle from './SectionTitle';
import DragHandle from 'components/DragHandle';

const StyledContainer = styled.div`
  ${containerStyle};
  .section-drag-handle {
    opacity: 0;
    transition: opacity 0.35s ease;
  }
  &:hover {
    .section-drag-handle {
      opacity: 1;
    }
  }
  ${({ isSectionHidden }) =>
    isSectionHidden &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};
`;

const AbsDragHandle = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const SpecSectionContainer = ({ id, children, isEditMode, isDraggingMode, isSectionHidden }) => {
  return (
    <StyledContainer id={id} isSectionHidden={isSectionHidden}>
      {children}
      {isEditMode &&
        !isDraggingMode && (
          <AbsDragHandle>
            <DragHandle noOfDots={6} className="section-drag-handle" isTooltipDisabled />
          </AbsDragHandle>
        )}
    </StyledContainer>
  );
};

SpecSectionContainer.Header = ({
  title,
  subtitle,
  isSubtitleEnabled,
  onSectionChange,
  sectionIndex,
  isEditMode,
  isDraggingMode,
  isSectionHidden,
}) => {
  return (
    <SectionTitle
      title={title}
      subtitle={subtitle}
      isSubtitleEnabled={isSubtitleEnabled}
      onSectionChange={onSectionChange}
      sectionIndex={sectionIndex}
      isEditMode={isEditMode}
      isDraggingMode={isDraggingMode}
      isSectionHidden={isSectionHidden}
    />
  );
};

const StyledBody = styled.div`
  ${({ shouldHide }) =>
    shouldHide &&
    css`
      display: none;
    `};
`;

SpecSectionContainer.Body = ({ shouldHide, children }) => {
  return <StyledBody shouldHide={shouldHide}>{children}</StyledBody>;
};

const StyledFooter = styled.div`
  ${({ shouldHide }) =>
    shouldHide &&
    css`
      display: none;
    `};
`;

SpecSectionContainer.Footer = ({ shouldHide, children }) => {
  return <StyledFooter shouldHide={shouldHide}>{children}</StyledFooter>;
};

export default SpecSectionContainer;
