import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { LightningIconFilled } from 'assets/icons';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import Text from 'components/Text';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  border: 2px solid ${theme.colors.accentLight};
  ${({ shouldAbsCenter }) =>
    shouldAbsCenter &&
    css`
      @media screen and (min-width: ${theme.breakpoints.md}) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 350px;
      }
    `};
  ${({ textAlign }) =>
    textAlign === 'center' &&
    css`
      align-items: center;
    `};
`;

const UpgradeCTA = ({
  title,
  subtitle,
  titleFontSize = theme.fontSizes.md,
  direction = 'column',
  justify,
  align = 'flex-start',
  currentCompany,
  ctaType = 'pageHeader',
  textAlign = 'left',
  shouldAbsCenter,
  label,
  ...props
}) => {
  if (ctaType === 'label') {
    return (
      <ChakraLink to={`/org/${currentCompany.slug}/admin/settings/billing`} as={Link} className="desktop">
        <Flex gap="4px" height="35px" bg={theme.colors.accentLight} borderRadius={theme.radii.md} p="8px">
          <LightningIconFilled color={theme.colors.accent} />
          <Text>{label}</Text>
        </Flex>
      </ChakraLink>
    );
  }

  return (
    <StyledFlex
      direction={direction}
      gap="8px"
      align={align}
      justify={justify}
      shouldAbsCenter={shouldAbsCenter}
      textAlign={textAlign}
      {...props}>
      <InfoGroup title={title} subtitle={subtitle} titleFontSize={titleFontSize} textAlign={textAlign} />
      <Link to={`/org/${currentCompany.slug}/admin/settings/billing`}>
        <Button variant="solid" colorScheme="lightBlue" color={theme.colors.accent} leftIcon={<LightningIconFilled />} as="div">
          Upgrade
        </Button>
      </Link>
    </StyledFlex>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(UpgradeCTA);
