import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { CheckIcon } from 'assets/icons';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';

const StyledFlex = styled(Flex)`
  ${containerStyle};
`;

const ConnectionManager = ({ slackWorkspace, onDisconnectSlackWorkspace, isDisconnectLoading }) => {
  return (
    <StyledFlex justify="space-between">
      <InfoGroup
        title={slackWorkspace.team_name}
        subtitle="Slack Connected"
        avatarSrc={slackWorkspace.team_image}
        noSpaceBetween={false}
        avatarBorderRadius={theme.radii.md}
        showAvatarBadge
        customAvatarBadgeIcon={<CheckIcon color="white" size="12px" />}
        avatarBadgeBg={theme.colors.primary}
      />
      <Button onClick={onDisconnectSlackWorkspace} isLoading={isDisconnectLoading} isDisabled={isDisconnectLoading}>
        Disconnect
      </Button>
    </StyledFlex>
  );
};

export default ConnectionManager;
