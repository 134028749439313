import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { blobToBase64 } from 'helpers/utils';
import { ReactMediaRecorder } from 'react-media-recorder';
import { Box, Flex, AspectRatio } from '@chakra-ui/react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Text from 'components/Text';

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background: ${theme.colors.redText};
  border-radius: 50px;
  margin-right: 6px;
`;

const RecordVideoModal = ({ isOpen, onClose, onUploadVideo }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [blobObj, setBlobObj] = useState();

  const onSave = () => {
    setIsSaving(true);
    blobToBase64(blobObj).then(file => {
      const data = new FormData();
      data.append('file', file);
      data.append('resource_type', 'video');
      data.append('upload_preset', 'spec_section_video');
      data.append('folder', 'spec_section_videos');
      data.append('cloud_name', 'teamspec');
      fetch('https://api.cloudinary.com/v1_1/teamspec/video/upload', {
        method: 'post',
        body: data,
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.error) {
            setIsSaving(false);
          } else {
            onUploadVideo(data.secure_url, data.public_id);
            onClose();
          }
        });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered constrainWidthMobile>
      <Modal.Header title="Record Video" showCloseButton={false} />
      <Modal.Body>
        <ReactMediaRecorder
          video
          onStop={(blobURL, blobObj) => setBlobObj(blobObj)}
          render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream }) => (
            <Flex direction="column" gap="16px">
              {!['idle', 'stopped'].includes(status) && (
                <Flex align="center" justify="center">
                  {status === 'recording' && <Dot />}
                  <Text
                    align="center"
                    justifyContent="center"
                    as="i"
                    color={theme.colors.accentText}
                    fontFamily={theme.fonts.light}
                    textTransform="capitalize">
                    {status.replace('acquiring_media', 'Loading...').replace('_', ' ')}
                  </Text>
                </Flex>
              )}
              {(status === 'recording' || status === 'stopped') && (
                <AspectRatio ratio={1.5 / 1} borderRadius={theme.radii.md} overflow="hidden" width="100%">
                  <>
                    {status === 'recording' && <VideoPreview stream={previewStream} />}
                    {status === 'stopped' && <video src={mediaBlobUrl} controls autoPlay width="100%" height="100%" />}
                  </>
                </AspectRatio>
              )}
              <Flex align="center" justify="center" gap="12px" width="100%">
                {status === 'idle' && (
                  <Button onClick={startRecording} flexGrow="1">
                    Start Recording
                  </Button>
                )}
                {status === 'recording' && (
                  <Button onClick={stopRecording} flexGrow="1">
                    Stop Recording
                  </Button>
                )}
                {status === 'stopped' &&
                  !isSaving && (
                    <Button onClick={clearBlobUrl} flexGrow="1" isDisabled={isSaving}>
                      Delete Recording
                    </Button>
                  )}
                {status === 'stopped' && (
                  <Button onClick={onSave} flexGrow="1" variant="solid" colorScheme="brand" isDisabled={isSaving} isLoading={isSaving}>
                    Save Recording
                  </Button>
                )}
              </Flex>
            </Flex>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
  const videoRef = useRef(null);

  useEffect(
    () => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    },
    [stream],
  );
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width="100%" height="100%" autoPlay style={{ background: theme.colors.grayFill }} />;
};

export default RecordVideoModal;
