import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SpecSearchIllustration } from 'assets/illustrations/all';
import ActivityTile from './ActivityTile';
import EmptyState from 'components/EmptyState';

const Styled = styled.div``;

const Results = ({ activities, currentUserSlug, didLoad }) => {
  return (
    <Styled>
      {activities.map((activity, index) => {
        return (
          <ActivityTile
            key={activity.id}
            activity={activity}
            isLastTile={index === activities.length - 1}
            currentUserSlug={currentUserSlug}
          />
        );
      })}
      {didLoad &&
        activities.length === 0 && (
          <EmptyState
            illustration={<SpecSearchIllustration />}
            title="No activity yet"
            subtitle="Check back later"
            bg="white"
            border="none"
            p={theme.padding.xl}
            minHeight="300px"
            justifyContent="center"
          />
        )}
    </Styled>
  );
};

export default Results;
