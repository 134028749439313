import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CheckIcon } from 'assets/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import Table from 'components/Table';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import HTMLText from 'components/HTMLText';
import InfoGroup from 'components/InfoGroup';
import Checkbox from 'components/Checkbox';
import Tag from 'components/Tag';

const OverlayBlur = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  z-index: 1;
`;

const UserRow = ({
  user,
  columns,
  onSetSearchQuery,
  navigate,
  areResultsSelectable,
  selectedUsers,
  onSelectUser,
  showEmailSubtitle,
  showAdminBadge,
  shouldBlurResult,
}) => {
  return (
    <Table.Row>
      {columns.map(column => {
        if (column === 'Name') {
          const isSelected = areResultsSelectable && selectedUsers.findIndex(selectedUser => selectedUser.slug === user.slug) !== -1;
          return (
            <Table.BodyResult key={column}>
              <Flex gap="12px">
                {areResultsSelectable && <Checkbox isChecked={isSelected} onChange={() => onSelectUser(user)} size="md" />}
                <Link as={RouterLink} to={`/${user.slug}`}>
                  <InfoGroup
                    avatarSrc={user.image || '-'}
                    title={
                      user.is_deactivated ? (
                        `${user.name} (Deactivated)`
                      ) : (
                        <Flex display="inline">
                          <span style={{ marginRight: '3px' }}>{user.name}</span>
                          {showAdminBadge &&
                            user.is_admin && <Tag label={user.admin_type} size="sm" variant="subtle" textTransform="capitalize" />}
                        </Flex>
                      )
                    }
                    subtitle={showEmailSubtitle ? user.email : user.current_user_has_reviewed && 'Reviewed'}
                    titleFontFamily={theme.fonts.medium}
                    subtitleAs="i"
                    subtitleFontColor={theme.colors.accentText}
                    showAvatarBadge={!showEmailSubtitle && user.current_user_has_reviewed}
                    customAvatarBadgeIcon={<CheckIcon color="white" size="12px" />}
                    avatarBadgeBg={theme.colors.primary}
                    noSpaceBetween={false}
                  />
                </Link>
              </Flex>
            </Table.BodyResult>
          );
        } else if (column === 'Title') {
          return (
            <Table.BodyResult
              key={column}
              onClick={() => {
                user.job_title &&
                  onSetSearchQuery({
                    display: 'Title',
                    query_type: 'spec_field',
                    query_field: 'job_title',
                    query_value: user.job_title,
                  });
              }}>
              {user.job_title || '–'}
            </Table.BodyResult>
          );
        } else if (column === 'Location') {
          return (
            <Table.BodyResult
              key={column}
              onClick={() => {
                user.location &&
                  onSetSearchQuery({
                    display: 'Location',
                    query_type: 'spec_field',
                    query_field: 'location',
                    query_value: user.location,
                  });
              }}>
              {user.location || '–'}
            </Table.BodyResult>
          );
        } else if (column === 'Spec Link') {
          return (
            <Table.BodyResult key={column} linkTo={user.has_spec ? `/${user.slug}` : null} color={user.has_spec && theme.colors.accent}>
              {user.has_spec ? `@${user.slug}` : '–'}
            </Table.BodyResult>
          );
        } else {
          const queryResult = user.query_results.find(queryResult => queryResult.display === column);
          if (queryResult) {
            return (
              <Table.BodyResult key={column}>
                {queryResult.results.map((result, index) => {
                  if (!result) return null;
                  const postText = index < queryResult.results.length - 1 ? ', ' : '';
                  const isHTMLResult = result.includes('<p') || result.includes('<br') || result.includes('<li');
                  // result.replace(/<\/?[^>]+(>|$)/g, '')
                  return (
                    <span key={result}>
                      {isHTMLResult ? <HTMLText html={result} /> : result}
                      {postText}
                    </span>
                  );
                })}
              </Table.BodyResult>
            );
          }
        }
      })}
      {shouldBlurResult && <OverlayBlur />}
    </Table.Row>
  );
};

export default UserRow;
