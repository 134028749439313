import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SIGNS } from 'data/zodiacData';
import InfoGroup from 'components/InfoGroup';
import Accordion from 'components/Accordion';
import Flex from 'components/Flex';
import Button from 'components/Button';
import SectionDirectoryLink from 'componentsCustom/SectionDirectoryLink';

const IconCircle = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  flex-shrink: 0;
`;

const LiveOutline = ({ answer, isEditMode, onDelete }) => {
  const signObj = SIGNS.find(sign => sign.sign === answer.answer);
  return (
    <Flex gap="16px" direction="column" align="flex-start">
      <InfoGroup
        icon={<IconCircle>{signObj.icon}</IconCircle>}
        title={signObj.sign}
        subtitle={signObj.birthday_range}
        spaceBetween="14px"
        noSpaceBetween={false}
      />
      <Accordion width="100%" flexGrow={1}>
        <Accordion.Item>
          <Accordion.Button text="Strengths" />
          <Accordion.Panel>{signObj.strengths}</Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Button text="Friendships" />
          <Accordion.Panel>{signObj.friendships}</Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Button text="In the workplace" />
          <Accordion.Panel>{signObj.description}</Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {isEditMode && <Button onClick={onDelete}>Change Sign</Button>}
      {!isEditMode && (
        <SectionDirectoryLink
          label="See others with this sign"
          path={`directory?query=true&display=${'Zodiac Sign'}&query_type=spec_section_answer&query_field=spec_section_answer_answer&query_value=${
            answer.answer
          }`}
        />
      )}
    </Flex>
  );
};

export default LiveOutline;
