import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ArrowRightIcon } from 'assets/icons';
import { SIGNS } from 'data/zodiacData';
import Flex from 'components/Flex';
import IconButton from 'components/IconButton';
import InfoGroup from 'components/InfoGroup';
import InputGroup from 'components/InputGroup';
import LiveOutline from './LiveOutline';

const CreateContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radii.md};
  padding: ${theme.padding.lg};
`;

const ZodiacSign = ({ section, sectionIndex, onSectionAnswerChange, triggerToast, isEditMode }) => {
  const answer = section.answers[0];
  const [sign, setSign] = useState(answer.answer);
  const answerIndex = 0;
  const hasAnswer = answer.answer;

  const onGenerate = () => {
    if (sign) {
      onSectionAnswerChange(sectionIndex, answerIndex, 'answer', sign);
    } else {
      triggerToast({ title: 'Please select a sign', status: 'error' });
    }
  };

  const onDelete = () => {
    setSign('');
    onSectionAnswerChange(sectionIndex, answerIndex, 'answer', '');
  };

  return (
    <React.Fragment>
      {hasAnswer ? (
        <LiveOutline answer={answer} isEditMode={isEditMode} onDelete={onDelete} />
      ) : (
        <CreateContainer gap="8px">
          <InfoGroup title="Enter your zodiac sign below" direction="column" align="center" textAlign="center" />
          <form
            onSubmit={e => {
              e.preventDefault();
              onGenerate();
            }}>
            <Flex gap="10px">
              <InputGroup
                type="select"
                value={sign}
                placeholder="Select Sign"
                field="answer"
                onChange={(field, value) => setSign(value)}
                width="210px"
                variant="outline"
                selectOptions={SIGNS.map((sign, index) => {
                  return { label: `${sign.sign} (${sign.birthday_range})`, value: sign.sign };
                })}
              />
              <IconButton colorScheme="brand" variant="solid" icon={<ArrowRightIcon color="white" />} type="submit" />
            </Flex>
          </form>
        </CreateContainer>
      )}
    </React.Fragment>
  );
};

export default React.memo(ZodiacSign, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
