import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton, Tooltip } from '@chakra-ui/react';

const Styled = styled.div``;

const TagsList = ({
  tags,
  selectedTags,
  nameAccessKey,
  size = 'lg',
  as = 'span',
  leftIcon,
  rightIcon,
  showDelete,
  onDelete,
  tagClassName,
  hoverCursor,
  shouldAddAnswerElementID,
  onClickTag,
  tagTooltip,
}) => {
  return (
    <React.Fragment>
      {tags.map((tag, tagIndex) => {
        let displayTag = tag;
        if (nameAccessKey) displayTag = tag[nameAccessKey];
        const nullElement = <div key={displayTag} className="hidden" />;
        let tagID;
        if (typeof tag === 'object') {
          if (tag.is_deleted) return nullElement;
          if (shouldAddAnswerElementID) tagID = `answer-${tag.front_id}-element`;
        }
        let isSelected = false;
        if (selectedTags) isSelected = selectedTags.includes(displayTag);

        return (
          <Tooltip key={displayTag} label={tagTooltip} placement="top">
            <Tag
              size={size}
              as={as}
              id={tagID}
              className={tagClassName}
              colorScheme={isSelected ? 'brand' : 'gray'}
              variant={isSelected ? 'solid' : 'subtle'}
              _hover={{ cursor: hoverCursor }}
              onClick={() => onClickTag && onClickTag(displayTag)}>
              {leftIcon && <TagLeftIcon as={leftIcon} />}
              <TagLabel>{displayTag}</TagLabel>
              {rightIcon && <TagRightIcon as={rightIcon} />}
              {showDelete && <TagCloseButton onClick={() => onDelete(tagIndex)} />}
            </Tag>
          </Tooltip>
        );
      })}
    </React.Fragment>
  );
};

export default TagsList;
