import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Modal from 'components/Modal';
import Flex from 'components/Flex';
import Button from 'components/Button';
import TagsList from 'componentsCustom/TagsList';
import InputGroup from 'components/InputGroup';

const TagsPickerModal = ({ isOpen, onClose, tags, selectedTags, onClickTag }) => {
  const [customOption, setCustomOption] = useState('');
  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} constrainWidthMobile>
      {tags &&
        tags.length > 0 && (
          <Modal.Body hasBorderBottom>
            <Flex direction="row" align="center" gap="10px" wrap="wrap">
              <TagsList tags={tags} selectedTags={selectedTags} as="button" onClickTag={onClickTag} />
            </Flex>
          </Modal.Body>
        )}

      <Modal.Body>
        <form
          onSubmit={e => {
            e.preventDefault();
            onClickTag(customOption);
            setCustomOption('');
          }}>
          <Flex gap="12px" width="100%">
            <InputGroup
              type="input"
              value={customOption}
              placeholder="Type custom option"
              field="option"
              onChange={(field, value) => setCustomOption(value)}
              autoComplete="off"
            />
            <Button type="submit" variant="solid" colorScheme="brand">
              Add
            </Button>
          </Flex>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TagsPickerModal;
