import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SlackIconFilled } from 'assets/icons';
import moment from 'moment';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Popover from 'components/Popover';
import IconButton from 'components/IconButton';

const StyledFlex = styled(Flex)`
  padding: 6px;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radii.sm};
`;

const EmojiImageWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const EmojiImage = styled.img`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  display: inline;
`;

const DisplayEmojiImage = styled.div`
  height: 34px;
  width: 34px;
  border: 1px solid ${theme.colors.border};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlackStatusBadge = ({ slackStatus }) => {
  let displayEmojiImage = <Text color={theme.colors.paragraphText}>{slackStatus.status_emoji_value}</Text>;

  if (slackStatus.status_emoji_display_info.length > 0) {
    displayEmojiImage = (
      <EmojiImageWrapper>
        <EmojiImage src={slackStatus.status_emoji_display_info[0].display_url} alt={slackStatus.status_emoji_display_info[0].emoji_name} />
      </EmojiImageWrapper>
    );
  }

  return (
    <Popover isLazy trigger="hover" placement="top">
      <Popover.PopoverTrigger>
        <DisplayEmojiImage>{displayEmojiImage}</DisplayEmojiImage>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent width="auto" maxWidth="350px">
        <Popover.PopoverArrow />
        <Popover.PopoverBody padding="0px">
          <Text textTransform="none" color={theme.colors.primaryText} p="8px" borderBottom={theme.layout.border}>
            Current Slack Status
          </Text>
          <Flex direction="column" padding="8px">
            <Text
              textTransform="none"
              fontFamily={theme.fonts.light}
              textAlign="center"
              color={theme.colors.primaryText}
              gap="4px"
              display="flex">
              {displayEmojiImage}
              {slackStatus.status_text}
            </Text>
            {slackStatus.status_expiration !== 0 && (
              <Text
                textTransform="uppercase"
                textAlign="center"
                color={theme.colors.lightAccentText}
                fontSize="10px"
                textTransform="uppercase">
                Expires in {moment(new Date(0).setUTCSeconds(slackStatus.status_expiration)).toNow()}
              </Text>
            )}
          </Flex>
        </Popover.PopoverBody>
      </Popover.PopoverContent>
    </Popover>
  );
};

export default SlackStatusBadge;
