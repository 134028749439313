import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, randomID, removeCookie, setWindowLocation } from 'helpers/utils';
import { isUsernameValid, isUserBasicInfoValid } from 'helpers/authUtils';
import { HStack } from '@chakra-ui/react';
import { withParams, withNavigation } from 'hooks/router';
import { connect } from 'react-redux';
import { createStandaloneToast } from '@chakra-ui/toast';
import update from 'immutability-helper';
import Modal from 'components/Modal';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import EditHeader from './EditHeader';
import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings';
import AlertDialog from 'components/AlertDialog';
import ShareLink from 'containers/ShareLink';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const returnEmptyField = () => {
  return {
    field_name: '',
    field_key: randomID(),
    field_value: '',
    field_type: '',
    field_placeholder: 'mylink.com',
    is_custom: true,
    field_data_type: 'link',
  };
};

class UserAccountModal extends React.Component {
  state = {
    selectedTabIndex: 0,
    spec: {},
    specUser: {},
    specFields: [],
    notificationSettings: [],
    isUsernameAvailable: true,
  };

  componentDidMount() {
    this.getSpec();
  }

  componentWillUnmount() {
    document.body.classList.remove('user-is-tabbing');
  }

  getSpec() {
    const { params, selectedTabIndex = 0 } = this.props;
    const slug = params.slug;
    Api.get({ url: `/api/v1/specs/${slug}/get_spec` }).then(data => {
      if (data.success) {
        this.setState({
          spec: data.spec,
          specUser: data.spec_user,
          specFields: data.spec_fields,
          notificationSettings: data.notification_settings,
          selectedTabIndex: selectedTabIndex,
          didLoad: true,
        });
      } else {
      }
    });
  }

  onSelectTabIndex = selectedTabIndex => this.setState({ selectedTabIndex: selectedTabIndex });

  onSpecChange = (field, value) => {
    this.setState({ spec: update(this.state.spec, { [field]: { $set: value } }), wereEditsMade: true });
  };

  onSpecUserChange = (field, value) => {
    if (field === 'slug') {
      if (!isUsernameValid(value)) return;
      this.isUserSlugAvailable(value);
      value = value.replace('.', '');
      value = value.toLowerCase();
    }

    this.setState({ specUser: update(this.state.specUser, { [field]: { $set: value } }), wereEditsMade: true });
  };

  isUserSlugAvailable(userSlug) {
    if (!userSlug || userSlug.length < 2) {
      this.setState({ isUsernameAvailable: true });
    } else {
      Api.post({
        url: `/api/v1/users/is_slug_available`,
        body: { user_slug: userSlug },
      }).then(data => {
        if (data.is_available || userSlug === this.props.params.slug) {
          this.setState({ isUsernameAvailable: true });
        } else {
          this.setState({ isUsernameAvailable: false });
        }
      });
    }
  }

  onFieldChange = (fieldKey, fieldValue) => {
    const existingIndex = this.state.specFields.findIndex(field => field.field_key === fieldKey);
    this.setState({
      specFields: update(this.state.specFields, { [existingIndex]: { field_value: { $set: fieldValue } } }),
      wereEditsMade: true,
    });
  };

  onAddCustomField = () => {
    this.setState({
      specFields: update(this.state.specFields, { $set: [...this.state.specFields, returnEmptyField()] }),
      wereEditsMade: true,
    });
  };

  onRemoveField = fieldKey => {
    const existingIndex = this.state.specFields.findIndex(field => field.field_key === fieldKey);
    this.setState({
      specFields: update(this.state.specFields, { [existingIndex]: { is_deleted: { $set: true } } }),
      wereEditsMade: true,
    });
  };

  onCustomFieldNameChange = (fieldKey, fieldName) => {
    const existingIndex = this.state.specFields.findIndex(field => field.field_key === fieldKey);
    this.setState({
      specFields: update(this.state.specFields, { [existingIndex]: { field_name: { $set: fieldName } } }),
      wereEditsMade: true,
    });
  };

  onSettingsChange = (settingKey, settingValue) => {
    const existingIndex = this.state.notificationSettings.findIndex(notificationSetting => notificationSetting.setting_key === settingKey);
    this.setState({
      notificationSettings: update(this.state.notificationSettings, { [existingIndex]: { setting_value: { $set: settingValue } } }),
    });
  };

  onClose = wasSaved => {
    const { specUser, wereEditsMade, showConfirmAlertDialog, hasRequiredEditsPending } = this.state;
    const { navigate } = this.props;
    const validation = this.returnValidation();

    if (validation.success) {
      if (hasRequiredEditsPending) {
        this.setState({ showConfirmAlertDialog: false });
        this.triggerToast({ title: 'Changes must be saved first' });
      } else if (wereEditsMade && !showConfirmAlertDialog) {
        this.setState({ showConfirmAlertDialog: true });
      } else {
        wasSaved ? navigate(`/${specUser.slug}?saved=true`) : navigate(`/${specUser.slug}`);
      }
    } else {
      this.setState({ hasRequiredEditsPending: true });
      this.triggerToast({ title: validation.notice });
    }
  };

  triggerToast = ({ title, status = 'error', duration = 3000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  returnValidation() {
    const { spec, specUser, specFields, isUsernameAvailable } = this.state;
    const { currentCompany, hasCompany } = this.props;
    if (!isUsernameAvailable) return { success: false, notice: `Username ${specUser.slug} is not available` };
    if (!specUser.slug) return { success: false, notice: 'Please enter a username' };
    if (specUser.slug.length < 2) return { success: false, notice: 'Username must be at least 2 letters' };
    // if (!specUser.image) return { success: false, notice: 'Please upload a profile image' };
    const hasJobTitle =
      specFields.findIndex(field => field.field_key === 'job_title' && field.field_value && field.field_value.length > 1) > -1;
    if (!hasJobTitle) return { success: false, notice: 'Please add your job title' };
    const hasLocation =
      specFields.findIndex(field => field.field_key === 'location' && field.field_value && field.field_value.length > 1) > -1;
    if (!hasLocation) return { success: false, notice: 'Please add your location' };
    return isUserBasicInfoValid(specUser, hasCompany && currentCompany.domain);
  }

  onDeleteSpec = () => {
    const didConfirm = window.confirm(
      'Are you sure you want to delete your Spec and account? This action cannot be undone. All of your data will be deleted.',
    );
    if (didConfirm) {
      const { specUser } = this.state;
      this.setState({ isDeleting: true });
      Api.delete({ url: `/api/v1/users/${specUser.slug}/delete_user` }).then(data => {
        if (data.success) {
          removeCookie('login_token');
          setTimeout(() => {
            setWindowLocation('/auth?toast=Your account has been deleted successfully&toast_duration=20000');
          }, 10);
        } else {
          this.triggerToast({ title: data.error, status: 'error' });
        }
      });
    }
  };

  onSave = () => {
    const { spec, specUser, specFields, notificationSettings } = this.state;
    const validation = this.returnValidation();
    if (validation.success) {
      this.setState({ isSaving: true });
      Api.post({
        url: `/api/v1/users/update_user_account`,
        body: {
          spec: spec,
          spec_user: specUser,
          spec_fields: specFields,
          notification_settings: notificationSettings,
        },
      }).then(data => {
        this.setState({ wereEditsMade: false, showConfirmAlertDialog: false, isSaving: false, hasRequiredEditsPending: false }, () => {
          this.onClose(true);
        });
      });
    } else {
      this.triggerToast({ title: validation.notice });
    }
  };

  render() {
    const {
      spec,
      specUser,
      specFields,
      selectedTabIndex,
      notificationSettings,
      wereEditsMade,
      showConfirmAlertDialog,
      isUsernameAvailable,
      didLoad,
      isSaving,
    } = this.state;
    const { isOpen } = this.props;
    if (!didLoad) return null;
    return (
      <Modal isOpen onClose={this.onClose}>
        <ToastContainer />
        <Tabs
          tabs={['Edit Header', 'Notifications', 'Settings', 'Share']}
          selectedIndex={selectedTabIndex}
          onSelectIndex={this.onSelectTabIndex}
          isSticky>
          <Modal.Body>
            <Tabs.TabPanels>
              <Tabs.TabPanel>
                <EditHeader
                  specUser={specUser}
                  specFields={specFields}
                  onSpecUserChange={this.onSpecUserChange}
                  onFieldChange={this.onFieldChange}
                  onRemoveField={this.onRemoveField}
                  onCustomFieldNameChange={this.onCustomFieldNameChange}
                  onAddCustomField={this.onAddCustomField}
                />
              </Tabs.TabPanel>

              <Tabs.TabPanel>
                <NotificationSettings notificationSettings={notificationSettings} onSettingsChange={this.onSettingsChange} />
              </Tabs.TabPanel>

              <Tabs.TabPanel>
                <AccountSettings
                  spec={spec}
                  specUser={specUser}
                  onSpecChange={this.onSpecChange}
                  onSpecUserChange={this.onSpecUserChange}
                  isUsernameAvailable={isUsernameAvailable}
                  onDeleteSpec={this.onDeleteSpec}
                />
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <ShareLink
                  shareDisplayURL={`https://spec.me/${specUser.slug}`}
                  shareURL={`https://spec.me/${specUser.slug}`}
                  entityType="spec"
                  entitySlug={specUser.slug}
                />
              </Tabs.TabPanel>
            </Tabs.TabPanels>
          </Modal.Body>

          <Modal.Footer isSticky isHidden={selectedTabIndex === 3}>
            <HStack spacing="6px">
              <Button onClick={this.onClose} colorScheme="brand" variant="ghost" disabled={isSaving}>
                Cancel
              </Button>
              <Button colorScheme="brand" variant="solid" isLoading={isSaving} onClick={this.onSave} isDisabled={isSaving}>
                Save
              </Button>
            </HStack>
          </Modal.Footer>
        </Tabs>

        <AlertDialog
          body="Are you sure? Your changes wont be saved."
          isOpen={showConfirmAlertDialog}
          onCancel={() => this.setState({ showConfirmAlertDialog: false })}
          onConfirm={() => this.onClose(false)}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default withNavigation(withParams(connect(mapStateToProps)(UserAccountModal)));
