import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, disabledOverlay } from 'styles/theme';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${disabledOverlay};
    `};
`;

const PageHeader = ({
  title,
  subtitle,
  button,
  titleFontSize = theme.fontSizes.md,
  direction = 'column',
  justify,
  align = 'flex-start',
  isDisabled,
}) => {
  return (
    <StyledFlex direction={direction} gap="8px" align={align} justify={justify} isDisabled={isDisabled}>
      <InfoGroup title={title} subtitle={subtitle} titleFontSize={titleFontSize} />
      {button}
    </StyledFlex>
  );
};

export default PageHeader;
