import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Flex from 'components/Flex';
import Tag from 'components/Tag';

const AppliedSearchQueries = ({ searchQueries, onRemoveSearchQuery }) => {
  if (searchQueries.length === 0) return null;
  if (searchQueries.findIndex(searchQuery => searchQuery.query_value) === -1) return null;
  return (
    <Flex justify="space-between" padding={theme.padding.lg} borderBottom={theme.layout.border}>
      <Flex gap="8px" wrap="wrap">
        {searchQueries.map((searchQuery, searchQueryIndex) => {
          if (!searchQuery.query_value) return null;
          return (
            <Tag
              key={searchQuery.display}
              label={searchQuery.query_value || searchQuery.display}
              showDelete
              colorScheme="gray"
              variant="subtle"
              onDelete={() => onRemoveSearchQuery(searchQueryIndex)}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default AppliedSearchQueries;
