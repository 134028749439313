const Strengthsfinder = () => {
  return (
    <svg width="328" height="44" viewBox="0 0 328 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="36" width="55" height="8" rx="4" fill="#E0E6ED" />
      <rect width="76" height="30" rx="6" fill="#E0E6ED" />
      <rect x="84" y="36" width="55" height="8" rx="4" fill="#E0E6ED" />
      <rect x="84" width="76" height="30" rx="6" fill="#E0E6ED" />
      <rect x="168" y="36" width="55" height="8" rx="4" fill="#E0E6ED" />
      <rect x="168" width="76" height="30" rx="6" fill="#E0E6ED" />
      <rect x="252" y="36" width="55" height="8" rx="4" fill="#E0E6ED" />
      <rect x="252" width="76" height="30" rx="6" fill="#E0E6ED" />
    </svg>
  );
};

export default Strengthsfinder;
