import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { theme } from 'styles/theme';
import { getCurrent } from 'reduxx/current';
import { Api, returnQuery, setCookie } from 'helpers/utils';
import { urls } from 'data/constants';
import { createStandaloneToast } from '@chakra-ui/toast';
import Loading from 'components/Loading';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const GlobalLoader = ({ dispatch, didCurrentLoad, children }) => {
  const params = returnQuery();

  useEffect(() => {
    dispatch(getCurrent()).then(data => {
      if (!data.is_signed_in && params.email && params.log_ver_tok) {
        accessViaVerificationTokenLink();
      } else if (params.toast) {
        toast({
          title: params.toast,
          status: params.toast_status || 'success',
          duration: params.toast_duration || 3000,
          isClosable: true,
        });
      }
    });
  }, []);

  const accessViaVerificationTokenLink = () => {
    Api.post({
      url: `/api/v1/auth/access_via_verification_token_link`,
      body: { email: params.email, log_ver_tok: params.log_ver_tok },
    }).then(data => {
      if (data.success) {
        setCookie('login_token', data.login_token);
        let redirectURL = `${urls.routeDomain}/${data.user.slug}`;
        if (params.redirect) redirectURL = params.redirect;
        setTimeout(() => {
          window.location.href = redirectURL + "?toast=You've been logged in successfully&toast_status=success";
        }, 50);
      } else {
        toast({
          title: data.error,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    });
  };

  if (!didCurrentLoad) return <Loading />;
  return (
    <>
      <ToastContainer />
      {children}
    </>
  );
};

const mapStateToProps = state => ({
  didCurrentLoad: state.currentReducer.didCurrentLoad,
});

export default connect(mapStateToProps)(GlobalLoader);
