const Media = ({ size = 110 }) => {
  return (
    <svg width="260" height="50" viewBox="0 0 260 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.29287 20.7071C4.92283 20.0771 5.99998 20.5233 5.99998 21.4142L5.99998 28.5858C5.99998 29.4767 4.92284 29.9229 4.29287 29.2929L0.707103 25.7071C0.316581 25.3166 0.31658 24.6834 0.707102 24.2929L4.29287 20.7071Z"
        fill="#E0E6ED"
      />
      <rect x="14" width="40" height="50" rx="6" fill="#E0E6ED" />
      <rect x="62" width="40" height="50" rx="6" fill="#E0E6ED" />
      <rect x="110" width="40" height="50" rx="6" fill="#E0E6ED" />
      <rect x="158" width="40" height="50" rx="6" fill="#E0E6ED" />
      <rect x="206" width="40" height="50" rx="6" fill="#E0E6ED" />
      <path
        d="M255.707 29.2929C255.077 29.9229 254 29.4767 254 28.5858L254 21.4142C254 20.5233 255.077 20.0771 255.707 20.7071L259.293 24.2929C259.683 24.6834 259.683 25.3166 259.293 25.7071L255.707 29.2929Z"
        fill="#E0E6ED"
      />
      <rect x="20" y="19" width="19" height="4" rx="2" fill="#F6F8FA" />
      <rect x="20" y="26" width="28" height="4" rx="2" fill="#F6F8FA" />
      <rect x="68" y="19" width="25" height="4" rx="2" fill="#F6F8FA" />
      <rect x="68" y="26" width="18" height="4" rx="2" fill="#F6F8FA" />
      <rect x="116" y="19" width="22" height="4" rx="2" fill="#F6F8FA" />
      <rect x="116" y="26" width="20" height="4" rx="2" fill="#F6F8FA" />
      <rect x="164" y="19" width="12" height="4" rx="2" fill="#F6F8FA" />
      <rect x="164" y="26" width="24" height="4" rx="2" fill="#F6F8FA" />
      <rect x="212" y="19" width="23" height="4" rx="2" fill="#F6F8FA" />
      <rect x="212" y="26" width="11" height="4" rx="2" fill="#F6F8FA" />
    </svg>
  );
};

export default Media;
