import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from 'assets/icons';
import Button from 'components/Button';

const SectionDirectoryLink = ({ label, path, ...props }) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="link"
      size="sm"
      textTransform="uppercase"
      color={theme.colors.lightAccentText}
      onClick={() => {
        navigate(path);
      }}
      {...props}>
      {label} <ArrowRightIcon style={{ marginLeft: 2 }} />
    </Button>
  );
};

export default SectionDirectoryLink;
