import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { cleanHTML } from 'helpers/utils';
import { returnUserListLinks } from 'helpers/specUtils';
import { CheckIcon } from 'assets/icons';
import { EmptyAnswerIllustration } from 'assets/illustrations/all';
import EmptyState from 'components/EmptyState';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Alert from 'components/Alert';
import ActivityPreviewCard from './ActivityPreviewCard';
import GettingStartedChecklist from './GettingStartedChecklist';

const PreSectionsComponents = ({
  spec,
  specUser,
  isEditMode,
  isCurrentUserCreator,
  doesHaveCompletedAnswers,
  currentUserHasReviewed,
  onToggleMarkAsReviewedModal,
  showGettingStartedChecklist,
  gettingStartedStepsCompleted,
  currentCompanySlug,
}) => {
  return (
    <React.Fragment>
      {showGettingStartedChecklist && <GettingStartedChecklist gettingStartedStepsCompleted={gettingStartedStepsCompleted} />}
      {!isCurrentUserCreator &&
        !spec.is_published && (
          <Alert title={`${specUser.first_name} hasn't published their Spec yet`} mb={['8px', '8px', '22px']} status="warning" />
        )}

      {!isEditMode && (
        <React.Fragment>
          <CardHeader
            title={
              currentUserHasReviewed
                ? `You've marked ${specUser.first_name}'s Spec as reviewed!`
                : `Let ${specUser.first_name} know when you've reviewed their Spec`
            }
            subtitle={
              spec.total_reviewed_by === 0 ? (
                `Be the first to mark ${specUser.first_name}'s Spec as reviewed`
              ) : (
                <>
                  Marked as reviewed by{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML(
                        returnUserListLinks({
                          userSlugs: spec.reviewed_by_sample,
                          returnLimit: 2,
                          totalNumber: spec.total_reviewed_by,
                          shouldLinkOthers: true,
                        }),
                      ),
                    }}
                  />
                </>
              )
            }
            rightElement={
              <Button
                colorScheme="brand"
                variant="solid"
                onClick={onToggleMarkAsReviewedModal}
                isDisabled={currentUserHasReviewed}
                leftIcon={currentUserHasReviewed && <CheckIcon />}
                flexShrink={0}>
                {currentUserHasReviewed ? 'Reviewed' : 'Mark as Reviewed'}
              </Button>
            }
          />
        </React.Fragment>
      )}

      {isEditMode && <ActivityPreviewCard spec={spec} currentCompanySlug={currentCompanySlug} />}
      {!isEditMode &&
        !doesHaveCompletedAnswers && (
          <EmptyState
            illustration={<EmptyAnswerIllustration />}
            title={`${specUser.first_name} doesn't have any answers yet`}
            subtitle="Check back soon for updates"
          />
        )}
    </React.Fragment>
  );
};

export default PreSectionsComponents;
