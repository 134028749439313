import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CloseIcon } from 'assets/icons';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import InfoGroup from 'components/InfoGroup';
import IconButton from 'components/IconButton';

// <Modal isOpen={isOpen} onClose={onClose} isCentered constrainWidthMobile>
//   <Modal.Header title="Title" subtitle="Subtitle" />
//   <Modal.Body>
//     <div />
//   </Modal.Body>
//
//   <Modal.Footer>
//     <div />
//   </Modal.Footer>
// </Modal>

const FixedModalCloseButton = styled(IconButton)`
  position: fixed;
  top: 22px;
  right: 22px;
  z-index: var(--chakra-zIndices-modal);
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

// https://chakra-ui.com/docs/components/overlay/modal
const ModalComponent = ({
  isOpen,
  onClose,
  size = 'md',
  isCentered = false,
  showCloseButton = false,
  constrainWidthMobile = false,
  modalContentBackground,
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} {...props} isCentered={isCentered}>
      <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(3px)" style={{ cursor: 'pointer' }} />
      <ModalContent className={constrainWidthMobile && 'constrain-mobile-width-mobile'} background={modalContentBackground}>
        {props.children}
      </ModalContent>
      {showCloseButton && <FixedModalCloseButton onClick={onClose} icon={<CloseIcon size="20px" />} isRound bg="white" />}
    </Modal>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  border-bottom: 2px solid ${theme.colors.border};
`;

ModalComponent.Header = ({ title, subtitle, showCloseButton = true, ...props }) => {
  return (
    <>
      <StyledModalHeader {...props}>
        <InfoGroup title={title} subtitle={subtitle} />
      </StyledModalHeader>
      {showCloseButton && <ModalCloseButton />}
    </>
  );
};

const StyledModalBody = styled(ModalBody)`
  ${({ useEqualPadding }) =>
    useEqualPadding &&
    css`
      padding: ${theme.spacing.lg};
    `};
  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid ${theme.colors.border};
    `};
`;

ModalComponent.Body = ({ useEqualPadding = true, hasBorderBottom, ...props }) => {
  return (
    <StyledModalBody {...props} useEqualPadding={useEqualPadding} hasBorderBottom={hasBorderBottom} className={props.className}>
      {props.children}
    </StyledModalBody>
  );
};

const StyledModalFooter = styled(ModalFooter)`
  padding: ${theme.spacing.lg};
  border-top: 2px solid ${theme.colors.border};
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      bottom: 0;
      z-index: 3;
      background: white;
      border-radius: 0 0 ${theme.radii.md} ${theme.radii.md};
    `};
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `};
`;

ModalComponent.Footer = ({ isSticky, isHidden, ...props }) => {
  return (
    <StyledModalFooter className={props.className} isSticky={isSticky} isHidden={isHidden} {...props}>
      {props.children}
    </StyledModalFooter>
  );
};

export default ModalComponent;
