import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, multiLineText } from 'styles/theme';
import { Flex, Editable, EditableInput, EditablePreview, Tag } from '@chakra-ui/react';
import EditableComponent from 'components/Editable';
import Text from 'components/Text';

const StyledFlex = styled(Flex)`
  .chakra-editable__textarea {
    padding: 0px !important;
    outline: 0px !important;
    vertical-align: top !important;
    border-radius: 0px;
  }
`;

const StyledEditable = styled(Editable)`
  font-size: ${theme.fontSizes.lg};
  font-family: ${theme.fonts.semiBold};
  .chakra-editable__preview,
  .chakra-editable__input {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
  }
  ${({ isSectionHidden }) =>
    isSectionHidden &&
    css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-right: 16px;
    `};
`;

const SectionTitle = ({
  title,
  subtitle,
  isSubtitleEnabled,
  onSectionChange,
  sectionIndex,
  isEditMode,
  isDraggingMode,
  isSectionHidden,
}) => {
  if (!isEditMode && !title) return null;
  return (
    <StyledFlex direction="column" mb={!isDraggingMode && !isSectionHidden && '10px'}>
      <StyledEditable defaultValue={title} placeholder="Type title" isDisabled={!isEditMode} isSectionHidden={isSectionHidden}>
        <EditablePreview mr="6px">{title}</EditablePreview>
        <EditableInput value={title} onChange={e => onSectionChange(sectionIndex, 'title', e.target.value)} />
        {isSectionHidden && (
          <Tag colorScheme="gray" variant="subtle" size="md" flexShrink="0" m="4px 0" fontFamily={theme.fonts.medium}>
            Hidden
          </Tag>
        )}
      </StyledEditable>
      {!isDraggingMode &&
        (isEditMode || subtitle) &&
        !isSectionHidden &&
        isSubtitleEnabled && (
          <Text color={theme.colors.paragraphText} fontFamily={theme.fonts.light} css={multiLineText} as="div">
            <EditableComponent
              value={subtitle}
              field="subtitle"
              placeholder="Type description"
              inputType="textarea"
              inputStyle={{ width: '100%' }}
              isDisabled={!isEditMode}
              onChange={(field, value) => onSectionChange(sectionIndex, field, value)}
            />
          </Text>
        )}
    </StyledFlex>
  );
};

export default SectionTitle;
