const WorkHistory = () => {
  return (
    <svg width="327" height="32" viewBox="0 0 327 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="26" y="24" width="105" height="8" rx="4" fill="#E0E6ED" />
      <rect x="26" y="12" width="128" height="8" rx="4" fill="#E0E6ED" />
      <rect x="26" width="34" height="8" rx="4" fill="#E0E6ED" />
      <rect x="64" width="34" height="8" rx="4" fill="#E0E6ED" />
      <rect width="22" height="22" rx="6" fill="#E0E6ED" />
      <rect x="199" y="24" width="105" height="8" rx="4" fill="#E0E6ED" />
      <rect x="199" y="12" width="128" height="8" rx="4" fill="#E0E6ED" />
      <rect x="199" width="34" height="8" rx="4" fill="#E0E6ED" />
      <rect x="237" width="34" height="8" rx="4" fill="#E0E6ED" />
      <rect x="173" width="22" height="22" rx="6" fill="#E0E6ED" />
    </svg>
  );
};

export default WorkHistory;
