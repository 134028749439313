import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';
import ReactPaginate from 'react-paginate';

const Styled = styled.div`
  .pagination {
    display: flex;
    list-style: none;
    padding-left: 0;
  }
  .page-link {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: ${theme.colors.paragraphText};
    text-decoration: none;
    background-color: white;
    border: 1px solid ${theme.colors.border};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: ${theme.fontSizes.md};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${theme.colors.grayFill};
    }
  }
  .page-item.disabled .page-link {
    color: ${theme.colors.paragraphText + '50'};
    pointer-events: none;
    background-color: white;
    border-color: ${theme.colors.border};
  }
  .page-item.disabled {
    cursor: not-allowed;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: white;
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: ${theme.radii.md};
    border-bottom-left-radius: ${theme.radii.md};
  }
  .page-item:last-child .page-link {
    border-top-right-radius: ${theme.radii.md};
    border-bottom-right-radius: ${theme.radii.md};
  }
`;

const Pagination = ({ numberOfResults, resultsPerPage, onPageChange, isDisabled }) => {
  return (
    <Styled>
      <ReactPaginate
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
        pageClassName={`page-item ${isDisabled && 'disabled'}`}
        pageLinkClassName="page-link"
        previousClassName={`page-item ${isDisabled && 'disabled'}`}
        previousLinkClassName="page-link"
        nextClassName={`page-item ${isDisabled && 'disabled'}`}
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        onPageChange={onPageChange}
        pageCount={Math.ceil(numberOfResults / resultsPerPage)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        renderOnZeroPageCount={false}
      />
    </Styled>
  );
};

export default Pagination;
