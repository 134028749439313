import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, disabledOverlay } from 'styles/theme';
import { Api } from 'helpers/utils';
import { Stack, StackDivider, Box } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import Flex from 'components/Flex';
import PageHeader from 'componentsCustom/PageHeader';
import ConnectionManager from './ConnectionManager';
import Loading from 'components/Loading';
import SwitchRow from 'components/SwitchRow';
import Button from 'components/Button';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';
import Text from 'components/Text';
import CheckboxItem from 'componentsCustom/CheckboxItem';

const Styled = styled.div``;

const HRIS = ({ triggerToast, currentCompanySlug, companyTier }) => {
  const [hrisProvider, setHrisProvider] = useState(null);
  const [hrisSettings, setHrisSettings] = useState(null);
  const [hrisSyncSettings, setHrisSyncSettings] = useState(null);
  const [isHrisEnabled, setIsHrisEnabled] = useState(false);
  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    getHrisProvider();
  }, []);

  const getHrisProvider = () => {
    Api.get({ url: `/api/v1/admin/hris/get_hris_provider` }).then(data => {
      if (data.success) {
        // setHrisProvider(data.hris_provider);
        setHrisSettings(data.hris_settings);
        setHrisSyncSettings(data.hris_sync_settings);
        // setIsHrisEnabled(data.is_hris_enabled);
        setDidLoad(true);
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  const onDisconnectHrisProvider = () => {
    // const didConfirm = window.confirm('Are you sure you want to disconnect your Slack workspace? This action cannot be undone.');
    // if (didConfirm) {
    //   setIsDisconnectLoading(true);
    //   Api.delete({ url: `/api/v1/admin/slack_workspaces/disconnect_slack_workspace` }).then(data => {
    //     if (data.success) {
    //       window.location.reload();
    //     } else {
    //       setIsDisconnectLoading(false);
    //       triggerToast({ title: data.error, status: 'error' });
    //     }
    //   });
    // }
  };

  const onSettingChange = (settingKey, settingValue, settingCategory) => {
    if (settingCategory === 'hris') {
      const settingIndex = hrisSettings.findIndex(hrisSetting => hrisSetting.setting_key === settingKey);
      setHrisSettings(update(hrisSettings, { [settingIndex]: { setting_value: { $set: settingValue } } }));
    } else if (settingCategory === 'hris_sync_data') {
      const settingIndex = hrisSyncSettings.findIndex(hrisSyncSetting => hrisSyncSetting.setting_key === settingKey);
      setHrisSyncSettings(update(hrisSyncSettings, { [settingIndex]: { setting_value: { $set: settingValue } } }));
    }
    Api.post({
      url: `/api/v1/admin/settings/update_individual_setting`,
      body: { setting_key: settingKey, setting_value: settingValue },
    }).then(data => {
      if (!data.success) triggerToast({ title: data.error, status: 'error' });
    });
  };

  if (!didLoad) return <Loading />;
  const isFeatureGated = companyTier < 3;
  return (
    <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
      {isFeatureGated && (
        <UpgradeCTA
          title="Upgrade to Enterprise to integrate HRIS"
          subtitle="Once upgraded, you can sync your HRIS system and access the features below"
          ctaType="pageHeader"
        />
      )}

      {isHrisEnabled ? (
        <ConnectionManager
          hrisProvider={hrisProvider}
          onDisconnectHrisProvider={onDisconnectHrisProvider}
          isDisconnectLoading={isDisconnectLoading}
          isDisabled={isFeatureGated}
        />
      ) : (
        <PageHeader
          title="Connect to HRIS"
          subtitle="Simplify onboarding by autofilling HRIS info to Specs, automatically invite new hires, remove deactivated employees, send invites by department, and more."
          button={<Button>Choose HRIS Provider</Button>}
          isDisabled={isFeatureGated}
        />
      )}
      <Box css={isFeatureGated && disabledOverlay}>
        <Stack spacing="16px" align="start" divider={<StackDivider borderColor={theme.colors.border} />} css={containerStyle}>
          {hrisSettings.map(hrisSetting => {
            return (
              <React.Fragment>
                <SwitchRow
                  key={hrisSetting.setting_key}
                  title={hrisSetting.title}
                  subtitle={hrisSetting.subtitle}
                  isChecked={isHrisEnabled ? hrisSetting.setting_value === 'on' : false}
                  onChange={isTurnedOn => onSettingChange(hrisSetting.setting_key, isTurnedOn ? 'on' : 'off', 'hris')}
                  isDisabled={!isHrisEnabled}
                  showButton={hrisSetting.setting_key === 'should_send_invites_to_new_hris_users' ? false : true}
                />
                {hrisSetting.setting_key === 'should_send_invites_to_new_hris_users' && (
                  <Link to={`/org/${currentCompanySlug}/admin/invites?tabIndex=1`}>
                    <Button mt="8px" rightIcon={<HiExternalLink />} as="div">
                      Auto Invite Settings
                    </Button>
                  </Link>
                )}
                {hrisSetting.setting_key === 'should_autofill_hris_user_info' && (
                  <Flex
                    direction="column"
                    align="flex-start"
                    p={theme.padding.md}
                    border={theme.layout.border}
                    borderRadius={theme.radii.md}
                    gap="8px"
                    width="100%"
                    mt="8px">
                    <Text>Choose HRIS info to sync</Text>
                    {hrisSyncSettings.map(hrisSyncSetting => {
                      return (
                        <CheckboxItem
                          key={hrisSyncSetting.setting_key}
                          label={hrisSyncSetting.title}
                          isChecked={isHrisEnabled ? hrisSyncSetting.setting_value === 'on' : false}
                          onChange={isTurnedOn => onSettingChange(hrisSyncSetting.setting_key, isTurnedOn ? 'on' : 'off', 'hris_sync_data')}
                        />
                      );
                    })}
                  </Flex>
                )}
              </React.Fragment>
            );
          })}
        </Stack>
      </Box>
    </Flex>
  );
};

export default HRIS;
