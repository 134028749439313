import PrivateMessage from 'assets/illustrations/PrivateMessage';
import SpecSearch from 'assets/illustrations/SpecSearch';
import CreateProfile from 'assets/illustrations/CreateProfile';
import EmptyAnswer from 'assets/illustrations/EmptyAnswer';
import WelcomeBear from 'assets/illustrations/WelcomeBear';

export const PrivateMessageIllustration = PrivateMessage;
export const SpecSearchIllustration = SpecSearch;
export const CreateProfileIllustration = CreateProfile;
export const EmptyAnswerIllustration = EmptyAnswer;
export const WelcomeBearIllustration = WelcomeBear;
