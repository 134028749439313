import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link, useLocation } from 'react-router-dom';
import { CloseIcon, GoogleIcon } from 'assets/icons';
import { Tooltip } from '@chakra-ui/react';
import update from 'immutability-helper';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Flex from 'components/Flex';
import OrDivider from 'components/OrDivider';
import GoogleAuth from 'componentsCustom/GoogleAuth';
import InfoGroup from 'components/InfoGroup';

const BasicInfo = ({
  newUser,
  onNewUserChange,
  onStateChange,
  onPreviousStepClick,
  onNextStepClick,
  currentCompany,
  hasCompany,
  isSubmitLoading,
  nextButtonText = 'Continue',
  typeOfAuth = 'signup',
  onGoogleAuthSuccess,
  isNameRequired,
  setIsNameRequired,
}) => {
  const location = useLocation();
  let title = 'Add Basic Info';
  let subtitle = 'Use your work email so we can connect you with your team in Spec.';
  if (hasCompany) subtitle = '';
  if (typeOfAuth === 'login') {
    title = 'Login';
    subtitle = '';
  }
  if (typeOfAuth === 'create_account') {
    title = '';
    subtitle = '';
  }
  return (
    <OnboardingFlow.Inner>
      {(title || subtitle) && <OnboardingFlow.Header title={title} subtitle={subtitle} />}

      {newUser.auth_method === 'email' && (
        <React.Fragment>
          <OnboardingFlow.Body>
            <GoogleAuth
              authMethod="get_user_google_info"
              isLoading={isSubmitLoading}
              domain={currentCompany?.domain}
              onSuccess={user => {
                const updatedUser = update(newUser, {
                  first_name: { $set: user.first_name },
                  last_name: { $set: user.last_name },
                  email: { $set: user.email },
                  image: { $set: user.image },
                  auth_method: { $set: 'google' },
                  google_access_token: { $set: user.google_access_token },
                });
                if (typeOfAuth === 'login') {
                  onGoogleAuthSuccess(updatedUser);
                } else {
                  onStateChange('newUser', updatedUser);
                }
              }}
            />
          </OnboardingFlow.Body>
          <OrDivider />
        </React.Fragment>
      )}

      {newUser.auth_method === 'email' && (
        <OnboardingFlow.Body>
          {(['signup'].includes(typeOfAuth) || isNameRequired) && (
            <Flex gap="16px">
              <InputGroup
                type="input"
                value={newUser.first_name}
                label="First Name"
                placeholder="First name"
                field="first_name"
                onChange={onNewUserChange}
                width="50%"
                autoFocus
              />
              <InputGroup
                type="input"
                value={newUser.last_name}
                label="Last Name"
                placeholder="Appleseed"
                field="last_name"
                onChange={onNewUserChange}
                width="50%"
              />
            </Flex>
          )}
          <InputGroup
            type="input"
            value={newUser.email}
            label="Work Email"
            sublabel={hasCompany && currentCompany.domain && `Please use your ${currentCompany.domain} email`}
            placeholder={hasCompany && currentCompany.domain ? `name@${currentCompany.domain}` : 'name@company.com'}
            field="email"
            onChange={onNewUserChange}
            width="100%"
            autoFocus={typeOfAuth === 'login'}
          />
        </OnboardingFlow.Body>
      )}

      {newUser.auth_method === 'google' && (
        <OnboardingFlow.Body>
          <Flex p={theme.padding.md} borderRadius={theme.radii.md} border={theme.layout.border} justify="space-between">
            <InfoGroup
              title={`${newUser.first_name} ${newUser.last_name}`}
              subtitle={newUser.email}
              avatarSrc={newUser.image}
              showAvatarBadge
              customAvatarBadgeIcon={<GoogleIcon />}
              noSpaceBetween={false}
            />
            <Tooltip label="Remove Google Account" placement="top">
              <IconButton
                icon={<CloseIcon size={16} />}
                isRound
                variant="ghost"
                onClick={() => {
                  const updatedUser = update(newUser, {
                    first_name: { $set: null },
                    last_name: { $set: null },
                    email: { $set: null },
                    image: { $set: null },
                    auth_method: { $set: 'email' },
                    google_access_token: { $set: null },
                  });
                  onStateChange('newUser', updatedUser);
                }}
              />
            </Tooltip>
          </Flex>
        </OnboardingFlow.Body>
      )}

      <OnboardingFlow.Footer>
        <Button variant="solid" colorScheme="brand" isLoading={isSubmitLoading} isDisabled={isSubmitLoading} type="submit">
          {nextButtonText}
        </Button>
      </OnboardingFlow.Footer>

      {typeOfAuth === 'signup' && (
        <OnboardingFlow.PostText onClick={onPreviousStepClick}>
          <div>Go to previous step</div>
        </OnboardingFlow.PostText>
      )}

      {typeOfAuth === 'login' && (
        <OnboardingFlow.PostText onClick={onPreviousStepClick}>
          Don't have an account?{' '}
          <Link to={location.pathname.replace('/login', '')} className="primary-link">
            Sign up
          </Link>
        </OnboardingFlow.PostText>
      )}

      {newUser.auth_method === 'email' &&
        typeOfAuth === 'create_account' && (
          <React.Fragment>
            {isNameRequired ? (
              <OnboardingFlow.PostText onClick={onPreviousStepClick}>
                Been here before?{' '}
                <span className="primary-link" onClick={() => setIsNameRequired(false)}>
                  Login
                </span>
              </OnboardingFlow.PostText>
            ) : (
              <OnboardingFlow.PostText onClick={onPreviousStepClick}>
                Don't have an account?{' '}
                <span className="primary-link" onClick={() => setIsNameRequired(true)}>
                  Sign up
                </span>
              </OnboardingFlow.PostText>
            )}
          </React.Fragment>
        )}
    </OnboardingFlow.Inner>
  );
};

export default BasicInfo;
