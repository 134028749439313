import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
import Flex from 'components/Flex';

const Styled = styled.div``;

const WelcomeModal = ({ isOpen, onClose, currentUserFirstName }) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === 'ArrowRight' || event.key === 'Enter') onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <Modal.Body>
        <Text fontSize={theme.fontSizes.xl} color={theme.colors.primaryText} fontFamily={theme.fonts.semiBold}>
          👋 Welcome {currentUserFirstName}!
        </Text>
        <Text color={theme.colors.paragraphText}>Here's a quick walkthrough showing you how to create your Spec.</Text>
        <Text color={theme.colors.accentText} fontFamily={theme.fonts.light} as="i">
          Press Start or use your arrow keys
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button colorScheme="brand" variant="solid" onClick={onClose} width="100%">
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
