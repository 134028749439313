import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Text } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/typography/text
const TextComponent = ({
  children,
  fontSize = theme.fontSizes.base,
  color = theme.colors.paragraphText,
  fontFamily = theme.fonts.medium,
  className,
  noOfLines,
  as,
  casing,
  ...props
}) => {
  return (
    <Text
      fontSize={fontSize}
      color={color}
      fontFamily={fontFamily}
      className={className}
      noOfLines={noOfLines}
      as={as}
      casing={casing}
      {...props}>
      {children}
    </Text>
  );
};

export default TextComponent;
