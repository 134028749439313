import { randomID } from 'helpers/utils';
import { LeftAlignIconOutline, TrashIconOutline, EyeIconOutline, EyeOffIconOutline, CopyIconOutline, LinkIcon } from 'assets/icons';
import { theme } from 'styles/theme';
import update from 'immutability-helper';

export const returnIfAnswerValid = (sectionType, answer) => {
  if (!answer) return false;
  if (sectionType === 'questions') {
    return !!answer.answer && !!answer.option && answer.answer !== `<p class="editor-paragraph"><br></p>` && !answer.is_deleted;
  } else if (sectionType === 'spectrum') {
    return !!answer.option && !!answer.option_two && !answer.is_deleted && answer.answer !== null;
  } else if (sectionType === 'freeform') {
    return !!answer.answer && !answer.is_deleted && answer.answer !== `<p class="editor-paragraph"><br></p>`;
  } else if (sectionType === 'work_history') {
    return answer.json_answer && (answer.json_answer.job_title || answer.json_answer.company) && !answer.is_deleted;
  }

  return !!answer.answer && !answer.is_deleted;
};

export const returnIfSectionHasAnswers = section => {
  if (section.is_deleted) return false;
  const sectionType = section.section_type;
  return section.answers.findIndex(answer => returnIfAnswerValid(sectionType, answer)) > -1;
};

export const returnEmptySectionAnswer = (sectionType, position = 50, defaultAnswer = '', defaultOption = '', defaultOptionTwo = '') => {
  if (sectionType === 'spectrum') {
    defaultAnswer = 50;
  }

  return {
    front_id: randomID(),
    option: defaultOption,
    answer: defaultAnswer,
    option_two: defaultOptionTwo,
    json_answer: {},
    node_answer: null,
    position: position,
    is_custom: true,
    is_deleted: false,
  };
};

export const returnFormattedSectionViaLibrary = sectionData => {
  return {
    front_id: randomID(),
    title: sectionData.title,
    subtitle: sectionData.subtitle,
    is_subtitle_enabled: sectionData.is_subtitle_enabled || false,
    body_html: sectionData.body_html,
    body_node: sectionData.body_node,
    section_type: sectionData.section_type,
    answers: sectionData.answers,
    options_bank: sectionData.options_bank,
    is_hidden: false,
    is_custom: false,
    position: 50,
    is_required: false,
    total_comments: 0,
    reactions: [],
    comments: [],
  };
};

export const returnEmptySection = sectionType => {
  let defaultAnswers = [
    {
      front_id: randomID(),
      option: '',
      answer: '',
      option_two: '',
      json_answer: {},
      node_answer: null,
      position: 1,
      is_custom: true,
      is_deleted: false,
    },
  ];
  let defaultTitle = '';

  if (sectionType === 'topics' || sectionType === 'images') {
    defaultAnswers = [];
  } else if (sectionType === 'myers_briggs') {
    defaultTitle = 'Myers–Briggs Results';
  } else if (sectionType === 'zodiac_sign') {
    defaultTitle = 'Zodiac Sign';
  } else if (sectionType === 'big_five') {
    defaultTitle = 'Big Five Personality Results';
    defaultAnswers = [
      {
        front_id: randomID(),
        option: 'Openness',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 1,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: 'Conscientiousness',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 2,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: 'Extraversion',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 3,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: 'Agreeableness',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 4,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: 'Neuroticism',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 5,
        is_custom: true,
        is_deleted: false,
      },
    ];
  } else if (sectionType === 'strengthsfinder') {
    defaultTitle = 'Strengthsfinder Themes';
    defaultAnswers = [
      {
        front_id: randomID(),
        option: '',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 1,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: '',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 2,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: '',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 3,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: '',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 4,
        is_custom: true,
        is_deleted: false,
      },
      {
        front_id: randomID(),
        option: '',
        answer: '',
        option_two: '',
        json_answer: '',
        node_answer: null,
        position: 5,
        is_custom: true,
        is_deleted: false,
      },
    ];
  } else if (sectionType === 'movies') {
    defaultTitle = 'Favorite Movies';
    defaultAnswers = [];
  } else if (sectionType === 'tv') {
    defaultTitle = 'Favorite TV Shows';
    defaultAnswers = [];
  } else if (sectionType === 'books') {
    defaultTitle = 'Favorite Books';
    defaultAnswers = [];
  } else if (sectionType === 'spectrum') {
    defaultAnswers = [
      {
        front_id: randomID(),
        option: '',
        answer: 50,
        option_two: '',
        json_answer: {},
        node_answer: null,
        position: 0,
        is_custom: true,
        is_deleted: false,
      },
    ];
  } else if (sectionType === 'work_history') {
    defaultTitle = 'Work History';
  }

  return {
    front_id: randomID(),
    title: defaultTitle,
    body_html: '',
    body_node: '',
    section_type: sectionType,
    answers: defaultAnswers,
    options_bank: [],
    is_hidden: false,
    is_custom: true,
    position: 50,
    is_required: false,
    total_comments: 0,
    reactions: [],
    comments: [],
  };
};

export const returnSectionMenuItems = ({
  isEditMode,
  isSubtitleEnabled,
  isSectionHidden,
  sectionIndex,
  onToggleSubtitleEnabled,
  onDuplicateSection,
  onToggleHideSection,
  onCopyLinkToSection,
  onDeleteSection,
  isWithinTemplate,
}) => {
  if (isEditMode) {
    return [
      {
        label: isSubtitleEnabled ? 'Remove section description' : 'Add section description',
        icon: <LeftAlignIconOutline size={16} />,
        onClick: () => onToggleSubtitleEnabled(sectionIndex),
        disabled: isSectionHidden,
      },
      {
        label: isSectionHidden ? 'Make section visible' : 'Hide section',
        icon: isSectionHidden ? <EyeIconOutline size={16} /> : <EyeOffIconOutline size={16} />,
        onClick: () => onToggleHideSection(sectionIndex),
      },
      { label: 'Duplicate section', icon: <CopyIconOutline size={16} />, onClick: () => onDuplicateSection(sectionIndex) },
      {
        label: 'Copy link to section',
        icon: <LinkIcon size={16} />,
        onClick: () => onCopyLinkToSection(sectionIndex),
        disabled: isWithinTemplate,
      },
      {
        label: 'Delete section',
        icon: <TrashIconOutline size={16} />,
        color: theme.colors.redText,
        onClick: onDeleteSection,
      },
    ];
  } else {
    return [{ label: 'Copy link to section', icon: <LinkIcon size={16} />, onClick: () => onCopyLinkToSection(sectionIndex) }];
  }
};

export const returnNewReactions = (existingReactions, selectedEmoji, currentUserSlug) => {
  const existingReactionIndex = existingReactions.findIndex(reaction => reaction.emoji === selectedEmoji);
  let selectedReaction;
  if (existingReactionIndex !== -1) selectedReaction = existingReactions[existingReactionIndex];
  let newReactions;
  if (existingReactionIndex === -1) {
    newReactions = [...existingReactions, { emoji: selectedEmoji, count: 1, users: [currentUserSlug] }];
  } else if (selectedReaction.users.includes(currentUserSlug)) {
    // Remove user slug from array
    // Decrement count
    // If count is 0, remove reaction entirely
    const newUsers = selectedReaction.users.filter(slug => slug !== currentUserSlug);
    const newCount = selectedReaction.count - 1;
    if (newCount === 0) {
      newReactions = existingReactions;
      newReactions.splice(existingReactionIndex, 1);
    } else {
      newReactions = update(existingReactions, {
        [existingReactionIndex]: {
          users: { $set: newUsers },
          count: { $set: newCount },
        },
      });
    }
  } else {
    // Increment count
    // Add currentUserSlug to reaction users array
    let newUsers = selectedReaction.users;
    newUsers.push(currentUserSlug);
    const newCount = selectedReaction.count + 1;
    newReactions = update(existingReactions, {
      [existingReactionIndex]: {
        users: { $set: newUsers },
        count: { $set: newCount },
      },
    });
  }
  return newReactions;
};

export const returnUserListLinks = ({ userSlugs, returnLimit = 50, totalNumber, shouldLinkOthers }) => {
  if (userSlugs.length === 0) return '';
  if (userSlugs.length === 1) return `${returnUserLink(userSlugs[0])}`;
  if (userSlugs.length === 2) return `${returnUserLink(userSlugs[0])} and ${returnUserLink(userSlugs[1])}`;
  let html = '';
  for (var index = 0; index < userSlugs.length; index++) {
    const userSlug = userSlugs[index];
    if (index === userSlugs.length - 1) {
      html += `and ${returnUserLink(userSlug)}`;
    } else {
      html += `${returnUserLink(userSlug)}, `;
      if (index + 1 === returnLimit) {
        const amountOfOthers = totalNumber - returnLimit;
        if (shouldLinkOthers) {
          html += totalNumber
            ? `and <a href='https://spec.me/directory' class='brand-link'>${amountOfOthers} other${amountOfOthers === 1 ? '' : 's'}</a>`
            : `and <a href='https://spec.me/directory' class='brand-link'>others</a>`;
        } else {
          html += totalNumber ? `and ${amountOfOthers} other${amountOfOthers === 1 ? '' : 's'}` : `and others`;
        }
        break;
      }
    }
  }
  return html;
};

const returnUserLink = userSlug => {
  return `<a href='https://spec.me/${userSlug}' target='_blank' class='brand-link'>@${userSlug}</a>`;
};

export const returnBlankComment = (currentUser, isPrivate = false, body = null, parentCommentFrontID = null) => {
  return {
    front_id: randomID(),
    parent_comment_front_id: parentCommentFrontID,
    body: body,
    created_at: new Date(),
    likes_count: 0,
    likes_users: [],
    is_private: isPrivate,
    is_forced_private: isPrivate,
    is_draft: true,
    comments: [],
    reactions: [],
    user: {
      name: currentUser && currentUser.name,
      slug: currentUser && currentUser.slug,
      image: currentUser && currentUser.image,
    },
  };
};

export const returnDuplicateSection = (sectionToDuplicate, newSectionPosition) => {
  let newAnswers = [];
  sectionToDuplicate.answers.forEach(answer => {
    newAnswers.push(
      update(answer, {
        front_id: { $set: randomID() },
        json_answer: { $set: structuredClone(answer.json_answer) },
      }),
    );
  });
  const duplicateSection = update(sectionToDuplicate, {
    id: {
      $set: null,
    },
    front_id: {
      $set: randomID(),
    },
    title: {
      $set: sectionToDuplicate.title + ' (copy)',
    },
    is_hidden: {
      $set: false,
    },
    position: {
      $set: newSectionPosition,
    },
    answers: {
      $set: newAnswers,
    },
    total_comments: {
      $set: 0,
    },
    reactions: {
      $set: [],
    },
    comments: {
      $set: [],
    },
  });
  return duplicateSection;
};

export const returnGhostElement = dragEl => {
  const elHeight = dragEl.offsetHeight;
  const elWidth = dragEl.offsetWidth;
  const newHeight = (elHeight * 100) / elWidth;
  var element = document.createElement('div');
  element.id = 'drag-ghost';
  element.textNode = 'Dragging';
  element.style.position = 'absolute';
  element.style.top = '-1000px';
  element.style.height = newHeight + 'px';
  element.style.width = '100px';
  element.style.backgroundColor = 'white';
  element.style.borderRadius = '6px';
  element.style.border = '1px solid #E2E8F0';
  return element;
};
