import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from 'assets/icons';
import { Flex, SimpleGrid, AspectRatio, Tooltip } from '@chakra-ui/react';
import { returnIfAnswerValid } from 'helpers/specUtils';
import Sortable from 'components/Sortable';
import AnswerHandleGroup from 'componentsCustom/AnswerHandleGroup';
import Text from 'components/Text';
import Button from 'components/Button';
import Carousel from 'components/Carousel';
import Editable from 'components/Editable';
import ImageUpload from 'containers/ImageUpload';
import ApiSearchModal from 'containers/ApiSearchModal';

const Styled = styled.div``;

const StyledTile = styled.div`
  position: relative;
  background: ${theme.colors.grayFill};
  border-radius: ${theme.radii.md};
  padding-bottom: 100%;
  width: 100%;
  ${({ imageAspectRatio }) =>
    imageAspectRatio === 'poster' &&
    css`
      padding-bottom: 154%;
    `};
  ${({ bg }) =>
    bg &&
    css`
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `};
  .show-on-hover {
    opacity: 0;
    transition: opacity 0.35s ease;
  }
  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `};
`;

const HoverOverlay = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff95;
  backdrop-filter: blur(3px);
  border-radius: ${theme.radii.md};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AbsControls = styled.div`
  position: absolute;
  top: 8px;
  right: 0px;
`;

const AddButtonWrapper = styled.div`
  position: relative;
  height: 0px;
  padding-bottom: 100%;
  width: 100%;
  ${({ imageAspectRatio }) =>
    imageAspectRatio === 'poster' &&
    css`
      padding-bottom: 154%;
    `};
`;

const AddButton = styled(Button)`
  position: absolute;
  top: 0;
  background: white;
  border: 1px solid ${theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  height: 100%;
  width: 100%;
`;

const CarouselSection = ({
  section,
  isEditMode,
  sectionIndex,
  onSectionAnswerChange,
  onAnswerSelect,
  onDragSectionAnswerComplete,
  sortableContainerID,
  sortableHandleClassName,
  isDraggingMode,
  setIsDraggingMode,
  addButtonText = 'Add New',
  columns = 4,
  isCaptionEditable = false,
  imageAspectRatio = 1,
  apiSearchPlaceholder = 'Search',
  isViaAPI,
}) => {
  if (isEditMode) {
    return (
      <React.Fragment>
        <Sortable
          sortableContainerID={sortableContainerID}
          sortableHandleClassName={sortableHandleClassName}
          onDragStart={event => {
            setIsDraggingMode(true);
          }}
          onDragEnd={event => {
            setIsDraggingMode(false);
            onDragSectionAnswerComplete(section.front_id, event.oldIndex, event.newIndex);
          }}>
          <SimpleGrid id={sortableContainerID} columns={[2, 3, columns]} spacing="12px">
            {section.answers.map((answer, answerIndex) => {
              if (answer.is_deleted || (!isEditMode && !returnIfAnswerValid(section.section_type, answer)))
                return <div key={answer.front_id} className="hidden" />;
              return (
                <Tile
                  key={answer.front_id}
                  answer={answer}
                  sectionIndex={sectionIndex}
                  answerIndex={answerIndex}
                  onSectionAnswerChange={onSectionAnswerChange}
                  sortableHandleClassName={sortableHandleClassName}
                  isDraggingMode={isDraggingMode}
                  isEditMode={isEditMode}
                  isCaptionEditable={isCaptionEditable}
                  imageAspectRatio={imageAspectRatio}
                  isViaAPI={isViaAPI}
                />
              );
            })}
            <AddButtonWrapper className="not-sortable" imageAspectRatio={imageAspectRatio}>
              {section.section_type === 'images' && (
                <ImageUpload
                  uploadPreset="spec_section_image"
                  folderName="spec_section_images"
                  onUpload={(field, imageURL) =>
                    onAnswerSelect(sectionIndex, section.section_type, { option: '', option_two: '', answer: imageURL })
                  }
                  customButton={
                    <AddButton variant="outline" leftIcon={<PlusIcon />} as="div">
                      {addButtonText}
                    </AddButton>
                  }
                />
              )}

              {['movies', 'tv', 'books'].includes(section.section_type) && (
                <ApiSearchModal
                  selectedResults={section.answers.map(answer => !answer.is_deleted && answer.answer)}
                  apiToSearch={section.section_type}
                  searchPlaceholder={apiSearchPlaceholder}
                  isCentered={false}
                  onSelectResult={(title, image) =>
                    onAnswerSelect(sectionIndex, section.section_type, { option: image, option_two: title, answer: title })
                  }>
                  <AddButton variant="outline" leftIcon={<PlusIcon />} as="div">
                    {addButtonText}
                  </AddButton>
                </ApiSearchModal>
              )}
            </AddButtonWrapper>
          </SimpleGrid>
        </Sortable>
      </React.Fragment>
    );
  }

  return (
    <Carousel slidesToShow={columns}>
      {section.answers.map((answer, answerIndex) => {
        if (answer.is_deleted || (!isEditMode && !returnIfAnswerValid(section.section_type, answer))) return null;
        return (
          <Tile
            key={answer.front_id}
            index={answerIndex}
            answer={answer}
            sectionIndex={sectionIndex}
            answerIndex={answerIndex}
            onSectionAnswerChange={onSectionAnswerChange}
            sortableHandleClassName={sortableHandleClassName}
            isDraggingMode={isDraggingMode}
            isEditMode={isEditMode}
            imageAspectRatio={imageAspectRatio}
            isViaAPI={isViaAPI}
            sectionTitle={section.title}
          />
        );
      })}
    </Carousel>
  );
};

const Tile = ({
  answer,
  sectionIndex,
  answerIndex,
  onSectionAnswerChange,
  sortableHandleClassName,
  isDraggingMode,
  isEditMode,
  isCaptionEditable,
  aspectRatio,
  imageAspectRatio,
  isViaAPI,
  sectionTitle,
}) => {
  const navigate = useNavigate();
  return (
    <Tooltip label={!isEditMode && isViaAPI && 'Click to see who else selected this'}>
      <StyledTile
        key={answer.front_id}
        bg={answer.answer.includes('http') ? answer.answer : answer.option}
        id={`answer-${answer.front_id}-element`}
        imageAspectRatio={imageAspectRatio}
        isClickable={!isEditMode && isViaAPI}
        onClick={() => {
          if (!isEditMode && isViaAPI) {
            navigate(
              `directory?query=true&display=${sectionTitle}&query_type=spec_section_answer&query_field=spec_section_title_answer&query_value=${
                answer.answer
              }`,
            );
          }
        }}>
        <HoverOverlay className={isDraggingMode ? 'hidden' : 'show-on-hover'}>
          {isEditMode || answer.option_two ? (
            <Text size={theme.fontSizes.sm} color={theme.colors.primaryText} noOfLines={5} align="center" padding="0 8px" as="div">
              <Editable
                value={answer.option_two}
                field="option_two"
                placeholder="Add caption..."
                inputType="textarea"
                showCompleteButton
                inputStyle={{ maxHeight: '50px' }}
                isDisabled={!isEditMode || !isCaptionEditable}
                onChange={(field, value) => onSectionAnswerChange(sectionIndex, answerIndex, field, value)}
              />
            </Text>
          ) : (
            <Text size={theme.fontSizes.sm} color={theme.colors.primaryText} noOfLines={5} align="center" padding="0 8px" as="i">
              No caption
            </Text>
          )}

          {isEditMode && (
            <AbsControls>
              <AnswerHandleGroup
                onDeleteClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
                dragHandleClassName={sortableHandleClassName}
                deleteIconColor={theme.colors.primaryText}
                dragHandleColor={theme.colors.primaryText}
              />
            </AbsControls>
          )}
        </HoverOverlay>
      </StyledTile>
    </Tooltip>
  );
};

export default CarouselSection;
