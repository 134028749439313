import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import moment from 'moment';
import Table from 'components/Table';
import InfoGroup from 'components/InfoGroup';
import Pagination from 'components/Pagination';
import Flex from 'components/Flex';
import Text from 'components/Text';

const Styled = styled.div``;

const Footer = styled(Flex)`
  padding: ${theme.padding.lg};
`;

const RecipientsList = ({ recipients, numberOfTotalRecipients, getInviteBatchRecipients }) => {
  return (
    <React.Fragment>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadTitle>User</Table.HeadTitle>
            <Table.HeadTitle>Status</Table.HeadTitle>
            <Table.HeadTitle>Joined On</Table.HeadTitle>
            <Table.HeadTitle>Spec Link</Table.HeadTitle>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {recipients.map(recipient => {
            const user = recipient.user;
            return (
              <Table.Row key={recipient.id}>
                <Table.BodyResult>
                  <Link as={user.has_spec ? RouterLink : Styled} to={`/${user.slug}`}>
                    <InfoGroup
                      avatarSrc={user.image || '-'}
                      title={user.name || user.email}
                      subtitle={user.name && user.email}
                      titleFontFamily={theme.fonts.medium}
                      subtitleAs="i"
                      subtitleFontColor={theme.colors.accentText}
                      noSpaceBetween={false}
                    />
                  </Link>
                </Table.BodyResult>

                <Table.BodyResult fontStyle="italic">{recipient.did_recipient_join ? 'Joined' : 'Pending'}</Table.BodyResult>

                <Table.BodyResult>{recipient.did_recipient_join ? moment(user.created_at).format('L') : '–'}</Table.BodyResult>

                <Table.BodyResult color={user.has_spec && theme.colors.accent}>
                  {user.has_spec ? (
                    <Link as={RouterLink} to={`/${user.slug}`} className="brand-link">
                      @{user.slug}
                    </Link>
                  ) : (
                    '–'
                  )}
                </Table.BodyResult>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Footer gap="10px" justify="flex-end">
        <Text fontFamily={theme.fonts.light}>
          {numberOfTotalRecipients} total result{numberOfTotalRecipients === 1 ? '' : 's'}
        </Text>
        <Pagination
          numberOfResults={numberOfTotalRecipients}
          resultsPerPage={12}
          onPageChange={e => e && getInviteBatchRecipients(e.selected + 1)}
        />
      </Footer>
    </React.Fragment>
  );
};

export default RecipientsList;
