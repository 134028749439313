import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import InfoGroup from 'components/InfoGroup';
import Flex from 'components/Flex';
import ShareLink from 'containers/ShareLink';

const PublishSuccessModal = ({ onClose }) => {
  const initialRef = useRef(null);
  const params = useParams();

  return (
    <Modal isOpen onClose={onClose} size="md" modalContentBackground="transparent" constrainWidthMobile initialFocusRef={initialRef}>
      <Modal.Body background="white" borderRadius={theme.radii.lg}>
        <InfoGroup
          avatarSrc="https://res.cloudinary.com/teamspec/image/upload/v1656960474/image_processing20200615-28257-9ojcb8_ilofhp.gif"
          avatarSize="md"
          avatarBorderRadius={theme.radii.md}
          spaceBetween="16px"
          noSpaceBetween={false}
          title="Your Spec has been published!"
          subtitle="Share with teammates to showcase who you are and how you work."
        />
      </Modal.Body>
      <Modal.Body mt="20px" background="white" borderRadius={theme.radii.lg}>
        <ShareLink
          shareDisplayURL={`https://spec.me/${params.slug}`}
          shareURL={`https://spec.me/${params.slug}`}
          entityType="spec"
          entitySlug={params.slug}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PublishSuccessModal;
