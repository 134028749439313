import React from 'react';
import 'styles/global.css';
import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import GlobalRoutes from 'global/GlobalRoutes';
import { theme } from 'styles/theme';
// Redux imports
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from 'reduxx/mainReducer';
import GlobalLoader from 'global/GlobalLoader';
const store = createStore(reducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GlobalLoader>
      <ChakraProvider theme={theme}>
        <GlobalRoutes />
      </ChakraProvider>
    </GlobalLoader>
  </Provider>,
);
