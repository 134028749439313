import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle } from 'styles/theme';
import { Tooltip } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Slider from 'components/Slider';
import InputGroup from 'components/InputGroup';
import AnswerHandleGroup from 'componentsCustom/AnswerHandleGroup';

const StyledFlex = styled(Flex)`
  ${editTileStyle};
  align-items: stretch;
`;

const InputWrapper = styled(Flex)`
  min-height: 44px;
  width: 130px;
  ${({ isRight }) =>
    isRight &&
    css`
      border-right: 1px solid ${theme.colors.border};
    `};
`;

const SliderWrapper = styled(Flex)`
  border-left: 1px solid ${theme.colors.border};
  border-right: 1px solid ${theme.colors.border};
  padding: 0 16px;
  min-height: 44px;
  flex-grow: 1;
`;

const EditTile = ({
  answer,
  onSectionAnswerChange,
  sectionIndex,
  answerIndex,
  sortableHandleClassName,
  answerElementID,
  isDraggingMode,
}) => {
  return (
    <StyledFlex id={answerElementID}>
      <InputWrapper>
        <InputGroup
          type="textarea"
          value={answer.option}
          placeholder="Option..."
          field="option"
          onChange={(field, value) => onSectionAnswerChange(sectionIndex, answerIndex, field, value)}
          variant="unstyled"
          p="10px 16px"
          autoComplete="off"
          minRows="1"
          maxHeight="78px"
        />
      </InputWrapper>

      <SliderWrapper>
        <Slider
          value={answer.answer === null ? 50 : answer.answer}
          onChange={value => onSectionAnswerChange(sectionIndex, answerIndex, 'answer', value)}
          min={0}
          max={100}
          step={25}
          sliderTrackFilledColor={theme.colors.grayFill}
          sliderThumbColor={theme.colors.primaryText}
          sliderThumbBoxShadow="none"
          sliderThumbSize="16px"
        />
      </SliderWrapper>

      <InputWrapper isRight>
        <InputGroup
          type="textarea"
          value={answer.option_two}
          placeholder="Option..."
          field="option_two"
          onChange={(field, value) => onSectionAnswerChange(sectionIndex, answerIndex, field, value)}
          variant="unstyled"
          p="10px 16px"
          textAlign="right"
          autoComplete="off"
          minRows="1"
        />
      </InputWrapper>

      <AnswerHandleGroup
        onDeleteClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
        dragHandleClassName={sortableHandleClassName}
        className="show-always"
      />
    </StyledFlex>
  );
};

export default EditTile;
