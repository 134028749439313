import { css } from '@emotion/react';
import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: '#111111',
    accent: '#0D99FF', // blue
    accentLight: '#e5f4ff',
    primaryText: '#1a202c',
    primaryBrand: '#EA4C89',
    paragraphText: '#3C4257',
    accentText: '#868F9A',
    lightAccentText: '#BDC4CF',
    placeholderText: '#718096',
    grayBackground: '#F8FAFC',
    border: '#E2E8F0',
    gray200: '#E2E8F0',
    grayFill: '#edf2f7',
    redText: '#C53030',
    lightRedText: '#FC8181',
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    brand: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#111111',
      600: '#353535',
      700: '#000000',
      800: 'red',
      900: 'green',
    },
    blue: {
      50: '#E5F4FF',
      100: '#B8E1FF',
      200: '#8ACEFF',
      300: '#5CBAFF',
      400: '#2EA7FF',
      500: '#0094FF',
      600: '#0076CC',
      700: '#005999',
      800: '#003B66',
      900: '#001E33',
    },
    lightBlue: {
      500: '#e5f4ff', // base color
      600: '#b8e1ff', // hover color
      700: '#8aceff', // active color (clicked)
      800: '#5cbbff',
      900: '#2ea8ff',
    },
  },
  padding: {
    none: '0',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '22px',
    xl: '28px',
    xxl: '40px',
    smButton: '10px 12px',
  },
  margin: {
    none: '0',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '22px',
    xl: '28px',
    xxl: '40px',
  },
  spacing: {
    none: '0',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '22px',
    xl: '28px',
    xxl: '40px',
    sectionElementsGap: '14px',
  },
  layout: {
    columnWidth: '750px',
    mobileBreakpoint: '750px',
    border: '1px solid #E2E8F0',
    expandedAdminColumnWidth: '900px',
  },
  radii: {
    none: '0',
    sm: '4px',
    base: '6px',
    md: '6px',
    lg: '8px',
    xl: '10px',
    xxl: '14px',
    full: '9999px',
  },
  fonts: {
    body: 'medium, -apple-system, system-ui, sans-serif',
    heading: 'bold, -apple-system, system-ui, sans-serif',
    mono: 'light, -apple-system, system-ui, sans-serif',
    bold: 'bold, -apple-system, system-ui, sans-serif',
    semiBold: 'semiBold, -apple-system, system-ui, sans-serif',
    medium: 'medium, -apple-system, system-ui, sans-serif',
    light: 'light, -apple-system, system-ui, sans-serif',
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    base: '14px',
    lg: '16px',
    xl: '18px',
    xxl: '20px',
    xxxl: '22px',
    headerOne: '20px',
  },
  fontWeights: {
    hairline: 'auto',
    thin: 'auto',
    light: 'auto',
    normal: 'auto',
    medium: 'auto',
    semibold: 'auto',
    bold: 'auto',
    extrabold: 'auto',
    black: 'auto',
  },
  lineHeights: {
    none: 1,
    xs: 1.25,
    sm: 1.375,
    md: 1.5,
    lg: 1.625,
    xl: 2,
  },
  breakpoints: {
    sm: '370px',
    md: '750px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
});

export const defaultHoverTransition = css`
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
`;

export const columnLayoutStyle = css`
  margin: 0 auto;
  width: ${theme.layout.columnWidth};
  max-width: 100vw;
`;

export const containerStyle = css`
  position: relative;
  border-radius: ${theme.radii.lg};
  background: white;
  padding: ${theme.padding.lg};
  width: 100%;
  border: 1px solid ${theme.colors.border};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    border-radius: 0px;
    width: 100vw;
    border-left: 0px;
    border-right: 0px;
  }
`;

export const containerStyleMobileConstrainedWidth = css`
  @media screen and (max-width: ${theme.breakpoints.md}) {
    border-radius: ${theme.radii.lg};
    border-left: 1px solid ${theme.colors.border};
    border-right: 1px solid ${theme.colors.border};
    width: calc(100vw - 40px);
  }
`;

export const multiLineText = css`
  white-space: pre-wrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

export const companyAvatarStyle = css`
  height: 40px;
  width: 40px;
  border-radius: ${theme.radii.md};
`;

export const editTileStyle = css`
  background: white;
  border-radius: ${theme.radii.md};
  border: 1px solid ${theme.colors.border};
  width: 100%;
  .answer-handles-show-on-hover {
    opacity: 0;
    transition: opacity 0.35s ease;
  }
  &:hover {
    .answer-handles-show-on-hover {
      opacity: 1;
    }
  }
`;

export const disabledOverlay = css`
  position: relative;
  opacity: 0.4;
  cursor: not-allowed;
  &::after {
    content: '';
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: not-allowed;
    z-index: 1;
  }
`;
