import React from 'react';
import { slackRedirectURI } from 'data/constants';

const ConnectSlackButton = () => {
  const href = `https://slack.com/oauth/v2/authorize?client_id=4067878816146.4053335285447&scope=commands,chat:write,team:read,usergroups:read,users.profile:read,users:read,users:read.email,im:write,mpim:write,channels:read&redirect_uri=${encodeURIComponent(
    slackRedirectURI,
  )}`;
  return (
    <a href={href}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};

export default ConnectSlackButton;
