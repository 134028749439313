import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import { PlusIcon } from 'assets/icons';
import Button from 'components/Button';
import Flex from 'components/Flex';

const Styled = styled.button`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`;

const UploadBox = styled(Box)`
  width: 80px;
  height: 80px;
  border-radius: 200px;
  border: 2px solid ${theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `};
  ${({ uploadBoxWidth }) =>
    uploadBoxWidth &&
    css`
      width: ${uploadBoxWidth};
    `};
  ${({ uploadBoxHeight }) =>
    uploadBoxHeight &&
    css`
      height: ${uploadBoxHeight};
    `};
  ${({ uploadBoxBorderRadius }) =>
    uploadBoxBorderRadius &&
    css`
      border-radius: ${uploadBoxBorderRadius};
    `};
`;

class ImageUpload extends React.Component {
  state = {
    didScriptLoad: false,
  };

  componentDidMount() {
    if (window.cloudinary) {
      this.setState({ didScriptLoad: true });
    } else {
      if (!document.getElementById('cloud-upload')) {
        const script = document.createElement('script');
        script.id = 'cloud-upload';
        script.type = 'application/javascript';
        script.src = 'https://upload-widget.cloudinary.com/global/all.js';
        script.async = true;
        script.addEventListener('load', () => this.setState({ didScriptLoad: true }));
        document.body.appendChild(script);
      } else {
        this.setState({ didScriptLoad: true });
      }
    }
  }

  componentWillUnmount() {
    if (this.myWidget) this.myWidget.destroy();
  }

  initialize = (shouldOpen = false) => {
    const {
      uploadPreset,
      folderName,
      aspectRatio = 1,
      sources = ['local', 'url', 'camera', 'instagram', 'facebook', 'google_drive', 'dropbox'],
      allowedFormats = ['image'],
    } = this.props;
    this.myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'teamspec',
        uploadPreset: uploadPreset,
        cropping: true, //add a cropping step
        // showAdvancedOptions: true,  //add advanced options (public_id and tag)
        sources: sources, // restrict the upload sources to URL and local files
        multiple: false, //restrict upload to a single file
        folder: folderName, //upload files to the specified folder
        showSkipCropButton: false,
        cropping_aspect_ratio: aspectRatio,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#868F9A',
            tabIcon: '#3C4257',
            menuIcons: '#5A616A',
            textDark: '#1a202c',
            textLight: '#FFFFFF',
            link: '#111111',
            action: '#0D99FF',
            inactiveTabIcon: '#868F9A',
            error: '#C53030',
            inProgress: '#111111',
            complete: '#0D99FF',
            sourceBg: '#FFF',
          },
          frame: {
            background: 'rgba(0, 0, 0, 0.64)',
          },
          fonts: {
            "'Inter', sans-serif": 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
          },
        },
        // tags: ["users", "profile"], //add the given tags to the uploaded files
        // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
        client_allowed_formats: allowedFormats, //restrict uploading to image files only
        showPoweredBy: false,
        // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
        // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
        // theme: 'purple', //change to a purple theme
      },
      (error, result) => {
        const event = result && result.event;
        if (!error && event === 'success') {
          this.onSuccess(result.info.secure_url, result.info.public_id);
        }
      },
    );
    if (shouldOpen) this.myWidget.open();
  };

  onSuccess(imageURL, publicID) {
    const { field, onUpload } = this.props;
    onUpload(field, imageURL, publicID);
  }

  onToggleOpen = () => {
    const { didScriptLoad, didInitialize } = this.state;
    if (didScriptLoad) {
      if (didInitialize) {
        this.myWidget.open();
      } else {
        this.setState({ didInitialize: true }, () => {
          this.initialize(true);
        });
      }
    }
  };

  render() {
    const { didScriptLoad } = this.state;
    const {
      value,
      customButton,
      width,
      uploadBoxHeight,
      uploadBoxWidth,
      uploadBoxBorderRadius,
      buttonVariant = 'outline',
      buttonText = 'Choose Image',
    } = this.props;
    return (
      <Styled onClick={this.onToggleOpen} disabled={!didScriptLoad} width={width} type="button">
        {customButton ? (
          customButton
        ) : (
          <Flex align="center">
            <UploadBox
              image={value}
              uploadBoxHeight={uploadBoxHeight}
              uploadBoxWidth={uploadBoxWidth}
              uploadBoxBorderRadius={uploadBoxBorderRadius}>
              {!value && <PlusIcon />}
            </UploadBox>
            <Button variant={buttonVariant} as="div">
              {buttonText}
            </Button>
          </Flex>
        )}
      </Styled>
    );
  }
}

export default ImageUpload;
