import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { HiCloudUpload, HiEye, HiEmojiHappy } from 'react-icons/hi';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import IconButton from 'components/IconButton';

const StatTile = styled.div`
  ${containerStyle};
`;

const OverviewStats = ({ overviewStats }) => {
  return (
    <Flex gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]}>
      <StatTile>
        <InfoGroup
          title={`${overviewStats.specs_published} Specs Published`}
          noSpaceBetween={false}
          icon={<IconButton icon={<HiCloudUpload />} />}
        />
      </StatTile>

      <StatTile>
        <InfoGroup title={`${overviewStats.spec_views} Spec Views`} noSpaceBetween={false} icon={<IconButton icon={<HiEye />} />} />
      </StatTile>

      <StatTile>
        <InfoGroup
          title={`${overviewStats.spec_engagements} Spec Engagements`}
          noSpaceBetween={false}
          icon={<IconButton icon={<HiEmojiHappy />} />}
        />
      </StatTile>
    </Flex>
  );
};

export default OverviewStats;
