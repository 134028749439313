import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Flex } from '@chakra-ui/react';
import InfoGroup from 'components/InfoGroup';
import Switch from 'components/Switch';
import Checkbox from 'components/Checkbox';

const SwitchRow = ({ title, subtitle, isChecked, onChange, useCheck = false, isDisabled = false, showButton = true }) => {
  return (
    <Flex justify="space-between" align="center" minWidth="100%">
      <InfoGroup title={title} subtitle={subtitle} titleFontFamily={theme.fonts.medium} />
      {showButton && (
        <React.Fragment>
          {useCheck ? (
            <Checkbox isChecked={isChecked} onChange={onChange} isDisabled={isDisabled} />
          ) : (
            <Switch isChecked={isChecked} onChange={onChange} isDisabled={isDisabled} />
          )}
        </React.Fragment>
      )}
    </Flex>
  );
};

export default SwitchRow;
