import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Slider from 'components/Slider';
import Flex from 'components/Flex';
import Text from 'components/Text';

const Styled = styled(Flex)`
  font-size: 14px;
  font-family: 'light';
  color: ${theme.colors.paragraphText};
  width: 100%;
`;

const LiveAnswer = ({ answer }) => {
  return (
    <Styled>
      <Text fontFamily={theme.fonts.light} color={theme.colors.primaryText} w="116px" flexShrink={0} pr="12px">
        {answer.option}
      </Text>
      <Slider
        value={answer.answer}
        min={0}
        max={100}
        step={10}
        sliderTrackFilledColor={theme.colors.grayFill}
        sliderThumbColor={theme.colors.primaryText}
        sliderThumbBoxShadow="none"
        sliderThumbSize="16px"
        trackHeight="2px"
        isDisabled
      />
      <Text fontFamily={theme.fonts.light} color={theme.colors.primaryText} w="116px" flexShrink={0} pl="12px" textAlign="right">
        {answer.option_two}
      </Text>
    </Styled>
  );
};

export default LiveAnswer;
