import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Api } from 'helpers/utils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Loading from 'components/Loading';
import InputGroup from 'components/InputGroup';

const StyledBox = styled(Box)`
  ${containerStyle};
  padding: 0px;
  width: 50%;
`;

const BarChartComponent = ({ title, dataKey, timeframes, ...props }) => {
  const [data, setData] = useState([]);
  const [didLoad, setDidLoad] = useState(false);
  const [timeframe, setTimeframe] = useState(timeframes[0].value);

  useEffect(
    () => {
      Api.post({
        url: `/api/v1/admin/analytics/get_analytics_data`,
        body: { data_key: dataKey, timeframe: timeframe },
      }).then(data => {
        if (data.success) {
          setData(data.analytics_data);
          setDidLoad(true);
        }
      });
    },
    [timeframe],
  );

  return (
    <StyledBox {...props}>
      <Flex p={theme.padding.lg} borderBottom={theme.layout.border} justify="space-between">
        <Text color={theme.colors.primaryText} fontFamily={theme.fonts.semiBold}>
          {title}
        </Text>
        <InputGroup
          type="select"
          value={timeframe}
          width="auto"
          field="timeframe"
          onChange={(field, value) => setTimeframe(value)}
          selectOptions={timeframes}
          height="34px"
        />
      </Flex>
      <Box p={theme.padding.lg} minHeight="244px">
        {didLoad ? (
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={{ fontSize: 12 }} />
              <YAxis tick={{ fontSize: 12 }} />
              <Tooltip
                wrapperStyle={{
                  fontSize: 12,
                }}
              />
              <Bar dataKey="amount" fill={theme.colors.primary} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Loading />
        )}
      </Box>
    </StyledBox>
  );
};

export default BarChartComponent;
