import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import { Api } from 'helpers/utils';
import { createStandaloneToast } from '@chakra-ui/toast';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InputGroup from 'components/InputGroup';
import Text from 'components/Text';
import InfoGroup from 'components/InfoGroup';
import ShareLink from 'containers/ShareLink';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const MarkAsReviewedModal = ({
  specUser,
  isOpen,
  onClose,
  onMarkedAsReviewedCallback,
  doesCurrentUserHaveSpec,
  currentUserSlug,
  triggerToast,
}) => {
  const initialRef = useRef(null);
  const [comment, setComment] = useState(null);
  const [isCommentPrivateToReceiver, setIsCommentPrivateToReceiver] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);

  const onSubmit = () => {
    if (specUser.slug === currentUserSlug) {
      toast({
        title: "You can't mark your own Spec as reviewed, sadly.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } else {
      setIsSubmitLoading(true);
      Api.post({
        url: `/api/v1/specs/${specUser.slug}/mark_as_reviewed`,
        body: {
          comment: comment,
          is_comment_private_to_receiver: isCommentPrivateToReceiver,
        },
      }).then(data => {
        if (data.success) {
          setDidSubmit(true);
          setIsSubmitLoading(false);
          onMarkedAsReviewedCallback();
        } else {
          setIsSubmitLoading(false);
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    }
  };

  if (didSubmit) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        modalContentBackground="transparent"
        constrainWidthMobile
        initialFocusRef={initialRef}
        isCentered={!doesCurrentUserHaveSpec}>
        <Modal.Body background="white" borderRadius={theme.radii.lg}>
          <InfoGroup
            avatarSrc="https://res.cloudinary.com/teamspec/image/upload/v1656960474/image_processing20200615-28257-9ojcb8_ilofhp.gif"
            avatarSize="md"
            avatarBorderRadius={theme.radii.md}
            spaceBetween="16px"
            noSpaceBetween={false}
            title="Marked as reviewed!"
            subtitle={`We'll let ${specUser.first_name} know.`}
          />
        </Modal.Body>
        {doesCurrentUserHaveSpec ? (
          <Modal.Body mt="20px" background="white" borderRadius={theme.radii.lg}>
            <ShareLink
              shareDisplayURL={`https://spec.me/${specUser.slug}`}
              shareURL={`https://spec.me/${specUser.slug}`}
              entityType="spec"
              entitySlug={specUser.slug}
              sectionTitle={`Share ${specUser.first_name}'s Spec`}
            />
          </Modal.Body>
        ) : (
          <Modal.Body mt="20px" background="white" borderRadius={theme.radii.lg}>
            <Flex direction="column" gap="8px">
              <Text>Haven't created your own Spec yet?</Text>
              <Link to={`/${currentUserSlug}`}>
                <Button variant="solid" colorScheme="brand" as="div">
                  Create Spec
                </Button>
              </Link>
            </Flex>
          </Modal.Body>
        )}
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md" constrainWidthMobile>
      <ToastContainer />
      <Modal.Body>
        <InputGroup
          type="textarea"
          value={comment}
          label="Comment"
          placeholder="Optional"
          field="comment"
          rows="2"
          onChange={(field, value) => setComment(value)}
          isDisabled={isSubmitLoading}
        />
        <Flex justify="space-between" mt="16px">
          {comment ? (
            <InputGroup
              type="select"
              value={isCommentPrivateToReceiver}
              onChange={(field, value) => setIsCommentPrivateToReceiver(value)}
              isDisabled={isSubmitLoading}
              variant="flushed"
              maxWidth="230px"
              selectOptions={[
                { label: `Comment private to ${specUser.first_name}`, value: true },
                { label: 'Comment public to all', value: false },
              ]}
            />
          ) : (
            <span />
          )}

          <Flex>
            <Button variant="solid" colorScheme="brand" isDisabled={isSubmitLoading} isLoading={isSubmitLoading} onClick={onSubmit}>
              Mark as Reviewed
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default MarkAsReviewedModal;
