import React, { useState, useEffect } from 'react';
import 'styles/editor.css';
import { createEditor, $getRoot, $getSelection } from 'lexical';
import EditorTheme from './themes/EditorTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
// import AutoFocusPlugin from '@lexical/react/LexicalAutoFocusPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';

import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { isEqual } from 'helpers/utils';

const Placeholder = ({ placeholder }) => {
  return <div className="editor-placeholder">{placeholder}</div>;
};

const editorConfig = {
  // The editor theme
  theme: EditorTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

const editor = createEditor(editorConfig);
const contentEditableElement = document.getElementById('editor-input');
editor.setRootElement(contentEditableElement);

const StyledEditorContainer = styled.div`
  ${'' /* ${({ allowHeaders }) =>
    !allowHeaders &&
    css`
      .editor-heading-h1,
      .editor-heading-h2 {
        font-size: 14px;
        font-family: ${theme.fonts.light};
        line-height: auto;
        margin: 0;
        margin-bottom: 8px;
      }
    `}; */};
`;

const returnTransformers = allowHeaders => {
  let newTransformers = TRANSFORMERS;
  if (!allowHeaders) {
    const headerTransformerIndex = newTransformers.findIndex(
      item => !!item.regExp && item.type === 'element' && String(item.regExp) === '/^(#{1,6})\\s/',
    );
    if (headerTransformerIndex > -1) {
      newTransformers.splice(headerTransformerIndex, 1);
    }
  }
  return newTransformers;
};

const TextEditor = ({
  value,
  placeholder = 'Type here...',
  onHTMLChange,
  onNodeChange,
  editorID,
  allowHeaders = true,
  isStandalone,
  autoFocus = false,
  extraClassName,
}) => {
  const [editorEl, setEditorEl] = useState(null);

  useEffect(() => {
    const newEditorEl = document.getElementById(editorID);
    setEditorEl(newEditorEl);
  }, []);

  const onEditorChange = editorState => {
    if (!!editorEl) {
      if (onHTMLChange) onHTMLChange(editorEl.innerHTML);
      if (onNodeChange) onNodeChange(JSON.stringify(editorState.toJSON()));
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        editorState: value ? editor.parseEditorState(value) : undefined,
        ...editorConfig,
      }}>
      <StyledEditorContainer
        className={isStandalone ? `standalone-text-editor editor-container ${extraClassName}` : `editor-container ${extraClassName}`}
        id="editor-container"
        allowHeaders={allowHeaders}>
        <ToolbarPlugin allowHeaders={allowHeaders} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" id={editorID} />}
            placeholder={<Placeholder placeholder={placeholder} />}
          />
          <OnChangePlugin onChange={editorState => onEditorChange(editorState)} ignoreSelectionChange />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={returnTransformers(allowHeaders)} />
        </div>
      </StyledEditorContainer>
    </LexicalComposer>
  );
};

export default React.memo(TextEditor, (prevProps, nextProps) => {
  return isEqual(prevProps.value, nextProps.value);
});
