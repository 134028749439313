const MyersBriggs = () => {
  return (
    <svg width="328" height="46" viewBox="0 0 328 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="59" y="7" width="225" height="8" rx="4" fill="#E0E6ED" />
      <rect x="59" y="19" width="269" height="8" rx="4" fill="#E0E6ED" />
      <rect x="59" y="31" width="185" height="8" rx="4" fill="#E0E6ED" />
      <rect width="46" height="46" rx="23" fill="#E0E6ED" />
      <rect x="12" y="17" width="4" height="12" rx="2" fill="#F6F8FA" />
      <rect x="18" y="17" width="4" height="12" rx="2" fill="#F6F8FA" />
      <rect x="24" y="17" width="4" height="12" rx="2" fill="#F6F8FA" />
      <rect x="30" y="17" width="4" height="12" rx="2" fill="#F6F8FA" />
    </svg>
  );
};

export default MyersBriggs;
