import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, columnLayoutStyle } from 'styles/theme';
import { Api } from 'helpers/utils';
import { connect } from 'react-redux';
import Header from './Header';
import Results from './Results';
import UnauthNotice from './UnauthNotice';
import Footer from './Footer';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';
import TermsFooter from 'componentsCustom/TermsFooter';

const Container = styled.div`
  ${columnLayoutStyle};
`;

const Styled = styled.div`
  ${containerStyle};
  padding: 0px;
  min-height: 400px;
`;

class Activity extends React.Component {
  state = {
    activities: [],
    activityType: 'personal',
    pageNumber: 1,
    numberOfHiddenResults: 0,
  };

  componentDidMount() {
    const { isSignedIn } = this.props;
    if (isSignedIn) this.getActivity();
  }

  getActivity(shouldResetActivities = false) {
    const { activityType, activities, pageNumber } = this.state;
    this.setState({ activities: shouldResetActivities ? [] : activities, didLoad: false });
    Api.get({ url: `/api/v1/activities/get_activities/${activityType}/${pageNumber}` }).then(data => {
      if (data.success) {
        this.setState({
          activities: this.state.activities.concat(data.activities),
          didLoad: true,
          hasMoreResults: data.activities.length === 12,
          numberOfHiddenResults: data.number_of_hidden_results,
        });
      }
    });
  }

  onChangeActivityType = activityType => {
    this.setState({ activityType: activityType, pageNumber: 1 }, () => this.getActivity(true));
  };

  onLoadMore = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.getActivity());
  };

  render() {
    const { activities, didLoad, activityType, hasMoreResults, numberOfHiddenResults } = this.state;
    const { isSignedIn, hasCompany, currentCompany, currentUser } = this.props;

    if (!isSignedIn) {
      return (
        <Container>
          <Styled>
            <UnauthNotice hasCompany={hasCompany} currentCompany={currentCompany} />
          </Styled>
        </Container>
      );
    }
    const isFeatureGated = hasCompany && currentCompany.tier === 1;
    return (
      <React.Fragment>
        <Container>
          <Styled>
            <Header activityType={activityType} onChangeActivityType={this.onChangeActivityType} />
            <Results activities={activities} didLoad={didLoad} currentUserSlug={currentUser.slug} />
            {hasMoreResults && <Footer onLoadMore={this.onLoadMore} />}
          </Styled>
          {isFeatureGated &&
            numberOfHiddenResults > 0 && (
              <UpgradeCTA
                title={
                  numberOfHiddenResults > 1
                    ? `${numberOfHiddenResults} activities older than 7 days are hidden from results`
                    : 'Activity older than 7 days is hidden from results'
                }
                subtitle="Upgrade to Pro to view unlimited activity"
                ctaType="pageHeader"
                mt={theme.spacing.lg}
              />
            )}
        </Container>
        <TermsFooter />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentUserLoaded: state.currentReducer.currentUserLoaded,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(Activity);
