import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Api } from 'helpers/utils';
import { BLANK_SPEC_TEMPLATE } from 'data/specData';
import { withParams } from 'hooks/router';
import update from 'immutability-helper';
import Loading from 'components/Loading';
import Flex from 'components/Flex';
import Tabs from 'components/Tabs';
import NavHeader from './NavHeader';
import SaveFooter from './SaveFooter';
import Settings from './Settings';
import SpecsList from './SpecsList';
import Invite from './Invite';
import SpecSections from 'pages/Spec/SpecSections';

const Body = styled.div`
  ${containerStyle};
  padding: 0px;
  ${({ isEditable }) =>
    isEditable &&
    css`
      border-radius: ${theme.radii.lg} ${theme.radii.lg} 0 0;
      margin-bottom: 0px;
    `};
`;

class EditSpecTemplate extends React.Component {
  state = {
    template: {},
    specSections: [],
    admins: [],
    didLoad: false,
    isCreateMode: false,
    wereEditsMade: false,
    selectedTabIndex: 0,
  };

  componentDidMount() {
    this.getTemplate();
  }

  getTemplate() {
    const { params, triggerToast, currentUserID } = this.props;
    const slug = params.slug;
    Api.get({ url: `/api/v1/admin/spec_templates/${slug}/get_spec_template` }).then(data => {
      if (data.success) {
        if (slug === 'create') {
          const template = update(BLANK_SPEC_TEMPLATE, { creator_id: { $set: currentUserID } });
          this.setState({ template: template, admins: data.admins, isCreateMode: true, wereEditsMade: true, didLoad: true });
        } else {
          this.setState({ template: data.spec_template, specSections: data.spec_sections, admins: data.admins, didLoad: true });
        }
      } else {
        triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  onTemplateChange = (field, value, shouldSave) => {
    let didConfirm = true;
    if (field === 'is_deleted') didConfirm = window.confirm('Are you sure you want to delete this template? This action cannot be undone.');
    if (didConfirm) {
      if (field === 'html_landing_page_message' && value === `<p class=\"editor-paragraph\"><br></p>`) value = null;
      this.setState(
        prevState => {
          return {
            ...prevState,
            template: update(prevState.template, { [field]: { $set: value } }),
            wereEditsMade: true,
          };
        },
        () => {
          if (shouldSave) this.onSave();
        },
      );
    }
  };

  onUpdateSpecSectionsCallback = specSections => {
    this.setState({ specSections: specSections, wereEditsMade: true });
  };

  onCompletedSectionAnswersCallback = () => this.setState({ doesHaveCompletedSectionAnswers: true });

  returnValidation() {
    const { template, specSections, isCreateMode } = this.state;
    if (!template.title) return { success: false, error: 'Please enter a title for this template' };
    if (!isCreateMode && (!specSections || specSections.length === 0))
      return { success: false, error: 'Template must have at least one section' };
    return { success: true };
  }

  onSave = () => {
    const { template, specSections } = this.state;
    const { params, navigate, triggerToast, currentCompanySlug } = this.props;
    const validation = this.returnValidation();
    if (validation.success || template.is_deleted) {
      this.setState({ isSaveLoading: true });
      Api.post({
        url: `/api/v1/admin/spec_templates/${params.slug}/create_update_spec_template`,
        body: { spec_template: template, spec_sections: specSections },
      }).then(data => {
        if (data.success) {
          if (data.spec_template.is_deleted) {
            navigate(`/org/${currentCompanySlug}/admin/templates`);
            triggerToast({ title: 'Template deleted', status: 'success' });
          } else {
            this.setState({ isSaveLoading: false, wereEditsMade: false });
            let saveMessage = 'Changes saved';

            if (data.spec_template.slug !== params.slug) {
              navigate(`/org/${currentCompanySlug}/admin/templates/${data.spec_template.slug}`);
              this.setState({ template: data.spec_template, specSections: data.spec_sections, isCreateMode: false });
              saveMessage = 'Template created';
            }

            triggerToast({ title: saveMessage, status: 'success' });
          }
        } else {
          this.setState({ isSaveLoading: false });
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    } else {
      triggerToast({ title: validation.error, status: 'error' });
    }
  };

  render() {
    const {
      template,
      specSections,
      admins,
      didLoad,
      isCreateMode,
      wereEditsMade,
      isSaveLoading,
      selectedTabIndex,
      doesHaveCompletedSectionAnswers,
    } = this.state;
    const { currentCompanySlug, triggerToast, onCopyShareLink } = this.props;
    if (!didLoad) return <Loading />;
    const isEditable = [0, 1].includes(selectedTabIndex);
    return (
      <Flex direction="column" align="stretch" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]} key={template.slug}>
        {!isCreateMode && (
          <NavHeader
            template={template}
            onTemplateChange={this.onTemplateChange}
            currentCompanySlug={currentCompanySlug}
            onCopyShareLink={onCopyShareLink}
          />
        )}
        <Body isEditable={isEditable}>
          <Tabs
            tabs={isCreateMode ? [] : ['Settings', 'Sections', 'Specs', 'Invite']}
            isSticky
            isLazy
            onSelectIndex={index => this.setState({ selectedTabIndex: index })}>
            <Tabs.TabPanels>
              <Tabs.TabPanel padding={theme.padding.lg}>
                <Settings template={template} onTemplateChange={this.onTemplateChange} admins={admins} />
              </Tabs.TabPanel>

              <Tabs.TabPanel padding={[0, 0, theme.padding.lg]}>
                <SpecSections
                  isWithinTemplate
                  currentSpecSlug={template.slug}
                  specSections={specSections}
                  isEditMode
                  isCurrentUserCreator
                  isPublished
                  isSignedIn
                  triggerToast={triggerToast}
                  onCompletedAnswersCallback={this.onCompletedSectionAnswersCallback}
                  doesHaveCompletedAnswers={doesHaveCompletedSectionAnswers}
                  onUpdateSpecSectionsCallback={this.onUpdateSpecSectionsCallback}
                />
              </Tabs.TabPanel>

              <Tabs.TabPanel>
                <SpecsList specTemplateName={template.name} specTemplateSlug={template.slug} />
              </Tabs.TabPanel>

              <Tabs.TabPanel padding={theme.padding.lg}>
                <Invite specTemplateSlug={template.slug} currentCompanySlug={currentCompanySlug} />
              </Tabs.TabPanel>
            </Tabs.TabPanels>
          </Tabs>
        </Body>
        {isEditable && (
          <SaveFooter
            wereEditsMade={wereEditsMade}
            isCreateMode={isCreateMode}
            isSaveLoading={isSaveLoading}
            onSave={this.onSave}
            currentCompanySlug={currentCompanySlug}
          />
        )}
      </Flex>
    );
  }
}

export default withParams(EditSpecTemplate);
