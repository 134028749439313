import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, columnLayoutStyle } from 'styles/theme';
import { withNavigation, withParams } from 'hooks/router';
import { Api, returnQuery } from 'helpers/utils';
import { connect } from 'react-redux';
import Header from './Header';
import AppliedSearchQueries from './AppliedSearchQueries';
import Results from './Results';
import Footer from './Footer';
import Modal from 'components/Modal';
import UnauthNotice from './UnauthNotice';
import TermsFooter from 'componentsCustom/TermsFooter';

const DEFAULT_COLUMNS = ['Name', 'Title', 'Location', 'Spec Link'];

const Styled = styled.div`
  ${containerStyle};
  ${columnLayoutStyle};
  padding: 0px;
  ${({ isWithinPanel }) =>
    isWithinPanel &&
    css`
      width: 100%;
      border: none;
      border-radius: 0px;
      background: transparent;
    `};
`;

class Directory extends React.Component {
  state = {
    users: [],
    pageNumber: 1,
    numberOfResults: 0,
    resultsPerPage: 12,
    searchTerm: '',
    searchQueries: [],
    columns: [],
    isSearchButtonOpen: false,
    isInitialLoadComplete: false,
    userStatus: 'has_spec',
    selectedUsers: [],
  };

  componentDidMount() {
    const { isSignedIn } = this.props;
    if (isSignedIn) this.handleURLParams();
    this.navSearchInput = document.getElementById('nav-search-input');
    if (this.navSearchInput) this.navSearchInput.addEventListener('keyup', event => this.onSearchTermChange(event.target.value, true));
  }

  componentWillUnmount() {
    if (this.navSearchInput) this.navSearchInput.removeEventListener('keyup', event => this.onSearchTermChange('', true));
  }

  handleURLParams() {
    const parsed = returnQuery();
    if (parsed.query && parsed.display) {
      this.setState(
        {
          searchQueries: [
            {
              display: parsed.display,
              query_type: parsed.query_type,
              query_field: parsed.query_field,
              query_value: parsed.query_value,
            },
          ],
        },
        () => this.getUsers(),
      );
    } else {
      const { initialSearchQueries } = this.props;
      if (initialSearchQueries && initialSearchQueries.length > 0) {
        this.setState({ searchQueries: initialSearchQueries }, () => this.getUsers());
      } else {
        this.getUsers();
      }
    }
  }

  getUsers() {
    const { shouldConditionallyLimitResults } = this.props;
    const { searchTerm, searchQueries, userStatus, pageNumber, resultsPerPage } = this.state;
    this.setColumns();
    Api.post({
      url: `/api/v1/search/directory_users`,
      body: {
        search_term: searchTerm,
        search_queries: searchQueries,
        user_status: userStatus,
        page_number: pageNumber,
        results_per_page: resultsPerPage,
        should_limit_results: shouldConditionallyLimitResults,
      },
    }).then(data => {
      if (data.success) {
        this.setState({
          users: data.search_results,
          numberOfResults: data.number_of_results,
          isInitialLoadComplete: true,
        });
      } else {
        this.setState({ isInitialLoadComplete: true });
      }
    });
  }

  setColumns() {
    const { searchQueries } = this.state;
    let columns = [];
    if (searchQueries.length > 0) {
      columns = ['Name'];
      searchQueries.forEach(query => {
        if (query.display) columns.push(query.display);
      });
    } else {
      columns = DEFAULT_COLUMNS;
    }
    columns = [...new Set(columns)];
    if (columns.length < 4 && !columns.includes('Title')) columns.push('Title');
    if (columns.length < 4 && !columns.includes('Location')) columns.push('Location');
    if (columns.length < 4 && !columns.includes('Spec Link')) columns.push('Spec Link');
    this.setState({ columns: columns });
  }

  onSearchTermChange = (searchTerm, isViaNav = false) => {
    if (!isViaNav) this.navSearchInput.value = '';
    this.setState({ searchTerm: searchTerm, isSearchButtonOpen: !isViaNav }, () => this.getUsers());
  };

  onSetSearchQuery = searchQuery => {
    const { searchQueries } = this.state;
    let newSearchQueries = searchQueries;
    const existingIndex = newSearchQueries.findIndex(
      searchQueryEl => searchQueryEl.display === searchQuery.display && searchQueryEl.query_value === searchQuery.query_value,
    );
    if (existingIndex === -1) newSearchQueries.push(searchQuery);
    this.setState({ searchQueries: newSearchQueries, pageNumber: 1 }, () => this.getUsers());
  };

  onRemoveSearchQuery = searchQueryIndex => {
    let searchQueries = this.state.searchQueries;
    searchQueries.splice(searchQueryIndex, 1);
    this.setState({ searchQueries: searchQueries, pageNumber: 1 }, () => this.getUsers());
  };

  onChangePageNumber = pageNumber => {
    this.setState({ pageNumber: pageNumber }, () => this.getUsers());
  };

  onUserStatusChange = userStatus => {
    this.setState({ userStatus: userStatus, selectedUsers: [] }, () => this.getUsers());
  };

  onSelectUser = selectedUser => {
    const { selectedUsers } = this.state;
    let newSelectedUsers = selectedUsers;
    const existingIndex = selectedUsers.findIndex(selectedUserEl => selectedUserEl.slug === selectedUser.slug);
    if (existingIndex === -1) {
      newSelectedUsers.push(selectedUser);
    } else {
      newSelectedUsers.splice(existingIndex, 1);
    }
    this.setState({ selectedUsers: newSelectedUsers });
  };

  onSetDeactivatedStatus = isDeactivated => {
    const { triggerToast } = this.props;
    const { selectedUsers } = this.state;
    let didConfirm = true;
    if (isDeactivated)
      didConfirm = window.confirm(`Are you sure you want to deactivate ${selectedUsers.length === 1 ? 'this user?' : 'these users?'}`);
    if (didConfirm) {
      Api.post({
        url: `/api/v1/admin/users/update_deactivated_status`,
        body: {
          selected_users: selectedUsers,
          is_deactivated: isDeactivated,
        },
      }).then(data => {
        if (data.success) {
          if (triggerToast)
            triggerToast({
              title: `User${selectedUsers.length === 1 ? '' : 's'} have been ${isDeactivated ? 'deactivated' : 'reactivated'}`,
              status: 'success',
            });
          this.setState({ selectedUsers: [] }, () => this.getUsers());
        } else {
          if (triggerToast) triggerToast({ title: data.error, status: 'error' });
        }
      });
    }
  };

  onSetAdminStatus = adminType => {
    const { triggerToast } = this.props;
    const { selectedUsers } = this.state;
    Api.post({
      url: `/api/v1/admin/users/update_admin_status`,
      body: {
        selected_users: selectedUsers,
        admin_type: adminType,
      },
    }).then(data => {
      if (data.success) {
        if (triggerToast)
          triggerToast({
            title: `User${selectedUsers.length === 1 ? '' : 's'} have been set as ${adminType}${selectedUsers.length === 1 ? '' : 's'}`,
            status: 'success',
          });
        this.setState({ selectedUsers: [] }, () => this.getUsers());
      } else {
        if (triggerToast) triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  render() {
    const {
      users,
      columns,
      searchTerm,
      isSearchButtonOpen,
      searchQueries,
      userStatus,
      isInitialLoadComplete,
      pageNumber,
      numberOfResults,
      resultsPerPage,
      selectedUsers,
    } = this.state;
    const {
      navigate,
      isModal,
      isSignedIn,
      hasCompany,
      currentCompany,
      params,
      isWithinPanel,
      headerTitle = 'Spec Directory',
      showHeaderTitle,
      isWithinAdminUsers,
      shouldConditionallyLimitResults,
      renderLocation,
    } = this.props;
    const Outer = isModal ? Modal : Styled;
    if (!isSignedIn) {
      return (
        <Outer isOpen onClose={() => navigate(`/${params.slug}`)} constrainWidthMobile size="md">
          <UnauthNotice hasCompany={hasCompany} currentCompany={currentCompany} />
        </Outer>
      );
    }
    const isFeatureGated = shouldConditionallyLimitResults && hasCompany && currentCompany.tier === 1 && users.length > 6;
    return (
      <React.Fragment>
        <Outer isOpen onClose={() => navigate(`/${params.slug}`)} constrainWidthMobile size="2xl" isWithinPanel={isWithinPanel}>
          <Header
            headerTitle={headerTitle}
            showHeaderTitle={showHeaderTitle}
            searchTerm={searchTerm}
            onSearchTermChange={this.onSearchTermChange}
            userStatus={userStatus}
            onUserStatusChange={this.onUserStatusChange}
            isSearchButtonOpen={isSearchButtonOpen}
            onSearchButtonClick={() => this.setState({ isSearchButtonOpen: !isSearchButtonOpen })}
            isWithinPanel={isWithinPanel}
            isWithinAdminUsers={isWithinAdminUsers}
            isFeatureGated={isFeatureGated}
            companySlug={isSignedIn && hasCompany && currentCompany.slug}
          />
          <AppliedSearchQueries searchQueries={searchQueries} onRemoveSearchQuery={this.onRemoveSearchQuery} />
          <Results
            users={users}
            columns={columns}
            onSetSearchQuery={this.onSetSearchQuery}
            navigate={navigate}
            isInitialLoadComplete={isInitialLoadComplete}
            areResultsSelectable={isWithinAdminUsers}
            selectedUsers={selectedUsers}
            onSelectUser={this.onSelectUser}
            showEmailSubtitle={isWithinAdminUsers}
            showAdminBadge={isSignedIn && hasCompany && currentCompany.tier > 1}
            isFeatureGated={isFeatureGated}
          />
          <Footer
            pageNumber={pageNumber}
            numberOfResults={numberOfResults}
            onChangePageNumber={this.onChangePageNumber}
            resultsPerPage={resultsPerPage}
            userStatus={userStatus}
            selectedUsersLength={selectedUsers.length}
            onSetDeactivatedStatus={this.onSetDeactivatedStatus}
            onSetAdminStatus={this.onSetAdminStatus}
            isFeatureGated={isFeatureGated}
          />
        </Outer>
        {renderLocation === 'directory-page' && <TermsFooter />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentUserLoaded: state.currentReducer.currentUserLoaded,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default withParams(withNavigation(connect(mapStateToProps)(Directory)));
