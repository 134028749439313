import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';
import Flex from 'components/Flex';
import ImageUpload from 'containers/ImageUpload';
import ApiSearchInput from 'containers/ApiSearchInput';

const ProfileInfo = ({ newUser, onNewUserChange, isSubmitLoading, onPreviousStepClick }) => {
  return (
    <OnboardingFlow.Inner>
      <OnboardingFlow.Header title="Add Basic Info" subtitle="We'll add these details to your Spec" />

      <OnboardingFlow.Body>
        <InputGroup
          type="input"
          value={newUser.job_title}
          label="Job Title"
          placeholder="Marketing Coordinator"
          field="job_title"
          onChange={onNewUserChange}
          autoFocus
        />

        <ApiSearchInput
          apiToSearch="cities"
          value={newUser.location}
          onChange={selectedResult => onNewUserChange('location', selectedResult)}
          inputProps={{
            label: 'Location',
            placeholder: 'Search City',
          }}
        />
      </OnboardingFlow.Body>

      <OnboardingFlow.Footer>
        <Button variant="solid" colorScheme="brand" isLoading={isSubmitLoading} isDisabled={isSubmitLoading} type="submit">
          Continue
        </Button>
      </OnboardingFlow.Footer>

      <OnboardingFlow.PostText onClick={onPreviousStepClick}>
        <div>Go to previous step</div>
      </OnboardingFlow.PostText>
    </OnboardingFlow.Inner>
  );
};

export default ProfileInfo;
