import React from 'react';
import ShareLink from 'containers/ShareLink';

const Invite = ({ specTemplateSlug, currentCompanySlug }) => {
  return (
    <ShareLink
      shareDisplayURL={`https://spec.me/org/${currentCompanySlug}/template/${specTemplateSlug}`}
      shareURL={`https://spec.me/org/${currentCompanySlug}/template/${specTemplateSlug}`}
      entitySlug={specTemplateSlug}
      entityType="spec_template"
    />
  );
};

export default Invite;
