import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, companyAvatarStyle } from 'styles/theme';
import { withLocation, withNavigation } from 'hooks/router';
import { Outlet, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeCookie } from 'helpers/utils';
import { Grid } from '@chakra-ui/react';
import { ChevronDownIcon } from 'assets/icons';
import Flex from 'components/Flex';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Search from './Search';
import SpecLogo from 'assets/images/SpecLogo';
import Menu from 'components/Menu';
import Loading from 'components/Loading';
import NotSignedInNavigation from './NotSignedInNavigation';

const StyledNavigation = styled(Grid)`
  position: sticky;
  top: 0;
  justify-content: space-between;
  height: 72px;
  width: 100vw;
  background: ${theme.colors.primary};
  color: white;
  padding: 16px;
  z-index: 50;
  margin-bottom: ${theme.margin.xxl};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 0px;
  }
`;

const Divider = styled.div`
  height: 20px;
  width: 1px;
  background: #ffffff30;
  margin: auto 10px auto 20px;
  ${({ isRight }) =>
    isRight &&
    css`
      margin: auto 20px auto 10px;
    `};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin: auto 6px auto 12px;
    ${({ isRight }) =>
      isRight &&
      css`
        margin: auto 12px auto 6px;
      `};
  }
`;

const BackgroundBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 100vw;
  background: ${theme.colors.primary};
`;

class Navigation extends React.Component {
  state = {};

  componentDidMount() {
    this.handleRedirect();
  }

  handleRedirect() {
    const { location, navigate, isSignedIn, currentUser, currentCompany, hasCompany } = this.props;
    // Have to use setTimeout to get navigate() to work
    setTimeout(() => {
      if (location.pathname === '/') {
        if (isSignedIn) {
          if (currentUser.has_spec) {
            navigate(`/${currentUser.slug}`);
            this.setState({ didLoad: true });
          } else {
            navigate(`/org/${currentCompany.slug}`);
            this.setState({ didLoad: true });
          }
        } else {
          window.location.href = 'https://welcome.spec.me';
        }
      } else {
        this.setState({ didLoad: true });
      }
    }, 0);
  }

  onLogout = () => {
    const didConfirm = window.confirm('Are you sure you want to logout?');
    if (didConfirm) {
      removeCookie('login_token');
      setTimeout(() => {
        window.location.reload();
      }, 50);
    }
  };

  render() {
    const { didLoad } = this.state;
    if (!didLoad) return <Loading />;
    const { children, location, isSignedIn, currentUser, currentCompany, hasCompany, navigate } = this.props;
    const currentPath = location.pathname;
    const hideBanner = currentPath.includes('admin');
    return (
      <>
        {hasCompany ? (
          <StyledNavigation templateColumns={['1fr 1fr', '1fr 1fr', '1fr 2fr 1fr']} id="nav-bar">
            <Flex>
              {isSignedIn ? (
                <Menu placement="bottom-end">
                  <Menu.Button>
                    <Flex gap="6px">
                      {currentCompany.logo ? (
                        <Avatar src={currentCompany.logo} css={companyAvatarStyle} borderRadius={theme.radii.md} />
                      ) : (
                        <SpecLogo variant="avatar" />
                      )}
                      <ChevronDownIcon className="mobile" />
                    </Flex>
                  </Menu.Button>

                  <Menu.List width="175px" minWidth="175px">
                    {[
                      currentUser.is_admin ? { label: 'Admin', onClick: () => navigate(`/org/${currentCompany.slug}/admin`) } : false,
                      { label: 'Directory', onClick: () => navigate(`/org/${currentCompany.slug}`) },
                      { label: 'Send Invites', onClick: () => navigate(`/org/${currentCompany.slug}/invite`) },
                    ].map(menuItem => {
                      if (!menuItem) return null;
                      return (
                        <Menu.Item key={menuItem.label} onClick={menuItem.onClick} color={menuItem.color}>
                          {menuItem.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu.List>
                </Menu>
              ) : (
                <SpecLogo variant="avatar" />
              )}
              <Divider className="desktop" />
              <NavButton linkTo={`/org/${currentCompany.slug}`} currentPath={currentPath} className="desktop">
                Directory
              </NavButton>
              {isSignedIn &&
                currentUser.is_admin && (
                  <NavButton
                    linkTo={`/org/${currentCompany.slug}/admin`}
                    currentPath={currentPath}
                    className="desktop"
                    forceIsActive={currentPath.includes('admin')}>
                    Admin
                  </NavButton>
                )}
            </Flex>

            <Flex justify="center" className="desktop">
              <Search directoryPath={`/org/${currentCompany.slug}`} />
            </Flex>

            {isSignedIn ? (
              <Flex justify="flex-end">
                <NavButton linkTo={`/org/${currentCompany.slug}/activity`} currentPath={currentPath}>
                  Activity
                </NavButton>
                <NavButton linkTo={`/${currentUser.slug}`} currentPath={currentPath} className="desktop">
                  My Spec
                </NavButton>
                <Divider isRight />
                <Menu placement="bottom-end">
                  <Menu.Button>
                    <Flex gap="6px">
                      <ChevronDownIcon className="mobile" />
                      <Avatar height="40px" width="40px" src={currentUser.image} />
                    </Flex>
                  </Menu.Button>

                  <Menu.List width="175px" minWidth="175px">
                    {[
                      { label: 'My Spec', onClick: () => navigate(`/${currentUser.slug}`), className: 'mobile' },
                      { label: 'Settings', onClick: () => navigate(`/${currentUser.slug}/account`) },
                      {
                        label: 'Share My Spec',
                        onClick: () => navigate(currentUser.has_spec ? `/${currentUser.slug}/account/invite` : `/${currentUser.slug}`),
                      },
                      { label: 'Send Invites', onClick: () => navigate(`/org/${currentCompany.slug}/invite`) },
                      { label: 'Logout', color: theme.colors.redText, onClick: () => this.onLogout() },
                    ].map(menuItem => {
                      return (
                        <Menu.Item key={menuItem.label} onClick={menuItem.onClick} color={menuItem.color} className={menuItem.className}>
                          {menuItem.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu.List>
                </Menu>
              </Flex>
            ) : (
              <Flex justify="flex-end">
                <NavButton linkTo={`/org/${currentCompany.slug}/auth/login`} currentPath={currentPath}>
                  Login
                </NavButton>
                <NavButton linkTo={`/org/${currentCompany.slug}/auth`} currentPath={currentPath}>
                  Create Spec
                </NavButton>
              </Flex>
            )}
          </StyledNavigation>
        ) : (
          <NotSignedInNavigation />
        )}
        {!hideBanner && <BackgroundBanner className="desktop" id="primary-banner" />}
        <Outlet />
      </>
    );
  }
}

const NavButton = ({
  currentPath,
  linkTo,
  children,
  variant = 'ghost',
  colorScheme = 'whiteAlpha',
  color = 'white',
  className,
  forceIsActive = false,
  ...props
}) => {
  const isActive = forceIsActive || currentPath === linkTo;
  return (
    <Link to={linkTo}>
      <Button
        variant={variant}
        colorScheme={colorScheme}
        color={color}
        p="0 10px"
        as="div"
        className={isActive ? `${className} navigation-button-active` : className}
        {...props}>
        {children}
      </Button>
    </Link>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
  isSignedIn: state.currentReducer.isSignedIn,
});

export default withNavigation(withLocation(connect(mapStateToProps)(Navigation)));
