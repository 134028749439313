import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Text from 'components/Text';
import { Flex, Image } from '@chakra-ui/react';

const StyledFlex = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.padding.lg};
  height: 100vh;
  width: 100%;
  background: ${theme.colors.primary};
`;

const HeroImage = styled(Image)`
  border-radius: ${theme.radii.lg};
  width: 100%;
  max-width: 375px;
  background: transparent;
  margin-top: ${theme.margin.lg};
  @media screen and (max-height: 600px) {
    display: none;
  }
  @media screen and (min-height: 850px) {
    max-width: 410px;
  }
`;

const Hero = () => {
  return (
    <StyledFlex className="desktop">
      <Flex direction="column" maxWidth="450px">
        <Text as="h1" fontSize="30px" lineHeight="36px" fontFamily={theme.fonts.bold} color="white">
          Showcase who you are & how you work
        </Text>
        <Text color="white" fontSize={theme.fontSizes.lg} fontFamily={theme.fonts.light}>
          Outline your working style, preferences, and everything else about how you operate as a teammate or leader – all in your Spec.
        </Text>
        <HeroImage
          src="https://res.cloudinary.com/teamspec/image/upload/v1666033996/Spec_Personal_User_Manual_Readme_paqz1k.png"
          alt="Two Spec personal user manual examples side by side"
          loading="lazy"
        />
      </Flex>
    </StyledFlex>
  );
};

export default Hero;
