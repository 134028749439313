import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { SearchIcon } from 'assets/icons';
import InputGroup from 'components/InputGroup';

const Styled = styled.div`
  width: 280px;
`;

const Search = ({ directoryPath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchTermChange = value => {
    setSearchTerm(value);
    if (location.pathname !== directoryPath) {
      navigate(directoryPath);
    }
  };

  return (
    <Styled>
      <InputGroup
        type="input"
        value={searchTerm}
        placeholder="Search for a Spec"
        field="search"
        onChange={(field, value) => onSearchTermChange(value)}
        inputRightElement={<SearchIcon color={theme.colors.accentText} />}
        borderRadius="50px"
        bg="white"
        autoComplete="off"
        style={{ color: theme.colors.paragraphText }}
        _focus={{ color: theme.colors.paragraphText, background: 'white' }}
        id="nav-search-input"
      />
    </Styled>
  );
};

export default Search;
