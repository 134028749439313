import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { PlusIcon } from 'assets/icons';
import { Flex } from '@chakra-ui/react';
import {
  FreeformIllustration,
  MediaIllustration,
  QuestionsIllustration,
  SpectrumIllustration,
  TopicsIllustration,
  VideoIllustration,
  MyersBriggsIllustration,
  BigFiveIllustration,
  StrengthsfinderIllustration,
  ZodiacSignIllustration,
  WorkHistoryIllustration,
} from 'assets/sectionIllustrations/all';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';
import Tag from 'components/Tag';

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  border-radius: ${theme.radii.md};
  background: #f6f8fa;
  padding: 18px;
`;

export const getBannerIllustration = sectionType => {
  switch (sectionType) {
    case 'freeform':
      return <FreeformIllustration />;
      break;
    case 'questions':
      return <QuestionsIllustration />;
      break;
    case 'books':
      return <MediaIllustration />;
      break;
    case 'movies':
      return <MediaIllustration />;
      break;
    case 'tv':
      return <MediaIllustration />;
      break;

    case 'spectrum':
      return <SpectrumIllustration />;
      break;
    case 'topics':
      return <TopicsIllustration />;
      break;
    case 'list':
      return <QuestionsIllustration />;
      break;
    case 'work_history':
      return <WorkHistoryIllustration />;
      break;
    case 'images':
      return <MediaIllustration />;
      break;
    case 'video':
      return <VideoIllustration />;
      break;
    case 'myers_briggs':
      return <MyersBriggsIllustration />;
      break;
    case 'big_five':
      return <BigFiveIllustration />;
      break;
    case 'strengthsfinder':
      return <StrengthsfinderIllustration />;
      break;
    case 'zodiac_sign':
      return <ZodiacSignIllustration />;
      break;
    default:
    case 'freeform':
      return <QuestionsIllustration />;
      break;
  }
};

const LibraryOptionTile = ({ libraryOption, isLast, onSelect }) => {
  const sectionData = libraryOption.section_data;
  const isWithinAdmin = window.location.href.includes('/admin');
  return (
    <Flex direction="column" align="flex-start" gap="12px" p={theme.padding.lg} borderBottom={!isLast && theme.layout.border}>
      <Banner>{getBannerIllustration(sectionData.section_type)}</Banner>
      <Flex align="flex-start" justify="space-between" width="100%">
        <div>
          <InfoGroup title={libraryOption.title} subtitle={isWithinAdmin ? libraryOption.admin_subtitle : libraryOption.subtitle} />
          <Tag
            colorScheme="gray"
            variant="outline"
            border={theme.layout.border}
            color={theme.colors.paragraphText}
            boxShadow="none"
            size="md"
            flexShrink="0"
            mt="4px"
            width="auto"
            label={libraryOption.section_type_label}
          />
        </div>

        <Button variant="solid" colorScheme="brand" leftIcon={<PlusIcon />} flexShrink={0} onClick={onSelect} ml="16px">
          Add
        </Button>
      </Flex>
    </Flex>
  );
};

export default LibraryOptionTile;
