import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SearchIcon, CloseIcon, PlusIcon } from 'assets/icons';
import { Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Button from 'components/Button';
import InputGroup from 'components/InputGroup';

const Header = ({
  searchTerm,
  onSearchTermChange,
  userStatus,
  onUserStatusChange,
  isSearchButtonOpen,
  onSearchButtonClick,
  isWithinPanel = false,
  headerTitle,
  showHeaderTitle,
  isWithinAdminUsers,
  isFeatureGated,
  companySlug,
}) => {
  return (
    <Flex justify="space-between" padding={theme.padding.lg} borderBottom={theme.layout.border}>
      {isWithinPanel && !showHeaderTitle && <div />}
      {(!isWithinPanel || showHeaderTitle) && (
        <Text fontFamily={theme.fonts.semiBold} fontSize={theme.fontSizes.md} color={theme.colors.primaryText} className="desktop">
          {headerTitle}
        </Text>
      )}
      <Flex gap="12px">
        {isWithinAdminUsers && (
          <InputGroup
            type="select"
            value={userStatus}
            width="130px"
            field="userStatus"
            onChange={(field, value) => onUserStatusChange(value)}
            selectOptions={[
              { label: 'All Specs', value: 'has_spec' },
              { label: 'Published Specs', value: 'is_published' },
              { label: 'Unpublished Specs', value: 'is_unpublished' },
              { label: 'Deactivated Users', value: 'is_deactivated' },
              { label: 'View-Only Users', value: 'is_view_only' },
            ]}
            height="36px"
          />
        )}
        {isSearchButtonOpen ? (
          <Flex flexShrink={0}>
            <InputGroup
              type="input"
              value={searchTerm}
              placeholder="Search"
              field="search"
              onChange={(field, value) => onSearchTermChange(value, false)}
              inputRightElement={<CloseIcon onClick={() => onSearchTermChange('', true)} />}
              elementPointerEvents="auto"
              autoFocus
              maxWidth="150px"
              height="36px"
              inputElementHeight="36px"
              isDisabled={isFeatureGated}
            />
          </Flex>
        ) : (
          <Tooltip label={isFeatureGated && 'Search not available on free plan'} placement="top">
            <div>
              <Button
                variant="solid"
                leftIcon={<SearchIcon />}
                onClick={onSearchButtonClick}
                height="36px"
                p="0 12px"
                flexShrink={0}
                isDisabled={isFeatureGated}>
                Search
              </Button>
            </div>
          </Tooltip>
        )}
        {!isWithinPanel && (
          <Link to={`/org/${companySlug}/invite`}>
            <Button variant="solid" leftIcon={<PlusIcon />} colorScheme="brand" height="36px" p="0 12px" flexShrink={0} as="div">
              Invite
            </Button>
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
