const Spectrum = () => {
  return (
    <svg width="328" height="45" viewBox="0 0 328 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="3" width="328" height="5" rx="2.5" fill="#E0E6ED" />
      <rect y="20" width="328" height="5" rx="2.5" fill="#E0E6ED" />
      <rect y="37" width="328" height="5" rx="2.5" fill="#E0E6ED" />
      <rect x="159" width="10.8" height="10.8" rx="5.4" fill="#CAD1D9" />
      <rect x="251" y="17.1001" width="10.8" height="10.8" rx="5.4" fill="#CAD1D9" />
      <rect x="60" y="34.2" width="10.8" height="10.8" rx="5.4" fill="#CAD1D9" />
    </svg>
  );
};

export default Spectrum;
