import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Tooltip } from '@chakra-ui/react';
// import { ButtonGroup } from '@chakra-ui/react';

const StyledHandle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  border: none;
  width: 18px;
  height: 12px;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
  cursor: move !important;
  ${({ noOfDots }) =>
    noOfDots < 6 &&
    css`
      width: 16px;
    `};
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  width: 6px;
`;

const Dot = styled.div`
  height: 3px;
  width: 3px;
  border-radius: 50px;
  transition: background 0.5s ease;
  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `};
`;

const DragHandle = ({
  noOfDots = 6,
  color = theme.colors.accentText,
  className = 'drag-handle',
  tooltipLabel = 'Drag to reorder',
  isTooltipDisabled = false,
}) => {
  return (
    <Tooltip label={tooltipLabel} placement="top" openDelay={400} isDisabled={isTooltipDisabled}>
      <StyledHandle noOfDots={noOfDots} className={className}>
        {Array.from(new Array(noOfDots), (x, i) => {
          return (
            <DotWrapper key={i}>
              <Dot className="dot" color={color} />
            </DotWrapper>
          );
        })}
      </StyledHandle>
    </Tooltip>
  );
};

export default DragHandle;
