import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, columnLayoutStyle } from 'styles/theme';
import { CloseIcon } from 'assets/icons';
import { SimpleGrid } from '@chakra-ui/react';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Flex from 'components/Flex';
import Checkbox from 'components/Checkbox';
import CheckboxItem from 'componentsCustom/CheckboxItem';

const StyledSimpleGrid = styled(SimpleGrid)`
  ${containerStyle};
  ${columnLayoutStyle};
  background: ${theme.colors.grayFill};
  margin-bottom: ${theme.spacing.lg};
  padding: 0px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.margin.xs};
    padding: ${theme.padding.lg};
  }
`;

const GettingStartedChecklist = ({ gettingStartedStepsCompleted }) => {
  return (
    <StyledSimpleGrid columns={[1, 1, 3]} spacing={['12px', '12px', '0px']}>
      <CheckboxItem
        label="Add username, image, & basic info"
        isChecked={gettingStartedStepsCompleted.includes('basic_info')}
        p={['0', '0', '16px 22px']}
        borderRight={[0, 0, theme.layout.border]}
        checkedTextAs="del"
        isCheckReadyOnly
      />
      <CheckboxItem
        label="Fill out Spec & publish when you're ready"
        isChecked={gettingStartedStepsCompleted.includes('is_published')}
        p={['0', '0', '16px 22px']}
        borderRight={[0, 0, theme.layout.border]}
        checkedTextAs="del"
        isCheckReadyOnly
      />
      <CheckboxItem
        label="Share with teammates & track activity"
        isChecked={gettingStartedStepsCompleted.includes('was_shared')}
        p={['0', '0', '16px 22px']}
        checkedTextAs="del"
        isCheckReadyOnly
      />
    </StyledSimpleGrid>
  );
};

export default GettingStartedChecklist;
