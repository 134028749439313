import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SimpleGrid, Flex } from '@chakra-ui/react';
import TermsFooter from 'componentsCustom/TermsFooter';
import Hero from './Hero';

const FlexAuthWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
  padding: ${theme.padding.lg};
`;

const GeneralSignup = ({ children }) => {
  return (
    <SimpleGrid columns={[1, 1, 2]}>
      <Hero />
      <FlexAuthWrapper>
        <div className="desktop" />
        {children}
        <TermsFooter p={`${theme.padding.lg} 0 0 0`} />
      </FlexAuthWrapper>
    </SimpleGrid>
  );
};

export default GeneralSignup;
