const Questions = ({ size = 110 }) => {
  return (
    <svg width="328" height="32" viewBox="0 0 328 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="8" height="8" rx="4" fill="#E0E6ED" />
      <rect x="10" width="257" height="8" rx="4" fill="#E0E6ED" />
      <rect x="10" y="12" width="318" height="8" rx="4" fill="#E0E6ED" />
      <rect x="10" y="24" width="163" height="8" rx="4" fill="#E0E6ED" />
      <rect y="12" width="8" height="8" rx="4" fill="#E0E6ED" />
      <rect y="24" width="8" height="8" rx="4" fill="#E0E6ED" />
    </svg>
  );
};

export default Questions;
