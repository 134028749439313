import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle, multiLineText } from 'styles/theme';
import { RightArrowUpOutline, ExternalLinkIconOutline } from 'assets/icons';
import { Flex } from '@chakra-ui/react';
import { cleanHTML, toURL } from 'helpers/utils';
import Text from 'components/Text';
import HTMLText from 'components/HTMLText';

const returnFormatted = (jsonAnswer, field) => {
  let returnString = '';
  if (field === 'timeframe') {
    if (jsonAnswer.start_year && jsonAnswer.end_year) {
      returnString = `${jsonAnswer.start_year} – ${jsonAnswer.end_year}`;
    } else if (jsonAnswer.start_year && !jsonAnswer.end_year) {
      returnString = `${jsonAnswer.start_year} – ${jsonAnswer.start_year}`;
    } else if (!jsonAnswer.start_year && jsonAnswer.end_year) {
      returnString = `${jsonAnswer.end_year} – ${jsonAnswer.end_year}`;
    }
  } else if (field === 'title') {
    if (jsonAnswer.job_title && jsonAnswer.company) {
      if (jsonAnswer.company_url) {
        returnString = `${jsonAnswer.job_title} at <a href='${toURL(jsonAnswer.company_url)}' target='_blank' class='hover-underline'>${
          jsonAnswer.company
        }</a>`;
      } else {
        returnString = `${jsonAnswer.job_title} at ${jsonAnswer.company}`;
      }
    } else if (jsonAnswer.job_title && !jsonAnswer.company) {
      returnString = `${jsonAnswer.job_title}`;
    } else if (!jsonAnswer.job_title && jsonAnswer.company) {
      if (jsonAnswer.company_url) {
        returnString = `<a href='${toURL(jsonAnswer.company_url)}' target='_blank' class='hover-underline'>${jsonAnswer.company}</a>`;
      } else {
        returnString = `${jsonAnswer.company}`;
      }
    }
  }
  return returnString;
};

const LiveAnswer = ({ answer }) => {
  const jsonAnswer = answer.json_answer;
  const timeframe = returnFormatted(jsonAnswer, 'timeframe');
  return (
    <Flex
      css={editTileStyle}
      direction={['column', 'column', 'row']}
      p="12px 16px"
      align="flex-start"
      width="100%"
      gap={['12px', '12px', '16px']}>
      {timeframe && (
        <Text fontFamily={theme.fonts.light} color={theme.colors.lightAccentText} flexShrink={0} minWidth="100px">
          {timeframe}
        </Text>
      )}
      <Flex direction="column" grow={1}>
        <Flex align={['flex-start', 'flex-start', 'center']} justify="space-between" width="100%" direction={['column', 'column', 'row']}>
          <Text fontFamily={theme.fonts.semiBold} color={theme.colors.primaryText} display="flex" alignItems="center">
            <span dangerouslySetInnerHTML={{ __html: returnFormatted(jsonAnswer, 'title') }} />
            {jsonAnswer.company && jsonAnswer.company_url && <ExternalLinkIconOutline size="14px" style={{ marginLeft: '2px' }} />}
          </Text>
          <Text fontFamily={theme.fonts.light} color={theme.colors.lightAccentText}>
            {jsonAnswer.location}
          </Text>
        </Flex>
        <Text fontFamily={theme.fonts.light} css={multiLineText}>
          {jsonAnswer.description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default LiveAnswer;
