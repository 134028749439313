import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { returnGhostElement } from 'helpers/specUtils';
import Sortable from 'sortablejs';

const Styled = styled.div``;

const SortableComponent = ({
  children,
  sortableContainerID,
  sortableHandleClassName,
  filterClass = 'not-sortable',
  onChoose,
  onUnchoose,
  onDragStart,
  onDragEnd,
  shouldHideGhost = true,
}) => {
  useEffect(() => {
    const containerEl = document.getElementById(sortableContainerID);
    Sortable.create(containerEl, {
      handle: `.${sortableHandleClassName}`,
      ghostClass: 'dragging-ghost',
      filter: `.${filterClass}`,
      onMove: event => {
        return !event.related.classList.contains(filterClass);
      },
      onChoose: event => {
        if (onChoose) onChoose(event);
      },
      onUnchoose: event => {
        if (onUnchoose) onUnchoose(event);
      },
      onStart: event => {
        document.body.classList.add('draggable-cursor');
        if (onDragStart) onDragStart(event);
      },
      onEnd: event => {
        document.body.classList.remove('draggable-cursor');
        if (onDragEnd) onDragEnd(event);
      },
      setData: (dataTransfer, dragEl) => {
        if (shouldHideGhost) {
          const element = returnGhostElement(dragEl);
          document.body.appendChild(element);
          dataTransfer.setDragImage(element, 0, 0);
        }
      },
    });
  }, []);

  return <Styled>{children}</Styled>;
};

export default SortableComponent;
