import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle } from 'styles/theme';
import { Tooltip } from '@chakra-ui/react';
import Flex from 'components/Flex';
import TextEditor from 'components/TextEditor';
import InputGroup from 'components/InputGroup';
import AnswerHandleGroup from 'componentsCustom/AnswerHandleGroup';

const Styled = styled(Flex)`
  ${editTileStyle};
`;

const EditTile = ({ answer, onSectionAnswerChange, sectionIndex, answerIndex, sortableHandleClassName, answerElementID }) => {
  return (
    <Styled id={answerElementID}>
      <InputGroup
        type="textarea"
        value={answer.answer}
        placeholder="Type here"
        field="answer"
        onChange={(field, value) => onSectionAnswerChange(sectionIndex, answerIndex, field, value)}
        variant="unstyled"
        minRows="1"
      />
      <AnswerHandleGroup
        onDeleteClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
        dragHandleClassName={sortableHandleClassName}
      />
    </Styled>
  );
};

export default EditTile;
