import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api } from 'helpers/utils';
import { PlusIcon } from 'assets/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { getCurrent } from 'reduxx/current';
import update from 'immutability-helper';
import moment from 'moment';
import Table from 'components/Table';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Section from 'components/Section';
import InputGroup from 'components/InputGroup';
import InfoTooltip from 'componentsCustom/InfoTooltip';
import NewAdminModal from './NewAdminModal';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';

const Styled = styled.div``;

class Admins extends React.Component {
  state = {
    admins: [],
    pendingInvites: [],
  };

  componentDidMount() {
    const { companyTier } = this.props;
    if (companyTier > 1) {
      this.getAdminUsers();
    } else {
      this.setState({ didLoad: true });
    }
  }

  getAdminUsers() {
    Api.get({ url: `/api/v1/admin/admins/get_admins` }).then(data => {
      if (data.success) {
        this.setState({ admins: data.admins, pendingInvites: data.pending_invites, didLoad: true });
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  onAdminChange = (adminIndex, field, value) => {
    let didConfirm = true;
    if (field === 'is_admin' && value === false) didConfirm = window.confirm('Are you sure you want to remove them as an admin?');
    if (didConfirm) {
      this.setState(
        {
          admins: update(this.state.admins, { [adminIndex]: { [field]: { $set: value } } }),
        },
        () => {
          this.onSaveAdminChange(this.state.admins[adminIndex]);
        },
      );
    }
  };

  onSaveAdminChange(admin) {
    const { triggerToast, dispatch } = this.props;
    Api.post({
      url: `/api/v1/admin/admins/update_admin`,
      body: { user: admin },
    }).then(data => {
      if (data.success) {
        triggerToast({ title: 'Changes have been saved' });
        dispatch(getCurrent(false));
      }
    });
  }

  onToggleNewAdminModal = () => {
    this.setState({ showNewAdminModal: !this.state.showNewAdminModal });
  };

  onCreateNewAdminCallback = () => {
    this.setState({ showNewAdminModal: false }, () => this.getAdminUsers());
  };

  onPendingInviteChange = (pendingInviteIndex, field, value) => {
    let didConfirm = true;
    if (field === 'should_invite_as_admin' && value === false)
      didConfirm = window.confirm('Are you sure you want to cancel this admin invite?');
    if (didConfirm) {
      this.setState(
        {
          pendingInvites: update(this.state.pendingInvites, { [pendingInviteIndex]: { [field]: { $set: value } } }),
        },
        () => {
          this.onSavePendingInviteChange(this.state.pendingInvites[pendingInviteIndex]);
        },
      );
    }
  };

  onSavePendingInviteChange(pendingInvite) {
    const { triggerToast, dispatch } = this.props;
    Api.post({
      url: `/api/v1/admin/admins/update_pending_invite`,
      body: { invite_batch_recipient: pendingInvite },
    }).then(data => {
      if (data.success) {
        triggerToast({ title: 'Changes have been saved' });
      }
    });
  }

  render() {
    const { admins, pendingInvites, didLoad, showNewAdminModal } = this.state;
    const { currentUserAdminType, currentUserSlug, companyDomain, triggerToast, companyTier } = this.props;
    if (!didLoad) return <Loading />;

    if (companyTier === 1) {
      return (
        <UpgradeCTA
          title="Upgrade to Pro to add admin roles"
          subtitle="Once upgraded, only admins can access the company admin dashboard"
          ctaType="pageHeader"
          shouldAbsCenter
          textAlign="center"
        />
      );
    }
    const isOwner = currentUserAdminType === 'owner';
    return (
      <Section>
        <NewAdminModal
          isOpen={showNewAdminModal}
          onClose={this.onToggleNewAdminModal}
          companyDomain={companyDomain}
          triggerToast={triggerToast}
          onCreateNewAdminCallback={this.onCreateNewAdminCallback}
        />

        <Section.Body p="0px">
          <Flex justify="space-between" p={theme.padding.lg} borderBottom={theme.layout.border}>
            <InfoGroup title="Admins" />
            {isOwner && (
              <Button
                variant="solid"
                colorScheme="brand"
                leftIcon={<PlusIcon />}
                padding={theme.padding.smButton}
                height="auto"
                onClick={this.onToggleNewAdminModal}>
                New Admin
              </Button>
            )}
          </Flex>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadTitle>Name</Table.HeadTitle>
                <Table.HeadTitle>
                  <Flex>
                    Role
                    <InfoTooltip label="The difference between 'admins' and 'owners' is that owners can invite and remove other admins" />
                  </Flex>
                </Table.HeadTitle>
                <Table.HeadTitle>Last Active</Table.HeadTitle>
                {isOwner && <Table.HeadTitle />}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {pendingInvites.map((pendingInvite, pendingInviteIndex) => {
                if (!pendingInvite.should_invite_as_admin) return null;
                return (
                  <Table.Row key={pendingInvite.id}>
                    <Table.BodyResult>
                      <InfoGroup
                        avatarSrc={'-'}
                        title={pendingInvite.recipient_email}
                        subtitle="Invite pending"
                        titleFontFamily={theme.fonts.medium}
                        subtitleAs="i"
                        subtitleFontColor={theme.colors.accentText}
                        noSpaceBetween={false}
                      />
                    </Table.BodyResult>
                    <Table.BodyResult className="capitalize">
                      {isOwner ? (
                        <InputGroup
                          type="select"
                          value={pendingInvite.invite_admin_type}
                          field="invite_admin_type"
                          onChange={(field, value) => this.onPendingInviteChange(pendingInviteIndex, field, value)}
                          selectOptions={[{ label: 'Admin', value: 'admin' }, { label: 'Owner', value: 'owner' }]}
                        />
                      ) : (
                        pendingInvite.invite_admin_type
                      )}
                    </Table.BodyResult>
                    <Table.BodyResult>Sent {moment(pendingInvite.created_at).format('MMM Do')}</Table.BodyResult>
                    {isOwner && (
                      <Table.BodyResult>
                        <Button
                          variant="link"
                          color={theme.colors.redText}
                          onClick={() => this.onPendingInviteChange(pendingInviteIndex, 'should_invite_as_admin', false)}>
                          Cancel
                        </Button>
                      </Table.BodyResult>
                    )}
                  </Table.Row>
                );
              })}
              {admins.map((admin, adminIndex) => {
                const isLast = adminIndex === admins.length - 1;
                if (!admin.is_admin) return null;
                return (
                  <Table.Row key={admin.id} className={`${isLast && 'table-row-no-border-bottom'}`}>
                    <Table.BodyResult>
                      <Link as={RouterLink} to={`/${admin.slug}`}>
                        <InfoGroup
                          avatarSrc={admin.image || '-'}
                          title={admin.name}
                          subtitle={admin.email}
                          titleFontFamily={theme.fonts.medium}
                          subtitleAs="i"
                          subtitleFontColor={theme.colors.accentText}
                          noSpaceBetween={false}
                        />
                      </Link>
                    </Table.BodyResult>
                    <Table.BodyResult className="capitalize">
                      {isOwner ? (
                        <InputGroup
                          type="select"
                          value={admin.admin_type}
                          field="admin_type"
                          onChange={(field, value) => this.onAdminChange(adminIndex, field, value)}
                          selectOptions={[{ label: 'Admin', value: 'admin' }, { label: 'Owner', value: 'owner' }]}
                          isDisabled={admins.length < 2}
                        />
                      ) : (
                        admin.admin_type
                      )}
                    </Table.BodyResult>
                    <Table.BodyResult>{moment(admin.last_active_at).format('MMM Do')}</Table.BodyResult>
                    {isOwner && (
                      <Table.BodyResult>
                        <Button
                          variant="link"
                          color={theme.colors.redText}
                          isDisabled={admins.length < 2}
                          onClick={() => this.onAdminChange(adminIndex, 'is_admin', false)}>
                          Remove
                        </Button>
                      </Table.BodyResult>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Section.Body>
      </Section>
    );
  }
}

export default Admins;
