import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SpecSearchIllustration } from 'assets/illustrations/all';
import { PlusIcon } from 'assets/icons';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';

const InviteBatchesList = ({ inviteBatches }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Flex justify="space-between" p={theme.padding.lg} borderBottom={theme.layout.border}>
        <InfoGroup title="Sent Invites" subtitle="Send new invites and track Specs created" />
        <Link to="create">
          <Button variant="solid" colorScheme="brand" leftIcon={<PlusIcon />} padding={theme.padding.smButton} height="auto" as="div">
            Send Invites
          </Button>
        </Link>
      </Flex>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadTitle>Creator</Table.HeadTitle>
            <Table.HeadTitle>Send Date</Table.HeadTitle>
            <Table.HeadTitle># Invites</Table.HeadTitle>
            <Table.HeadTitle># Joined</Table.HeadTitle>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {inviteBatches.map((inviteBatch, index) => {
            const isLast = index === inviteBatches.length - 1;
            return (
              <Table.Row
                key={inviteBatch.id}
                className={`${isLast && 'table-row-no-border-bottom'} table-row-link-hover`}
                onClick={() => navigate(`${inviteBatch.id}`)}>
                <Table.BodyResult>{inviteBatch.creator.name}</Table.BodyResult>
                <Table.BodyResult>{moment(inviteBatch.should_send_at).format('ddd, MMM Do, h:mm a')}</Table.BodyResult>
                <Table.BodyResult>{inviteBatch.number_invites_sent}</Table.BodyResult>
                <Table.BodyResult>{inviteBatch.number_invites_joined}</Table.BodyResult>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {inviteBatches.length === 0 && (
        <EmptyState
          illustration={<SpecSearchIllustration />}
          title="No Invites Sent"
          subtitle="Send invites by clicking + Send Invites above"
          bg="white"
          border="none"
          p={theme.padding.xl}
        />
      )}
    </React.Fragment>
  );
};

export default InviteBatchesList;
