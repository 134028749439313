import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Stack, SimpleGrid } from '@chakra-ui/react';
import { ArrowRightIcon } from 'assets/icons';
import Flex from 'components/Flex';
import Text from 'components/Text';
import InfoGroup from 'components/InfoGroup';

const StyledStack = styled(Stack)`
  ${containerStyle};
  background: ${theme.colors.grayFill};
  margin-bottom: ${theme.spacing.lg};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.margin.xs};
  }
  .show-on-hover {
    display: none;
  }
  &:hover {
    .show-on-hover {
      display: flex;
      align-items: center;
    }
    .hide-on-hover {
      display: none;
    }
  }
`;

const ActivityPreviewCard = ({ spec, currentCompanySlug }) => {
  return (
    <StyledStack spacing="8px" id="activity-preview-card">
      <Flex justify="space-between">
        <Text fontSize={theme.fontSizes.lg} fontFamily={theme.fonts.semiBold} color={theme.colors.primaryText}>
          Activity
        </Text>
        <Text
          fontSize={theme.fontSizes.sm}
          fontFamily={theme.fonts.medium}
          color={theme.colors.lightAccentText}
          textTransform="uppercase"
          className="hide-on-hover">
          Private to you
        </Text>
        <Link to={`/org/${currentCompanySlug}/activity`} className="show-on-hover">
          <Text
            fontSize={theme.fontSizes.sm}
            fontFamily={theme.fonts.medium}
            color={theme.colors.accent}
            display="flex"
            alignItems="center"
            textTransform="uppercase"
            as="span"
            _hover={{ textDecoration: 'underline' }}>
            View activity <ArrowRightIcon size="12px" style={{ marginLeft: 2 }} />
          </Text>
        </Link>
      </Flex>

      <SimpleGrid columns={[1, 1, 3]} spacing="16px">
        <InfoGroup
          title={`${spec.total_views} Spec view${spec.total_views === 1 ? '' : 's'}`}
          subtitle="Views are anonymous, but you can track activity over time"
          subtitleLineHeight={theme.lineHeights.sm}
        />
        <InfoGroup
          title={`${spec.total_reviewed_by} marked as reviewed`}
          subtitle="See who has marked your Spec as reviewed"
          subtitleLineHeight={theme.lineHeights.sm}
        />
        <InfoGroup
          title={`${spec.total_comments} comment${spec.total_comments === 1 ? '' : 's'}`}
          subtitle="See comments and reactions on your Spec"
          subtitleLineHeight={theme.lineHeights.sm}
        />
      </SimpleGrid>
    </StyledStack>
  );
};

export default ActivityPreviewCard;
