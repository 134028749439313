import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Text from 'components/Text';
import InfoTooltip from 'componentsCustom/InfoTooltip';

const Styled = styled.div`
  ${containerStyle};
  width: 33.33%;
`;

const Header = styled.div`
  width: 100%;
  padding-bottom: ${theme.padding.md};
  border-bottom: 1px solid ${theme.colors.border};
  margin-bottom: ${theme.margin.md};
  min-height: 95px;
`;

const BulletWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
`;

const Bullet = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  background: ${theme.colors.accent};
  border-radius: 50%;
  flex-shrink: 0;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px;
    background: ${theme.colors.accent};
    border: 2px solid white;
    border-radius: 50%;
  }
`;

const PlanTile = ({ plan, selectedTier, onPlanSelect }) => {
  const isSelected = selectedTier === plan.tier;
  return (
    <Styled>
      <Header>
        <Text fontSize={theme.fontSizes.xxl} fontFamily={theme.fonts.semiBold}>
          {plan.title}
        </Text>
        <Text fontFamily={theme.fonts.light} color={theme.colors.accentText}>
          {plan.subtitle}
        </Text>
      </Header>
      <Box minHeight="69px" mb={theme.margin.md}>
        <Text fontSize="32px" fontFamily={theme.fonts.semiBold}>
          {plan.price}
          {plan.price_unit_label && (
            <Text fontFamily={theme.fonts.light} color={theme.colors.accentText} as="span" ml="5px">
              {plan.price_unit_label}
            </Text>
          )}
        </Text>
        <Flex>
          <Text fontFamily={theme.fonts.light} color={theme.colors.accentText}>
            {plan.price_subtitle}
          </Text>
        </Flex>
      </Box>

      {isSelected ? (
        <Button width="100%" isDisabled>
          Current Plan
        </Button>
      ) : (
        <React.Fragment>
          {selectedTier > plan.tier ? (
            <Button
              width="100%"
              bg="white"
              border={`1px solid ${theme.colors.border}`}
              variant="unstyled"
              onClick={() => onPlanSelect(plan.tier)}>
              {plan.unselected_button_text}
            </Button>
          ) : (
            <Button
              width="100%"
              bg={plan.unselected_button_bg}
              border={plan.unselected_button_border}
              color={plan.unselected_button_text_color}
              variant="unstyled"
              onClick={() => onPlanSelect(plan.tier)}>
              {plan.unselected_button_text}
            </Button>
          )}
        </React.Fragment>
      )}

      <Flex align="flex-start" direction="column" gap={theme.spacing.md} mt={theme.margin.md}>
        <Text fontFamily={theme.fonts.light} color={theme.colors.accentText} minHeight="21px">
          {plan.features_pre_title}
        </Text>
        {plan.features.map((feature, index) => {
          return (
            <Flex key={index} gap="10px" align="flex-start">
              <BulletWrapper>
                <Bullet />
              </BulletWrapper>
              <Text fontFamily={theme.fonts.light} color={theme.colors.accentText}>
                {feature}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Styled>
  );
};

export default PlanTile;
