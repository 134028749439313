import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, disabledOverlay } from 'styles/theme';
import { Api, returnQuery } from 'helpers/utils';
import { Routes, Route, useLocation } from 'react-router-dom';
import Tabs from 'components/Tabs';
import InviteBatchesList from './InviteBatchesList';
import Loading from 'components/Loading';
import CreateInviteBatch from './CreateInviteBatch';
import InviteBatchDetails from './InviteBatchDetails';
import AutoInviteSettings from './AutoInviteSettings';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';
import Alert from 'components/Alert';

const SectionContainer = styled.div`
  ${containerStyle};
  padding: 0px;
  width: 100%;
  margin-top: ${theme.margin.lg};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-top: ${theme.margin.xs};
  }
`;

const Invites = ({ triggerToast, currentCompany, selectedTier }) => {
  const location = useLocation();
  const [inviteBatches, setInviteBatches] = useState([]);
  const [specTemplatesData, setSpecTemplatesData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(
    () => {
      const parsed = returnQuery();
      if (parsed.tabIndex) setTabIndex(+parsed.tabIndex);
      Api.get({ url: `/api/v1/admin/invites/get_invite_batches` }).then(data => {
        if (data.success) {
          setInviteBatches(data.invite_batches);
          setSpecTemplatesData(data.spec_templates_data);
          setDidLoad(true);
        } else {
          triggerToast({ title: data.error, status: 'error' });
        }
      });
    },
    [location.pathname],
  );

  if (!didLoad) return <Loading />;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Tabs
            tabs={['Sent Invites', 'Auto Invite Settings']}
            isStandaloneTabList
            noBorderRadiusMobile
            defaultIndex={tabIndex}
            onSelectIndex={selectedIndex => setTabIndex(selectedIndex)}>
            {tabIndex === 1 &&
              selectedTier === 1 && (
                <UpgradeCTA
                  title="Upgrade to Pro to enable Auto Invite Settings"
                  subtitle="Once upgraded, you can automate new hire onboarding using the features below"
                  ctaType="pageHeader"
                  mt={[theme.margin.xs, theme.margin.xs, theme.margin.lg]}
                />
              )}

            {tabIndex === 1 &&
              selectedTier > 1 &&
              !currentCompany.is_slack_enabled && (
                <Alert
                  title="You'll need to integrate Spec with your Slack or HRIS system to turn on automatic invites"
                  mt={[theme.margin.xs, theme.margin.xs, theme.margin.lg]}
                  status="warning"
                />
              )}

            <SectionContainer>
              <Tabs.TabPanels>
                <Tabs.TabPanel>
                  <InviteBatchesList inviteBatches={inviteBatches} />
                </Tabs.TabPanel>

                <Tabs.TabPanel css={selectedTier === 1 && disabledOverlay}>
                  <AutoInviteSettings specTemplatesData={specTemplatesData} triggerToast={triggerToast} currentCompany={currentCompany} />
                </Tabs.TabPanel>
              </Tabs.TabPanels>
            </SectionContainer>
          </Tabs>
        }
      />
      <Route
        path="/create"
        element={<CreateInviteBatch specTemplatesData={specTemplatesData} triggerToast={triggerToast} currentCompany={currentCompany} />}
      />
      <Route path="/:id" element={<InviteBatchDetails triggerToast={triggerToast} currentCompanySlug={currentCompany.slug} />} />
    </Routes>
  );
};

export default Invites;
