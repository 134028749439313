import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, columnLayoutStyle } from 'styles/theme';
import { connect } from 'react-redux';
import { Api } from 'helpers/utils';
import ShareLink from 'containers/ShareLink';
import Section from 'components/Section';
import Loading from 'components/Loading';
import TermsFooter from 'componentsCustom/TermsFooter';

const Styled = styled.div`
  ${columnLayoutStyle};
`;

class Invite extends React.Component {
  state = {
    specTemplateSlug: 'bar',
  };

  componentDidMount() {
    Api.get({ url: `/api/v1/invites/get_default_spec_template_slug` }).then(data => {
      if (data.success) {
        this.setState({ specTemplateSlug: data.default_spec_template_slug, didLoad: true });
      }
    });
  }

  render() {
    const { specTemplateSlug, didLoad } = this.state;
    const { hasCompany, currentCompany } = this.props;
    if (!didLoad) return <Loading />;
    const shareURL = `https://spec.me/org/${currentCompany.slug}/template/${specTemplateSlug}`;
    return (
      <React.Fragment>
        <Styled>
          <Section>
            <Section.Header title="Send Invites" subtitle="Invite your teammates to create their own Spec" />
            <Section.Body>
              <ShareLink
                shareDisplayURL={shareURL}
                shareURL={shareURL}
                entitySlug={specTemplateSlug}
                entityType="spec_template"
                copyLinkButtonText="Copy Invite Link"
                defaultMessage={`Hey there,\n\nI'm inviting you to create your Spec – a document that outlines your working style, preferences, quirks, and everything else how about how you operate.\n\nOnce published, I and others across the company will be able to review your Spec and start improving how we work together.`}
              />
            </Section.Body>
          </Section>
        </Styled>
        <TermsFooter />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(Invite);
