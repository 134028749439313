import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Stack, StackDivider } from '@chakra-ui/react';
import SwitchRow from 'components/SwitchRow';

const NotificationSettings = ({ notificationSettings, onSettingsChange }) => {
  return (
    <Stack spacing="16px" align="start" divider={<StackDivider borderColor={theme.colors.border} />}>
      {notificationSettings.map(notificationSetting => {
        return (
          <SwitchRow
            key={notificationSetting.setting_key}
            title={notificationSetting.title}
            subtitle={notificationSetting.subtitle}
            isChecked={notificationSetting.setting_value === 'on'}
            onChange={isTurnedOn => onSettingsChange(notificationSetting.setting_key, isTurnedOn ? 'on' : 'off')}
            useCheck
          />
        );
      })}
    </Stack>
  );
};

export default NotificationSettings;
