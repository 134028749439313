import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { PrivateMessageIllustration, SpecSearchIllustration, CreateProfileIllustration } from 'assets/illustrations/all';
import EmptyState from 'components/EmptyState';

const Styled = styled.div`
  ${containerStyle};
  margin: 0 auto;
  width: ${theme.layout.columnWidth};
  max-width: 100vw;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnauthNotice = ({
  isSignedIn,
  specSlug,
  isCurrentUserCreator,
  hasAccess,
  doesSpecExist,
  doesUserExist,
  onCreateSpecExistingUser,
  hasCompany,
  currentCompany,
  isSpecCreateLoading,
}) => {
  const navigate = useNavigate();
  if (isCurrentUserCreator) {
    return (
      <Styled>
        <EmptyState
          illustration={<CreateProfileIllustration />}
          title="You haven't created your Spec yet"
          subtitle="Showcase who you are & how you work"
          bg="white"
          border="none"
          buttonText="Create Spec"
          onButtonClick={onCreateSpecExistingUser}
          isButtonLoading={isSpecCreateLoading}
        />
      </Styled>
    );
  }

  if (!doesSpecExist) {
    if (doesUserExist) {
      return (
        <Styled>
          <EmptyState
            illustration={<SpecSearchIllustration />}
            title={`@${specSlug} hasn't created their Spec yet`}
            subtitle="Check back soon for updates!"
            bg="white"
            border="none"
          />
        </Styled>
      );
    }
    return (
      <Styled>
        <EmptyState
          illustration={<SpecSearchIllustration />}
          title="This Spec doesn't exist"
          subtitle="Check to make sure you have the correct URL"
          bg="white"
          border="none"
        />
      </Styled>
    );
  }

  return (
    <Styled>
      <EmptyState
        illustration={<PrivateMessageIllustration />}
        title="This Spec is private"
        subtitle={isSignedIn ? `You need to be part of @${specSlug}'s company to view` : 'Please join or login to view'}
        bg="white"
        border="none"
        buttonText="Login"
        onButtonClick={() =>
          navigate(`/org/${currentCompany.slug}/auth/create-account?login_initial=true&redirect=${window.location.href}`)
        }
        buttonColorScheme="gray"
        buttonVariant="outline"
        secondButtonText="Join"
        onSecondButtonClick={() => navigate(`/org/${currentCompany.slug}/auth/create-account?redirect=${window.location.href}`)}
        showButtons={!isSignedIn}
      />
    </Styled>
  );
};

export default UnauthNotice;
