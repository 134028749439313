import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Stack } from '@chakra-ui/react';
import { getIcon } from 'helpers/utils';
import { LocationIconFilled, PlusIcon, CloseIcon } from 'assets/icons';
import { LANGUAGES } from 'data/constants';
import ImageUpload from 'containers/ImageUpload';
import Button from 'components/Button';
import InputGroup from 'components/InputGroup';
import Text from 'components/Text';
import MonthDayPicker from './MonthDayPicker';
import MonthYearPicker from './MonthYearPicker';
import ApiSearchModal from 'containers/ApiSearchModal';

const Divider = styled.div`
  margin: 22px -22px 6px -22px !important;
  width: calc(100% + 44px);
  height: 2px;
  background: ${theme.colors.border};
`;

const EditHeader = ({
  specUser,
  specFields,
  onSpecUserChange,
  onFieldChange,
  onRemoveField,
  onCustomFieldNameChange,
  onAddCustomField,
}) => {
  return (
    <Stack spacing="16px" align="start">
      <InputGroup
        label="Image"
        field="image"
        customComponent={
          <ImageUpload
            value={specUser.image}
            onUpload={onSpecUserChange}
            field="image"
            uploadPreset="profile_image"
            folderName="profile_images"
          />
        }
        // errorLabel={!specUser.image && 'Required'}
      />

      {specFields.map(field => {
        if (field.field_data_type === 'link' || field.is_deleted) return null;
        if (field.field_key === 'birthday') {
          return (
            <MonthDayPicker
              key={field.field_key}
              value={field.field_value}
              label={field.field_name}
              field={field.field_key}
              onChange={onFieldChange}
            />
          );
        } else if (field.field_key === 'joined_company_date') {
          return (
            <MonthYearPicker
              key={field.field_key}
              value={field.field_value}
              label={field.field_name}
              field={field.field_key}
              onChange={onFieldChange}
            />
          );
        } else if (field.field_key === 'langudfages') {
          return (
            <InputGroup
              key={field.field_key}
              type="multi-select"
              value={field.field_value.split(',').map(language => {
                return { label: language, value: language };
              })}
              label={field.field_name}
              placeholder={field.field_placeholder}
              field={field.field_key}
              onChange={onFieldChange}
              selectOptions={LANGUAGES.map(language => {
                return { label: language, value: language };
              })}
            />
          );
        } else if (field.field_key === 'location' || field.field_key === 'hometown') {
          return (
            <ApiSearchModal
              key={field.field_key}
              apiToSearch="cities"
              selectedResults={[field.field_value]}
              onSelectResult={selectedResult => onFieldChange(field.field_key, selectedResult)}
              searchPlaceholder={field.field_placeholder}
              displayAsInput
              inputProps={{
                type: 'input',
                key: field.field_key,
                value: field.field_value,
                label: field.field_name,
                placeholder: field.field_placeholder,
                field: field.field_key,
                inputRightElement: <span style={{ color: theme.colors.accentText }}>{getIcon(field.field_key, false)}</span>,
                errorLabel: field.field_key === 'location' && !field.field_value && 'Required',
                readOnly: true,
              }}
            />
          );
        }

        return (
          <InputGroup
            key={field.field_key}
            type={field.field_type}
            value={field.field_value}
            label={field.field_name}
            placeholder={field.field_placeholder}
            field={field.field_key}
            onChange={onFieldChange}
            inputRightElement={<span style={{ color: theme.colors.accentText }}>{getIcon(field.field_key, false)}</span>}
            errorLabel={['job_title'].includes(field.field_key) && !field.field_value && 'Required'}
          />
        );
      })}
      <Divider />
      <Text color={theme.colors.primaryText}>Links</Text>
      {specFields.map(field => {
        if (field.field_data_type !== 'link' || field.is_deleted) return null;
        return (
          <InputGroup
            type="input"
            key={field.field_key}
            value={field.field_value}
            label={field.field_name}
            placeholder={field.field_placeholder}
            field={field.field_key}
            onChange={onFieldChange}
            isLabelEditable={field.is_custom}
            editableLabelPlaceholder="Type link title..."
            inputRightElement={
              field.is_custom ? (
                <button onClick={() => onRemoveField(field.field_key)}>
                  <CloseIcon color={theme.colors.accentText} />
                </button>
              ) : (
                <span style={{ color: theme.colors.accentText }}>{getIcon(field.field_key, false)}</span>
              )
            }
            elementPointerEvents="auto"
            onLabelChange={onCustomFieldNameChange}
          />
        );
      })}
      <Button variant="outline" leftIcon={<PlusIcon />} onClick={onAddCustomField}>
        Custom Link
      </Button>
    </Stack>
  );
};

export default EditHeader;
