import 'intro.js/introjs.css';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Steps, Hints } from 'intro.js-react';

const IntroSteps = ({
  stepsEnabled,
  steps,
  initialStep = 0,
  onExit,
  firstStepNextLabel = 'Next',
  nextLabel = 'Next',
  doneLabel = 'Done',
  skipLabel = '×',
  showBullets = true,
  showProgress = false,
  disableInteraction = true,
  exitOnOverlayClick = true,
}) => {
  const onSetNextButtonLabel = stepIndex => {
    let currentNextLabel = stepIndex === 0 ? firstStepNextLabel : nextLabel;
    if (stepIndex === steps.length - 1) currentNextLabel = doneLabel;
    document.querySelector('.introjs-nextbutton').innerHTML = currentNextLabel;
  };

  return (
    <Steps
      onStart={stepIndex => onSetNextButtonLabel(stepIndex)}
      onAfterChange={stepIndex => onSetNextButtonLabel(stepIndex)}
      enabled={stepsEnabled}
      steps={steps}
      initialStep={initialStep}
      onExit={onExit}
      options={{
        hideNext: false,
        doneLabel: doneLabel,
        skipLabel: skipLabel,
        hidePrev: true,
        showBullets: showBullets,
        disableInteraction: disableInteraction,
        showProgress: showProgress,
        exitOnOverlayClick: exitOnOverlayClick,
        scrollTo: 'tooltip',
      }}
    />
  );
};

export default IntroSteps;
