import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Stack, HStack } from '@chakra-ui/react';
import { LockedIconFilled, EarthIconFilled } from 'assets/icons';
import CompanyEmailValidator from 'company-email-validator';
import InputGroup from 'components/InputGroup';
import InfoGroup from 'components/InfoGroup';
import Box from 'components/Box';
import Button from 'components/Button';
import ChangeEmailModal from './ChangeEmailModal';
import ShareControls from 'containers/ShareLink/ShareControls';

const AccountSettings = ({ spec, specUser, onSpecChange, onSpecUserChange, isUsernameAvailable, onDeleteSpec }) => {
  const [activeChangeEmailField, setActiveChangeEmailField] = useState(null);

  return (
    <Stack spacing="16px" align="start">
      {!!activeChangeEmailField && (
        <ChangeEmailModal
          isOpen
          onClose={() => setActiveChangeEmailField(null)}
          currentEmail={activeChangeEmailField === 'email' ? specUser.email : specUser.recovery_email}
          emailField={activeChangeEmailField}
          onSavedCallback={email => onSpecUserChange(activeChangeEmailField, email)}
        />
      )}
      <InputGroup
        type="input"
        value={specUser.slug}
        label="URL"
        placeholder="username"
        field="slug"
        onChange={onSpecUserChange}
        inputLeftAddon="spec.me/"
        errorLabel={
          !isUsernameAvailable && (
            <span>
              Username <em>{specUser.slug}</em> is not available, try another!
            </span>
          )
        }
      />
      <HStack spacing="16px" style={{ width: '100%' }}>
        <InputGroup
          type="input"
          value={specUser.first_name}
          label="First Name"
          placeholder="First"
          field="first_name"
          onChange={onSpecUserChange}
          width="50%"
        />
        <InputGroup
          type="input"
          value={specUser.last_name}
          label="Last Name"
          placeholder="Last"
          field="last_name"
          onChange={onSpecUserChange}
          width="50%"
        />
      </HStack>
      <InputGroup
        type="input"
        value={specUser.email}
        label="Email"
        placeholder="Email"
        field="email"
        onChange={onSpecUserChange}
        isDisabled={CompanyEmailValidator.isCompanyEmail(specUser.email)}
        onFocus={() => setActiveChangeEmailField('email')}
      />

      <InputGroup
        type="input"
        value={specUser.recovery_email}
        label="Recovery Email"
        sublabel="If you leave your company you can recover your Spec with this email"
        placeholder="Personal email"
        field="recovery_email"
        autoComplete="off"
        onChange={() => setActiveChangeEmailField('recovery_email')}
        onFocus={() => setActiveChangeEmailField('recovery_email')}
        onClick={() => setActiveChangeEmailField('recovery_email')}
      />

      <InputGroup
        label="Spec Status"
        type="select"
        value={spec.is_published}
        placeholder="Select published status:"
        field="is_published"
        onChange={onSpecChange}
        selectOptions={[{ label: 'Published', value: true }, { label: 'Unpublished', value: false }].map(option => {
          return { label: option.label, value: option.value };
        })}
      />

      <ShareControls isPublic={spec.is_public} onUpdateSharePermissions={isPublic => onSpecChange('is_public', isPublic)} />

      <Button color={theme.colors.primaryText} variant="link" onClick={onDeleteSpec}>
        Delete my Spec & account
      </Button>
    </Stack>
  );
};

export default AccountSettings;
