import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, columnLayoutStyle } from 'styles/theme';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { copyToClipboard } from 'helpers/utils';
import { createStandaloneToast } from '@chakra-ui/toast';
import { Grid } from '@chakra-ui/react';
import { withNavigation, withLocation } from 'hooks/router';
import { urls } from 'data/constants';
import Navigation from './Navigation';
import Overview from './sections/Overview';
import Analytics from './sections/Analytics';
import SpecTemplates from './sections/SpecTemplates';
import EditSpecTemplate from './sections/SpecTemplates/EditSpecTemplate';
import Users from './sections/Users';
import Invites from './sections/Invites';
import Settings from './sections/Settings';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const StyledGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 300px 1fr;
  margin-top: -40px;
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    grid-template-columns: 200px 1fr;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
`;

const Body = styled.div`
  position: relative;
  grid-column: 2 / 3;
  ${columnLayoutStyle};
  max-width: calc(100vw - 300px);
  padding: ${theme.padding.xl} ${theme.padding.lg};
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    max-width: calc(100vw - 200px);
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 100vw;
    max-width: 100vw;
    padding: 0px;
  }
  ${({ shouldExpandWidth }) =>
    shouldExpandWidth &&
    css`
      width: ${theme.layout.expandedAdminColumnWidth};
    `};
`;

class Admin extends React.Component {
  state = {};

  componentDidMount() {
    const { isSignedIn, currentUser, hasCompany, currentCompany } = this.props;
    if (hasCompany && isSignedIn && currentUser.is_super_admin) {
      this.setState({ didLoad: true });
    } else if (!hasCompany || !isSignedIn || !currentUser.is_admin || currentUser.company_id !== currentCompany.id) {
      window.location.href = hasCompany
        ? `${urls.routeDomain}/org/${currentCompany.slug}/auth/login?redirect=${window.location.href}`
        : `${urls.routeDomain}/auth/login?redirect=${window.location.href}`;
    } else {
      this.setState({ didLoad: true });
    }
  }

  triggerToast = ({ title, status = 'success', duration = 4000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  onCopyShareLink = copyLink => {
    copyToClipboard(copyLink);
    this.triggerToast({ title: 'Link copied to your clipboard', status: 'success' });
  };

  render() {
    const { didLoad } = this.state;
    const { isSignedIn, currentUser, hasCompany, currentCompany, navigate, location } = this.props;
    if (!didLoad) return null;
    const shouldExpandWidth = location.pathname.includes('/analytics') || location.pathname.includes('/billing');
    const selectedTier = hasCompany && currentCompany.tier;
    return (
      <StyledGrid>
        <ToastContainer />
        <Navigation currentCompanySlug={currentCompany?.slug} selectedTier={selectedTier} />
        <Body shouldExpandWidth={shouldExpandWidth}>
          <Routes>
            <Route path="/" element={<Overview currentUserFirstName={currentUser?.first_name} />} />
            <Route
              path="/templates"
              element={
                <SpecTemplates
                  triggerToast={this.triggerToast}
                  onCopyShareLink={this.onCopyShareLink}
                  isFeatureGated={selectedTier === 1}
                />
              }
            />
            <Route
              path="/templates/:slug"
              element={
                <EditSpecTemplate
                  navigate={navigate}
                  currentCompanySlug={currentCompany?.slug}
                  triggerToast={this.triggerToast}
                  onCopyShareLink={this.onCopyShareLink}
                  currentUserID={currentUser?.id}
                />
              }
            />
            <Route path="/analytics" element={<Analytics isFeatureGated={selectedTier === 1} />} />
            <Route path="/users" element={<Users triggerToast={this.triggerToast} />} />
            <Route
              path="/invites/*"
              element={<Invites triggerToast={this.triggerToast} currentCompany={currentCompany} selectedTier={selectedTier} />}
            />
            <Route path="/settings/*" element={<Settings triggerToast={this.triggerToast} currentCompanySlug={currentCompany?.slug} />} />
          </Routes>
        </Body>
      </StyledGrid>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default withLocation(withNavigation(connect(mapStateToProps)(Admin)));
