import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, LightningIconFilled } from 'assets/icons';
import { HiHome, HiTemplate, HiChartBar, HiUsers, HiMail, HiCog } from 'react-icons/hi';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InfoGroup from 'components/InfoGroup';
import TermsFooter from 'componentsCustom/TermsFooter';

const PRIMARY_ROUTES = [
  { display: 'Overview', path: '', icon: <HiHome size={18} /> },
  { display: 'Templates', path: 'templates', icon: <HiTemplate size={18} /> },
  { display: 'Analytics', path: 'analytics', icon: <HiChartBar size={18} /> },
  { display: 'Users', path: 'users', icon: <HiUsers size={18} /> },
  { display: 'Invites', path: 'invites', icon: <HiMail size={18} /> },
  { display: 'Settings', path: 'settings', has_sub_routes: true, icon: <HiCog size={18} /> },
];

const SETTINGS_ROUTES = [
  { display: 'Slack', path: 'settings/slack' },
  { display: 'HRIS', path: 'settings/hris' },
  { display: 'SSO', path: 'settings/sso', should_hide: true },
  { display: 'Admins', path: 'settings/admins' },
  { display: 'Billing', path: 'settings/billing' },
];

const returnPlanTitle = selectedTier => {
  if (selectedTier === 1) {
    return 'Free Plan';
  } else if (selectedTier === 2) {
    return 'Professional Plan';
  } else if (selectedTier === 3) {
    return 'Enterprise Plan';
  }
};

const Styled = styled.div`
  position: sticky;
  top: 72px;
  grid-column: 1 / 2;
  padding: ${theme.padding.lg};
  border-right: ${theme.layout.border};
  background: white;
  height: calc(100vh - 72px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    position: relative;
    top: 0px;
    border-right: none;
    width: 100vw;
    overflow-x: auto;
    border-bottom: ${theme.layout.border};
    margin-bottom: 8px;
    height: auto;
    padding: ${theme.padding.md};
  }
  ${({ hasBorderTop }) =>
    hasBorderTop &&
    css`
      border-top: ${theme.layout.border};
    `};
`;

const NavigationRoutes = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`;

const TierBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-radius: ${theme.radii.md};
  border: 1px solid ${theme.colors.border};
  margin-top: 16px;
`;

const TermsFooterWrapper = styled.div`
  width: calc(100% + 44px);
  margin: 22px -22px -22px -22px;
  border-top: 1px solid ${theme.colors.border};
  #terms-footer {
    align-items: flex-start;
  }
`;

const Navigation = ({ currentCompanySlug, selectedTier }) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Styled>
        <NavigationRoutes>
          {PRIMARY_ROUTES.map(route => {
            let isSelected;
            if (route.path === '') {
              isSelected = location.pathname === `/org/${currentCompanySlug}/admin` || location.pathname === '/admin';
            } else {
              isSelected = location.pathname.includes(route.path);
            }
            let showActiveState = isSelected;
            if (route.path === 'settings' && isSelected && location.pathname !== `/org/${currentCompanySlug}/admin/settings`)
              showActiveState = false;
            return (
              <Link key={route.display} to={route.path}>
                <Button
                  variant={showActiveState ? 'solid' : 'ghost'}
                  as="div"
                  width="100%"
                  justifyContent="flex-start"
                  padding="14px 16px"
                  height="auto"
                  justifyContent="space-between">
                  <Flex gap={['8px', '8px', '14px']}>
                    {route.icon}
                    {route.display}
                  </Flex>
                  {route.has_sub_routes && (isSelected ? <ChevronUpIcon className="desktop" /> : <ChevronDownIcon className="desktop" />)}
                </Button>
              </Link>
            );
          })}
          {location.pathname.includes('/admin/settings') && <SubNavigation location={location} className="desktop" />}
        </NavigationRoutes>
        <div>
          <ChakraLink to={`/org/${currentCompanySlug}/admin/settings/billing`} as={Link} className="desktop">
            <TierBadge>
              {selectedTier > 1 && <LightningIconFilled style={{ marginRight: 6 }} color={theme.colors.accent} />}
              <InfoGroup
                title={returnPlanTitle(selectedTier)}
                subtitle={selectedTier === 1 && 'Upgrade'}
                titleFontFamily={theme.fonts.medium}
                subtitleFontColor={theme.colors.accent}
              />
            </TierBadge>
          </ChakraLink>
          <TermsFooterWrapper className="desktop">
            <TermsFooter showLogo={false} p={theme.padding.lg} />
          </TermsFooterWrapper>
        </div>
      </Styled>
      {location.pathname.includes('/admin/settings') && (
        <Styled className="mobile" hasBorderTop>
          <SubNavigation location={location} />
        </Styled>
      )}
    </React.Fragment>
  );
};

const SubNavigation = ({ location, className }) => {
  return (
    <Flex direction={['row', 'row', 'column']} align="stretch" className={className}>
      {SETTINGS_ROUTES.map(route => {
        const isSelected = location.pathname.includes(route.path);
        if (route.should_hide) return null;
        return (
          <Link key={route.display} to={route.path}>
            <Button
              variant={isSelected ? 'solid' : 'ghost'}
              as="div"
              width="100%"
              justifyContent="flex-start"
              padding="14px 16px"
              height="auto"
              justifyContent="space-between">
              {route.display}
            </Button>
          </Link>
        );
      })}
    </Flex>
  );
};

export default Navigation;
