import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle, multiLineText } from 'styles/theme';
import { Link, useLocation } from 'react-router-dom';
import { SettingsIconFilled, EditIconFilled, EarthIconFilled, LockedIconFilled, ImageAddIconFilled } from 'assets/icons';
import { Tooltip, ButtonGroup } from '@chakra-ui/react';
import Text from 'components/Text';
import InfoGroup from 'components/InfoGroup';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Flex from 'components/Flex';
import Box from 'components/Box';
import Fields from './Fields';
import SlackStatusBadge from './SlackStatusBadge';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  margin-bottom: ${theme.margin.lg};
  min-height: 320px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.margin.xs};
    min-height: auto;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.margin.xs};
    min-height: auto;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .image-name-info-group {
      flex-direction: column;
      align-items: flex-start;
    }
    .image-name-info-group .space-between {
      height: 4px;
    }
  }
`;

const Bio = styled(Box)`
  text-align: left;
  padding: 0px;
  background: transparent;
  border-radius: 0px;
`;

const AbsUploadButton = styled.div`
  position: absolute;
  top: 96px;
  left: 96px;
  transform: translate(-100%, -100%);
`;

const Header = ({ specUser, specFields, isCurrentUserCreator, isEditMode, isPublic }) => {
  const location = useLocation();
  const [isBioExpanded, setIsBioExpanded] = useState(false);
  const jobTitleFieldObject = specFields.find(field => field.field_key === 'job_title');
  const bioObject = specFields.find(field => field.field_key === 'bio');
  const hasNoImage = !specUser.image;
  return (
    <StyledFlex direction="column" gap="16px" align="stretch">
      <Flex align="flex-start" justify="space-between">
        <Flex position="relative">
          <InfoGroup
            avatarSrc={specUser.image || '-'}
            avatarSize="xl"
            title={specUser.name}
            subtitle={jobTitleFieldObject.field_value}
            titleFontSize={theme.fontSizes.headerOne}
            spaceBetween="16px"
            noSpaceBetween={false}
            showAvatarBadge={specUser.slack_status && specUser.slack_status.status_text}
            customAvatarBadgeIcon={<SlackStatusBadge slackStatus={specUser.slack_status} />}
            avatarBadgeBg="white"
            avatarBadgeBoxSize="auto"
            className="image-name-info-group"
          />
          {isEditMode &&
            hasNoImage &&
            isCurrentUserCreator &&
            !(specUser.slack_status && specUser.slack_status.status_text) && (
              <AbsUploadButton>
                <IconButton
                  variant="outline"
                  bg="white"
                  isRound
                  size="sm"
                  as={Link}
                  to="account"
                  icon={<ImageAddIconFilled size={16} color={theme.colors.paragraphText} />}
                />
              </AbsUploadButton>
            )}
        </Flex>
        {!isEditMode && (
          <Link to="share" className="desktop">
            <Button variant="outline" as="div">
              Share
            </Button>
          </Link>
        )}

        {isCurrentUserCreator &&
          isEditMode && (
            <Flex gap="12px">
              <ButtonGroup isAttached>
                <Button variant="outline" as={Link} to="account" color={theme.colors.paragraphText}>
                  <span className="mobile">
                    <EditIconFilled />
                  </span>
                  <span className="desktop">Edit Header</span>
                </Button>

                <Tooltip label="Edit Settings" placement="top">
                  <Button variant="outline" as={Link} to="account/settings" color={theme.colors.paragraphText}>
                    <SettingsIconFilled />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Tooltip label={isPublic ? 'Edit Spec Visibility (Public)' : 'Edit Spec Visibility (Private)'} placement="top">
                <Link to="account/settings" className="desktop">
                  <IconButton
                    variant="outline"
                    as="div"
                    color={theme.colors.paragraphText}
                    icon={isPublic ? <EarthIconFilled /> : <LockedIconFilled />}
                  />
                </Link>
              </Tooltip>
            </Flex>
          )}
      </Flex>
      {bioObject.field_value && (
        <Bio onClick={() => setIsBioExpanded(true)} as={!isBioExpanded && 'button'}>
          <Text fontFamily={theme.fonts.light} noOfLines={!isBioExpanded && 5} css={multiLineText}>
            {bioObject.field_value}
          </Text>
        </Bio>
      )}
      {!bioObject.field_value &&
        isEditMode && (
          <Text
            fontFamily={theme.fonts.light}
            color={theme.colors.lightAccentText}
            noOfLines={!isBioExpanded && 5}
            css={multiLineText}
            as="i">
            No bio
          </Text>
        )}
      <Fields specFields={specFields} isEditMode={isEditMode} />
    </StyledFlex>
  );
};

export default Header;
