import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { ButtonGroup } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Button from 'components/Button';

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
  .first {
    border-right: 0px;
  }
  .second {
    border-left: 0px;
  }
  .first:hover,
  .second:hover {
    color: ${theme.colors.primaryText};
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: ${theme.colors.border};
`;

const Header = ({ activityType, onChangeActivityType }) => {
  return (
    <Flex justify="space-between" padding={theme.padding.lg} borderBottom={theme.layout.border}>
      <Text fontFamily={theme.fonts.semiBold} fontSize={theme.fontSizes.md} color={theme.colors.primaryText} className="desktop">
        Activity
      </Text>

      <StyledButtonGroup isAttached id="edit-mode-toggle-buttons">
        <Button
          variant="outline"
          className="first"
          color={activityType === 'personal' ? theme.colors.primaryText : theme.colors.primaryText + '55'}
          onClick={() => onChangeActivityType('personal')}>
          My Activity
        </Button>
        <Divider />
        <Button
          variant="outline"
          className="second"
          color={activityType === 'company' ? theme.colors.primaryText : theme.colors.primaryText + '55'}
          onClick={() => onChangeActivityType('company')}>
          Company Activity
        </Button>
      </StyledButtonGroup>
    </Flex>
  );
};

export default Header;
