const FreeForm = ({ size = 110 }) => {
  return (
    <svg width="234" height="24" viewBox="0 0 234 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11H12.5C13.163 11 13.7989 10.7366 14.2678 10.2678C14.7366 9.79893 15 9.16304 15 8.5C15 7.83696 14.7366 7.20107 14.2678 6.73223C13.7989 6.26339 13.163 6 12.5 6H8V11ZM18 15.5C18 16.0909 17.8836 16.6761 17.6575 17.2221C17.4313 17.768 17.0998 18.2641 16.682 18.682C16.2641 19.0998 15.768 19.4313 15.2221 19.6575C14.6761 19.8836 14.0909 20 13.5 20H6V4H12.5C13.381 4.00004 14.2425 4.25865 14.9779 4.74378C15.7132 5.2289 16.29 5.9192 16.6367 6.72907C16.9834 7.53894 17.0847 8.43276 16.9282 9.29969C16.7716 10.1666 16.3641 10.9685 15.756 11.606C16.4386 12.0013 17.0053 12.5692 17.3992 13.2526C17.7931 13.9361 18.0003 14.7112 18 15.5ZM8 13V18H13.5C14.163 18 14.7989 17.7366 15.2678 17.2678C15.7366 16.7989 16 16.163 16 15.5C16 14.837 15.7366 14.2011 15.2678 13.7322C14.7989 13.2634 14.163 13 13.5 13H8Z"
        fill="#E0E6ED"
      />
      <path d="M45 20H37V18H39.927L42.043 6H39V4H47V6H44.073L41.957 18H45V20Z" fill="#E0E6ED" />
      <path
        d="M198.364 15.536L196.95 14.12L198.364 12.706C198.832 12.2424 199.203 11.6911 199.457 11.0836C199.711 10.4761 199.843 9.8244 199.844 9.16595C199.845 8.5075 199.717 7.85525 199.465 7.24665C199.214 6.63804 198.845 6.08507 198.38 5.61947C197.914 5.15387 197.361 4.78483 196.752 4.53351C196.144 4.2822 195.491 4.15357 194.833 4.15502C194.175 4.15646 193.523 4.28794 192.915 4.54192C192.308 4.79589 191.757 5.16736 191.293 5.63499L189.879 7.04999L188.464 5.63599L189.88 4.22199C191.193 2.90917 192.973 2.17163 194.83 2.17163C196.687 2.17163 198.467 2.90917 199.78 4.22199C201.093 5.53481 201.83 7.31538 201.83 9.17199C201.83 11.0286 201.093 12.8092 199.78 14.122L198.365 15.536H198.364ZM195.536 18.364L194.121 19.778C192.808 21.0908 191.028 21.8283 189.171 21.8283C187.314 21.8283 185.534 21.0908 184.221 19.778C182.908 18.4652 182.171 16.6846 182.171 14.828C182.171 12.9714 182.908 11.1908 184.221 9.87799L185.636 8.46399L187.05 9.87999L185.636 11.294C185.168 11.7575 184.797 12.3089 184.543 12.9164C184.289 13.5239 184.157 14.1756 184.156 14.834C184.155 15.4925 184.283 16.1447 184.535 16.7533C184.786 17.3619 185.155 17.9149 185.62 18.3805C186.086 18.8461 186.639 19.2152 187.248 19.4665C187.856 19.7178 188.509 19.8464 189.167 19.845C189.825 19.8435 190.477 19.712 191.085 19.4581C191.692 19.2041 192.243 18.8326 192.707 18.365L194.121 16.951L195.536 18.365V18.364ZM194.828 7.75699L196.243 9.17199L189.172 16.242L187.757 14.828L194.828 7.75799V7.75699Z"
        fill="#E0E6ED"
      />
      <path
        d="M158 4H171V6H158V4ZM155 3V6H156V7H153V6H154V4H153V3H155ZM153 14V11.5H155V11H153V10H156V12.5H154V13H156V14H153ZM155 19.5H153V18.5H155V18H153V17H156V21H153V20H155V19.5ZM158 11H171V13H158V11ZM158 18H171V20H158V18Z"
        fill="#E0E6ED"
      />
      <path
        d="M128 4H141V6H128V4ZM124.5 6.5C124.102 6.5 123.721 6.34196 123.439 6.06066C123.158 5.77936 123 5.39782 123 5C123 4.60218 123.158 4.22064 123.439 3.93934C123.721 3.65804 124.102 3.5 124.5 3.5C124.898 3.5 125.279 3.65804 125.561 3.93934C125.842 4.22064 126 4.60218 126 5C126 5.39782 125.842 5.77936 125.561 6.06066C125.279 6.34196 124.898 6.5 124.5 6.5ZM124.5 13.5C124.102 13.5 123.721 13.342 123.439 13.0607C123.158 12.7794 123 12.3978 123 12C123 11.6022 123.158 11.2206 123.439 10.9393C123.721 10.658 124.102 10.5 124.5 10.5C124.898 10.5 125.279 10.658 125.561 10.9393C125.842 11.2206 126 11.6022 126 12C126 12.3978 125.842 12.7794 125.561 13.0607C125.279 13.342 124.898 13.5 124.5 13.5ZM124.5 20.4C124.102 20.4 123.721 20.242 123.439 19.9607C123.158 19.6794 123 19.2978 123 18.9C123 18.5022 123.158 18.1206 123.439 17.8393C123.721 17.558 124.102 17.4 124.5 17.4C124.898 17.4 125.279 17.558 125.561 17.8393C125.842 18.1206 126 18.5022 126 18.9C126 19.2978 125.842 19.6794 125.561 19.9607C125.279 20.242 124.898 20.4 124.5 20.4ZM128 11H141V13H128V11ZM128 18H141V20H128V18Z"
        fill="#E0E6ED"
      />
      <path
        d="M107.154 14C107.384 14.516 107.5 15.09 107.5 15.72C107.5 17.062 106.976 18.112 105.929 18.867C104.88 19.622 103.433 20 101.586 20C99.946 20 98.323 19.619 96.716 18.856V16.6C98.236 17.477 99.791 17.916 101.382 17.916C103.933 17.916 105.212 17.184 105.221 15.719C105.226 15.422 105.172 15.1269 105.06 14.8515C104.949 14.5761 104.783 14.3259 104.573 14.116L104.453 13.999H93V11.999H111V13.999H107.154V14ZM103.076 11H97.629C97.4538 10.8403 97.2929 10.6656 97.148 10.478C96.716 9.92 96.5 9.246 96.5 8.452C96.5 7.216 96.966 6.165 97.897 5.299C98.83 4.433 100.271 4 102.222 4C103.693 4 105.101 4.328 106.444 4.984V7.136C105.244 6.449 103.929 6.106 102.498 6.106C100.018 6.106 98.779 6.888 98.779 8.452C98.779 8.872 98.997 9.238 99.433 9.551C99.869 9.864 100.407 10.113 101.046 10.301C101.666 10.481 102.343 10.715 103.076 11V11Z"
        fill="#E0E6ED"
      />
      <path
        d="M68 3V12C68 13.0609 68.4214 14.0783 69.1716 14.8284C69.9217 15.5786 70.9391 16 72 16C73.0609 16 74.0783 15.5786 74.8284 14.8284C75.5786 14.0783 76 13.0609 76 12V3H78V12C78 13.5913 77.3679 15.1174 76.2426 16.2426C75.1174 17.3679 73.5913 18 72 18C70.4087 18 68.8826 17.3679 67.7574 16.2426C66.6321 15.1174 66 13.5913 66 12V3H68ZM64 20H80V22H64V20Z"
        fill="#E0E6ED"
      />
      <path
        d="M214.828 21L214.808 21.02L214.787 21H212.992C212.729 20.9997 212.476 20.895 212.29 20.7088C212.104 20.5226 212 20.2702 212 20.007V3.993C212.002 3.73038 212.107 3.47902 212.293 3.29322C212.478 3.10742 212.729 3.00209 212.992 3H231.008C231.556 3 232 3.445 232 3.993V20.007C231.998 20.2696 231.893 20.521 231.707 20.7068C231.522 20.8926 231.271 20.9979 231.008 21H214.828V21ZM230 15V5H214V19L224 9L230 15ZM230 17.828L224 11.828L216.828 19H230V17.828ZM218 11C217.47 11 216.961 10.7893 216.586 10.4142C216.211 10.0391 216 9.53043 216 9C216 8.46957 216.211 7.96086 216.586 7.58579C216.961 7.21071 217.47 7 218 7C218.53 7 219.039 7.21071 219.414 7.58579C219.789 7.96086 220 8.46957 220 9C220 9.53043 219.789 10.0391 219.414 10.4142C219.039 10.7893 218.53 11 218 11Z"
        fill="#E0E6ED"
      />
    </svg>
  );
};

export default FreeForm;
