import React from 'react';
import Directory from 'pages/Directory';

const SpecsList = ({ specTemplateName, specTemplateSlug }) => {
  return (
    <Directory
      isWithinPanel
      initialSearchQueries={[
        {
          display: specTemplateName,
          query_type: 'spec_template',
          query_field: specTemplateSlug,
          query_value: '',
        },
      ]}
    />
  );
};

export default SpecsList;
