import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import {
  SmileIconOutline,
  HorizontalDotsIcon,
  ChatIconOutline,
  TrashIconOutline,
  EyeIconOutline,
  EyeOffIconOutline,
  CopyIconOutline,
  LinkIcon,
} from 'assets/icons';
import { HStack, Tooltip, Tag } from '@chakra-ui/react';
import Flex from 'components/Flex';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import Menu from 'components/Menu';
import EmojiPicker from 'components/EmojiPicker';
import ReactionsList from './ReactionsList';
import Comments from './Comments';

const StyledFlex = styled(Flex)``;

const StyledReactionsBar = styled(HStack)`
  flex-wrap: wrap;
  max-width: 85%;
  margin: -4px;
`;

const Padding = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

const CommentsWrapper = styled.div`
  margin: 22px -22px -22px -22px;
  width: calc(100% + 44px);
  border-top: 1px solid ${theme.colors.border};
  padding: 22px;
`;

const getNumberOfComments = comments => {
  let total = 0;
  comments.forEach(comment => {
    if (!comment.is_deleted && !comment.is_draft) {
      total += 1;
      comment.comments.forEach(comment => {
        if (!comment.is_deleted && !comment.is_draft) total += 1;
      });
    }
  });
  return total;
};

const SectionFooter = ({
  reactions,
  onSelectReaction,
  comments,
  onShowComments,
  visibleCommentSectionIDs,
  sectionIndex,
  sectionFrontID,
  isSignedIn,
  currentUser,
  menuItems,
  currentSpecSlug,
  onCommentChange,
  onCommentAdd,
  onCommentReaction,
  onCommentPost,
  isSectionHidden,
  isWithinTemplate,
}) => {
  const numberOfComments = getNumberOfComments(comments);
  const showComments = visibleCommentSectionIDs.includes(sectionFrontID);
  return (
    <React.Fragment>
      <Flex align="flex-start" justify="space-between" marginTop={!isSectionHidden && '20px'}>
        {isWithinTemplate && <div />}
        <StyledReactionsBar spacing="0px" display={isSectionHidden || isWithinTemplate ? 'none' : 'flex'}>
          <ReactionsList
            reactions={reactions}
            onSelectReaction={onSelectReaction}
            isSignedIn={isSignedIn}
            currentUserSlug={currentUser?.slug}
            sectionIndex={sectionIndex}
          />

          <EmojiPicker onEmojiSelect={emoji => onSelectReaction(sectionIndex, emoji)}>
            <Tooltip label="Add reaction" placement="top" openDelay={200} hasArrow>
              <Padding>
                <IconButton variant="outline" isRound size="sm" icon={<SmileIconOutline size={16} />} as="div" />
              </Padding>
            </Tooltip>
          </EmojiPicker>
          <Padding>
            <Button
              variant="link"
              padding="0px"
              fontSize="14px"
              size="sm"
              color={theme.colors.paragraphText}
              leftIcon={<ChatIconOutline size={16} />}
              onClick={() => onShowComments(sectionIndex, sectionFrontID)}>
              {showComments ? (
                'Hide Comments'
              ) : (
                <>
                  {numberOfComments > 0 && numberOfComments} Comment{numberOfComments > 1 && 's'}
                </>
              )}
            </Button>
          </Padding>
        </StyledReactionsBar>

        <Menu placement="top-end">
          <Menu.Button>
            <IconButton variant="outline" isRound size="sm" as="div" icon={<HorizontalDotsIcon size={16} />} />
          </Menu.Button>

          <Menu.List>
            {menuItems.map(menuItem => {
              return (
                <Menu.Item
                  key={menuItem.label}
                  color={menuItem.color}
                  icon={menuItem.icon}
                  onClick={menuItem.onClick}
                  isDisabled={menuItem.disabled}>
                  {menuItem.label}
                </Menu.Item>
              );
            })}
          </Menu.List>
        </Menu>
      </Flex>
      {showComments && (
        <CommentsWrapper>
          <Comments
            comments={comments}
            sectionIndex={sectionIndex}
            onCommentChange={onCommentChange}
            onCommentAdd={onCommentAdd}
            onCommentReaction={onCommentReaction}
            onCommentPost={onCommentPost}
            isSignedIn={isSignedIn}
            currentUserSlug={currentUser?.slug}
            currentSpecSlug={currentSpecSlug}
          />
        </CommentsWrapper>
      )}
    </React.Fragment>
  );
};

export default React.memo(SectionFooter, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
