import React from 'react';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateSpec from './CreateSpec';
import Login from './Login';
import GeneralSignup from './GeneralSignup';
import Modal from 'components/Modal';

const Auth = ({ hasCompany, isModal }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onModalClose = () => {
    if (params.slug) {
      navigate(`/${params.slug}`);
    } else {
      navigate(-1);
    }
  };

  if (isModal) {
    return (
      <Modal isOpen isCentered onClose={onModalClose} constrainWidthMobile>
        <AuthRoutes isModal />
      </Modal>
    );
  }

  if (!location.pathname.includes('/org')) {
    return (
      <GeneralSignup>
        <AuthRoutes pageWithin="general_signup" />
      </GeneralSignup>
    );
  }

  return <AuthRoutes pageWithin="within_company_signup" />;
};

const AuthRoutes = ({ pageWithin, isModal }) => (
  <Routes>
    <Route path="/" element={<CreateSpec pageWithin={pageWithin} isModal={isModal} />} />
    <Route path="/create-account" element={<Login pageWithin={pageWithin} typeOfAuth="create_account" isModal={isModal} />} />
    <Route path="/login" element={<Login pageWithin={pageWithin} isModal={isModal} />} />
  </Routes>
);

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(Auth);
