import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, copyToClipboard, arrayMove, randomID, returnQuery } from 'helpers/utils';
import { returnGhostElement } from 'helpers/specUtils';
import {
  returnIfSectionHasAnswers,
  returnEmptySectionAnswer,
  returnSectionMenuItems,
  returnNewReactions,
  returnBlankComment,
  returnDuplicateSection,
} from 'helpers/specUtils';
import { AddCircleIconOutline } from 'assets/icons';
import { default as Container } from 'componentsCustom/SpecSectionContainer';
import { Flex } from '@chakra-ui/react';
import update from 'immutability-helper';
import Sortable from 'sortablejs';
import Questions from './Questions';
import Spectrum from './Spectrum';
import Topics from './Topics';
import Freeform from './Freeform';
import List from './List';
import WorkHistory from './WorkHistory';
import Video from './Video';
import MyersBriggs from './MyersBriggs';
import BigFive from './BigFive';
import Strengthsfinder from './Strengthsfinder';
import ZodiacSign from './ZodiacSign';
import MediaCarousel from './MediaCarousel';
import CreatorNavigationFooter from 'pages/Spec/layout/CreatorNavigationFooter';
import SectionFooter from './SectionFooter';
import Button from 'components/Button';
import SpecSectionPicker from 'containers/SpecSectionPicker';

class SpecSections extends React.Component {
  state = {
    specSections: [],
    hasUnsavedChanges: false,
    newComments: [{}],
    visibleCommentSectionIDs: [],
    isDraggingMode: false,
  };

  componentDidMount() {
    const { specSections, isCurrentUserCreator } = this.props;
    this.setState({ specSections: specSections }, () => {
      this.handleQueryParams();
    });
    if (isCurrentUserCreator) this.initiateCreatorControls();
  }

  handleQueryParams() {
    const params = returnQuery();
    if (params.section && params.section.length > 3) {
      const sectionIndex = this.state.specSections.findIndex(specSection => specSection.front_id === params.section);
      if (sectionIndex !== -1) {
        this.scrollToHighlight(`section-${sectionIndex}-element`, true);
        this.onShowComments(sectionIndex, params.section);
      }
    }
  }

  initiateCreatorControls() {
    this.onStartAutosave();
    const containerEl = document.getElementById('sortable-sections-container');
    const filterClass = 'not-sortable';
    Sortable.create(containerEl, {
      handle: '.section-drag-handle',
      ghostClass: 'dragging-ghost',
      filter: `.${filterClass}`,
      onMove: event => {
        return !event.related.classList.contains(filterClass);
      },
      setData: (dataTransfer, dragEl) => {
        const element = returnGhostElement(dragEl);
        document.body.appendChild(element);
        dataTransfer.setDragImage(element, 0, 0);
      },
      onStart: event => {
        document.documentElement.classList.add('draggable-cursor');
        this.setState({ isDraggingMode: true });
      },
      onEnd: event => {
        document.documentElement.classList.remove('draggable-cursor');
        this.setState({ isDraggingMode: false });
        this.onDragSectionComplete(event.oldIndex, event.newIndex);
      },
    });
  }

  componentWillUnmount() {
    clearInterval(this.autoSaveInterval);
  }

  onStartAutosave() {
    const { isWithinTemplate } = this.props;
    const intervalAmount = isWithinTemplate ? 50 : 2500;
    this.autoSaveInterval = setInterval(() => {
      const { hasUnsavedChanges } = this.state;
      if (hasUnsavedChanges) this.onSaveSpecSections();
    }, intervalAmount);
  }

  onAddSection = section => {
    this.setState({ specSections: [...this.state.specSections, section], hasUnsavedChanges: true, showSectionPicker: false }, () => {
      this.scrollToHighlight(`section-${this.state.specSections.length - 1}-element`, true);
    });
  };

  onSectionChange = (sectionIndex, field, value) => {
    this.setState({
      specSections: update(this.state.specSections, {
        [sectionIndex]: {
          [field]: {
            $set: value,
          },
        },
      }),
      hasUnsavedChanges: true,
      editedSectionIndex: sectionIndex,
    });
  };

  onSectionAnswerChange = (sectionIndex, answerIndex, field, value) => {
    if (field === 'answer' && value === `<p class=\"editor-paragraph\"><br></p>`) value = null;
    this.setState(prevState => {
      return {
        ...prevState,
        specSections: update(prevState.specSections, {
          [sectionIndex]: {
            answers: {
              [answerIndex]: {
                [field]: {
                  $set: value,
                },
              },
            },
          },
        }),
        hasUnsavedChanges: true,
        editedSectionIndex: sectionIndex,
      };
    });
  };

  onAddNewAnswer = (sectionIndex, sectionType) => {
    const insertIndex = this.state.specSections[sectionIndex].answers.length;
    this.setState({
      specSections: update(this.state.specSections, {
        [sectionIndex]: {
          answers: {
            $set: [...this.state.specSections[sectionIndex].answers, returnEmptySectionAnswer(sectionType, insertIndex)],
          },
        },
      }),
      hasUnsavedChanges: true,
      editedSectionIndex: sectionIndex,
    });
  };

  onAnswerSelect = (sectionIndex, sectionType, selectedAnswer, shouldAddToOptionsBank) => {
    // check if answer already selected. If so, remove. If not, add.
    // used via topics options bank picker
    const { specSections } = this.state;
    const existingAnswerIndex = specSections[sectionIndex].answers.findIndex(answer => answer.answer === selectedAnswer.answer);
    let newAnswers = specSections[sectionIndex].answers;
    if (existingAnswerIndex === -1) {
      newAnswers.push(
        returnEmptySectionAnswer(sectionType, newAnswers.length, selectedAnswer.answer, selectedAnswer.option, selectedAnswer.option_two),
      );
    } else {
      const isDeleted = !newAnswers[existingAnswerIndex].is_deleted;
      newAnswers = update(newAnswers, { [existingAnswerIndex]: { is_deleted: { $set: isDeleted } } });
    }

    let newOptionsBank = specSections[sectionIndex].options_bank;
    if (shouldAddToOptionsBank) {
      const existingOptionBankIndex = specSections[sectionIndex].options_bank.findIndex(option => option === selectedAnswer.option);
      if (existingOptionBankIndex === -1) newOptionsBank.push(selectedAnswer.option);
    }

    this.setState({
      specSections: update(this.state.specSections, {
        [sectionIndex]: {
          answers: { $set: newAnswers },
          options_bank: { $set: newOptionsBank },
        },
      }),
      hasUnsavedChanges: true,
      editedSectionIndex: sectionIndex,
    });
  };

  onDragSectionComplete = (oldIndex, newIndex) => {
    this.setState(
      {
        specSections: arrayMove(this.state.specSections, oldIndex, newIndex),
        hasUnsavedChanges: true,
        // redrawKey: randomID(),
        // editedSectionIndex: oldIndex,
      },
      () => {
        this.scrollToHighlight(`section-${newIndex}-element`, true);
      },
    );
  };

  onDragSectionAnswerComplete = (sectionFrontID, oldIndex, newIndex) => {
    const sectionIndex = this.state.specSections.findIndex(section => section.front_id === sectionFrontID);
    this.setState(
      {
        specSections: update(this.state.specSections, {
          [sectionIndex]: {
            answers: {
              $set: arrayMove(this.state.specSections[sectionIndex].answers, oldIndex, newIndex),
            },
          },
        }),
        hasUnsavedChanges: true,
        editedSectionIndex: sectionIndex,
      },
      () => {
        this.scrollToHighlight(`answer-${this.state.specSections[sectionIndex].answers[newIndex].front_id}-element`, false);
      },
    );
  };

  scrollToHighlight(elementID, shouldScroll) {
    const element = document.getElementById(elementID);
    if (element) {
      const headerOffset = 94;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      if (shouldScroll) window.scrollTo({ top: offsetPosition });
      element.classList.add('placed-element-pulse');
      setTimeout(() => {
        element.classList.remove('placed-element-pulse');
      }, 1000);
    }
  }

  onToggleHideSection = (sectionIndex, shouldHide) => {
    this.onSectionChange(sectionIndex, 'is_hidden', shouldHide);
    setTimeout(() => this.scrollToHighlight(`section-${sectionIndex}-element`, false), 50);
  };

  onToggleSubtitleEnabled = (sectionIndex, isSubtitleEnabled) => {
    this.onSectionChange(sectionIndex, 'is_subtitle_enabled', isSubtitleEnabled);
    setTimeout(() => this.scrollToHighlight(`section-${sectionIndex}-element`, false), 50);
  };

  onDuplicateSection = sectionIndex => {
    const { specSections } = this.state;
    const sectionToDuplicate = specSections[sectionIndex];
    const duplicateSection = returnDuplicateSection(sectionToDuplicate, specSections.length);
    this.setState(
      {
        specSections: [...this.state.specSections, duplicateSection],
        hasUnsavedChanges: true,
        editedSectionIndex: sectionIndex,
      },
      () => {
        this.scrollToHighlight(`section-${specSections.length}-element`, true);
      },
    );
  };

  onCopyLinkToSection = sectionFrontID => {
    const { currentSpecSlug } = this.props;
    const link = `https://spec.me/${currentSpecSlug}?section=${sectionFrontID}`;
    copyToClipboard(link);
    this.props.triggerToast({ title: 'Section link copied to clipboard', status: 'success' });
  };

  onDeleteSection = sectionIndex => {
    const didConfirm = window.confirm("Are you sure you want to delete this section? You won't be able to undo this.");
    if (didConfirm) {
      this.onSectionChange(sectionIndex, 'is_deleted', true);
      this.props.triggerToast({ title: 'Section deleted', status: 'success' });
    }
  };

  onSelectReaction = (sectionIndex, selectedEmoji) => {
    if (this.props.shouldPreventAuthAccess()) return;
    const { specSections } = this.state;
    const { currentUser } = this.props;
    const section = specSections[sectionIndex];
    const newReactions = returnNewReactions(section.reactions, selectedEmoji, currentUser.slug);
    this.setState({
      specSections: update(this.state.specSections, {
        [sectionIndex]: {
          reactions: {
            $set: [].concat(newReactions),
          },
        },
      }),
      editedSectionIndex: sectionIndex,
    });
    // Next, save reaction to database
    const sectionFrontID = specSections[sectionIndex].front_id;
    Api.post({
      url: `/api/v1/comments/post_reaction`,
      body: {
        emoji: selectedEmoji,
        section_front_id: sectionFrontID,
        parent_comment_front_id: null,
        spec_slug: this.props.currentSpecSlug,
      },
    }).then(data => {
      if (!data.success) this.props.triggerToast({ title: data.error, status: 'error' });
    });
  };

  onShowComments = (sectionIndex, sectionFrontID) => {
    const { visibleCommentSectionIDs, specSections } = this.state;
    const { currentUser } = this.props;
    let newVisibleCommentSectionIDs = visibleCommentSectionIDs;
    if (newVisibleCommentSectionIDs.includes(sectionFrontID)) {
      newVisibleCommentSectionIDs = newVisibleCommentSectionIDs.filter(frontiD => frontiD !== sectionFrontID);
    } else {
      // Check if section has a top level draft comment. If not, add one
      const draftCommentIndex = specSections[sectionIndex].comments.findIndex(comment => comment.is_draft);
      if (draftCommentIndex === -1) {
        this.setState({
          specSections: update(this.state.specSections, {
            [sectionIndex]: {
              comments: {
                $set: [...specSections[sectionIndex].comments, returnBlankComment(currentUser)],
              },
            },
          }),
          editedSectionIndex: sectionIndex,
        });
      }
      newVisibleCommentSectionIDs.push(sectionFrontID);
    }
    this.setState({ visibleCommentSectionIDs: newVisibleCommentSectionIDs, editedSectionIndex: sectionIndex });
  };

  onCommentAdd = (sectionIndex, parentCommentIndex, commentOnUserSlug, commentOnIsPrivate) => {
    // Triggered when clicking 'Reply'. Need to:
    // 1. No matter if its a 1 level or 2 level nested comment, need to add it as 1 level nest
    // 2. Anytime you press reply, prepend value with @username you're replying to
    // 3. If you're replying to a private comment, make new comment private by default
    // 4. Check if draft comment already exists, don't add multiple
    const { specSections } = this.state;
    const { currentUser } = this.props;
    const draftCommentIndex = specSections[sectionIndex].comments[parentCommentIndex].comments.findIndex(comment => comment.is_draft);
    const parentCommentFrontID = specSections[sectionIndex].comments[parentCommentIndex].front_id;
    let newComments;
    if (draftCommentIndex === -1) {
      newComments = [
        ...specSections[sectionIndex].comments[parentCommentIndex].comments,
        returnBlankComment(currentUser, commentOnIsPrivate, `@${commentOnUserSlug} `, parentCommentFrontID),
      ];
    } else {
      newComments = update(specSections[sectionIndex].comments[parentCommentIndex].comments, {
        [draftCommentIndex]: {
          $set: returnBlankComment(currentUser, commentOnIsPrivate, `@${commentOnUserSlug} `, parentCommentFrontID),
        },
      });
    }
    this.setState({
      specSections: update(specSections, {
        [sectionIndex]: {
          comments: {
            [parentCommentIndex]: {
              comments: {
                $set: newComments,
              },
            },
          },
        },
      }),
      editedSectionIndex: sectionIndex,
    });
  };

  onCommentChange = (sectionIndex, parentCommentIndex, commentIndex, field, value) => {
    if (this.props.shouldPreventAuthAccess()) value = '';
    let didConfirm = true;
    if (field === 'is_deleted')
      didConfirm = window.confirm("Are you sure you want to delete this comment? You won't be able to undo this.");
    if (didConfirm) {
      const { specSections } = this.state;
      let commentFrontID;
      if (typeof parentCommentIndex === 'number') {
        commentFrontID = specSections[sectionIndex].comments[parentCommentIndex].comments[commentIndex].front_id;
        this.setState({
          specSections: update(specSections, {
            [sectionIndex]: {
              comments: {
                [parentCommentIndex]: {
                  comments: {
                    [commentIndex]: {
                      [field]: {
                        $set: value,
                      },
                    },
                  },
                },
              },
            },
          }),
          editedSectionIndex: sectionIndex,
        });
      } else {
        commentFrontID = specSections[sectionIndex].comments[commentIndex].front_id;
        this.setState({
          specSections: update(specSections, {
            [sectionIndex]: {
              comments: {
                [commentIndex]: {
                  [field]: {
                    $set: value,
                  },
                },
              },
            },
          }),
          editedSectionIndex: sectionIndex,
        });
      }
      if (field === 'is_deleted') {
        Api.delete({ url: `/api/v1/comments/${this.props.currentSpecSlug}/delete_comment/${commentFrontID}` }).then(data => {
          if (data.success) this.props.triggerToast({ title: 'Comment has been deleted', status: 'success' });
        });
      }
    }
  };

  onCommentPost = (sectionIndex, parentCommentIndex, commentIndex) => {
    // 1. Validate comment body exists
    // 2. If it's a top level comment, add a new blank draft comment
    // 3. Save new comment to database
    const { specSections } = this.state;
    const newComment =
      typeof parentCommentIndex === 'number'
        ? specSections[sectionIndex].comments[parentCommentIndex].comments[commentIndex]
        : specSections[sectionIndex].comments[commentIndex];
    if (!newComment.body || newComment.body.trim().length < 1) {
      this.props.triggerToast({ title: 'Please include a comment!', status: 'error' });
      return;
    }
    this.onCommentChange(sectionIndex, parentCommentIndex, commentIndex, 'is_draft', false);
    // POST newComment
    // When posting, use parent_comment_front_id to determine which model the comment should be on
    const sectionFrontID = specSections[sectionIndex].front_id;
    Api.post({
      url: `/api/v1/comments/post_comment`,
      body: { comment: newComment, section_front_id: sectionFrontID, spec_slug: this.props.currentSpecSlug },
    }).then(data => {
      if (!data.success) this.props.triggerToast({ title: data.error, status: 'error' });
      if (data.success && !newComment.parent_comment_front_id) {
        this.setState({
          specSections: update(this.state.specSections, {
            [sectionIndex]: {
              comments: {
                $set: [...this.state.specSections[sectionIndex].comments, returnBlankComment(this.props.currentUser)],
              },
            },
          }),
        });
      }
    });
  };

  onCommentReaction = (sectionIndex, parentCommentIndex, commentIndex, selectedEmoji) => {
    if (this.props.shouldPreventAuthAccess()) return;
    const { specSections } = this.state;
    const { currentUser } = this.props;
    let newReactions = [];
    let parentComment;
    if (typeof parentCommentIndex === 'number') {
      parentComment = specSections[sectionIndex].comments[parentCommentIndex].comments[commentIndex];
      const existingReactions = parentComment.reactions;
      newReactions = returnNewReactions(existingReactions, selectedEmoji, currentUser.slug);
      this.onCommentChange(sectionIndex, parentCommentIndex, commentIndex, 'reactions', newReactions);
    } else {
      parentComment = specSections[sectionIndex].comments[commentIndex];
      const existingReactions = parentComment.reactions;
      newReactions = returnNewReactions(existingReactions, selectedEmoji, currentUser.slug);
    }
    this.onCommentChange(sectionIndex, parentCommentIndex, commentIndex, 'reactions', newReactions);
    // Next, save reaction to database
    const sectionFrontID = specSections[sectionIndex].front_id;
    const parentCommentFrontID = parentComment.front_id;
    Api.post({
      url: `/api/v1/comments/post_reaction`,
      body: {
        emoji: selectedEmoji,
        section_front_id: sectionFrontID,
        parent_comment_front_id: parentCommentFrontID,
        spec_slug: this.props.currentSpecSlug,
      },
    }).then(data => {
      if (!data.success) this.props.triggerToast({ title: data.error, status: 'error' });
    });
  };

  // While I'm typing there's a save to the database. Between the time that the fetch starts. 1. Fetch starts 2. I keep typing 3. Fetch ends and says no more unsaved changes 4. If I stop typing at that point, anything in between won't be saved
  onSaveSpecSections = () => {
    const { specSections } = this.state;
    const { currentSpecSlug, isWithinTemplate, onUpdateSpecSectionsCallback } = this.props;
    this.setState({ hasUnsavedChanges: false });
    if (isWithinTemplate) {
      onUpdateSpecSectionsCallback(specSections);
    } else {
      Api.post({
        url: `/api/v1/specs/${currentSpecSlug}/update_spec_sections`,
        body: { spec_sections: specSections },
      }).then(data => {});
    }
  };
  render() {
    const { specSections, isDraggingMode, hasUnsavedChanges, visibleCommentSectionIDs, showSectionPicker, editedSectionIndex } = this.state;
    const {
      isEditMode,
      onEditModeSelect,
      isPublished,
      onTogglePublish,
      isSignedIn,
      currentUser,
      isCurrentUserCreator,
      currentSpecSlug,
      onCompletedAnswersCallback,
      doesHaveCompletedAnswers,
      triggerToast,
      isWithinTemplate,
      publishDueDate,
    } = this.props;
    return (
      <React.Fragment>
        <Flex direction="column" gap={[theme.spacing.xs, theme.spacing.xs, theme.spacing.lg]} id="sortable-sections-container">
          {specSections.map((section, sectionIndex) => {
            const nullElement = <div key={section.front_id} className="hidden" />;
            const type = section.section_type;
            let SectionBody;
            if (type === 'questions') {
              SectionBody = Questions;
            } else if (type === 'spectrum') {
              SectionBody = Spectrum;
            } else if (type === 'topics') {
              SectionBody = Topics;
            } else if (type === 'freeform') {
              SectionBody = Freeform;
            } else if (type === 'list') {
              SectionBody = List;
            } else if (type === 'work_history') {
              SectionBody = WorkHistory;
            } else if (type === 'video') {
              SectionBody = Video;
            } else if (type === 'myers_briggs') {
              SectionBody = MyersBriggs;
            } else if (type === 'big_five') {
              SectionBody = BigFive;
            } else if (type === 'strengthsfinder') {
              SectionBody = Strengthsfinder;
            } else if (type === 'zodiac_sign') {
              SectionBody = ZodiacSign;
            } else if (['images', 'movies', 'tv', 'books'].includes(type)) {
              SectionBody = MediaCarousel;
            } else {
              return nullElement;
            }

            const isSectionHidden = section.is_hidden;
            if (!isEditMode && !returnIfSectionHasAnswers(section)) return nullElement;
            if (!isEditMode && isSectionHidden) return nullElement;
            if (section.is_deleted) return nullElement;
            if (!isEditMode && !doesHaveCompletedAnswers) onCompletedAnswersCallback();
            return (
              <Container
                key={section.front_id}
                isEditMode={isEditMode}
                isDraggingMode={isDraggingMode}
                id={`section-${sectionIndex}-element`}
                isSectionHidden={isSectionHidden}>
                <Container.Header
                  title={section.title}
                  subtitle={section.subtitle}
                  isSubtitleEnabled={section.is_subtitle_enabled}
                  onSectionChange={this.onSectionChange}
                  sectionIndex={sectionIndex}
                  isEditMode={isEditMode}
                  isDraggingMode={isDraggingMode}
                  isSectionHidden={isSectionHidden}
                />
                <Container.Body key={isSectionHidden} shouldHide={isDraggingMode || isSectionHidden}>
                  <SectionBody
                    section={section}
                    sectionIndex={sectionIndex}
                    onSectionChange={this.onSectionChange}
                    onSectionAnswerChange={this.onSectionAnswerChange}
                    isEditMode={isEditMode}
                    onAddNewAnswer={this.onAddNewAnswer}
                    onAnswerSelect={this.onAnswerSelect}
                    onDragSectionAnswerComplete={this.onDragSectionAnswerComplete}
                    sortableContainerID={`section-${section.front_id}-answers`}
                    sortableHandleClassName={`section-${section.front_id}-answers-handle`}
                    triggerToast={triggerToast}
                    editedSectionIndex={editedSectionIndex}
                  />
                </Container.Body>
                <Container.Footer shouldHide={isDraggingMode}>
                  <SectionFooter
                    reactions={section.reactions}
                    onSelectReaction={this.onSelectReaction}
                    comments={section.comments}
                    onShowComments={this.onShowComments}
                    visibleCommentSectionIDs={visibleCommentSectionIDs}
                    sectionIndex={sectionIndex}
                    editedSectionIndex={editedSectionIndex}
                    sectionFrontID={section.front_id}
                    isSignedIn={isSignedIn}
                    currentUser={currentUser}
                    onCommentChange={this.onCommentChange}
                    onCommentAdd={this.onCommentAdd}
                    onCommentReaction={this.onCommentReaction}
                    onCommentPost={this.onCommentPost}
                    currentSpecSlug={currentSpecSlug}
                    isSectionHidden={isSectionHidden}
                    isWithinTemplate={isWithinTemplate}
                    menuItems={returnSectionMenuItems({
                      isEditMode: isEditMode,
                      isSubtitleEnabled: section.is_subtitle_enabled,
                      isSectionHidden: isSectionHidden,
                      sectionIndex: sectionIndex,
                      isWithinTemplate: isWithinTemplate,
                      onToggleSubtitleEnabled: () => this.onToggleSubtitleEnabled(sectionIndex, !section.is_subtitle_enabled),
                      onDuplicateSection: () => this.onDuplicateSection(sectionIndex),
                      onToggleHideSection: () => this.onToggleHideSection(sectionIndex, !isSectionHidden),
                      onCopyLinkToSection: () => this.onCopyLinkToSection(section.front_id),
                      onDeleteSection: () => this.onDeleteSection(sectionIndex),
                    })}
                  />
                </Container.Footer>
              </Container>
            );
          })}
          {isEditMode && (
            <Button
              leftIcon={<AddCircleIconOutline size={18} />}
              variant="solid"
              className="not-sortable"
              id="add-section-button"
              border={theme.layout.border}
              borderRadius={[0, 0, theme.radii.md]}
              onClick={() => this.setState({ showSectionPicker: true })}>
              Add Section
            </Button>
          )}
        </Flex>
        {!isWithinTemplate &&
          isCurrentUserCreator &&
          !isDraggingMode && (
            <CreatorNavigationFooter
              isEditMode={isEditMode}
              isPublished={isPublished}
              onTogglePublish={onTogglePublish}
              onEditModeSelect={onEditModeSelect}
              hasUnsavedChanges={hasUnsavedChanges}
              publishDueDate={publishDueDate}
            />
          )}
        <SpecSectionPicker
          isOpen={showSectionPicker}
          onClose={() => this.setState({ showSectionPicker: false })}
          onAddSection={this.onAddSection}
        />
      </React.Fragment>
    );
  }
}

export default SpecSections;
