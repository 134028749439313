import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { HStack } from '@chakra-ui/react';
import { MONTHS, YEARS } from 'data/constants';
import { getNoDaysInMonth } from 'helpers/utils';
import InputGroup from 'components/InputGroup';

const MonthYearPicker = ({ value, label, field, onChange }) => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      if (date) {
        setMonth(date.getMonth());
        setYear(date.getFullYear());
      }
    }
  });

  const onMonthSelect = selectedMonth => {
    selectedMonth = +selectedMonth;
    setMonth(selectedMonth);
    if (year !== null) {
      const value = new Date(year, selectedMonth, 1, 0, 0, 0, 0);
      onChange(field, value);
    }
  };

  const onYearSelect = selectedYear => {
    setYear(+selectedYear);
    if (month !== null) {
      const value = new Date(selectedYear, month, 1, 0, 0, 0, 0);
      onChange(field, value);
    }
  };
  return (
    <InputGroup
      label={label}
      customComponent={
        <HStack spacing="16px">
          <InputGroup
            type="select"
            value={month}
            width="50%"
            placeholder="Month"
            field="month"
            onChange={(field, value) => onMonthSelect(value)}
            selectOptions={MONTHS.map((month, index) => {
              return { label: month, value: index };
            })}
          />

          <InputGroup
            type="select"
            value={year}
            width="50%"
            placeholder="Year"
            field="year"
            onChange={(field, value) => onYearSelect(value)}
            selectOptions={YEARS.map(year => {
              return { label: year, value: year };
            })}
          />
        </HStack>
      }
    />
  );
};

export default MonthYearPicker;
