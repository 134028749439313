import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { PlusIcon } from 'assets/icons';
import { Api } from 'helpers/utils';
import { Input } from '@chakra-ui/react';
import { withNavigation } from 'hooks/router';
import moment from 'moment';
import update from 'immutability-helper';
import Flex from 'components/Flex';
import Button from 'components/Button';
import Section from 'components/Section';
import InputGroup from 'components/InputGroup';
import Text from 'components/Text';
import FileUpload from 'componentsCustom/FileUpload';

const SectionContainer = styled.div`
  ${containerStyle};
  padding: 0px;
  width: 100%;
  margin-top: 22px;
`;

class CreateInviteBatch extends React.Component {
  state = {
    newInviteBatch: {
      spec_template_id: null,
      invite_method: 'email',
      invite_method_source_key: 'type_emails',
      invite_method_source_value: [],
      invite_message:
        "Hey there!\n\nToday we’re rolling out a new tool that will allow us to strengthen our relationships and improve how we work together.\n\nAs part of that, you’re invited to create your Spec – a document that outlines your working style, preferences, quirks, and everything else how about how you operate.\n\nAfter publishing your Spec, it will be shared with teammates and others across the company so that they can work more effectively with you.\n\nPlease publish your Spec as soon as you're able – by [TYPE DUE DATE] at the latest.",
      should_send_at: new Date().toISOString().split('T')[0],
      spec_publish_due_date: 7,
      invite_source: 'via_admin_invites',
      csv_read_column_index: 0,
    },
    slackChannelOptions: [],
  };

  componentDidMount() {
    const { specTemplatesData, currentCompany } = this.props;
    if (currentCompany.is_slack_enabled) this.getSlackChannels();
    this.setState({
      newInviteBatch: update(this.state.newInviteBatch, {
        spec_template_id: { $set: specTemplatesData.find(template => template.is_primary_template).id },
      }),
    });
  }

  onNewInviteChange = (field, value) => {
    this.setState(
      {
        newInviteBatch: update(this.state.newInviteBatch, { [field]: { $set: value } }),
      },
      () => {
        if (field === 'invite_method_source_key') {
          this.setState({ newInviteBatch: update(this.state.newInviteBatch, { invite_method_source_value: { $set: [] } }) });
        }
      },
    );
  };

  getSlackChannels() {
    Api.get({ url: `/api/v1/admin/slack_workspaces/get_slack_channels` }).then(data => {
      if (data.success) {
        let slackChannelOptions = [];
        data.channels.forEach(channel => {
          slackChannelOptions.push({ value: channel.id, label: `# ${channel.name} members`, subLabel: 'hi' });
        });
        this.setState({ slackChannelOptions: slackChannelOptions });
      }
    });
  }

  returnValidation(shouldSendAt) {
    const { newInviteBatch } = this.state;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (shouldSendAt < today) {
      return { success: false, error: 'Send date cannot be in the past' };
    }

    switch (newInviteBatch.invite_method_source_key) {
      case 'type_emails':
        if (!newInviteBatch.invite_method_source_value.includes('@')) {
          return { success: false, error: 'Please type at least one email' };
        }
        break;
      case 'upload_csv':
        if (!Array.isArray(newInviteBatch.invite_method_source_value[0]) || newInviteBatch.invite_method_source_value.length < 2) {
          return { success: false, error: 'Please upload a CSV with at least one row' };
        }
        break;
      case 'slack_members_by_channel':
        if (!newInviteBatch.invite_method_source_value || newInviteBatch.invite_method_source_value.length < 1) {
          return { success: false, error: 'Please select a Slack channel' };
        }
        break;
    }

    if (newInviteBatch.invite_message.includes('[TYPE DUE DATE]')) {
      return { success: false, error: 'Be sure to replace [TYPE DUE DATE] with the actual due date in your invite message!' };
    }

    return { success: true };
  }

  onCreate = () => {
    const { triggerToast } = this.props;
    let { newInviteBatch } = this.state;
    const today = new Date();
    let shouldSendAt = new Date(moment(newInviteBatch.should_send_at).local()).setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
    );
    shouldSendAt = new Date(shouldSendAt);
    const validation = this.returnValidation(shouldSendAt);
    if (validation.success) {
      newInviteBatch = update(newInviteBatch, { should_send_at: { $set: shouldSendAt } });
      this.setState({ isSaving: true });
      Api.post({
        url: `/api/v1/admin/invites/create_invite_batch`,
        body: { invite_batch: newInviteBatch },
      }).then(data => {
        const { navigate, currentCompany } = this.props;
        if (data.success) {
          this.setState({ isSaving: false });
          triggerToast({ title: 'Invites created!', status: 'success' });
          navigate(`/org/${currentCompany.slug}/admin/invites/${data.invite_batch.id}`);
        } else {
          this.setState({ isSaving: false });
          triggerToast({ title: data.error, status: 'error', duration: 8000 });
        }
      });
    } else {
      triggerToast({ title: validation.error, status: 'error' });
    }
  };

  render() {
    const { newInviteBatch, slackChannelOptions, isSaving } = this.state;
    const { specTemplatesData, navigate, triggerToast, currentCompany } = this.props;
    const inviteMethod = newInviteBatch.invite_method;
    const inviteMethodSourceKey = newInviteBatch.invite_method_source_key;
    const slackMarkupText = '*bold* _italics_ ~strike~ >quote `code` <link.com|Link>';
    return (
      <Section>
        <Section.Body>
          <Flex direction="column" align="stretch" gap="16px">
            <Flex gap="16px">
              <InputGroup
                label="Invite Method"
                type="select"
                value={newInviteBatch.invite_method}
                width="50%"
                field="invite_method"
                onChange={(field, value) => this.onNewInviteChange(field, value)}
                selectOptions={[
                  { label: 'Send invites via email', value: 'email' },
                  { label: 'Send invites via Slack DM', value: 'slack', disabled: !currentCompany.is_slack_enabled },
                ]}
              />
              <InputGroup
                label="Spec Template"
                type="select"
                value={newInviteBatch.spec_template_id}
                width="50%"
                field="spec_template_id"
                onChange={(field, value) => this.onNewInviteChange(field, value)}
                selectOptions={specTemplatesData.map(template => {
                  return { label: template.title, value: template.id };
                })}
              />
            </Flex>
            <Flex gap="16px">
              <InputGroup
                label="Send Date"
                type="date"
                value={newInviteBatch.should_send_at}
                width="50%"
                field="should_send_at"
                min={new Date().toISOString().split('T')[0]}
                onChange={(field, value) => this.onNewInviteChange(field, value)}
              />
              <InputGroup
                label="Spec Publish Due Date"
                type="select"
                value={newInviteBatch.spec_publish_due_date}
                width="50%"
                field="spec_publish_due_date"
                onChange={(field, value) => this.onNewInviteChange(field, value)}
                selectOptions={[
                  { label: 'No due date', value: 0 },
                  { label: '2 days after invites sent', value: 2 },
                  { label: '4 days after invites sent', value: 4 },
                  { label: '1 week after invites sent', value: 7 },
                  { label: '10 days after invites sent', value: 10 },
                  { label: '2 weeks after invites sent', value: 14 },
                  { label: '3 weeks after invites sent', value: 21 },
                  { label: '1 month after invites sent', value: 30 },
                ]}
              />
            </Flex>
          </Flex>
        </Section.Body>

        <Section.Body borderTop={theme.layout.border}>
          <Flex direction="column" align="stretch" gap="16px">
            <InputGroup
              label="Who do you want to invite?"
              type="select"
              value={newInviteBatch.invite_method_source_key}
              field="invite_method_source_key"
              onChange={(field, value) => this.onNewInviteChange(field, value)}
              selectOptions={[
                { label: 'Enter emails', value: 'type_emails' },
                { label: 'Upload CSV with emails', value: 'upload_csv' },
                { label: 'Slack members by channel...', value: 'slack_members_by_channel', disabled: !currentCompany.is_slack_enabled },
                { label: 'All Slack members (excluding guests)', value: 'all_slack_members', disabled: !currentCompany.is_slack_enabled },
                { label: 'HRIS users by department...', value: 'hris_users_by_department', disabled: true },
                { label: 'All HRIS users (full-time employees only)', value: 'all_hris_users', disabled: true },
              ]}
            />

            {inviteMethodSourceKey === 'slack_members_by_channel' &&
              currentCompany.is_slack_enabled && (
                <InputGroup
                  label="Which Slack channels do you want to invite?"
                  sublabel="We won't send anyone more than one invite, even if they're in multiple channels you select"
                  type="multi-select"
                  field="invite_method_source_value"
                  onChange={(field, value) => this.onNewInviteChange(field, value)}
                  selectOptions={slackChannelOptions}
                  closeMenuOnSelect={false}
                />
              )}

            {inviteMethodSourceKey === 'type_emails' && (
              <InputGroup
                label="Emails"
                sublabel={currentCompany.domain && `Enter @${currentCompany.domain} emails only. To add or remove domains, go to settings.`}
                type="input"
                value={newInviteBatch.invite_method_source_value}
                field="invite_method_source_value"
                placeholder="Type or paste emails, separated by commas"
                onChange={(field, value) => this.onNewInviteChange(field, value)}
              />
            )}

            {inviteMethodSourceKey === 'upload_csv' && (
              <FileUpload onFileAccepted={csvFile => this.onNewInviteChange('invite_method_source_value', csvFile?.data || [])} />
            )}

            {inviteMethodSourceKey === 'upload_csv' &&
              Array.isArray(newInviteBatch.invite_method_source_value[0]) && (
                <InputGroup
                  label="CSV Email Column"
                  sublabel="Choose which CSV column has the emails you want to invite"
                  type="select"
                  value={newInviteBatch.csv_read_column_index}
                  field="csv_read_column_index"
                  onChange={(field, value) => this.onNewInviteChange(field, value)}
                  selectOptions={newInviteBatch.invite_method_source_value[0].map((columnName, columnIndex) => {
                    return { label: `"${columnName}"`, value: columnIndex };
                  })}
                />
              )}

            <InputGroup
              label={newInviteBatch.invite_method === 'slack' ? 'Invite Slack DM' : 'Invite Message'}
              type="textarea"
              value={newInviteBatch.invite_message}
              field="invite_message"
              placeholder="Invite message"
              onChange={(field, value) => this.onNewInviteChange(field, value)}
            />
            {newInviteBatch.invite_method === 'slack' && (
              <Text fontSize={theme.fontSizes.sm} color={theme.colors.lightAccentText} mt="-8px">
                {slackMarkupText}
              </Text>
            )}
          </Flex>
        </Section.Body>
        <Section.Footer gap="0" isSticky>
          <Button variant="ghost" isDisabled={isSaving} onClick={() => navigate(`/org/${currentCompany.slug}/admin/invites`)}>
            Cancel
          </Button>
          <Button variant="solid" colorScheme="brand" isLoading={isSaving} onClick={this.onCreate} isDisabled={isSaving}>
            Create Invites
          </Button>
        </Section.Footer>
      </Section>
    );
  }
}

export default withNavigation(CreateInviteBatch);
