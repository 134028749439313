import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Checkbox from 'components/Checkbox';
import InfoGroup from 'components/InfoGroup';
import Avatar from 'components/Avatar';

const ResultTile = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: auto;
  transition: background 0.25s ease;
  border-radius: ${theme.radii.md};
  &:hover {
    background: ${theme.colors.grayFill};
  }
`;

const Poster = styled.div`
  position: relative;
  background: ${theme.colors.grayFill};
  border-radius: ${theme.radii.md};
  width: 60px;
  height: 92px;
  flex-shrink: 0;
  ${({ bg }) =>
    bg &&
    css`
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `};
`;

const SearchResultTile = ({ result, image, title, subtitle, onSelectResult, imageType = 'avatar', isSelected }) => {
  return (
    <ResultTile variant="ghost" as="button" onClick={() => onSelectResult(result)}>
      <Flex align="center" gap="12px">
        {image && imageType === 'avatar' && <Avatar src={image} name={title} size="sm" />}
        {image && imageType === 'poster' && <Poster bg={image} />}
        <InfoGroup title={title} subtitle={subtitle} titleFontFamily={theme.fonts.medium} />
      </Flex>
      {isSelected && <Checkbox isChecked size="md" />}
    </ResultTile>
  );
};

export default SearchResultTile;
