import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import Directory from 'pages/Directory';

const SectionContainer = styled.div`
  ${containerStyle};
  padding: 0px;
  width: 100%;
`;

const Users = ({ triggerToast }) => {
  return (
    <SectionContainer>
      <Directory
        headerTitle="Users"
        isWithinPanel
        showHeaderTitle
        isWithinAdminUsers
        shouldConditionallyLimitResults
        triggerToast={triggerToast}
      />
    </SectionContainer>
  );
};

export default Users;
