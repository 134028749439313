import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Flex from 'components/Flex';
import InputGroup from 'components/InputGroup';
import TextEditor from 'components/TextEditor';

const Settings = ({ template, onTemplateChange, admins }) => {
  return (
    <Flex direction="column" gap="16px" align="stretch">
      <InputGroup
        type="input"
        value={template.title}
        label="Template Title"
        labelTooltip="The template title is only visible to admins"
        placeholder="Title"
        field="title"
        onChange={onTemplateChange}
        autoFocus
      />

      <InputGroup
        label="Spec Publish Due Date"
        labelTooltip="When someone signs up using this template, a publish due date will appear on their Spec. If you send direct invites with a different due date, this value will be overridden."
        type="select"
        value={template.spec_publish_due_date_x_days_after_signup}
        field="spec_publish_due_date_x_days_after_signup"
        onChange={onTemplateChange}
        selectOptions={[
          { label: 'No due date', value: 0 },
          { label: '2 days after signing up', value: 2 },
          { label: '4 days after signing up', value: 4 },
          { label: '7 days after signing up', value: 7 },
          { label: '10 days after signing up', value: 10 },
          { label: '2 weeks after signing up', value: 14 },
          { label: '3 weeks after signing up', value: 21 },
          { label: '1 month after signing up', value: 30 },
        ]}
      />

      <InputGroup
        label="Landing Page Message"
        labelTooltip="When you send invites, this will appear on the invite landing page"
        customComponent={
          <TextEditor
            placeholder="Type here..."
            allowHeaders
            isStandalone
            value={template.node_landing_page_message}
            editorID={`editor-template-${template.slug}`}
            onHTMLChange={value => onTemplateChange('html_landing_page_message', value)}
            onNodeChange={value => onTemplateChange('node_landing_page_message', value)}
            extraClassName="gray-text-editor"
          />
        }
      />

      <InputGroup
        label="Landing Page Message Author"
        labelTooltip="This person's name will appear at the bottom of the invite landing page"
        type="select"
        value={template.creator_id}
        placeholder="Select admin"
        field="creator_id"
        onChange={onTemplateChange}
        selectOptions={admins.map(admin => {
          return { label: `${admin.first_name} ${admin.last_name}`, value: admin.id };
        })}
      />

      <InputGroup
        label="Update Notification Frequency"
        type="select"
        value={template.update_notification_every_x_weeks}
        placeholder="Select frequency"
        field="update_notification_every_x_weeks"
        onChange={onTemplateChange}
        selectOptions={[
          { label: "Don't send update notifications", value: 0 },
          { label: 'Every 2 months', value: 8 },
          { label: 'Every 3 months', value: 12 },
          { label: 'Every 4 months', value: 16 },
          { label: 'Every 6 months', value: 24 },
          { label: 'Every 8 months', value: 32 },
          { label: 'Every 12 months', value: 48 },
        ]}
      />

      {template.update_notification_every_x_weeks !== '0' && (
        <InputGroup
          type="textarea"
          value={template.update_notification_message}
          label="Update Notification Message"
          placeholder="Hi {first_name}! We all change over time, and so do our working styles and preferences. As such, we’re sending you a reminder to take a few minutes today to update your Spec and share it with your teammates."
          field="update_notification_message"
          onChange={onTemplateChange}
        />
      )}
    </Flex>
  );
};

export default Settings;
