import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { HorizontalDotsIcon } from 'assets/icons';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Pagination from 'components/Pagination';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import UpgradeCTA from 'componentsCustom/UpgradeCTA';

const Footer = ({
  pageNumber,
  numberOfResults,
  onChangePageNumber,
  resultsPerPage,
  userStatus,
  selectedUsersLength,
  onSetDeactivatedStatus,
  onSetAdminStatus,
  isFeatureGated,
}) => {
  if (numberOfResults === 0) return null;
  return (
    <Flex justify="space-between" padding={theme.padding.lg} gap="10px">
      {isFeatureGated && selectedUsersLength === 0 && <UpgradeCTA ctaType="label" label="Upgrade to Pro to see all results" />}
      {selectedUsersLength === 0 && <div />}
      {selectedUsersLength > 0 && (
        <Flex gap="10px">
          <Text fontFamily={theme.fonts.light} color={theme.colors.accentText} as="i">
            {selectedUsersLength} user{selectedUsersLength === 1 ? '' : 's'} selected
          </Text>
          <Menu>
            <Menu.Button>
              <IconButton variant="outline" isRound size="sm" as="div" icon={<HorizontalDotsIcon size={16} />} />
            </Menu.Button>
            <Menu.List>
              {['has_spec', 'is_published', 'is_unpublished', 'is_view_only'].includes(userStatus) && (
                <>
                  <Menu.Item onClick={() => onSetAdminStatus('admin')}>Make User{selectedUsersLength === 1 ? '' : 's'} Admin</Menu.Item>
                  <Menu.Item onClick={() => onSetAdminStatus('owner')}>Make User{selectedUsersLength === 1 ? '' : 's'} Owner</Menu.Item>
                  <Menu.Item color={theme.colors.redText} onClick={() => onSetDeactivatedStatus(true)}>
                    Deactivate User{selectedUsersLength === 1 ? '' : 's'}
                  </Menu.Item>
                </>
              )}
              {userStatus === 'is_deactivated' && (
                <Menu.Item onClick={() => onSetDeactivatedStatus(false)}>Reactivate User{selectedUsersLength === 1 ? '' : 's'}</Menu.Item>
              )}
            </Menu.List>
          </Menu>
        </Flex>
      )}

      <Flex gap="10px">
        <Text fontFamily={theme.fonts.light}>
          {numberOfResults} total result{numberOfResults === 1 ? '' : 's'}
        </Text>
        <Pagination
          numberOfResults={numberOfResults}
          resultsPerPage={resultsPerPage}
          onPageChange={e => e && onChangePageNumber(e.selected + 1)}
          isDisabled={isFeatureGated}
        />
      </Flex>
    </Flex>
  );
};

export default Footer;
