import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { returnEmptySection, returnFormattedSectionViaLibrary } from 'helpers/specUtils';
import { SECTION_TYPES, SECTIONS_LIBRARY } from 'data/specData';
import Modal from 'components/Modal';
import Tabs from 'components/Tabs';
import SectionTile from './SectionTile';
import LibraryOptionTile from './LibraryOptionTile';

const SpecSectionPicker = ({ isOpen, onClose, onAddSection }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <Tabs
        tabs={['Create Custom Section', 'Choose from Templates']}
        selectedIndex={selectedTabIndex}
        onSelectIndex={setSelectedTabIndex}
        isSticky>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            {SECTION_TYPES.map((section, index) => {
              return (
                <SectionTile
                  key={section.section_type}
                  section={section}
                  isLast={index === SECTION_TYPES.length - 1}
                  onSelect={() => onAddSection(returnEmptySection(section.section_type))}
                />
              );
            })}
          </Tabs.TabPanel>

          <Tabs.TabPanel>
            {SECTIONS_LIBRARY.map((libraryOption, index) => {
              return (
                <LibraryOptionTile
                  key={index}
                  libraryOption={libraryOption}
                  isLast={index === SECTIONS_LIBRARY.length - 1}
                  onSelect={() => onAddSection(returnFormattedSectionViaLibrary(libraryOption.section_data))}
                />
              );
            })}
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </Modal>
  );
};

export default React.memo(SpecSectionPicker, (prevProps, nextProps) => {
  // re-render if FALSE
  if (prevProps.isOpen !== nextProps.isOpen) return false;
  return true;
});
