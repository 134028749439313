import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CREATE_SPEC_INTRO_STEPS } from 'data/specData';
import WelcomeModal from '../features/WelcomeModal';
import PublishSuccessModal from '../features/PublishSuccessModal';
import IntroSteps from 'components/IntroSteps';

const MiscTopLevelComponents = ({
  showWelcomeModal,
  showIntroWalkthrough,
  showPublishSuccess,
  onCloseWelcomeModal,
  onClosePublishSuccess,
  onExitIntroSteps,
  currentUser,
  specUser,
}) => {
  return (
    <React.Fragment>
      {showWelcomeModal && <WelcomeModal isOpen onClose={onCloseWelcomeModal} currentUserFirstName={currentUser.first_name} />}
      {showIntroWalkthrough && (
        <IntroSteps
          stepsEnabled
          steps={CREATE_SPEC_INTRO_STEPS}
          doneLabel="Got it, let's start"
          skipLabel={null}
          onExit={onExitIntroSteps}
          exitOnOverlayClick={true}
        />
      )}
      {showPublishSuccess && <PublishSuccessModal onClose={onClosePublishSuccess} />}
    </React.Fragment>
  );
};

export default MiscTopLevelComponents;
