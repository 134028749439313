export const isUsernameValid = username => {
  if (!username) return true;
  if (username.length > 25) return false;
  const isValid = /^[a-z0-9_\.]+$/.test(username);
  const arePeriodsValid = !username.includes('.');
  return isValid && arePeriodsValid;
};

export const isEmailValid = (email, companyDomain) => {
  const isFormatValid = email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  let isCompanyMatch = true;
  if (companyDomain) isCompanyMatch = email.includes(companyDomain);
  return isFormatValid && isCompanyMatch;
};

export const isLoginEmailValid = (email, companyDomain, isOtherPersonEmail = false) => {
  if (!email) {
    return { success: false, notice: 'Please enter an email' };
  } else if (!isEmailValid(email)) {
    return { success: false, notice: 'Email format is not valid' };
  } else if (companyDomain && !email.includes(companyDomain)) {
    return {
      success: false,
      notice: isOtherPersonEmail ? `Please enter a @${companyDomain} email address` : `Please use your ${companyDomain} email address`,
    };
  }
  return { success: true };
};

export const isUserBasicInfoValid = (user, companyDomain) => {
  if (!user) return { success: false, notice: 'An error occurred, please refresh and try again.' };
  if (!user.first_name) {
    return { success: false, notice: 'First name required' };
  } else if (!user.last_name) {
    return { success: false, notice: 'Last name required' };
  } else if (!user.email) {
    return { success: false, notice: 'Email required' };
  } else if (!isEmailValid(user.email)) {
    return { success: false, notice: 'Email format is not valid' };
  } else if (companyDomain && !user.email.includes(companyDomain)) {
    return { success: false, notice: `Please use your @${companyDomain} email address` };
  }
  return { success: true };
};

export const isValidURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};
