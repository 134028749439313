import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CloseIcon, GoogleIcon } from 'assets/icons';
import { Avatar, AvatarBadge, AvatarGroup, Icon } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/media-and-icons/avatar
const AvatarCompoent = ({ size, src, showBadge, customBadgeIcon, badgeBg = 'white', badgeBoxSize = '1.1em', ...props }) => {
  return (
    <Avatar size={size} src={src} {...props}>
      {showBadge && (
        <AvatarBadge boxSize={badgeBoxSize} bg={badgeBg} borderWidth="2px">
          {customBadgeIcon}
        </AvatarBadge>
      )}
    </Avatar>
  );
};

export default AvatarCompoent;
