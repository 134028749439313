import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { createStandaloneToast } from '@chakra-ui/toast';
import { Api, setCookie, setWindowLocation, returnQuery } from 'helpers/utils';
import { isUsernameValid, isEmailValid, isUserBasicInfoValid } from 'helpers/authUtils';
import { connect } from 'react-redux';
import CompanyEmailValidator from 'company-email-validator';
import update from 'immutability-helper';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import Button from 'components/Button';
import Username from './Username';
import BasicInfo from '../sharedComponents/BasicInfo';
import ProfileInfo from './ProfileInfo';
import EnterCode from '../sharedComponents/EnterCode';
import CompanyDetails from './CompanyDetails';
import TermsFooter from 'componentsCustom/TermsFooter';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const Styled = styled.div``;

class CreateSpec extends React.Component {
  state = {
    completedSteps: [],
    currentStep: 'username',
    newUser: {
      first_name: null,
      last_name: null,
      slug: null,
      email: null,
      image: null,
      auth_method: 'email',
    },
    newCompany: {
      name: null,
      logo: null,
      domain: null,
    },
    isUsernameAvailable: true,
    isCompanyEmail: false,
    specTemplateSlug: null,
  };

  componentDidMount() {
    this.handleQueryParams();
  }

  componentWillUnmount() {
    document.body.classList.remove('user-is-tabbing');
  }

  handleQueryParams() {
    const parsed = returnQuery();
    if (parsed.template) this.setState({ specTemplateSlug: parsed.template });
    if (parsed.username) this.onNewUserChange('slug', parsed.username, true);
    if (parsed.email) this.onNewUserChange('email', parsed.email);
  }

  onStateChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onNewUserChange = (field, value, shouldAutoTriggerNextStep = false) => {
    if (field === 'slug') {
      if (!isUsernameValid(value)) return;
      this.isUserSlugAvailable(value);
    }
    this.setState({ newUser: update(this.state.newUser, { [field]: { $set: value } }) }, () => {
      if (shouldAutoTriggerNextStep) this.onNextStepClick();
    });
  };

  onNewCompanyChange = (field, value) => {
    this.setState({ newCompany: update(this.state.newCompany, { [field]: { $set: value } }) });
  };

  isUserSlugAvailable(userSlug) {
    if (!userSlug || userSlug.length < 2) {
      this.setState({ isUsernameAvailable: true });
    } else {
      Api.post({
        url: `/api/v1/users/is_slug_available`,
        body: { user_slug: userSlug },
      }).then(data => {
        if (data.is_available) {
          this.setState({ isUsernameAvailable: true });
        } else {
          this.setState({ isUsernameAvailable: false });
        }
      });
    }
  }

  onNextStepClick = () => {
    const { steps, currentStep, newUser } = this.state;
    const { currentUser, isSignedIn, currentCompany, hasCompany } = this.props;
    this.returnValidation(currentStep).then(validation => {
      if (validation.success) {
        let nextStep;
        if (currentStep === 'username') {
          const basicInfoValidation = isUserBasicInfoValid(currentUser, hasCompany && currentCompany.domain);
          if (isSignedIn && hasCompany && basicInfoValidation.success) {
            this.onSubmit();
          } else {
            nextStep = 'basic_info';
          }
          this.onAddToCompletedSteps();
          this.setState({ currentStep: nextStep });
        } else if (currentStep === 'basic_info') {
          nextStep = 'profile_info';
          this.onAddToCompletedSteps();
          this.setState({ currentStep: nextStep });
        } else if (currentStep === 'profile_info') {
          if (hasCompany) {
            this.onSubmit();
          } else {
            const isCompanyEmail = CompanyEmailValidator.isCompanyEmail(newUser.email);
            if (isCompanyEmail) {
              this.setState({ isSubmitLoading: true });
              Api.post({
                url: `/api/v1/companies/does_domain_exist`,
                body: { company_domain: newUser.email.split('@')[1] },
              }).then(data => {
                if (data.does_domain_exist) {
                  this.onSubmit();
                } else {
                  nextStep = 'company_details';
                  this.onAddToCompletedSteps();
                  this.setState({ currentStep: nextStep, isCompanyEmail: isCompanyEmail, isSubmitLoading: false });
                }
              });
            } else {
              this.onAddToCompletedSteps();
              this.setState({ currentStep: 'company_details', isCompanyEmail: isCompanyEmail, isSubmitLoading: false });
            }
          }
        } else if (currentStep === 'company_details') {
          this.onSubmit();
        }
      } else {
        this.triggerToast({ title: validation.notice, status: 'error' });
      }
    });
  };

  onAddToCompletedSteps() {
    const { currentStep } = this.state;
    let completedSteps = this.state.completedSteps;
    if (!completedSteps.includes(currentStep)) this.setState({ completedSteps: [...completedSteps, currentStep] });
  }

  onPreviousStepClick = () => {
    const { currentStep, completedSteps } = this.state;
    let currentStepIndex = completedSteps.findIndex(completedStep => completedStep === currentStep);
    if (currentStepIndex === -1) currentStepIndex = completedSteps.length;
    const nextStep = completedSteps[currentStepIndex - 1];
    this.setState({ currentStep: nextStep });
  };

  asyncIsUserSlugAvailable(userSlug) {
    return new Promise(resolve => {
      Api.post({
        url: `/api/v1/users/is_slug_available`,
        body: { user_slug: userSlug },
      }).then(data => {
        resolve({ isUsernameAvailable: data.is_available });
      });
    });
  }

  returnValidation(currentStep) {
    // Needs to return a promise so that we can fetch to make sure slug is available
    return new Promise(resolve => {
      const { newUser, newCompany, isUsernameAvailable } = this.state;
      const { currentCompany, hasCompany } = this.props;
      this.asyncIsUserSlugAvailable(newUser.slug).then(slugAvailability => {
        if (currentStep === 'username') {
          if (!isUsernameAvailable || !slugAvailability.isUsernameAvailable)
            resolve({ success: false, notice: `Username ${newUser.slug} is not available` });
          if (!newUser.slug) resolve({ success: false, notice: 'Please enter a username' });
          if (newUser.slug.length < 2) resolve({ success: false, notice: 'Username must be at least 2 letters' });
        } else if (currentStep === 'basic_info') {
          resolve(isUserBasicInfoValid(newUser, hasCompany && currentCompany.domain));
        } else if (currentStep === 'profile_info') {
          if (!newUser.job_title) resolve({ success: false, notice: 'Please enter your job title' });
          if (!newUser.location) resolve({ success: false, notice: 'Please enter your location' });
        } else if (currentStep === 'company_details') {
          if (!newCompany.name) resolve({ success: false, notice: 'Please enter a company name' });
        }
        resolve({ success: true });
      });
    });
  }

  triggerToast = ({ title, status = 'success', duration = 2000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  onSubmit = () => {
    this.onAddToCompletedSteps();
    this.setState({ isSubmitLoading: true });
    const { newUser, newCompany, isCompanyEmail, specTemplateSlug } = this.state;
    const { currentCompany, hasCompany } = this.props;
    Api.post({
      url: `/api/v1/users/create_user`,
      body: {
        new_user: newUser,
        new_company: newCompany,
        has_company: hasCompany,
        current_company: currentCompany,
        is_company_email: CompanyEmailValidator.isCompanyEmail(newUser.email),
        auth_type: 'create_spec',
        spec_template_slug: specTemplateSlug,
      },
    }).then(data => {
      if (data.success) {
        if (newUser.auth_method === 'google') {
          setCookie('login_token', data.login_token);
          setTimeout(() => {
            setWindowLocation(`/${data.user.slug}?action=create-spec`);
          }, 10);
        } else {
          this.setState({ currentStep: 'enter_code', isSubmitLoading: false });
        }
      } else {
        this.setState({ isSubmitLoading: false });
        this.triggerToast({ title: data.error, status: 'error', duration: 4000 });
      }
    });
  };

  render() {
    const { currentStep, newUser, newCompany, isSubmitLoading, isUsernameAvailable, isCompanyEmail } = this.state;
    const { isSignedIn, currentCompany, hasCompany, pageWithin, isModal } = this.props;
    return (
      <React.Fragment>
        <ToastContainer />
        <OnboardingFlow pageWithin={pageWithin} isModal={isModal}>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.onNextStepClick();
            }}>
            {currentStep === 'username' && (
              <Username
                newUser={newUser}
                onNewUserChange={this.onNewUserChange}
                isSubmitLoading={isSubmitLoading}
                isUsernameAvailable={isUsernameAvailable}
                isSignedIn={isSignedIn}
              />
            )}

            {currentStep === 'basic_info' && (
              <BasicInfo
                newUser={newUser}
                onNewUserChange={this.onNewUserChange}
                onStateChange={this.onStateChange}
                onNextStepClick={this.onNextStepClick}
                onPreviousStepClick={this.onPreviousStepClick}
                currentCompany={currentCompany}
                hasCompany={hasCompany}
                isSubmitLoading={isSubmitLoading}
              />
            )}

            {currentStep === 'profile_info' && (
              <ProfileInfo
                newUser={newUser}
                onNewUserChange={this.onNewUserChange}
                onPreviousStepClick={this.onPreviousStepClick}
                isSubmitLoading={isSubmitLoading}
              />
            )}

            {currentStep === 'company_details' && (
              <CompanyDetails
                newCompany={newCompany}
                onNewCompanyChange={this.onNewCompanyChange}
                newCompanyDomain={newUser.email.split('@')[1]}
                onNextStepClick={this.onNextStepClick}
                onPreviousStepClick={this.onPreviousStepClick}
                isSubmitLoading={isSubmitLoading}
                isCompanyEmail={isCompanyEmail}
              />
            )}
          </form>

          {currentStep === 'enter_code' && (
            <EnterCode
              email={newUser.email}
              onEnterCodeEmailEdit={() => this.setState({ currentStep: 'basic_info' })}
              successAction="create_spec"
            />
          )}
        </OnboardingFlow>
        {pageWithin === 'within_company_signup' && <TermsFooter />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(CreateSpec);
