import React from 'react';
import { theme } from 'styles/theme';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark } from '@chakra-ui/react';

const SliderComponent = ({
  value = 50,
  onChange,
  sliderTrackColor = theme.colors.grayFill,
  sliderTrackFilledColor = theme.colors.primary,
  sliderThumbColor = theme.colors.white,
  sliderThumbBoxShadow = 'var(--chakra-shadows-base)',
  sliderThumbSize = '14px',
  trackHeight = '4px',
  ...props
}) => {
  return (
    <Slider value={value} defaultValue={50} onChange={val => onChange(val)} {...props} _disabled={{ opacity: 1, cursor: 'default' }}>
      <SliderTrack
        bg={sliderTrackColor}
        _disabled={{ backgroundColor: sliderTrackColor, opacity: 1, cursor: 'default' }}
        height={trackHeight}>
        <SliderFilledTrack bg={sliderTrackFilledColor} _disabled={{ backgroundColor: sliderTrackFilledColor, opacity: 1 }} />
      </SliderTrack>
      <SliderThumb
        bg={sliderThumbColor}
        boxShadow={sliderThumbBoxShadow}
        boxSize={sliderThumbSize}
        _disabled={{ backgroundColor: sliderThumbColor, opacity: 1, cursor: 'default' }}
      />
    </Slider>
  );
};

export default SliderComponent;
