import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Flex } from '@chakra-ui/react';
import { PlusIcon } from 'assets/icons';
import { returnIfAnswerValid } from 'helpers/specUtils';
import EditTile from './EditTile';
import LiveTile from './LiveTile';
import Button from 'components/Button';
import Sortable from 'components/Sortable';

const Questions = ({
  section,
  sectionIndex,
  onSectionAnswerChange,
  isEditMode,
  onAddNewAnswer,
  onDragSectionAnswerComplete,
  sortableContainerID,
  sortableHandleClassName,
  editedSectionIndex,
}) => {
  return (
    <Sortable
      sortableContainerID={sortableContainerID}
      sortableHandleClassName={sortableHandleClassName}
      onDragEnd={event => onDragSectionAnswerComplete(section.front_id, event.oldIndex, event.newIndex)}>
      <Flex id={sortableContainerID} direction="column" align="flex-start" gap={theme.spacing.sectionElementsGap}>
        {section.answers.map((answer, answerIndex) => {
          if (answer.is_deleted || (!isEditMode && !returnIfAnswerValid(section.section_type, answer)))
            return <div key={answer.front_id} className="hidden" />;
          if (isEditMode) {
            return (
              <EditTile
                key={answer.front_id}
                answer={answer}
                onSectionAnswerChange={onSectionAnswerChange}
                sectionIndex={sectionIndex}
                answerIndex={answerIndex}
                sortableHandleClassName={sortableHandleClassName}
                answerElementID={`answer-${answer.front_id}-element`}
              />
            );
          }
          return <LiveTile key={answer.front_id} answer={answer} />;
        })}
        {isEditMode && (
          <Flex className="not-sortable" width="100%">
            <Button variant="outline" leftIcon={<PlusIcon />} onClick={() => onAddNewAnswer(sectionIndex, section.section_type)}>
              Add Question
            </Button>
          </Flex>
        )}
      </Flex>
    </Sortable>
  );
};

export default React.memo(Questions, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
