import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';

const Username = ({ newUser, onNewUserChange, isSubmitLoading, isUsernameAvailable, isSignedIn }) => {
  const title = 'Create your Spec';
  const subtitle = '';
  return (
    <OnboardingFlow.Inner>
      <OnboardingFlow.Header title={title} subtitle={subtitle} />

      <OnboardingFlow.Body>
        <InputGroup
          type="input"
          value={newUser.slug}
          label="URL"
          placeholder="username"
          field="slug"
          onChange={onNewUserChange}
          autoComplete="off"
          inputLeftAddon="spec.me/"
          autoFocus
          errorLabel={
            !isUsernameAvailable && (
              <span>
                Username <em>{newUser.slug}</em> is not available, try another!
              </span>
            )
          }
        />
      </OnboardingFlow.Body>

      <OnboardingFlow.Footer>
        <Button variant="solid" colorScheme="brand" isLoading={isSubmitLoading} isDisabled={isSubmitLoading} type="submit">
          Continue
        </Button>
      </OnboardingFlow.Footer>

      <OnboardingFlow.PostText>
        Been here before?{' '}
        <Link to="login" className="primary-link">
          Login
        </Link>
      </OnboardingFlow.PostText>
    </OnboardingFlow.Inner>
  );
};

export default Username;
