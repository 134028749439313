import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, copyToClipboard } from 'helpers/utils';
import { Box, Tooltip } from '@chakra-ui/react';
import { isLoginEmailValid } from 'helpers/authUtils';
import { CloseIcon, InfoIconFilled } from 'assets/icons';
import { createStandaloneToast } from '@chakra-ui/toast';
import { connect } from 'react-redux';
import InputGroup from 'components/InputGroup';
import Flex from 'components/Flex';
import Button from 'components/Button';
import SearchResultTile from 'componentsCustom/SearchResultTile';
import Tag from 'components/Tag';
import OrDivider from 'components/OrDivider';
import ShareControls from './ShareControls';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const SearchResultsContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${theme.colors.border};
  z-index: 1;
  border-radius: ${theme.radii.md};
  padding: 4px;
`;

class ShareSpec extends React.Component {
  state = {
    searchTerm: '',
    searchResults: [],
    selectedRecipients: [],
    message: '',
    isPubliclyShareable: true,
    companyDomain: null,
    showShareControls: false,
  };

  componentDidMount() {
    this.getSharePermissions();
    this.determineShowShareControls();
    const { defaultMessage = '' } = this.props;
    this.setState({ message: defaultMessage, didLoad: true });
  }

  determineShowShareControls() {
    const { entityType = 'spec', entitySlug, isSignedIn, currentUser } = this.props;
    if (entityType === 'spec' && isSignedIn && entitySlug === currentUser.slug) {
      this.setState({ showShareControls: true });
    }
  }

  getSharePermissions() {
    const { entityType = 'spec', entitySlug } = this.props;
    Api.post({
      url: `/api/v1/invites/get_share_permissions`,
      body: { entity_slug: entitySlug, entity_type: entityType },
    }).then(data => {
      if (data.success) {
        this.setState({
          isPubliclyShareable: data.is_publicly_shareable,
          companyDomain: data.company_domain,
        });
      }
    });
  }

  onUpdateSharePermissions = isPubliclyShareable => {
    const { entityType = 'spec', entitySlug } = this.props;
    this.setState({ isPubliclyShareable: isPubliclyShareable });
    Api.post({
      url: `/api/v1/invites/update_spec_share_permissions`,
      body: { slug: entitySlug, is_public: isPubliclyShareable },
    }).then(data => {
      if (data.success) {
        this.triggerToast({ title: 'Visibility permissions saved' });
      } else {
        this.triggerToast({ title: data.error, status: 'error' });
      }
    });
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onSearchTermChange = searchTerm => {
    this.setState({ searchTerm: searchTerm });
    if (!searchTerm || searchTerm.length < 3) {
      this.setState({ searchResults: [] });
    } else {
      Api.post({
        url: `/api/v1/search/share_users`,
        body: { search_term: searchTerm },
      }).then(data => {
        if (data.success) {
          let searchResults = data.search_results;
          if (searchResults.length === 0 && !searchTerm.includes(' ')) {
            searchResults = [{ email: searchTerm }];
          }
          this.setState({ searchResults: searchResults });
        }
      });
    }
  };

  onRecipientSelect = recipient => {
    const { isPubliclyShareable, companyDomain } = this.state;
    const validation = isLoginEmailValid(recipient.email, !isPubliclyShareable && companyDomain, true);
    if (validation.success) {
      let selectedRecipients = this.state.selectedRecipients;
      const existingRecipientIndex = selectedRecipients.findIndex(existingRecipient => existingRecipient.email === recipient.email);
      if (existingRecipientIndex === -1) {
        selectedRecipients.push(recipient);
      } else {
        selectedRecipients.splice(existingRecipientIndex, 1);
      }
      this.setState({ selectedRecipients: selectedRecipients, searchTerm: '', searchResults: [] });
    } else {
      this.triggerToast({ title: validation.notice, status: 'error' });
    }
  };

  triggerToast = ({ title, status = 'success', duration = 4000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  onSend = () => {
    const { selectedRecipients, message } = this.state;
    const { entityType = 'spec', entitySlug, shareURL } = this.props;
    this.setState({ isSendLoading: true });
    Api.post({
      url: `/api/v1/invites/share_link`,
      body: {
        recipients: selectedRecipients,
        message: message,
        share_url: shareURL,
        entity_type: entityType,
        entity_slug: entitySlug,
      },
    }).then(data => {
      if (data.success) {
        this.triggerToast({ title: 'Sent successfully!' });
        this.setState({ selectedRecipients: [], message: '', isSendLoading: false });
      } else {
        this.triggerToast({ title: data.error || 'An error occurred, please try again or contact support.', status: 'error' });
        this.setState({ isSendLoading: false });
      }
    });
  };

  render() {
    const {
      searchTerm,
      searchResults,
      selectedRecipients,
      message,
      isSendLoading,
      isPubliclyShareable,
      companyDomain,
      showShareControls,
      didLoad,
    } = this.state;
    const {
      shareDisplayURL,
      shareURL,
      entityType = 'spec',
      sectionTitle,
      inputVariant = 'filled',
      copyLinkButtonText = 'Copy Share Link',
    } = this.props;
    if (!didLoad) return null;
    return (
      <Flex direction="column" gap="16px" align="stretch" id="share-link">
        <ToastContainer />
        <InputGroup
          type="input"
          label={sectionTitle}
          value={shareDisplayURL}
          readOnly
          inputRightElementWidth="auto"
          variant={inputVariant}
          elementPointerEvents="auto"
          inputRightElement={
            <Button
              variant="solid"
              fontSize={theme.fontSizes.sm}
              height="auto"
              padding="6px 8px"
              mr="6px"
              bg={inputVariant === 'filled' ? 'white' : theme.colors.grayFill}
              _hover={inputVariant === 'filled' ? { background: '#f9f9f9' } : { background: theme.colors.gray200 }}
              onClick={() => {
                copyToClipboard(shareURL, 'share-link');
                this.triggerToast({ title: 'Link copied to your clipboard' });
              }}>
              {copyLinkButtonText}
            </Button>
          }
        />
        <OrDivider />
        <form
          onSubmit={e => {
            e.preventDefault();
          }}>
          <Flex position="relative" direction="column" gap="8px" align="stretch">
            <InputGroup
              type="input"
              value={searchTerm}
              placeholder="Enter email or name"
              field="searchTerm"
              onChange={(field, value) => this.onSearchTermChange(value)}
              autoFocus
              autoComplete="off"
              elementPointerEvents="auto"
              inputRightElementWidth="auto"
              variant={inputVariant}
              inputRightElement={
                <Flex gap="6px" p="0 10px">
                  {searchTerm && (
                    <button onClick={() => this.onSearchTermChange('')}>
                      <CloseIcon color={theme.colors.accentText} />
                    </button>
                  )}
                  {!isPubliclyShareable &&
                    companyDomain &&
                    entityType === 'spec' && (
                      <Tooltip
                        label={`This Spec is private and can only be shared with people who have a @${companyDomain} email address.`}
                        placement="top">
                        <span>
                          <InfoIconFilled color={theme.colors.accentText} />
                        </span>
                      </Tooltip>
                    )}
                </Flex>
              }
            />
            {searchResults.length > 0 && (
              <SearchResultsContainer direction="column">
                {searchResults.map((result, index) => {
                  const isSelected = selectedRecipients.findIndex(existingRecipient => existingRecipient.email === result.email) !== -1;
                  return (
                    <SearchResultTile
                      key={index}
                      result={result}
                      image={result.image || '-'}
                      title={result.name}
                      subtitle={result.email}
                      onSelectResult={result => this.onRecipientSelect(result)}
                      isSelected={isSelected}
                    />
                  );
                })}
              </SearchResultsContainer>
            )}
          </Flex>
        </form>
        {selectedRecipients.length > 0 && (
          <Flex gap="8px" wrap="wrap" mt="-8px">
            {selectedRecipients.map(recipient => {
              return (
                <Tag
                  key={recipient.slug}
                  label={recipient.email}
                  avatarSrc={recipient.image || '-'}
                  avatarName={recipient.name}
                  bg="white"
                  color={theme.colors.paragraphText}
                  border={theme.layout.border}
                  padding="8px"
                  onDelete={() => this.onRecipientSelect(recipient)}
                  showDelete
                />
              );
            })}
          </Flex>
        )}

        <InputGroup
          type="textarea"
          value={message}
          placeholder="Enter message (optional)"
          field="message"
          onChange={this.onInputChange}
          variant={inputVariant}
        />
        <Button
          colorScheme="brand"
          variant="solid"
          isDisabled={selectedRecipients.length === 0 || isSendLoading}
          isLoading={isSendLoading}
          onClick={this.onSend}>
          Send
        </Button>

        {showShareControls && (
          <>
            <OrDivider shouldHideOrText />
            <ShareControls isPublic={isPubliclyShareable} onUpdateSharePermissions={this.onUpdateSharePermissions} showLabel={false} />
          </>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(ShareSpec);
