import React from 'react';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { css } from '@emotion/react';
import { CloseIcon } from 'assets/icons';
import { HStack, Tooltip } from '@chakra-ui/react';
import DragHandle from 'components/DragHandle';

const StyledHStack = styled(HStack)`
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};
`;

const DeleteButton = styled.button`
  transition: background 0.5s ease;
  &:hover {
    svg {
      color: ${theme.colors.primaryText} !important;
    }
  }
`;

const AnswerHandleGroup = ({
  showDrag = true,
  showClose = true,
  onDeleteClick,
  padding = '0 10px',
  className = 'answer-handles-show-on-hover',
  dragTooltipLabel = 'Drag to reorder',
  deleteTooltipLabel = 'Delete',
  dragHandleClassName,
  deleteIconColor = theme.colors.accentText,
  dragHandleColor,
}) => {
  return (
    <StyledHStack align="center" justify="flex-end" spacing="1px" padding={padding} className={className}>
      {showDrag && (
        <DragHandle
          noOfDots={4}
          color={theme.colors.accentText}
          tooltipLabel={dragTooltipLabel}
          className={dragHandleClassName}
          isTooltipDisabled
          color={dragHandleColor}
        />
      )}
      {showClose && (
        <Tooltip label={deleteTooltipLabel} placement="top" openDelay={1000}>
          <DeleteButton onClick={onDeleteClick}>
            <CloseIcon color={deleteIconColor} size={18} />
          </DeleteButton>
        </Tooltip>
      )}
    </StyledHStack>
  );
};

export default AnswerHandleGroup;
