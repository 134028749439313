import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import Button from 'components/Button';

const AlertDialogComponent = ({
  isOpen,
  onCancel,
  onConfirm,
  title = 'Unsaved Changes',
  body = "Are you sure? You can't undo this action afterwards.",
}) => {
  const cancelRef = React.useRef();
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onCancel={onCancel} isCentered size="sm">
      <AlertDialogOverlay bg="blackAlpha.700" backdropFilter="blur(3px)">
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{body}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme="red" variant="solid" onClick={onConfirm} ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogComponent;
