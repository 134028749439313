import {
  GiAries,
  GiTaurus,
  GiGemini,
  GiCancer,
  GiLeo,
  GiVirgo,
  GiLibra,
  GiScorpio,
  GiSagittarius,
  GiCapricorn,
  GiAquarius,
  GiPisces,
} from 'react-icons/gi';

export const SIGNS = [
  {
    icon: <GiAries />,
    sign: 'Aries',
    birthday_range: 'March 21st - April 20th',
    strengths: 'Courageous, confident, determined, passionate, energetic',
    weaknesses: 'Impatient, short-tempered, impulsive',
    description:
      'Aries are the first of the zodiac, therefore, they have a desire to be first at everything and are natural born leaders who are fiercely competitive. In the workplace, they are often gamechangers and are a great force. However, it can be important for this sun sign to remember to be patient and to not overstep boundaries.',
    friendships: `As a fire sign, you’ve got enthusiasm and determination to spare. After all, Rams are passionate leaders. You’re the type to give your friends a push and keep them motivated by cheering them on. You’re confident in your ability to build community and keep your friends close, always doling out the positive energy they need.`,
  },

  {
    icon: <GiTaurus />,
    sign: 'Taurus',
    birthday_range: 'April 21st - May 21st',
    strengths: 'Patient, reliable, responsible, practical',
    weaknesses: 'Stubborn, possessive, greedy',
    description: `People born under this sun sign are often known as “the rock of the workplace”. They value their security and stability and are known for being dependable and reliable in the workplace. You can typically count on a Taurus to be unfazed by potential emotional turmoil that may easily cloud other’s vision.`,
    friendships: `Loyal to the core, Tauruses never walk away from a friend in need and will always have their friends back, no matter what. You’re dependable, the kind of friend who will drop everything to answer a call or do a favor for someone you love.`,
  },

  {
    icon: <GiGemini />,
    sign: 'Gemini',
    birthday_range: 'May 22nd - June 21st',
    strengths: 'Curious, adaptable, versatile, intellectual',
    weaknesses: 'Inconsistent, indecisive, superficial',
    description: `Geminis have been known to be “the most delightful to work with”. This sun sign is ruled by Mercury, so they value communication. The symbol of Gemini is the “twins”, which can make them seem “superficial” at times as they may act different in different surroundings. However, it’s important to keep in mind that this sign is very adaptable to its surroundings and can vibe with anyone.`,
    friendships: `You’re the kind of fun-loving friend who is always eager to meet new people, but you choose to only get close to a select few. You don’t get too deep with many, but you always have one best friend who you’ll do anything for.`,
  },
  {
    icon: <GiCancer />,
    sign: 'Cancer',
    birthday_range: 'June 22nd - July 22nd',
    strengths: 'Loyal, sympathetic, persuasive, imaginative',
    weaknesses: 'Manipulative, pessimistic, clingy',
    description: `Cancers are very much in tune with their own emotions, as well as other’s emotions which can make them a very caring co-worker. The symbol for Cancer is a crab, which reflects how Cancers can sometimes be very guarded (like how a crab’s shell provides protection) which makes it hard to let their true personality shine through.`,
    friendships: `As the most innately maternal sign of the zodiac, you’re both loyal and nurturing to your friends, with bountiful compassion and empathy. You’re a great listener and are genuinely interested in your friends’ wellbeing.

You’re not afraid to keep it real with those you are close to. Cancers serve as a shoulder to cry on, but their kindness can serve as a double-edged sword, as they’ll use your weaknesses to keep you in check.`,
  },
  {
    icon: <GiLeo />,
    sign: 'Leo',
    birthday_range: 'July 23rd - August 21st',
    strengths: 'Generous, humorous, cheerful, creative',
    weaknesses: 'Arrogant, bossy, inflexible',
    description: `Leos are often known as the “king of the office jungle” as their symbol is the lion. Leos thrive on the energy of others and have the ability to bring their own enthusiasm to the workplace. Leos are ruled by the Sun, so they are the center of their own universe which can make it quite easy to bruise a Leo’s ego.`,
    friendships: `As a Lion who loves the limelight, you typically like to surround yourself with friends who are on your same level. You’re most interested in people who are just as passionate and intelligent as you, who can keep up with your high energy and quick-wit. But you’re also quite generous as a friend, and it’s a Leo who’s most likely to brag and show off their friendships on social media.`,
  },
  {
    icon: <GiVirgo />,
    sign: 'Virgo',
    birthday_range: 'August 22nd - September 23rd',
    strengths: 'Analytical, hardworking, practical, diligent',
    weaknesses: 'Shy, overly critical of self and others, perfectionist',
    description: `Virgos are known as the “diligent, silent soldiers” in the workplace. They are intelligent, however they typically over-think, over-worry, and over-work themselves. In addition, Virgos can sometimes come off as very critical of others.`,
    friendships: `You’re everyone’s go-to person for sound advice. You’re great at directing and instructing your friends in how to handle problems. Using your keen analytical mind, you won’t judge your friends, instead providing them with logical ways to help.`,
  },
  {
    icon: <GiLibra />,
    sign: 'Libra',
    birthday_range: 'September 24th - October 23rd',
    strengths: 'Cooperative, fair-minded, diplomatic, easygoing',
    weaknesses: 'Avoids confrontations, indecisive, holds a grudge',
    description: `The symbol for Libra is the scales and because of this, Libras value justice and balance. They are often known as the “unifying force of justice” in the workplace as they are usually unbothered by others and are known to be unbiased and very fair. However, at times, a Libra’s confidence in their judgment can be overpowering and can end up clouding their judgment.`,
    friendships: `You have a penchant for bringing people together. Your motto is “the more the merrier” and you love to be surrounded by great people and stimulating conversations. Often you’re the glue that connects your besties to other friends. But while you tend to be eager for companionship, you can be hesitant to get too deep with your pals.`,
  },
  {
    icon: <GiScorpio />,
    sign: 'Scorpio',
    birthday_range: 'October 24th - November 22nd',
    strengths: 'Brave, passionate, resourceful, determined',
    weaknesses: 'Jealous, secretive, distrusting',
    description: `Just like a scorpion (the symbol for this sun sign) Scorpios are the people at work that you don’t want to mess with. However, on the plus side, they can accomplish anything due to their drive, determination, passion, and their resourcefulness.`,
    friendships: `Oh fiery, Scorpio, you only have the patience for so much intensity, so like with most things, you’re all or nothing when it comes to your friendships. The friends who have proven their trust and loyalty are the ones who will have your undying time and affection. Once they have proven themselves to you, you will love them deeply.`,
  },
  {
    icon: <GiSagittarius />,
    sign: 'Sagittarius',
    birthday_range: 'November 23rd - December 22nd',
    strengths: 'Generous, idealistic, optimistic, straightforward',
    weaknesses: 'Impatient, careless, irresponsible',
    description: `Sagittarians have been known to be a “ray of sunshine in a windowless office”. They are often very optimistic and adventurous. However, there are times when this sun sign takes things too far and can get caught up pursuing a good time.`,
    friendships: `Though you value your independence, you’re the kind of friend who can always be counted on for a wild night. Friends call on you for an adventure. And while you’re all about making memories with your crew, you’re also gifted at giving philosophical advice, using your inquisitive personality to your friend’s benefit.`,
  },
  {
    icon: <GiCapricorn />,
    sign: 'Capricorn',
    birthday_range: 'December 23rd - January 20th',
    strengths: 'Responsible, disciplined, practical, careful',
    weaknesses: 'Pessimistic, know-it-all, unforgiving',
    description: `Capricorns are often very efficient and helpful in the workplace. You can count on them to be responsible and practical. Capricorns value stability and order which is often reflected in their work. At times, Capricorns may come off as a know-it-all, however they just might actually know it all.`,
    friendships: `Capricorns inspire their friends to achieve greatness and success. So if you’re going to make it to the top, you want your friends to be there, too. Using your ambitious mindset to inspire them, you’ll make sure your friends will get there with you. Like anything in life, you take your friendships seriously.`,
  },
  {
    icon: <GiAquarius />,
    sign: 'Aquarius',
    birthday_range: 'January 21st - February 19th',
    strengths: 'Independent, humanitarian, original, progressive',
    weaknesses: 'Avoids emotional expression, temperamental, uncompromising',
    description: `Aquarians are known as one of the most “good-natured of the bunch”. Due to their independence and originality, they often isolate themselves and they have sometimes been known to set double standards.`,
    friendships: `As one of the most unique signs of the zodiac, Aquarius, you march to the beat of your own drum. You can sometimes be competitive and fanatical with others, including your friends, but, mostly, you are kind and giving. People are drawn to your relaxed nature, which makes it easy for you to make new friends, despite sometimes being known as a more introverted type.`,
  },
  {
    icon: <GiPisces />,
    sign: 'Pisces',
    birthday_range: 'February 20th - March 20th',
    strengths: 'Compassionate, artistic, gentle, wise, intuitive',
    weaknesses: 'Overly trusting, fearful, desire to escape reality',
    description: `Pisces are the “babies” of the zodiac signs and are often a “mix” of all zodiac signs. This makes Pisces very intuitive of others which can them more aware of how other people are feeling. Like Cancer, this makes for a very caring and emotionally intelligent co-worker.`,
    friendships: `Pisces are deep, emotional water signs, so they’re able to read their friends on a deeper level than most. Pisces are the most non-judgemental friends—until they aren’t. They can occasionally wave back and forth, but it doesn’t mean they don’t love their best friends.`,
  },
];
