import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { INDICATORS } from 'data/myersBriggsData';
import InfoGroup from 'components/InfoGroup';
import Accordion from 'components/Accordion';
import Flex from 'components/Flex';
import HTMLText from 'components/HTMLText';
import Button from 'components/Button';
import SectionDirectoryLink from 'componentsCustom/SectionDirectoryLink';

const StyledFlex = styled(Flex)`
  strong {
    font-weight: normal;
    font-family: ${theme.fonts.semiBold};
    display: block;
    margin-bottom: -16px;
  }
`;

const Circle = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.base};
  flex-shrink: 0;
`;

const LiveOutline = ({ answer, isEditMode, onDelete }) => {
  const jsonAnswer = INDICATORS.find(indicator => indicator.indicator === answer.answer);
  return (
    <StyledFlex gap="16px" direction="column" align="flex-start">
      <InfoGroup
        icon={<Circle>{answer.answer}</Circle>}
        title={jsonAnswer.title}
        subtitle={jsonAnswer.subtitle}
        spaceBetween="14px"
        noSpaceBetween={false}
      />
      <Accordion width="100%" flexGrow={1}>
        {jsonAnswer.sections.map(section => {
          return (
            <Accordion.Item key={section.title}>
              <Accordion.Button text={section.title} />
              <Accordion.Panel>
                <HTMLText html={section.body} />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {isEditMode && <Button onClick={onDelete}>Change Type</Button>}
      {!isEditMode && (
        <SectionDirectoryLink
          label="See others with this type"
          path={`directory?query=true&display=${'Myers–Briggs Results'}&query_type=spec_section_answer&query_field=spec_section_answer_answer&query_value=${
            answer.answer
          }`}
        />
      )}
    </StyledFlex>
  );
};

export default LiveOutline;
