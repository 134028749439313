import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Flex from 'components/Flex';
import Checkbox from 'components/Checkbox';
import Text from 'components/Text';

const CheckboxItem = ({ isChecked, onChange, label, isCheckReadyOnly, checkedTextAs, isDisabled, ...props }) => {
  return (
    <Flex gap="12px" flexGrow={1} {...props} align="flex-start">
      <Flex direction="column" height="20px" justify="center">
        <Checkbox
          isChecked={isChecked}
          onChange={onChange}
          size="md"
          readOnly={isCheckReadyOnly}
          className="disable-focus"
          isDisabled={isDisabled}
        />
      </Flex>
      <Text
        fontFamily={theme.fonts.light}
        color={theme.colors.paragraphText}
        as={isChecked && checkedTextAs}
        lineHeight={theme.lineHeights.sm}>
        {label}
      </Text>
    </Flex>
  );
};

export default CheckboxItem;
