import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { createStandaloneToast } from '@chakra-ui/toast';
import { connect } from 'react-redux';
import { Api, setCookie, setWindowLocation, returnQuery } from 'helpers/utils';
import { isLoginEmailValid, isUserBasicInfoValid } from 'helpers/authUtils';
import update from 'immutability-helper';
import CompanyEmailValidator from 'company-email-validator';
import BasicInfo from '../sharedComponents/BasicInfo';
import EnterCode from '../sharedComponents/EnterCode';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import TermsFooter from 'componentsCustom/TermsFooter';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const Styled = styled.div``;

class Login extends React.Component {
  state = {
    currentStep: 'basic_info',
    newUser: {
      email: '',
      auth_method: 'email',
    },
    isNameRequired: false,
  };

  componentDidMount() {
    const { isSignedIn, currentUser, typeOfAuth } = this.props;
    if (isSignedIn) {
      setWindowLocation(`/${currentUser.slug}`);
    } else {
      const parsed = returnQuery();
      if (typeOfAuth === 'create_account' && !parsed.login_initial) {
        this.setState({ isNameRequired: true });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('user-is-tabbing');
  }

  onStateChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onNewUserChange = (field, value) => {
    this.setState({ newUser: update(this.state.newUser, { [field]: { $set: value } }) });
  };

  onGoogleAuthSuccess = newUser => {
    this.setState({ newUser: newUser }, () => {
      this.onSubmit();
    });
  };

  setIsNameRequired = isNameRequired => this.setState({ isNameRequired });

  triggerToast = ({ title, status = 'success', duration = 2000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  returnValidation() {
    const { newUser, isNameRequired } = this.state;
    const { currentCompany, hasCompany } = this.props;
    if (isNameRequired) {
      return isUserBasicInfoValid(newUser, hasCompany && currentCompany.domain);
    }
    return isLoginEmailValid(newUser.email, hasCompany && currentCompany.domain);
  }

  onSubmit = () => {
    const validation = this.returnValidation();
    if (validation.success) {
      this.setState({ isSubmitLoading: true });
      const { newUser, isNameRequired } = this.state;
      const { currentCompany, hasCompany, typeOfAuth = 'login' } = this.props;
      Api.post({
        url: `/api/v1/auth/submit_login`,
        body: {
          user: newUser,
          has_company: hasCompany,
          current_company: currentCompany,
          is_company_email: CompanyEmailValidator.isCompanyEmail(newUser.email),
          type_of_auth: isNameRequired ? typeOfAuth : 'login',
        },
      }).then(data => {
        if (data.success) {
          if (newUser.auth_method === 'google') {
            setCookie('login_token', data.login_token);
            let redirectURL = `/${data.user.slug}`;
            const parsed = returnQuery();
            if (parsed.redirect) redirectURL = parsed.redirect;
            setTimeout(() => {
              window.location.href = redirectURL;
            }, 10);
          } else {
            this.setState({ currentStep: 'enter_code', isSubmitLoading: false });
          }
        } else {
          this.setState({ isSubmitLoading: false });
          this.triggerToast({ title: data.error, status: 'error', duration: 4000 });
        }
      });
    } else {
      this.triggerToast({ title: validation.notice, status: 'error' });
    }
  };

  render() {
    const { currentStep, newUser, isSubmitLoading, isNameRequired } = this.state;
    const { isSignedIn, currentCompany, hasCompany, pageWithin, typeOfAuth = 'login', isModal } = this.props;
    return (
      <React.Fragment>
        <ToastContainer />
        <OnboardingFlow pageWithin={pageWithin} isModal={isModal}>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.onSubmit();
            }}>
            {currentStep === 'basic_info' && (
              <BasicInfo
                newUser={newUser}
                onStateChange={this.onStateChange}
                onNewUserChange={this.onNewUserChange}
                currentCompany={currentCompany}
                hasCompany={hasCompany}
                isSubmitLoading={isSubmitLoading}
                nextButtonText={typeOfAuth === 'create_account' ? 'Continue' : 'Submit'}
                typeOfAuth={typeOfAuth}
                onGoogleAuthSuccess={this.onGoogleAuthSuccess}
                isNameRequired={isNameRequired}
                setIsNameRequired={this.setIsNameRequired}
              />
            )}
          </form>
          {currentStep === 'enter_code' && (
            <EnterCode
              email={newUser.email}
              onEnterCodeEmailEdit={() => this.setState({ currentStep: 'basic_info' })}
              successAction="account_login"
            />
          )}
        </OnboardingFlow>
        {pageWithin === 'within_company_signup' && <TermsFooter />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default connect(mapStateToProps)(Login);
