const isLocal = window.location.hostname === 'localhost';
export const urls = {
  routeDomain: isLocal ? 'http://localhost:3001' : `https://spec.me`,
  cookieDomain: isLocal ? null : `.spec.me`,
};

export const slackRedirectURI = isLocal ? 'https://localhost:3001/add_to_slack' : 'https://spec.me/add_to_slack';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LANGUAGES = [
  'Mandarin Chinese',
  'Spanish',
  'English',
  'Hindi/Urdu',
  'Arabic',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'German',
  'Javanese',
  'Punjabi',
  'Wu',
  'French',
  'Telugu',
  'Vietnamese',
  'Marathi',
  'Korean',
  'Tamil',
  'Italian',
  'Turkish',
  'Cantonese/Yue',
];

export const YEARS = [];

for (var i = 2022; i > 1900; i--) {
  YEARS.push(i);
}
