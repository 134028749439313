import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';

const StyledBox = styled(Box)`
  position: relative;
  width: 100%;
  height: 18px;
  &::before {
    content: 'OR';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 8px;
    font-size: 12px;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: ${theme.colors.border};
  }
  ${({ shouldHideOrText }) =>
    shouldHideOrText &&
    css`
      &::before {
        display: none;
      }
    `};
`;

const OrDivider = ({ shouldHideOrText }) => {
  return <StyledBox shouldHideOrText={shouldHideOrText} />;
};

export default OrDivider;
