import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { HStack } from '@chakra-ui/react';
import { MONTHS } from 'data/constants';
import { getNoDaysInMonth } from 'helpers/utils';
import InputGroup from 'components/InputGroup';

const DEFAULT_DAY_OPTIONS = Array.from(new Array(31), (x, i) => {
  return { label: i + 1, value: i + 1 };
});

const MonthDayPicker = ({ value, label, field, onChange }) => {
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [dayOptions, setDayOptions] = useState(DEFAULT_DAY_OPTIONS);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      if (date) {
        setMonth(date.getMonth());
        setDay(date.getDate());
      }
    }
  });

  const onMonthSelect = selectedMonth => {
    selectedMonth = +selectedMonth;
    setMonth(selectedMonth);
    const daysInMonth = getNoDaysInMonth(selectedMonth);
    const newDayOptions = Array.from(new Array(daysInMonth), (x, i) => {
      return { label: i + 1, value: i + 1 };
    });
    setDayOptions([].concat(newDayOptions));
    if (day !== null) {
      const value = new Date(1900, selectedMonth, day, 0, 0, 0, 0);
      onChange(field, value);
    }
  };

  const onDaySelect = selectedDay => {
    selectedDay = +selectedDay;
    setDay(selectedDay);
    if (month !== null) {
      const value = new Date(1900, month, selectedDay, 0, 0, 0, 0);
      onChange(field, value);
    }
  };

  return (
    <InputGroup
      label={label}
      customComponent={
        <HStack spacing="16px">
          <InputGroup
            type="select"
            value={month}
            width="50%"
            placeholder="Month"
            field="month"
            onChange={(field, value) => onMonthSelect(value)}
            selectOptions={MONTHS.map((month, index) => {
              return { label: month, value: index };
            })}
          />

          <InputGroup
            type="select"
            value={day}
            width="50%"
            placeholder="Day"
            field="day"
            onChange={(field, value) => onDaySelect(value)}
            selectOptions={dayOptions}
          />
        </HStack>
      }
    />
  );
};

export default MonthDayPicker;
