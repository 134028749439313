import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Flex, AspectRatio } from '@chakra-ui/react';
import { PlayIconFilled, VideoRecordIconFilled, VideoUploadIconFilled } from 'assets/icons';
import ReactPlayer from 'react-player/file';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import ImageUpload from 'containers/ImageUpload';
import RecordVideoModal from 'componentsCustom/RecordVideoModal';

const Video = ({ section, sectionIndex, onSectionAnswerChange, isEditMode }) => {
  const [showRecordModal, setRecordModalVisibility] = useState(false);
  const answer = section.answers[0];
  const answerIndex = 0;
  const hasVideo = answer.answer;

  const onUploadVideo = (videoURL, publicID) => {
    onSectionAnswerChange(sectionIndex, answerIndex, 'answer', videoURL);
    setTimeout(() => {
      onSectionAnswerChange(
        sectionIndex,
        answerIndex,
        'option',
        `https://res.cloudinary.com/teamspec/video/upload/so_0/e_blur:1000/b_black,o_65/${publicID}.jpg`,
      );
    }, 50);
  };

  return (
    <React.Fragment>
      {hasVideo ? (
        <Flex direction="column" gap="12px" align="flex-start">
          <AspectRatio ratio={1.5 / 1} borderRadius={theme.radii.md} overflow="hidden" width="100%">
            <ReactPlayer
              url={answer.answer}
              width="100%"
              height="100%"
              controls
              light={answer.option}
              playIcon={
                <IconButton
                  variant="solid"
                  colorScheme="whiteAlpha"
                  isRound
                  size="lg"
                  icon={<PlayIconFilled size={24} color="white" />}
                  as="div"
                />
              }
            />
          </AspectRatio>
          {isEditMode && <Button onClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'answer', '')}>Delete Video</Button>}
        </Flex>
      ) : (
        <Flex align="center" gap="12px">
          <Button leftIcon={<VideoRecordIconFilled />} onClick={() => setRecordModalVisibility(true)}>
            Record Video
          </Button>
          <ImageUpload
            uploadPreset="spec_section_video"
            folderName="spec_section_videos"
            onUpload={(field, videoURL, publicID) => onUploadVideo(videoURL, publicID)}
            sources={['local', 'url', 'google_drive', 'dropbox']}
            allowedFormats={['video']}
            customButton={
              <Button as="div" leftIcon={<VideoUploadIconFilled />}>
                Upload Video
              </Button>
            }
          />
        </Flex>
      )}
      <RecordVideoModal
        key={showRecordModal}
        isOpen={isEditMode && showRecordModal}
        onClose={() => setRecordModalVisibility(false)}
        onUploadVideo={(videoURL, publicID) => onUploadVideo(videoURL, publicID)}
      />
    </React.Fragment>
  );
};

export default React.memo(Video, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
