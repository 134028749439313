import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { getIcon } from 'helpers/utils';
import { toURL } from 'helpers/utils';
import { Link } from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import moment from 'moment';
import Text from 'components/Text';

const Styled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -8px;
`;

const FieldTile = styled.div`
  display: flex;
  align-items: center;
  width: 33.33%;
  padding: 8px;
  @media screen and (max-width: ${theme.layout.mobileBreakpoint}) {
    width: 50%;
  }
`;

const IconWrap = styled.div`
  color: ${theme.colors.lightAccentText};
  font-size: 18px;
`;

const TextGroup = styled.div`
  margin-left: 16px;
`;

const Fields = ({ specFields, isEditMode }) => {
  return (
    <Styled>
      {specFields.map(field => {
        if (['bio', 'job_title'].includes(field.field_key)) return null;
        let displayValue = field.field_value;
        if (!isEditMode && !field.field_value) return null;
        if (isEditMode && !field.field_value) displayValue = '–';
        if (field.field_type === 'date' && field.field_value) displayValue = moment(field.field_value).format(field.field_data_format);
        return (
          <FieldTile key={field.field_key}>
            <IconWrap>{getIcon(field.field_key)}</IconWrap>
            <TextGroup>
              <Text color={theme.colors.lightAccentText} fontSize={theme.fontSizes.xs} casing="uppercase" noOfLines={1}>
                {field.field_name}
              </Text>
              <Text noOfLines={1}>
                {field.field_data_type === 'link' && !!field.field_value ? (
                  <Link href={toURL(field.field_value)} isExternal style={{ display: 'flex', alignItems: 'center' }}>
                    My {field.field_name} <RiExternalLinkLine style={{ marginLeft: 2 }} />
                  </Link>
                ) : (
                  displayValue
                )}
              </Text>
            </TextGroup>
          </FieldTile>
        );
      })}
    </Styled>
  );
};

export default Fields;
