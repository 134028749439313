import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import { ArrowLeftIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '@chakra-ui/react';
import Flex from 'components/Flex';
import Button from 'components/Button';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    overflow-x: auto;
    padding: ${theme.padding.md} ${theme.padding.lg};
  }
`;

const NavHeader = ({ template, onTemplateChange, currentCompanySlug, onCopyShareLink }) => {
  return (
    <StyledFlex justify="space-between" gap="12px">
      <Link to={`/org/${currentCompanySlug}/admin/templates`}>
        <Button as="div" leftIcon={<ArrowLeftIcon />} padding={theme.padding.smButton} height="auto">
          All Templates
        </Button>
      </Link>
      <Flex gap="12px">
        <Tooltip label="This is already your primary template" placement="top" isDisabled={!template.is_primary_template}>
          <span>
            <Button
              padding={theme.padding.smButton}
              height="auto"
              isDisabled={template.is_primary_template}
              onClick={() => onTemplateChange('is_primary_template', true, true)}>
              Set as primary
            </Button>
          </span>
        </Tooltip>
        <Tooltip label="You can't delete your primary template" placement="top" isDisabled={!template.is_primary_template}>
          <span>
            <Button
              padding={theme.padding.smButton}
              height="auto"
              isDisabled={template.is_primary_template}
              onClick={() => onTemplateChange('is_deleted', true, true)}>
              Delete
            </Button>
          </span>
        </Tooltip>
        <Button
          variant="solid"
          colorScheme="brand"
          padding={theme.padding.smButton}
          height="auto"
          onClick={() => onCopyShareLink(`https://spec.me/org/${template.company_slug}/template/${template.slug}`)}>
          Copy Invite Link
        </Button>
      </Flex>
    </StyledFlex>
  );
};

export default NavHeader;
