import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Flex } from '@chakra-ui/react';
import { PlusIcon } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/IconButton';
import Sortable from 'components/Sortable';
import TagsList from 'componentsCustom/TagsList';
import TagsPickerModal from 'componentsCustom/TagsPickerModal';
import Button from 'components/Button';

const Topics = ({
  section,
  sectionIndex,
  onSectionAnswerChange,
  isEditMode,
  onAnswerSelect,
  onDragSectionAnswerComplete,
  sortableContainerID,
  sortableHandleClassName,
}) => {
  const navigate = useNavigate();
  const [showOptionsBank, setOptionsBankVisibility] = useState(false);
  return (
    <React.Fragment>
      <Sortable
        sortableContainerID={sortableContainerID}
        sortableHandleClassName={sortableHandleClassName}
        onDragEnd={event => onDragSectionAnswerComplete(section.front_id, event.oldIndex, event.newIndex)}
        shouldHideGhost={false}>
        <Flex id={sortableContainerID} direction="row" align="center" gap="10px" wrap="wrap">
          <TagsList
            tags={section.answers}
            nameAccessKey="answer"
            showDelete={isEditMode}
            onDelete={answerIndex => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
            tagClassName={isEditMode && sortableHandleClassName}
            shouldAddAnswerElementID
            hoverCursor={isEditMode && 'move'}
            tagTooltip={!isEditMode && 'Click to see who else selected this'}
            as={!isEditMode && 'button'}
            onClickTag={tag => {
              if (!isEditMode) {
                navigate(
                  `directory?query=true&display=${
                    section.title
                  }&query_type=spec_section_answer&query_field=spec_section_title_answer&query_value=${tag}`,
                );
              }
            }}
          />
          {isEditMode && (
            <Button
              size="sm"
              leftIcon={<PlusIcon size={14} />}
              className="not-sortable"
              onClick={() => setOptionsBankVisibility(true)}
              fontSize="14px">
              Choose
            </Button>
          )}
        </Flex>
      </Sortable>

      <TagsPickerModal
        isOpen={showOptionsBank}
        onClose={() => setOptionsBankVisibility(false)}
        tags={section.options_bank}
        selectedTags={section.answers.map(answer => !answer.is_deleted && answer.answer)}
        onClickTag={selectedAnswer =>
          onAnswerSelect(sectionIndex, section.section_type, { option: selectedAnswer, answer: selectedAnswer }, true)
        }
      />
    </React.Fragment>
  );
};

export default React.memo(Topics, (prevProps, nextProps) => {
  // re-render if FALSE
  if (nextProps.editedSectionIndex === nextProps.sectionIndex) return false;
  if (prevProps.sectionIndex !== nextProps.sectionIndex) return false;
  if (prevProps.isEditMode !== nextProps.isEditMode) return false;
  return true;
});
