export const TRAITS_INFO = [
  {
    trait: 'Openness',
    description: `Not to be confused with one's tendency to be open and disclose their thoughts and feelings, Openness in the context of the Big Five refers more specifically to Openness to Experience, or openness to considering new ideas. This trait has also been called "Intellect" by some researchers, but this terminology has been largely abandoned because it implies that people high in Openness are more intelligent, which is not necessarily true.

Openness describes a person's tendency to think abstractly. Those who are high in Openness tend to be creative, adventurous, and intellectual. They enjoy playing with ideas and discovering novel experiences. Those who are low in Openness tend to be practical, traditional, and focused on the concrete. They tend to avoid the unknown and follow traditional ways.

In the brain, Openness seems to be related to the degree to which certain brain regions are interconnected. Those high in Openness seem to have more connection between disparate brain regions, which may explain why they are more likely to see connections where others do not.`,
    high_description: `If someone receives a high score in the ‘openness’ category, they are willing to take new experiences in their stride. They can be highly creative, bringing new ideas to the table, and often use their imagination to make positive changes in the work environment. A person with a high openness score will cope well with changes at work but will struggle with repetitive, mundane tasks that lack creativity and require logic. `,
    low_description: `If someone receives a low openness score, however, they typically prefer methodical and logical approaches to their work. They are also less likely to embrace change, preferring to maintain the status quo in terms of their work style. `,
  },
  {
    trait: 'Conscientiousness',
    description: `Conscientiousness describes a person's level of goal orientation and persistence. Those who are high in Conscientiousness are organized and determined, and are able to forego immediate gratification for the sake of long-term achievement. Those who are low in this trait are impulsive and easily sidetracked.

In the brain, Conscientiousness is associated with frontal lobe activity. The frontal lobe can be thought of as the "executive brain," moderating and regulating the more animal and instinctual impulses from other areas of the brain. For example, while we might instinctually want to eat a piece of cake that's in front of us, the frontal lobe steps in and says "no, that's not healthy, and it doesn't fit in with our diet goals." People who are high in Conscientiousness are more likely to use this brain region to control their impulses and keep themselves on track.`,
    high_description: `Those who receive a high score in the ‘conscientious’ category generally prefer structured, ordered approaches to their work. They are not only ambitious but will also use their determination and preparedness to achieve their goals. Those with conscientious personalities are hard workers who use schedules and plans to optimize their work. `,
    low_description: `If someone has a low conscientiousness score, they might tend to be much more flexible in their approach to work. Structures aren’t always required for people with low conscientiousness scores, and they don’t rely heavily on being organized. This might make it challenging for such people to meet strict deadlines or manage their time efficiently.`,
  },
  {
    trait: 'Extraversion',
    description: `Extraversion describes a person’s inclination to seek stimulation from the outside world, especially in the form of attention from other people. Extraverts engage actively with others to earn friendship, admiration, power, status, excitement, and romance. Introverts, on the other hand, conserve their energy, and do not work as hard to earn these social rewards.

In the brain, Extraversion seems to be related to dopamine activity. Dopamine can be thought of as the "reward" neurotransmitter, and is the main chemical associated with our instinct to pursue a goal. The classic example is a rat in a maze, whose brain pumps out dopamine as he frantically seeks the cheese. Extraverts tend to have more dopamine activity, indicating that they are more responsive to the potential for a reward. Introverts have less dopamine activity, and so are less likely to put themselves out to chase down rewards.`,
    high_description: `If someone scores highly in the extraversion category, they are typically energized when interacting with co-workers, and tend to be more productive in team discussions. However, working independently might sometimes be a challenge for those who have a high extraversion score.`,
    low_description: `On the other hand, a low extraversion score indicates that they might prefer working independently on projects and achieve their best work working alone. They might tend to struggle with collaborating or discussing ideas with team members.`,
  },
  {
    trait: 'Agreeableness',
    description: `Agreeableness describes the extent to which a person prioritizes the needs of others over their own needs. People who are high in Agreeableness experience a great deal of empathy and tend to get pleasure out of serving and taking care of others. People who are low in Agreeableness tend to experience less empathy and put their own concerns ahead of others.

In the brain, high Agreeableness has been associated with increased activity in the superior temporal gyrus, a region responsible for language processing and the recognition of emotions in others.`,
    high_description: `Someone with a high agreeableness score is likely to assist others with challenging tasks. They are also happy to collaborate with team members and work towards resolving problems in the workplace. In other words, their agreeable trait makes them good problem solvers.`,
    low_description: `If someone has a low agreeableness score, they might tend to be more forceful with their opinions. They can be headstrong in their ideas. This means people with low agreeableness scores might need to work on being more sympathetic to others' views and try to ensure they listen to other team members’ perspectives more.`,
  },
  {
    trait: 'Neuroticism',
    description: `Neuroticism describes a person's tendency to respond to stressors with negative emotions, including fear, sadness, anxiety, guilt, and shame.

This trait can be thought of as an alarm system. People experience negative emotions as a sign that something is wrong in the world. Fear is a response to danger, guilt a response to having done something wrong. However, not everyone has the same reaction to a given situation. High Neuroticism scorers are more likely to react to a situation with strong negative emotions. Low Neuroticism scorers are more likely to brush off their misfortune and move on.

In the brain, Neuroticism appears to relate to the interconnection of several regions, including regions involved in processing negative stimuli (such as angry faces or aggressive dogs) and dealing with negative emotions. One study found an association between high Neuroticism and altered serotonin processing in the brain. `,
    high_description: `Those who score highly in terms of neuroticism typically find working in high-pressure situations a challenge. They prefer calmer work environments, which make it easier for them to avoid stress. In high-pressure work situations, those with high neuroticism scores are likely to struggle with their emotions and be highly concerned when they make mistakes.`,
    low_description: `In contrast, if someone has a low neuroticism score, they can work in a more stable, predictable way, despite high-pressure situations. They will also have a more positive outlook in terms of challenging projects and are generally more optimistic. Staying calm where others might become stressed in the workplace comes naturally to those who have a low neuroticism score.`,
  },
];
