import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Menu, MenuButton, MenuList, MenuItem, MenuItemOption, MenuGroup, MenuOptionGroup, MenuDivider } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/overlay/menu
const MenuComponent = ({ children, ...props }) => {
  return <Menu {...props}>{children}</Menu>;
};

MenuComponent.Button = ({ children, ...props }) => {
  return <MenuButton {...props}>{children}</MenuButton>;
};

MenuComponent.List = ({ children, ...props }) => {
  return (
    <MenuList {...props} zIndex="dropdown" position="relative">
      {children}
    </MenuList>
  );
};

MenuComponent.Item = ({ children, color = theme.colors.primaryText, ...props }) => {
  return (
    <MenuItem {...props} color={color} fontSize="14px">
      {children}
    </MenuItem>
  );
};

export default MenuComponent;
