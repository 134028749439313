import React from 'react';
import { theme } from 'styles/theme';
import { InfoIconFilled } from 'assets/icons';
import { Tooltip, Box } from '@chakra-ui/react';

const InfoTooltip = ({ label, placement = 'top', color = theme.colors.accentText, marginLeft = 4, ...props }) => {
  return (
    <Tooltip label={label} placement={placement} {...props}>
      <span style={{ marginLeft: marginLeft }}>
        <InfoIconFilled color={color} />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
