import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { THEMES } from 'data/strengthsfinderData';
import Accordion from 'components/Accordion';
import Flex from 'components/Flex';
import Button from 'components/Button';
import SectionDirectoryLink from 'componentsCustom/SectionDirectoryLink';

const LiveOutline = ({ answers, isEditMode, onDelete, sectionTitle }) => {
  return (
    <Flex gap="16px" direction="column" align="flex-start">
      <Accordion width="100%" flexGrow={1}>
        {answers.map((answer, answerIndex) => {
          const theme = THEMES.find(theme => theme.theme === answer.answer);
          return (
            <Accordion.Item key={answer.option}>
              <Accordion.Button text={`${answerIndex + 1}. ${answer.answer}`} />
              <Accordion.Panel>{theme.description}</Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {isEditMode && <Button onClick={onDelete}>Reset Answers</Button>}
      {!isEditMode && (
        <SectionDirectoryLink
          label="See others' themes"
          path={`directory?query=true&display=${sectionTitle}&query_type=spec_section_answer&query_field=spec_section_title&query_value=${sectionTitle}`}
        />
      )}
    </Flex>
  );
};

export default LiveOutline;
