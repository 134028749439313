import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from 'containers/Navigation';
import Spec from 'pages/Spec';
import Directory from 'pages/Directory';
import Activity from 'pages/Activity';
import Auth from 'pages/Auth';
import NotificationsHub from 'pages/NotificationsHub';
import Admin from 'pages/Admin';
import SpecTemplateLanding from 'pages/SpecTemplateLanding';
import Invite from 'pages/Invite';
import UserAccountModal from 'containers/UserAccountModal';
import ShareCurrentURLModal from 'componentsCustom/ShareCurrentURLModal';
import SlackRedirect from 'containers/SlackRedirect';

const GlobalRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/add_to_slack" element={<SlackRedirect />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/" element={<Navigation />}>
          <Route path="/notifications/:notifications_token" element={<NotificationsHub />} />
          <Route path="/org/:slug" element={<Directory shouldConditionallyLimitResults renderLocation="directory-page" />} />
          <Route path="/org/:slug/admin/*" element={<Admin />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/org/:slug/activity" element={<Activity />} />
          <Route path="/org/:slug/invite" element={<Invite />} />
          <Route path="/org/:slug/auth/*" element={<Auth />} />
          <Route path="/org/:slug/template/:slug" element={<SpecTemplateLanding />} />

          <Route path="/:slug" element={<Spec />}>
            <Route path="/:slug/auth/*" element={<Auth isModal />} />
            <Route path="/:slug/share" element={<ShareCurrentURLModal />} />
            <Route path="/:slug/directory" element={<Directory isModal />} />
            <Route path="/:slug/account">
              <Route index element={<UserAccountModal />} />
              <Route path="/:slug/account/notifications" element={<UserAccountModal selectedTabIndex={1} />} />
              <Route path="/:slug/account/settings" element={<UserAccountModal selectedTabIndex={2} />} />
              <Route path="/:slug/account/invite" element={<UserAccountModal selectedTabIndex={3} />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

const handleFirstTab = e => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
};
window.addEventListener('keydown', handleFirstTab);

export default GlobalRoutes;
