import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { MentionsInput, Mention } from 'react-mentions';
// import { ButtonGroup } from '@chakra-ui/react';

const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const Styled = styled.div`
  flex-grow: 1;
  .mentions-input__input {
    width: 100% !important;
    border-radius: ${theme.radii.md} !important;
    width: 100% !important;
    min-width: 0px !important;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    appearance: none !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
    transition-duration: 200ms !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    line-height: 1.375 !important;
    vertical-align: top !important;
    font-size: 14px !important;
    padding-inline-start: 1rem !important;
    padding-inline-end: 1rem !important;
    border: 2px solid !important;
    border-color: transparent !important;
    background: ${theme.colors.grayFill} !important;
    resize: none !important;
    min-height: 40px;
  }
  .mentions-input__input:focus {
    box-shadow: inherit !important;
    background: transparent !important;
    border-color: #bdc4cf !important;
  }
  .mention {
    color: red !important;
  }
`;

const MentionsInputComponent = ({ value, onChange }) => {
  return (
    <Styled>
      <MentionsInput value={value} onChange={onChange} style={mentionsInputStyle} className="mentions-input" placeholder="Comment">
        <Mention
          markup="{{__id__}}"
          trigger="@"
          className="mention"
          data={[{ id: 'lebron', display: 'Lebron James' }]}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <div className={`user ${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>
          )}
        />
      </MentionsInput>
    </Styled>
  );
};

export default MentionsInputComponent;
