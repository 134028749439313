import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { createStandaloneToast } from '@chakra-ui/toast';
import { Api, randomID, returnQuery } from 'helpers/utils';
import { urls } from 'data/constants';
import { connect } from 'react-redux';
import { getCurrent } from 'reduxx/current';
import { withNavigation, withParams, withLocation } from 'hooks/router';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import update from 'immutability-helper';
import Header from './layout/Header';
import PreSectionsComponents from './layout/PreSectionsComponents';
import UnauthNotice from './layout/UnauthNotice';
import MarkAsReviewedModal from './features/MarkAsReviewedModal';
import SpecSections from './SpecSections';
import Loading from 'components/Loading';
import TermsFooter from 'componentsCustom/TermsFooter';
import MiscTopLevelComponents from './MiscTopLevelComponents';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const StyledBody = styled.div`
  margin: 0 auto;
  width: ${theme.layout.columnWidth};
  max-width: 100vw;
`;

class Spec extends React.Component {
  state = {
    spec: {},
    specUser: {},
    specFields: {},
    specSections: {},
    isCurrentUserCreator: true,
    isEditMode: true,
    showIntroWalkthrough: false,
    gettingStartedStepsCompleted: [],
  };

  componentDidMount() {
    const { params } = this.props;
    this.handleQueryParams();
    this.getSpec();
  }

  componentDidUpdate(prevProps) {
    const { params, location } = this.props;
    if (prevProps.params && prevProps.params.slug !== params.slug) {
      this.getSpec();
      this.setState({ didLoad: false });
    }
    if (prevProps.location.pathname !== location.pathname && location.pathname === `/${params.slug}`) {
      this.getSpec();
      const params = returnQuery();
      if (params.saved) {
        this.triggerToast({ title: 'Your changes have been saved' });
        this.props.dispatch(getCurrent(false));
      }
    }
  }

  handleQueryParams = ({ isCurrentUserCreator } = {}) => {
    const params = returnQuery();
    if (params.action === 'create-spec' && isCurrentUserCreator) {
      this.setState({ showWelcomeModal: true });
    } else if (params.is_via_iframe) {
      this.handleWithinIframe();
    }
  };

  handleWithinIframe() {
    document.body.classList.add('disable-all-mouse-events');
    document.body.classList.add('show-body-scrollbars');

    const navBarElement = document.getElementById('nav-bar');
    if (navBarElement) navBarElement.style.display = 'none';

    const primaryBanner = document.getElementById('primary-banner');
    if (primaryBanner) primaryBanner.style.height = '328px';

    // Responsive styling handled in globall.css
    const specContainer = document.getElementById('spec-container');
    if (specContainer) specContainer.classList.add('spec-container-iframe');

    const termsFooter = document.getElementById('terms-footer');
    if (termsFooter) termsFooter.style.display = 'none';

    this.setState({ isWithinIframe: true });
  }

  getSpec() {
    const { params } = this.props;
    const slug = params.slug;
    Api.get({ url: `/api/v1/specs/${slug}/get_spec` }).then(data => {
      if (data.success) {
        document.title = `${data.spec_user.first_name}'s Spec`;
        this.setState(
          {
            spec: data.spec,
            specUser: data.spec_user,
            specFields: data.spec_fields,
            specSections: data.spec_sections,
            isCurrentUserCreator: data.is_current_user_creator,
            isCurrentUserPartOfCompany: data.is_current_user_part_of_company,
            isEditMode: !this.state.isEditMode ? false : data.is_current_user_creator,
            hasAccess: true,
            doesSpecExist: true,
            didLoad: true,
          },
          () => {
            this.handleQueryParams({ isCurrentUserCreator: data.is_current_user_creator });
            if (data.is_current_user_creator) this.calculateGettingStartedStepsCompleted();
          },
        );
      } else {
        const { isSignedIn, currentUser } = this.props;
        this.setState({
          hasAccess: data.has_access,
          doesSpecExist: data.does_spec_exist,
          doesUserExist: data.does_user_exist,
          isCurrentUserCreator: isSignedIn && slug === currentUser.slug,
          didLoad: true,
        });
      }
    });
  }

  componentWillUnmount() {
    document.title = 'Spec';
  }

  calculateGettingStartedStepsCompleted() {
    const { spec, specUser, specFields } = this.state;
    let gettingStartedStepsCompleted = [];
    const hasJobTitle =
      specFields.findIndex(field => field.field_key === 'job_title' && field.field_value && field.field_value.length > 1) > -1;
    if (specUser.image && hasJobTitle) gettingStartedStepsCompleted.push('basic_info');
    if (spec.is_published) gettingStartedStepsCompleted.push('is_published');
    if (spec.is_published && spec.total_views > 1) gettingStartedStepsCompleted.push('was_shared');
    this.setState({ gettingStartedStepsCompleted });
  }

  onEditModeSelect = isEditMode => {
    // Need to reset doesHaveCompletedAnswers incase they add or delete answers while toggling back and forth
    window.scrollTo(0, 0);
    this.setState({ isEditMode: isEditMode, doesHaveCompletedAnswers: false });
  };

  triggerToast = ({ title, status = 'success', duration = 4000 }) => {
    toast({
      title: title,
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  onTogglePublish = () => {
    const { spec } = this.state;
    const { params } = this.props;
    const isPublished = !spec.is_published;
    Api.post({
      url: `/api/v1/specs/${params.slug}/publish`,
      body: { is_published: isPublished },
    }).then(data => {
      if (data.success) {
        this.setState({
          spec: update(this.state.spec, { is_published: { $set: isPublished } }),
          showPublishSuccess: isPublished,
          gettingStartedStepsCompleted: [...this.state.gettingStartedStepsCompleted, 'is_published'],
        });
      }
    });
  };

  onCompletedAnswersCallback = () => this.setState({ doesHaveCompletedAnswers: true });

  onToggleMarkAsReviewedModal = () => {
    if (this.shouldPreventAuthAccess()) return;
    this.setState({ showMarkAsReviewedModal: !this.state.showMarkAsReviewedModal });
  };

  onMarkedAsReviewedCallback = () => {
    const { spec } = this.state;
    const { currentUser } = this.props;
    this.setState({
      spec: update(spec, {
        total_reviewed_by: { $set: spec.total_reviewed_by + 1 },
        current_user_has_reviewed: { $set: true },
        reviewed_by_sample: { $set: [currentUser.slug].concat(spec.reviewed_by_sample) },
      }),
    });
  };

  shouldPreventAuthAccess = () => {
    const { specUser, isCurrentUserPartOfCompany } = this.state;
    const { isSignedIn, navigate } = this.props;
    if (isSignedIn && isCurrentUserPartOfCompany) {
      return false;
    } else if (isSignedIn && !isCurrentUserPartOfCompany) {
      this.triggerToast({ title: `You need to be part of ${specUser.first_name}'s company to engage with their Spec`, status: 'error' });
      return true;
    } else {
      navigate(`/${specUser.slug}/auth/create-account?redirect=${urls.routeDomain}/${specUser.slug}`);
      return true;
    }
  };

  onCreateSpecExistingUser = () => {
    this.setState({ isSpecCreateLoading: true });
    Api.get({ url: `/api/v1/specs/create_spec_existing_user` }).then(data => {
      this.setState({ isSpecCreateLoading: false });
      window.location.href = window.location.href + '?action=create-spec';
    });
  };

  render() {
    const {
      spec,
      specUser,
      specFields,
      specSections,
      isCurrentUserCreator,
      isEditMode,
      doesHaveCompletedAnswers,
      showMarkAsReviewedModal,
      hasAccess,
      doesSpecExist,
      doesUserExist,
      isSpecCreateLoading,
      showWelcomeModal,
      showIntroWalkthrough,
      showPublishSuccess,
      gettingStartedStepsCompleted,
      isWithinIframe,
      didLoad,
    } = this.state;
    const { isSignedIn, currentUser, hasCompany, currentCompany, params, navigate } = this.props;
    if (!didLoad) return <Loading />;
    if (didLoad && (!hasAccess || !doesSpecExist)) {
      return (
        <UnauthNotice
          isSignedIn={isSignedIn}
          specSlug={params.slug}
          isCurrentUserCreator={isCurrentUserCreator}
          hasAccess={hasAccess}
          doesSpecExist={doesSpecExist}
          doesUserExist={doesUserExist}
          onCreateSpecExistingUser={this.onCreateSpecExistingUser}
          hasCompany={hasCompany}
          currentCompany={currentCompany}
          isSpecCreateLoading={isSpecCreateLoading}
        />
      );
    }
    const currentUserHasReviewed = spec.current_user_has_reviewed;
    return (
      <Box pb={['8px', '8px', '150px']} id="spec-container">
        <ToastContainer />
        <MiscTopLevelComponents
          showWelcomeModal={showWelcomeModal}
          showIntroWalkthrough={showIntroWalkthrough}
          showPublishSuccess={showPublishSuccess}
          onCloseWelcomeModal={() => this.setState({ showIntroWalkthrough: true, showWelcomeModal: false })}
          onClosePublishSuccess={() => this.setState({ showPublishSuccess: false })}
          currentUser={currentUser}
          specUser={specUser}
          onExitIntroSteps={() => {
            this.setState({ showIntroWalkthrough: false });
            window.scrollTo(0, 0);
            // navigate(`/${specUser.slug}/account`);
          }}
        />

        <StyledBody>
          <Header
            specUser={specUser}
            specFields={specFields}
            isCurrentUserCreator={isCurrentUserCreator}
            isEditMode={isEditMode}
            isPublic={spec.is_public}
          />
          <PreSectionsComponents
            spec={spec}
            specUser={specUser}
            isCurrentUserCreator={isCurrentUserCreator}
            isEditMode={isEditMode}
            doesHaveCompletedAnswers={doesHaveCompletedAnswers}
            currentUserHasReviewed={currentUserHasReviewed}
            onToggleMarkAsReviewedModal={this.onToggleMarkAsReviewedModal}
            showGettingStartedChecklist={isCurrentUserCreator && isEditMode && (!spec.is_published || spec.total_views < 6)}
            gettingStartedStepsCompleted={gettingStartedStepsCompleted}
            currentCompanySlug={hasCompany && currentCompany?.slug}
          />
          <SpecSections
            currentSpecSlug={specUser.slug}
            specSections={specSections}
            isEditMode={isEditMode}
            isCurrentUserCreator={isCurrentUserCreator}
            onEditModeSelect={this.onEditModeSelect}
            isPublished={spec.is_published}
            onTogglePublish={this.onTogglePublish}
            isSignedIn={isSignedIn}
            currentUser={currentUser}
            triggerToast={this.triggerToast}
            onCompletedAnswersCallback={this.onCompletedAnswersCallback}
            doesHaveCompletedAnswers={doesHaveCompletedAnswers}
            shouldPreventAuthAccess={this.shouldPreventAuthAccess}
            publishDueDate={!spec.is_published && spec.is_publish_due_date_enabled && spec.publish_due_date}
          />
          {!isSignedIn && <TermsFooter showFixedCreateCTA={!isWithinIframe} currentCompanySlug={hasCompany && currentCompany?.slug} />}
        </StyledBody>
        <MarkAsReviewedModal
          specUser={specUser}
          isOpen={showMarkAsReviewedModal}
          onClose={this.onToggleMarkAsReviewedModal}
          onMarkedAsReviewedCallback={this.onMarkedAsReviewedCallback}
          doesCurrentUserHaveSpec={isSignedIn && currentUser.has_spec}
          currentUserSlug={isSignedIn && currentUser.slug}
          triggerToast={this.triggerToast}
        />
        <Outlet />
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentReducer.currentUser,
  isSignedIn: state.currentReducer.isSignedIn,
  currentCompany: state.currentReducer.currentCompany,
  hasCompany: state.currentReducer.hasCompany,
});

export default withNavigation(withLocation(withParams(connect(mapStateToProps)(Spec))));
