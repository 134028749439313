import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton } from '@chakra-ui/react';
import Avatar from 'components/Avatar';

const TagComponent = ({
  label,
  size = 'lg',
  colorScheme = 'gray',
  variant = 'solid',
  showDelete,
  onDelete,
  avatarSrc,
  avatarName,
  avatarSize = 'xs',
  textTransform,
  ...props
}) => {
  return (
    <Tag size={size} colorScheme={colorScheme} variant={variant} {...props}>
      {avatarSrc && <Avatar src={avatarSrc} name={avatarName} size={avatarSize} mr={2} />}
      <TagLabel textTransform={textTransform}>{label}</TagLabel>
      {showDelete && <TagCloseButton onClick={onDelete} />}
    </Tag>
  );
};

export default TagComponent;
