const ZodiacSign = () => {
  return (
    <svg width="329" height="48" viewBox="0 0 329 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 24V1C32.2171 1 39.81 5.38378 43.9186 12.5L24 24Z" fill="#E0E6ED" stroke="#F6F8FA" stroke-width="2" />
      <path d="M24 24L43.9186 12.5C48.0271 19.6162 48.0271 28.3838 43.9186 35.5L24 24Z" fill="#E0E6ED" stroke="#F6F8FA" stroke-width="2" />
      <path d="M24 24L43.9186 35.5C39.81 42.6162 32.2171 47 24 47V24Z" fill="#E0E6ED" stroke="#F6F8FA" stroke-width="2" />
      <path d="M24.0001 24V47C15.783 47 8.1901 42.6162 4.08154 35.5L24.0001 24Z" fill="#E0E6ED" stroke="#F6F8FA" stroke-width="2" />
      <path
        d="M24 24L4.08142 35.5C-0.0271385 28.3838 -0.0271385 19.6162 4.08142 12.5L24 24Z"
        fill="#E0E6ED"
        stroke="#F6F8FA"
        stroke-width="2"
      />
      <path d="M24.0001 24L4.08154 12.5C8.1901 5.38378 15.783 1 24.0001 1V24Z" fill="#E0E6ED" stroke="#F6F8FA" stroke-width="2" />
      <rect x="60" y="8" width="225" height="8" rx="4" fill="#E0E6ED" />
      <rect x="60" y="20" width="269" height="8" rx="4" fill="#E0E6ED" />
      <rect x="60" y="32" width="185" height="8" rx="4" fill="#E0E6ED" />
    </svg>
  );
};

export default ZodiacSign;
