import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, containerStyle } from 'styles/theme';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { Box } from '@chakra-ui/react';

const StyledFlex = styled(Flex)`
  ${containerStyle};
  margin-bottom: ${theme.spacing.lg};
  @media screen and (max-width: 750px) {
    margin-bottom: ${theme.margin.xs};
    ${({ isColumnMobile }) =>
      isColumnMobile &&
      css`
        flex-direction: column;
        align-items: flex-start;
      `};
  }
`;

const CardHeader = ({
  title,
  subtitle,
  titleFontSize = theme.fontSizes.base,
  titleFontColor = theme.colors.primaryText,
  titleFontFamily = theme.fonts.semiBold,
  subtitleFontSize = 'md',
  subtitleFontColor = theme.colors.paragraphText,
  subtitleFontFamily = theme.fonts.light,
  rightElement,
  isColumnMobile = true,
  ...props
}) => {
  return (
    <StyledFlex {...props} justify="space-between" isColumnMobile={isColumnMobile} gap="8px">
      <Box>
        <Text fontSize={titleFontSize} color={titleFontColor} fontFamily={titleFontFamily}>
          {title}
        </Text>
        <Text fontSize={subtitleFontSize} color={subtitleFontColor} fontFamily={subtitleFontFamily}>
          {subtitle}
        </Text>
      </Box>
      {rightElement}
    </StyledFlex>
  );
};

export default CardHeader;
