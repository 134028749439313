import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, multiLineText } from 'styles/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, HStack, Tooltip, Link } from '@chakra-ui/react';
import { LockedIconFilled, UnlockedIconFilled, SmileIconOutline } from 'assets/icons';
import { Api, replaceStringBetween, linkifyText, replaceAtMentionsWithLinks } from 'helpers/utils';
import moment from 'moment';
import Avatar from 'components/Avatar';
import Text from 'components/Text';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import InputGroup from 'components/InputGroup';
import ReactionsList from '../ReactionsList';
import EmojiPicker from 'components/EmojiPicker';
import MentionsInput from 'components/MentionsInput';
import Flex from 'components/Flex';
import CommentEmptyState from './CommentEmptyState';
import SearchResultTile from 'componentsCustom/SearchResultTile';
import HTMLText from 'components/HTMLText';
import HiddenBackdrop from 'components/HiddenBackdrop';

const StyledComment = styled(HStack)`
  ${({ isNestedComment }) =>
    false &&
    !isNestedComment &&
    css`
      padding: 16px;
      border: 1px solid ${theme.colors.border};
      border-radius: ${theme.radii.md};
    `};
`;

const MentionSearchResultsContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${theme.colors.border};
  z-index: 3;
  border-radius: ${theme.radii.md};
  padding: 4px;
`;

const Comments = ({
  comments,
  isNestedComment,
  parentCommentUser,
  parentCommentIndex,
  sectionIndex,
  onCommentChange,
  onCommentAdd,
  onCommentReaction,
  onCommentPost,
  isSignedIn,
  currentUserSlug,
  currentSpecSlug,
}) => {
  const inputRef = useRef();
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchMentionIndex, setSearchMentionIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [cursorIndex, setCursorIndex] = useState(0);

  const handleCommentChange = (sectionIndex, parentCommentIndex, commentIndex, field, value) => {
    onCommentChange(sectionIndex, parentCommentIndex, commentIndex, field, value);

    if (!isSearchMode && value[value.length - 1] === '@') {
      setIsSearchMode(true);
      setSearchMentionIndex(value.length);
    } else if (isSearchMode && value[value.length - 1] === '@') {
      setIsSearchMode(false);
      setSearchMentionIndex(null);
    }

    const cursorIndex = inputRef.current.selectionEnd;
    if (value[cursorIndex - 1] === '@') {
      setIsSearchMode(true);
      setSearchMentionIndex(cursorIndex);
    }

    if (isSearchMode && value[value.length - 1] === ' ' && cursorIndex > value.length - 2) {
      setIsSearchMode(false);
      setSearchMentionIndex(null);
    }

    if (isSearchMode && value.length < searchMentionIndex) {
      setIsSearchMode(false);
      setSearchMentionIndex(null);
    }

    if (isSearchMode) {
      let endingIndex = value.indexOf(' ', searchMentionIndex);
      if (endingIndex === -1) endingIndex = value.length - 1;
      const searchTerm = value.substring(searchMentionIndex, endingIndex);
      Api.post({
        url: `/api/v1/search/share_users`,
        body: { search_term: searchTerm },
      }).then(data => {
        if (data.success) {
          setSearchTerm(searchTerm);
          if (searchTerm.length < 2) {
            setSearchResults([]);
          } else {
            setSearchResults(data.search_results);
          }
        }
      });
    }
  };

  const onCloseSearch = () => {
    setIsSearchMode(false);
    setSearchMentionIndex(null);
    setSearchResults([]);
    setSearchTerm('');
  };

  const onMentionUserSelect = (mentionUser, sectionIndex, parentCommentIndex, commentIndex, field, value) => {
    // replace all text between searchMentionIndex and either the next space or the end of the string
    let endingIndex = value.indexOf(' ', searchMentionIndex);
    if (endingIndex === -1) endingIndex = value.length - 1;
    const newValue = replaceStringBetween(value, searchMentionIndex, endingIndex + 1, mentionUser.slug);
    onCommentChange(sectionIndex, parentCommentIndex, commentIndex, field, newValue);
    setIsSearchMode(false);
    setSearchMentionIndex(null);
    setSearchResults([]);
    setSearchTerm('');
    inputRef.current.focus();
    // set the cursor position as one space after selected @mention, setSelectionRange(start, end). Account for mid string and end of string
    setTimeout(() => {
      const caretPosition = searchMentionIndex + mentionUser.slug.length + 1;
      inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }, 10);
  };

  return (
    <Stack spacing="13px">
      {comments.map((comment, commentIndex) => {
        if (comment.is_deleted) return null;

        let isPrivate = false;
        if (comment.is_private && comment.user.slug !== currentUserSlug && currentSpecSlug !== currentUserSlug) {
          if (parentCommentUser && parentCommentUser.slug === currentUserSlug) {
            isPrivate = false;
          } else {
            isPrivate = true;
          }
        }

        let privateCommentLabel = `Make visible to @${currentSpecSlug} only`;
        let privateCommentExplainer = `Comment will be visible to @${currentSpecSlug} only`;
        let privateCommentNotice = `Visible to you and @${currentSpecSlug} only`;
        // if (parentCommentUser && currentUserSlug === currentSpecSlug && parentCommentUser.slug !== currentUserSlug) {
        //   privateCommentLabel = `Make visible to @${parentCommentUser.slug} only`;
        //   privateCommentExplainer = `Comment will be visible to @${parentCommentUser.slug} only`;
        //   privateCommentNotice = `Visible to you and @${parentCommentUser.slug} only`;
        // }
        if (parentCommentUser && currentUserSlug === currentSpecSlug && parentCommentUser.slug !== currentUserSlug) {
          privateCommentLabel = `Make visible to @${parentCommentUser.slug} only`;
          privateCommentExplainer = `Comment will be visible to @${parentCommentUser.slug} only`;
          privateCommentNotice = `Visible to you and @${parentCommentUser.slug} only`;
        }

        if (currentUserSlug === currentSpecSlug && comment.user.slug !== currentUserSlug) {
          privateCommentLabel = `Make visible to @${comment.user.slug} only`;
          privateCommentExplainer = `Comment will be visible to @${comment.user.slug} only`;
          privateCommentNotice = `Visible to you and @${comment.user.slug} only`;
        }

        let allowPrivateToggle = true;
        if (
          parentCommentUser &&
          parentCommentUser.slug !== currentUserSlug &&
          currentUserSlug !== currentSpecSlug &&
          parentCommentUser.slug !== currentSpecSlug
        )
          allowPrivateToggle = false;

        if (parentCommentUser && parentCommentUser.slug === currentSpecSlug) allowPrivateToggle = false;

        if (comment.is_draft) {
          return (
            <HStack key={comment.front_id} align="flex-start" spacing="10px" width="100%">
              <Avatar src={comment.user.image} name={comment.user.name} size="sm" />
              <Stack width="100%" spacing="6px" align="flex-end" position="relative">
                <Flex width="100%" direction="column" align="flex-end" gap="8px">
                  <InputGroup
                    key={comment.front_id}
                    type="textarea"
                    value={comment.body}
                    placeholder={isNestedComment ? 'Reply' : 'Comment'}
                    field="body"
                    onChange={(field, value) => handleCommentChange(sectionIndex, parentCommentIndex, commentIndex, field, value)}
                    minRows="1"
                    elementPointerEvents="auto"
                    autoFocus={isSignedIn}
                    zIndex={1}
                    onFocus={e => {
                      inputRef.current = e.currentTarget;
                      var val = e.target.value;
                      if (val) {
                        e.target.value = '';
                        e.target.value = val;
                      }
                    }}
                    inputRightElement={
                      allowPrivateToggle && (
                        <PrivateCommentToggle
                          privateCommentLabel={privateCommentLabel}
                          parentCommentUser={parentCommentUser}
                          isPrivate={comment.is_private}
                          isForcedPrivate={comment.is_forced_private}
                          onToggleClick={() =>
                            !comment.is_forced_private &&
                            onCommentChange(sectionIndex, parentCommentIndex, commentIndex, 'is_private', !comment.is_private)
                          }
                        />
                      )
                    }
                  />
                  {comment.is_private && <ControlButton text={privateCommentExplainer} as="span" color={theme.colors.accent} />}
                </Flex>

                {isSearchMode &&
                  searchResults.length === 0 && (
                    <MentionSearchResultsContainer direction="column">
                      <Text p="16px" textAlign="center" color={theme.colors.accentText}>
                        {searchTerm.length > 2 ? `We can't find anybody with that username` : 'Search for somebody to mention'}
                      </Text>
                    </MentionSearchResultsContainer>
                  )}

                {isSearchMode &&
                  searchResults.length > 0 && (
                    <MentionSearchResultsContainer direction="column">
                      {searchResults.map((result, index) => {
                        const isSelected = false;
                        return (
                          <SearchResultTile
                            key={index}
                            result={result}
                            image={result.image || '-'}
                            title={result.name}
                            subtitle={`@${result.slug}`}
                            onSelectResult={result =>
                              onMentionUserSelect(result, sectionIndex, parentCommentIndex, commentIndex, 'body', comment.body)
                            }
                            isSelected={isSelected}
                          />
                        );
                      })}
                    </MentionSearchResultsContainer>
                  )}
                {isSearchMode && <HiddenBackdrop onClick={onCloseSearch} />}
              </Stack>
              <Button
                colorScheme="brand"
                variant="solid"
                height="32px"
                padding="12px"
                onClick={() => onCommentPost(sectionIndex, parentCommentIndex, commentIndex)}>
                Post
              </Button>
            </HStack>
          );
        }
        return (
          <StyledComment key={comment.front_id} isNestedComment={isNestedComment} align="flex-start" spacing="10px" width="100%">
            <Link as={RouterLink} to={`/${comment.user.slug}`}>
              <Avatar src={comment.user.image} name={comment.user.name} size="sm" />
            </Link>

            <Stack spacing="13px" width="100%">
              {isPrivate ? (
                <CommentEmptyState key={comment.front_id} title="Private comment" icon={<LockedIconFilled />} />
              ) : (
                <Stack spacing="2px">
                  <Text fontFamily={theme.fonts.light} css={multiLineText}>
                    <Link as={RouterLink} to={`/${comment.user.slug}`}>
                      <Text fontFamily={theme.fonts.semiBold} as="span" mr="3px">
                        {comment.user.name}
                      </Text>
                    </Link>
                    <HTMLText html={replaceAtMentionsWithLinks(linkifyText(comment.body))} />
                  </Text>
                  <HStack spacing="8px">
                    <ReactionsList
                      reactions={comment.reactions}
                      onSelectReaction={(sectionIndex, emoji) => onCommentReaction(sectionIndex, parentCommentIndex, commentIndex, emoji)}
                      currentUserSlug={currentUserSlug}
                      sectionIndex={sectionIndex}
                      isCommentReaction
                      isSignedIn={isSignedIn}
                    />

                    <EmojiPicker onEmojiSelect={emoji => onCommentReaction(sectionIndex, parentCommentIndex, commentIndex, emoji)}>
                      <ControlButton text="Reaction" as="div" allowVisualClick />
                    </EmojiPicker>

                    <ControlButton
                      text="Reply"
                      onClick={() =>
                        onCommentAdd(
                          sectionIndex,
                          typeof parentCommentIndex === 'number' ? parentCommentIndex : commentIndex,
                          comment.user.slug,
                          comment.is_private,
                        )
                      }
                    />
                    {(currentUserSlug === comment.user.slug || currentUserSlug === currentSpecSlug) && (
                      <ControlButton
                        text="Delete"
                        onClick={() => onCommentChange(sectionIndex, parentCommentIndex, commentIndex, 'is_deleted', true)}
                      />
                    )}
                    {comment.created_at && <ControlButton text={moment(comment.created_at).fromNow()} as="span" />}
                    {comment.is_private && <ControlButton text={privateCommentNotice} as="span" color={theme.colors.accent} />}
                  </HStack>
                </Stack>
              )}

              {comment.comments.length > 0 && (
                <Comments
                  comments={comment.comments}
                  isNestedComment
                  parentCommentUser={comment.user}
                  parentCommentIndex={typeof parentCommentIndex === 'number' ? parentCommentIndex : commentIndex}
                  sectionIndex={sectionIndex}
                  onCommentChange={onCommentChange}
                  onCommentAdd={onCommentAdd}
                  onCommentReaction={onCommentReaction}
                  onCommentPost={onCommentPost}
                  isSignedIn={isSignedIn}
                  currentUserSlug={currentUserSlug}
                  currentSpecSlug={currentSpecSlug}
                />
              )}
            </Stack>
          </StyledComment>
        );
      })}
    </Stack>
  );
};

const PrivateCommentToggle = ({ privateCommentLabel, isPrivate, isForcedPrivate, onToggleClick }) => {
  return (
    <React.Fragment>
      {isPrivate ? (
        <Tooltip label="Make visible to everyone" placement="top" openDelay={400} hasArrow closeOnClick={false}>
          <button onClick={onToggleClick} disabled={isForcedPrivate} className="disabled-button">
            <LockedIconFilled color={theme.colors.accentText} />
          </button>
        </Tooltip>
      ) : (
        <Tooltip label={privateCommentLabel} placement="top" openDelay={400} hasArrow closeOnClick={false}>
          <button onClick={onToggleClick}>
            <UnlockedIconFilled color={theme.colors.accentText} />
          </button>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

const ControlButton = ({ text, onClick, as = 'button', allowVisualClick = false, color = theme.colors.lightAccentText }) => {
  return (
    <Text
      fontSize={theme.fontSizes.sm}
      as={as}
      color={color}
      onClick={onClick}
      _hover={
        (as === 'button' || allowVisualClick) && {
          textDecoration: 'underline',
          color: theme.colors.paragraphText,
        }
      }>
      {text}
    </Text>
  );
};

export default Comments;
