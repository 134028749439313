import { Api } from 'helpers/utils';
const GET_CURRENT_BEGIN = 'GET_CURRENT_BEGIN';
const GET_CURRENT_SUCCESS = 'GET_CURRENT_SUCCESS';
const GET_CURRENT_FAILURE = 'GET_CURRENT_FAILURE';

export function getCurrent(shouldDispatchBegin = true) {
  return dispatch => {
    if (shouldDispatchBegin) dispatch(getCurrentBegin());

    const pathname = window.location.pathname;
    let companySlug, specSlug;
    if (pathname.includes('/org')) {
      companySlug = pathname.split('/')[2];
    } else if (!pathname.includes('/auth')) {
      specSlug = pathname.split('/')[1];
    }

    return Api.post({
      url: `/api/v1/users/get_current`,
      body: { company_slug: companySlug, spec_slug: specSlug },
    }).then(data => {
      console.log('currentData >>>>', data);
      dispatch(getCurrentSuccess(data.current_user, data.is_signed_in, data.current_company, data.has_company));
      return data;
    });

    // return fetch(`${fetchURL}/api/v1/users/get_current_user`, {
    //   headers: { Authorization: Cookies.get('id_token') },
    // })
    //   .then(handleErrors)
    //   .then(res => res.json())
    //   .then(currentUser => {
    //     dispatch(getCurrentSuccess(currentUser, isSignedIn));
    //     return currentUser;
    //   })
    //   .catch(error => dispatch(getCurrentFailure(error)));
  };
}

const handleErrors = response => {
  if (!response.ok) throw Error(response.statusText);
  return response;
};

export const getCurrentBegin = () => ({
  type: GET_CURRENT_BEGIN,
});

export const getCurrentSuccess = (currentUser, isSignedIn, currentCompany, hasCompany) => ({
  type: GET_CURRENT_SUCCESS,
  payload: { currentUser, isSignedIn, currentCompany, hasCompany },
});

export const getCurrentFailure = error => ({
  type: GET_CURRENT_FAILURE,
  payload: { error },
});

const DEFAULT_STATE = {
  currentUser: {},
  isSignedIn: false,
  currentCompany: {},
  hasCompany: false,
  didCurrentLoad: false,
};

export const currentReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_BEGIN:
      return Object.assign({}, state, {
        currentUser: {},
        isSignedIn: false,
        currentCompany: {},
        hasCompany: false,
        didCurrentLoad: false,
      });
    case GET_CURRENT_SUCCESS:
      return Object.assign({}, state, {
        currentUser: action.payload.currentUser,
        isSignedIn: action.payload.isSignedIn,
        currentCompany: action.payload.currentCompany,
        hasCompany: action.payload.hasCompany,
        didCurrentLoad: true,
      });
    case GET_CURRENT_FAILURE:
      return Object.assign({}, state, {
        currentUser: {},
        isSignedIn: false,
        currentCompany: {},
        hasCompany: false,
        didCurrentLoad: true,
      });
    default:
      return state;
  }
};
