import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputLeftElement,
  InputRightElement,
  Textarea,
  Editable,
  EditableInput,
  EditablePreview,
  Select,
} from '@chakra-ui/react';
import { Select as MultiSelect, chakraComponents } from 'chakra-react-select';
import autosize from 'autosize';
import Text from 'components/Text';
import InfoTooltip from 'componentsCustom/InfoTooltip';

const CUSTOM_COMPONENTS = {
  DropdownIndicator: props => (
    <chakraComponents.DropdownIndicator {...props}>
      <RiArrowDropDownLine />
    </chakraComponents.DropdownIndicator>
  ),
};

const Styled = styled.div`
  width: ${props => props.width};
  .input-element {
    width: 100%;
  }
  ${({ variant }) =>
    variant === 'filled' &&
    css`
      .chakra-input__left-addon {
        background: white !important;
        border-color: var(--chakra-colors-gray-100) !important;
        border: 2px solid;
      }
    `};
`;

const StyledFormLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
`;

const StyledEditable = styled(Editable)`
  font-size: 14px;
  margin: 0px 12px 4px 0px;
  .chakra-editable__preview,
  .chakra-editable__input {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
  }
`;

const InputGroupComponent = ({
  type,
  value,
  label,
  sublabel,
  labelTooltip,
  errorLabel,
  placeholder,
  field,
  variant = 'filled',
  inputLeftAddon,
  inputRightAddon,
  inputLeftElement,
  inputRightElement,
  labelClassName,
  onChange,
  width = '100%',
  elementPointerEvents = 'none',
  isLabelEditable,
  editableLabelPlaceholder = 'Type here...',
  onLabelChange,
  customComponent,
  selectOptions = [],
  readOnly = false,
  autoFocus = false,
  inputRightElementWidth,
  minHeight,
  minRows = '4',
  resize = 'none',
  inputElementHeight,
  ref,
  ...props
}) => {
  const inputRef = useRef();
  useEffect(() => {
    if (type === 'textarea') autosize(inputRef.current);
  }, []);

  let Element;
  if (type === 'input' || type === 'date') Element = Input;
  if (type === 'textarea') Element = Textarea;
  if (type === 'select') Element = Select;
  if (type === 'multi-select') Element = MultiSelect;

  const renderedSelectOptions =
    type === 'select'
      ? selectOptions.map(selectOption => {
          return (
            <option key={selectOption.label} value={selectOption.value} disabled={selectOption.disabled}>
              {selectOption.label}
            </option>
          );
        })
      : null;
  return (
    <Styled width={width} variant={variant}>
      {isLabelEditable ? (
        <StyledEditable defaultValue={label} placeholder={editableLabelPlaceholder} startWithEditView className={labelClassName}>
          <EditablePreview>
            <StyledFormLabel htmlFor={field} className={labelClassName}>
              {label}
            </StyledFormLabel>
          </EditablePreview>
          <EditableInput value={label} onChange={e => onLabelChange(field, e.target.value)} />
        </StyledEditable>
      ) : (
        <>
          {label && (
            <StyledFormLabel htmlFor={field} className={labelClassName}>
              {label}
              {labelTooltip && <InfoTooltip label={labelTooltip} />}
            </StyledFormLabel>
          )}
        </>
      )}
      {sublabel && (
        <Text fontSize={theme.fontSizes.sm} fontFamily={theme.fonts.light} m="-5px 0 5px 0">
          {sublabel}
        </Text>
      )}
      {customComponent ? (
        <>{customComponent}</>
      ) : (
        <InputGroup>
          {inputLeftAddon && <InputLeftAddon children={inputLeftAddon} />}
          {inputLeftElement && (
            <InputLeftElement pointerEvents={elementPointerEvents} children={inputLeftElement} height={inputElementHeight} />
          )}
          <Element
            value={value}
            onChange={e => onChange(field, type === 'multi-select' ? e : e.target.value)}
            id={field}
            placeholder={placeholder}
            variant={variant}
            focusBorderColor={theme.colors.lightAccentText}
            icon={<RiArrowDropDownLine />}
            className="input-element"
            id="input-element"
            options={selectOptions}
            menuPortalTarget={document.body}
            classNamePrefix="chakra-react-select"
            isMulti
            resize={resize}
            readOnly={readOnly}
            autoFocus={autoFocus}
            components={CUSTOM_COMPONENTS}
            minHeight={minHeight}
            rows={minRows}
            ref={ref || inputRef}
            autoComplete="off"
            type={type}
            {...props}>
            {renderedSelectOptions}
          </Element>
          {inputRightElement && (
            <InputRightElement
              pointerEvents={elementPointerEvents}
              children={inputRightElement}
              width={inputRightElementWidth}
              height={inputElementHeight}
            />
          )}
          {inputRightAddon && <InputRightAddon children={inputRightAddon} />}
        </InputGroup>
      )}
      {errorLabel && (
        <Text fontSize={theme.fontSizes.sm} fontFamily={theme.fonts.light} mt="4px" color={theme.colors.redText}>
          {errorLabel}
        </Text>
      )}
    </Styled>
  );
};

export default InputGroupComponent;
