import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { SpecSearchIllustration } from 'assets/illustrations/all';
import Table from 'components/Table';
import UserRow from './UserRow';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';

const LoadingWrapper = styled.div`
  position: relative;
  height: 221px;
  width: 100%;
`;

const Results = ({
  users,
  columns,
  onSetSearchQuery,
  navigate,
  isInitialLoadComplete,
  areResultsSelectable,
  selectedUsers,
  onSelectUser,
  showEmailSubtitle,
  showAdminBadge,
  isFeatureGated,
}) => {
  return (
    <React.Fragment>
      <Table>
        <Table.Head>
          <Table.Row>
            {isInitialLoadComplete &&
              columns.map(column => {
                return <Table.HeadTitle key={column}>{column}</Table.HeadTitle>;
              })}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {users.map((user, userIndex) => {
            return (
              <UserRow
                key={user.slug}
                user={user}
                columns={columns}
                onSetSearchQuery={onSetSearchQuery}
                navigate={navigate}
                areResultsSelectable={areResultsSelectable}
                selectedUsers={selectedUsers}
                onSelectUser={onSelectUser}
                showEmailSubtitle={showEmailSubtitle}
                showAdminBadge={showAdminBadge}
                shouldBlurResult={isFeatureGated && userIndex > 5}
              />
            );
          })}
        </Table.Body>
      </Table>
      {!isInitialLoadComplete && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {isInitialLoadComplete &&
        users.length === 0 && (
          <EmptyState
            illustration={<SpecSearchIllustration />}
            title="We couldn't find anyone"
            subtitle="Try different search terms or filters"
            bg="white"
            border="none"
            p={theme.padding.xl}
          />
        )}
    </React.Fragment>
  );
};

export default Results;
