import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Box, Link } from '@chakra-ui/react';
import Button from 'components/Button';

// <Table>
//   <Table.Head>
//     <Table.Row>
//       <Table.HeadTitle>Hi</Table.HeadTitle>
//       <Table.HeadTitle>Hi</Table.HeadTitle>
//       <Table.HeadTitle>Hi</Table.HeadTitle>
//     </Table.Row>
//   </Table.Head>
//   <Table.Body>
//     <Table.Row>
//       <Table.BodyResult>Hi</Table.BodyResult>
//       <Table.BodyResult>Hi</Table.BodyResult>
//       <Table.BodyResult>Hi</Table.BodyResult>
//     </Table.Row>
//   </Table.Body>
// </Table>

// https://chakra-ui.com/docs/components/table/usage
const TableComponent = ({ variant = 'simple', children, ...props }) => {
  return (
    <TableContainer>
      <Table variant={variant} {...props}>
        {children}
      </Table>
    </TableContainer>
  );
};

TableComponent.Head = ({ children, ...props }) => {
  return <Thead {...props}>{children}</Thead>;
};

TableComponent.Row = ({ children, linkTo, position = 'relative', ...props }) => {
  return (
    <Tr position={position} {...props}>
      {children}
    </Tr>
  );
};

TableComponent.Body = ({ children, ...props }) => {
  return <Tbody {...props}>{children}</Tbody>;
};

TableComponent.HeadTitle = ({
  padding = '12px 22px',
  color = theme.colors.paragraphText,
  fontFamily = theme.fonts.bold,
  children,
  ...props
}) => {
  return (
    <Th padding={padding} color={color} fontFamily={fontFamily} borderColor={theme.colors.border} {...props}>
      {children}
    </Th>
  );
};

TableComponent.BodyResult = ({
  padding = '16px 22px',
  fontSize = theme.fontSizes.md,
  fontFamily = theme.fonts.light,
  color = theme.colors.paragraphText,
  children,
  onClick,
  linkTo,
  ...props
}) => {
  return (
    <Td
      padding="0px"
      fontSize={fontSize}
      fontFamily={fontFamily}
      color={color}
      borderColor={theme.colors.border}
      {...props}
      maxWidth="250px"
      minWidth="130px">
      {onClick ? (
        <Button
          padding={padding}
          onClick={onClick}
          variant="ghost"
          height="auto"
          maxHeight="none"
          justifyContent="flex-start"
          whiteSpace="normal"
          textAlign="left">
          {children}
        </Button>
      ) : linkTo ? (
        <Box padding={padding}>
          <Link as={RouterLink} to={linkTo}>
            {children}
          </Link>
        </Box>
      ) : (
        <Box padding={padding} whiteSpace="normal">
          {children}
        </Box>
      )}
    </Td>
  );
};

export default TableComponent;
