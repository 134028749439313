const Video = () => {
  return (
    <svg width="80" height="50" viewBox="0 0 80 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="50" rx="6" fill="#E0E6ED" />
      <rect x="25" y="10" width="30" height="30" rx="15" fill="#F6F8FA" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.216 19.7522C38.555 19.7687 38.8832 19.8769 39.1656 20.0653L44.7906 23.8153C45.0474 23.9865 45.258 24.2185 45.4036 24.4906C45.5492 24.7628 45.6254 25.0666 45.6254 25.3753C45.6254 25.6839 45.5492 25.9878 45.4036 26.2599C45.258 26.5321 45.0474 26.764 44.7906 26.9353L39.1656 30.6853C38.8832 30.8737 38.555 30.9819 38.216 30.9984C37.8769 31.0148 37.5397 30.9389 37.2404 30.7788C36.9411 30.6187 36.6909 30.3803 36.5165 30.089C36.3421 29.7978 36.2499 29.4647 36.25 29.1253V21.6253C36.2499 21.2858 36.3421 20.9527 36.5165 20.6615C36.6909 20.3703 36.9411 20.1319 37.2404 19.9718C37.5397 19.8116 37.8769 19.7357 38.216 19.7522Z"
        fill="#E0E6ED"
      />
    </svg>
  );
};

export default Video;
