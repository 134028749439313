import { urls } from 'data/constants';
import moment from 'moment';
import DOMPurify from 'dompurify';
import Cookies from 'js-cookie';
import isEqualLodash from 'lodash.isequal';
import {
  RiTwitterFill,
  RiLinkedinBoxFill,
  RiSlackFill,
  RiMapPin2Fill,
  RiGlobeFill,
  RiCalendarFill,
  RiCakeFill,
  RiHome4Fill,
  RiGlobalFill,
  RiInformationFill,
  RiMailFill,
  RiAccountBoxFill,
  RiDribbbleFill,
  RiBriefcaseFill,
  RiUserVoiceFill,
} from 'react-icons/ri';
import queryString from 'query-string';

export const isEqual = isEqualLodash;

// const parsed = returnQuery();
export const returnQuery = () => {
  return queryString.parse(window.location.search);
};

export const setWindowLocation = (path, includeBaseURL = true) => {
  if (includeBaseURL) {
    window.location.href = `${urls.routeDomain}${path}`;
  } else {
    window.location.href = path;
  }
};

export const setCookie = (key, value) => {
  Cookies.set(key, value, { expires: 365, domain: urls.cookieDomain });
};

export const removeCookie = key => {
  Cookies.remove(key, { expires: 365, domain: urls.cookieDomain });
};

// moment.locale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s',
//     s: '1s',
//     ss: '%ss',
//     m: '1m',
//     mm: '%dm',
//     h: '1h',
//     hh: '%dh',
//     d: '1d',
//     dd: '%dd',
//     M: '1mo',
//     MM: '%dM',
//     y: '1Y',
//     yy: '%dY',
//   },
// });

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const cleanHTML = html => {
  return DOMPurify.sanitize(html);
};

export const toURL = url => {
  if (url.includes('http://')) return url.replace('http://', 'https://');
  if (!url.includes('http://') && !url.includes('https://')) return 'https://' + url;
  return url;
};

export const socialToURL = (social, url) => {
  url = url.replace(/\/$/, '');
  switch (social) {
    case 'instagram':
      return 'https://www.instagram.com/' + url.split('/').pop();
      break;
    case 'twitter':
      return 'https://www.twitter.com/' + url.split('/').pop();
      break;
    default:
      if (!url.includes('http://') && !url.includes('https://')) return 'https://' + url;
  }
  return url;
};

export const randomID = () => {
  return Math.floor(Math.random() * 100000);
};

export const getNoDaysInMonth = monthIndex => {
  return new Date(2022, monthIndex + 1, 0).getDate();
};

export const arrayMove = (array, oldIndex, newIndex) => {
  let numberOfDeletedElm = 1;

  const elm = array.splice(oldIndex, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  array.splice(newIndex, numberOfDeletedElm, elm);
  return array;
};

export const copyToClipboard = (string, elementID) => {
  // Sometimes the elementID needs to be passed incase you're trying to copy to clipboard from inside a modal or portal
  const el = document.createElement('textarea');
  el.value = string;
  if (elementID) {
    document.getElementById(elementID).appendChild(el);
  } else {
    document.body.appendChild(el);
  }
  el.select();
  document.execCommand('copy');
  if (elementID) {
    document.getElementById(elementID).removeChild(el);
  } else {
    document.body.removeChild(el);
  }
};

export const replaceStringBetween = (string, startIndex, endIndex, insertion) =>
  string.substring(0, startIndex) + insertion + ' ' + string.substring(endIndex);

export const linkifyText = (text, className = 'brand-link', target = '_blank') => {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return text.replace(urlRegex, function(url, b, c) {
    var url2 = c === 'www.' ? 'http://' + url : url;
    return '<a href="' + url2 + `" target="${target}" class=${className}>` + url + '</a>';
  });
};

export const replaceAtMentionsWithLinks = (slug, className = 'brand-link') => {
  return slug.replace(/@([a-z\d_]+)/gi, `<a href="https://spec.me/$1" class=${className}>@$1</a>`);
};

export const getIcon = (key, returnPlaceholderIfNone = true) => {
  switch (key) {
    case 'pronouns':
      return <RiAccountBoxFill />;
      break;
    case 'languages':
      return <RiGlobeFill />;
      break;
    case 'location':
      return <RiMapPin2Fill />;
      break;
    case 'birthday':
      return <RiCakeFill />;
      break;
    case 'joined_company_date':
      return <RiCalendarFill />;
      break;
    case 'email':
      return <RiMailFill />;
      break;
    case 'twitter':
      return <RiTwitterFill />;
      break;
    case 'linkedin':
      return <RiLinkedinBoxFill />;
      break;
    case 'slack':
      return <RiSlackFill />;
      break;
    case 'hometown':
      return <RiHome4Fill />;
      break;
    case 'website':
      return <RiGlobalFill />;
      break;
    case 'dribbble':
      return <RiDribbbleFill />;
      break;
    case 'job_title':
      return <RiBriefcaseFill />;
      break;
    case 'pronunciation':
      return <RiUserVoiceFill />;
      break;
    default:
      if (returnPlaceholderIfNone) {
        return <RiInformationFill />;
      } else {
        return null;
      }
  }
};

// -------- FETCH FUNCTIONS START --------

// Api.post({
//   url: `/api/v1/endpoint/method`,
//   body: { foo: bar },
// }).then(data => {
//   console.log(data)
// });

const apiDomain = window.location.hostname === 'localhost' ? `http://localhost:3000` : `https://api.spec.me`;

export const Api = {
  get,
  post,
  put,
  delete: _delete,
};

function get({ url }) {
  const companySlug = returnCompanySlug();
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: Cookies.get('login_token') },
  };
  return fetch(`${apiDomain}${url}?company_slug=${companySlug}`, requestOptions).then(handleResponse);
}

function post({ url, body }) {
  const companySlug = returnCompanySlug();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: Cookies.get('login_token') },
    body: JSON.stringify(body),
  };
  return fetch(`${apiDomain}${url}?company_slug=${companySlug}`, requestOptions).then(handleResponse);
}

function put({ url, body }) {
  const companySlug = returnCompanySlug();
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: Cookies.get('login_token') },
    body: JSON.stringify(body),
  };
  return fetch(`${apiDomain}${url}?company_slug=${companySlug}`, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete({ url }) {
  const companySlug = returnCompanySlug();
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: Cookies.get('login_token') },
  };
  return fetch(`${apiDomain}${url}?company_slug=${companySlug}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

const returnCompanySlug = () => {
  const pathsArray = window.location.pathname.split('/');
  const orgIndex = pathsArray.findIndex(path => path === 'org');
  if (orgIndex !== -1) {
    const companySlug = pathsArray[orgIndex + 1];
    if (companySlug) return companySlug;
  }
  return '';
};

// -------- FETCH FUNCTIONS END --------
