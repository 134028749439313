import React from 'react';
import { theme } from 'styles/theme';
import { Box } from '@chakra-ui/react';

const BoxComponent = ({ bg = theme.colors.grayFill, borderRadius = 'lg', ...props }) => {
  return (
    <Box {...props} bg={bg} borderRadius={borderRadius}>
      {props.children}
    </Box>
  );
};

export default BoxComponent;
