import React from 'react';
import { theme } from 'styles/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const Styled = styled.div`
  .chakra-tabs__tablist {
    border-bottom: 1px solid;
    border-color: ${theme.colors.border};
    padding: 0 ${theme.padding.lg};
  }
  .chakra-tabs__tab {
    padding: 16px 0 !important;
    margin-right: ${theme.spacing.md};
  }
  .chakra-tabs__tablist {
    .chakra-tabs__tab {
      margin-bottom: -1px;
    }
  }
  ${({ isSticky }) =>
    isSticky &&
    css`
      .chakra-tabs__tablist {
        position: sticky;
        top: 0;
        z-index: 3;
        background: white;
        border-radius: ${theme.radii.lg} ${theme.radii.lg} 0 0;
      }
    `};
`;

const StyledTabList = styled(TabList)`
  ${({ isStandaloneTabList }) =>
    isStandaloneTabList &&
    css`
      background: white;
      border: 1px solid ${theme.colors.border};
      border-radius: ${theme.radii.lg};
    `};
  ${({ noBorderRadiusMobile }) =>
    noBorderRadiusMobile &&
    css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        border-radius: 0px;
      }
    `};
`;

// https://chakra-ui.com/docs/components/disclosure/tabs
const TabsComponent = ({
  tabs,
  selectedIndex,
  onSelectIndex,
  variant = 'line',
  colorScheme = 'gray',
  size = 'md',
  isFitted = false,
  isSticky,
  isStandaloneTabList = false,
  noBorderRadiusMobile,
  ...props
}) => {
  return (
    <Styled isSticky={isSticky}>
      <Tabs
        {...props}
        onChange={index => onSelectIndex && onSelectIndex(index)}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        isFitted={isFitted}
        index={selectedIndex}>
        {tabs &&
          tabs.length > 0 && (
            <StyledTabList isStandaloneTabList={isStandaloneTabList} noBorderRadiusMobile={noBorderRadiusMobile}>
              {tabs.map((tab, index) => {
                return <Tab key={index}>{tab}</Tab>;
              })}
            </StyledTabList>
          )}
        {props.children}
      </Tabs>
    </Styled>
  );
};

TabsComponent.TabPanels = ({ children, ...props }) => {
  return <TabPanels {...props}>{children}</TabPanels>;
};

TabsComponent.TabPanel = ({ padding = '0px', children, ...props }) => {
  return (
    <TabPanel padding={padding} {...props}>
      {children}
    </TabPanel>
  );
};

export default TabsComponent;
