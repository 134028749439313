import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import ShareLink from 'containers/ShareLink';

const ShareCurrentURLModal = ({ isOpen, onClose, currentUserFirstName }) => {
  const initialRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Modal isOpen onClose={() => navigate(`/${params.slug}`)} initialFocusRef={initialRef} constrainWidthMobile>
      <Modal.Header title="Share" showCloseButton={false} />
      <Modal.Body>
        <ShareLink
          shareDisplayURL={`https://spec.me/${params.slug}`}
          shareURL={`https://spec.me/${params.slug}`}
          entitySlug={params.slug}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ShareCurrentURLModal;
