import Freeform from 'assets/sectionIllustrations/Freeform';
import Media from 'assets/sectionIllustrations/Media';
import Questions from 'assets/sectionIllustrations/Questions';
import BigFive from 'assets/sectionIllustrations/BigFive';
import Images from 'assets/sectionIllustrations/Images';
import MyersBriggs from 'assets/sectionIllustrations/MyersBriggs';
import Spectrum from 'assets/sectionIllustrations/Spectrum';
import Strengthsfinder from 'assets/sectionIllustrations/Strengthsfinder';
import Topics from 'assets/sectionIllustrations/Topics';
import Video from 'assets/sectionIllustrations/Video';
import WorkHistory from 'assets/sectionIllustrations/WorkHistory';
import ZodiacSign from 'assets/sectionIllustrations/ZodiacSign';

export const FreeformIllustration = Freeform;
export const MediaIllustration = Media;
export const QuestionsIllustration = Questions;
export const BigFiveIllustration = BigFive;
export const ImagesIllustration = Images;
export const MyersBriggsIllustration = MyersBriggs;
export const SpectrumIllustration = Spectrum;
export const StrengthsfinderIllustration = Strengthsfinder;
export const TopicsIllustration = Topics;
export const VideoIllustration = Video;
export const WorkHistoryIllustration = WorkHistory;
export const ZodiacSignIllustration = ZodiacSign;
