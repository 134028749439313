import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Text from 'components/Text';
import Flex from 'components/Flex';
import IconButton from 'components/IconButton';

const CommentEmptyState = ({ icon, title, ...props }) => {
  return (
    <Flex bg="white" border={`1px solid ${theme.colors.border}`} p="16px" {...props}>
      <Text
        fontSize={theme.fontSizes.sm}
        fontFamily={theme.fonts.medium}
        align="center"
        as="div"
        mr="6px"
        height="13px"
        color={theme.colors.accentText}>
        {icon}
      </Text>
      <Text fontSize={theme.fontSizes.sm} align="center" as="div" lineHeight={1} color={theme.colors.accentText}>
        {title}
      </Text>
    </Flex>
  );
};

export default CommentEmptyState;
