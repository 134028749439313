import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, setWindowLocation, returnQuery } from 'helpers/utils';
import { slackRedirectURI } from 'data/constants';
import Loading from 'components/Loading';

class SlackRedirect extends React.Component {
  state = {};

  componentDidMount() {
    this.createSlackWorkspace();
  }

  createSlackWorkspace() {
    const parsed = returnQuery();
    Api.post({
      url: `/api/v1/admin/slack_workspaces/create_slack_workspace`,
      body: { access_code: parsed.code, redirect_uri: slackRedirectURI },
    }).then(data => {
      if (data.success && !parsed.error) {
        setWindowLocation(`/org/${data.company_slug}/admin/settings/slack`);
      } else {
        this.setState({ error: data.error || parsed.error });
      }
    });
  }

  render() {
    const { error } = this.state;
    return <Loading />;
  }
}

export default SlackRedirect;
