import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import { ButtonGroup, HStack, Tooltip } from '@chakra-ui/react';
import { EditIconFilled, EyeIconFilled, SettingsIconFilled } from 'assets/icons';
import moment from 'moment';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import InfoGroup from 'components/InfoGroup';
import InfoTooltip from 'componentsCustom/InfoTooltip';
import Flex from 'components/Flex';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'L LT',
};

const Styled = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
  border-top: 1px solid ${theme.colors.border};
  z-index: 50;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${theme.layout.columnWidth};
  .first {
    border-right: 0px;
  }
  .second {
    border-left: 0px;
  }
  .first:hover,
  .second:hover {
    color: ${theme.colors.primaryText};
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: ${theme.colors.border};
`;

const CreatorNavigationFooter = ({
  isEditMode,
  isPublished,
  onTogglePublish,
  onEditModeSelect,
  hasUnsavedChanges,
  onToggleUserAccountModal,
  publishDueDate,
}) => {
  const isPublishDueToday = !isPublished && publishDueDate && moment(publishDueDate).isSame(new Date(), 'day');
  const showPublishDueDate = publishDueDate && !isPublished;
  return (
    <Styled>
      <Inner>
        <Flex gap="12px">
          <StyledButtonGroup isAttached id="edit-mode-toggle-buttons">
            <Button
              variant="outline"
              className="first"
              color={!isEditMode ? theme.colors.primaryText : theme.colors.primaryText + '55'}
              leftIcon={<EyeIconFilled />}
              onClick={() => onEditModeSelect(false)}>
              Preview
            </Button>
            <Divider />
            <Button
              variant="outline"
              className="second"
              color={isEditMode ? theme.colors.primaryText : theme.colors.primaryText + '55'}
              leftIcon={<EditIconFilled />}
              onClick={() => onEditModeSelect(true)}>
              Edit
            </Button>
          </StyledButtonGroup>
          {showPublishDueDate &&
            !hasUnsavedChanges &&
            isEditMode && (
              <Text fontFamily={theme.fonts.light} color={theme.colors.lightAccentText} as="i" className="desktop" textAlign="left">
                Changes saved
              </Text>
            )}
        </Flex>
        <HStack spacing="14px">
          {!showPublishDueDate &&
            !hasUnsavedChanges &&
            isEditMode && (
              <Text fontFamily={theme.fonts.light} color={theme.colors.lightAccentText} as="i" className="desktop">
                Changes saved
              </Text>
            )}

          {publishDueDate && (
            <InfoGroup
              title="PUBLISH DUE DATE"
              subtitle={
                isPublishDueToday ? (
                  'Today'
                ) : (
                  <Flex float="right">
                    <span className="capitalize-first-letter">{moment(publishDueDate).fromNow()}</span>
                    <InfoTooltip
                      label={`An admin set the due date as ${moment(publishDueDate).format('dddd, MMMM Do')}`}
                      placement="top"
                      hasArrow
                      textAlign="center"
                      maxWidth="215px"
                    />
                  </Flex>
                )
              }
              textAlign="right"
              titleFontSize={theme.fontSizes.xs}
              titleFontColor={theme.colors.lightAccentText}
              subtitleFontColor={(isPublishDueToday || moment(publishDueDate).isBefore(new Date())) && theme.colors.redText}
              className="desktop"
            />
          )}

          {isPublished ? (
            <Link to="account/settings">
              <IconButton variant="outline" size="md" as="div" icon={<SettingsIconFilled />} />
            </Link>
          ) : (
            <Button colorScheme="brand" variant="solid" onClick={onTogglePublish} id="publish-button">
              Publish
            </Button>
          )}
        </HStack>
      </Inner>
    </Styled>
  );
};

export default CreatorNavigationFooter;
