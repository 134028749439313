import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';
import Flex from 'components/Flex';
import ImageUpload from 'containers/ImageUpload';

const CompanyDetails = ({ newCompany, onNewCompanyChange, newCompanyDomain, onPreviousStepClick, isSubmitLoading, isCompanyEmail }) => {
  const title = 'Add Company Details';
  let subtitle = `We couldn't find a team with the ${newCompanyDomain} domain, let's create one!`;
  if (!isCompanyEmail) subtitle = `It looks like you're using your personal email, so we couldn't find your team. Let's create one!`;
  return (
    <OnboardingFlow.Inner>
      <OnboardingFlow.Header title={title} subtitle={subtitle} />

      <OnboardingFlow.Body>
        <InputGroup
          type="input"
          value={newCompany.name}
          label="Company Name"
          placeholder="Acme Corp"
          field="name"
          onChange={onNewCompanyChange}
          width="100%"
          autoFocus
        />

        <InputGroup
          label="Company Logo"
          field="logo"
          customComponent={
            <ImageUpload
              value={newCompany.logo}
              onUpload={onNewCompanyChange}
              field="logo"
              uploadPreset="company_logo"
              folderName="company_logos"
              uploadBoxHeight="50px"
              uploadBoxWidth="50px"
              uploadBoxBorderRadius={theme.radii.md}
              buttonVariant="link"
              buttonText="Choose File"
            />
          }
        />
      </OnboardingFlow.Body>

      <OnboardingFlow.Footer>
        <Button variant="solid" colorScheme="brand" isLoading={isSubmitLoading} isDisabled={isSubmitLoading} type="submit">
          Complete
        </Button>
      </OnboardingFlow.Footer>

      <OnboardingFlow.PostText onClick={onPreviousStepClick}>
        <div>Go to previous step</div>
      </OnboardingFlow.PostText>
    </OnboardingFlow.Inner>
  );
};

export default CompanyDetails;
