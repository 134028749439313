import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CloseIcon } from 'assets/icons';
import { Box } from '@chakra-ui/react';
import { Api } from 'helpers/utils';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';
import Flex from 'components/Flex';
import SearchResultTile from 'componentsCustom/SearchResultTile';

// 73px will put it 12px below input, if label is present
const ResultsContainer = styled(Flex)`
  position: absolute;
  top: 73px;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radii.md};
  z-index: 1;
`;

const CloseOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
`;

// TODO: This can be consolidated with ApiSearchModal, repeats a lot of logic
class ApiSearchInput extends React.Component {
  state = {
    searchTerm: '',
    searchResults: [],
    showResults: false,
  };

  componentDidMount() {
    const { value = '' } = this.props;
    this.setState({ searchTerm: value });
  }

  onSearchTermChange = searchTerm => {
    this.setState({ searchTerm: searchTerm, showResults: true });
    const { apiToSearch, onChange } = this.props;
    onChange(searchTerm);
    if (searchTerm.length < 2) {
      this.setState({ searchResults: [] });
      return;
    }
    Api.post({
      url: `/api/v1/search/external_api`,
      body: {
        search_term: searchTerm,
        api_to_search: apiToSearch,
      },
    })
      .then(data => {
        if (!data.errors) {
          let searchResults = [];
          if (apiToSearch === 'cities') {
            searchResults = data.predictions.map(prediction => {
              return {
                title: prediction.description.replace(', USA', ''),
                extraData: prediction.place_id,
              };
            });
          }
          this.setState({ searchResults: searchResults });
        }
      })
      .catch(error => console.error('There was an error!', error));
  };

  onClearSearch = () => {
    this.setState({ searchTerm: '', searchResults: [], showResults: false });
  };

  onSelectResult = value => {
    this.props.onChange(value);
    this.setState({ searchTerm: value, showResults: false });
  };

  render() {
    const { searchTerm, searchResults, showResults } = this.state;
    const { inputProps } = this.props;
    return (
      <>
        <Flex direction="column" gap="8px" position="relative" zIndex={1}>
          <InputGroup
            type="input"
            value={searchTerm}
            onChange={(field, value) => this.onSearchTermChange(value)}
            autoComplete="off"
            onFocus={() => this.setState({ showResults: true })}
            {...inputProps}
          />

          {showResults &&
            searchResults.length > 0 && (
              <ResultsContainer direction="column" width="100%">
                {searchResults.map((result, index) => {
                  const isSelected = searchTerm.includes(result.title);
                  if (!result.title) return null;
                  return (
                    <SearchResultTile
                      key={index}
                      result={result}
                      image={result.image}
                      title={result.title}
                      subtitle={result.subtitle}
                      onSelectResult={result => this.onSelectResult(result.title)}
                      imageType="poster"
                      isSelected={isSelected}
                    />
                  );
                })}
              </ResultsContainer>
            )}
        </Flex>
        {showResults && searchResults.length > 0 && <CloseOverlay onClick={() => this.setState({ showResults: false })} />}
      </>
    );
  }
}

export default ApiSearchInput;
