import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import Modal from 'components/Modal';
import InfoGroup from 'components/InfoGroup';
import IconButton from 'components/IconButton';

const ConfirmationModal = ({ isOpen, onClose, isCentered, emoji, icon, iconButton, title, subtitle }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered} size="xs">
      <Modal.Body>
        <InfoGroup
          emoji={emoji}
          icon={iconButton ? <IconButton icon={iconButton} isRound as="div" /> : icon}
          title={title}
          subtitle={subtitle}
          spaceBetween="4px"
          noSpaceBetween={false}
          direction="column"
          align="center"
          textAlign="center"
          titleFontSize={theme.fontSizes.lg}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
