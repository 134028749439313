import React from 'react';
import { theme } from 'styles/theme';
import { IconButton } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/form/icon-button
const IconButtonComponent = React.forwardRef(
  ({ icon, ariaLabel, variant = 'solid', colorScheme = 'gray', size = 'md', color = theme.colors.paragraphText, ...props }, ref) => (
    <IconButton
      ref={ref}
      {...props}
      icon={icon}
      aria-label={ariaLabel}
      variant={variant}
      colorScheme={colorScheme}
      size={size}
      color={color}
    />
  ),
);

export default IconButtonComponent;
