import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { PrivateMessageIllustration } from 'assets/illustrations/all';
import EmptyState from 'components/EmptyState';

const Styled = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnauthNotice = ({ hasCompany, currentCompany }) => {
  const navigate = useNavigate();
  return (
    <Styled>
      <EmptyState
        illustration={<PrivateMessageIllustration />}
        title="Activity is private"
        subtitle="Please join or login to view"
        bg="white"
        border="none"
        buttonText={currentCompany && 'Login'}
        onButtonClick={() =>
          navigate(`/org/${currentCompany?.slug}/auth/create-account?login_initial=true&redirect=${window.location.href}`)
        }
        buttonColorScheme="gray"
        buttonVariant="outline"
        secondButtonText={currentCompany && 'Join'}
        onSecondButtonClick={() => navigate(`/org/${currentCompany?.slug}/auth/create-account?redirect=${window.location.href}`)}
      />
    </Styled>
  );
};

export default UnauthNotice;
