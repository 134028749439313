import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { cleanHTML } from 'helpers/utils';
// import { ButtonGroup } from '@chakra-ui/react';

const Styled = styled.div`
  font-size: 14px;
  font-family: 'light';
  color: ${theme.colors.paragraphText};
  max-width: 100%;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  .editor-quote {
    color: ${theme.colors.paragraphText};
  }
  .editor-heading-h1,
  .editor-heading-h2 {
    color: ${theme.colors.primaryText};
  }
  br {
    content: '';
    margin: 4em;
    display: block;
    font-size: 24%;
  }
  ${({ shouldDisplayInline }) =>
    shouldDisplayInline &&
    css`
      display: inline;
      div {
        display: inline;
      }
    `};
`;

const HTMLText = ({ html, shouldDisplayInline }) => {
  return (
    <Styled shouldDisplayInline={shouldDisplayInline}>
      <div dangerouslySetInnerHTML={{ __html: cleanHTML(html) }} />
    </Styled>
  );
};

export default HTMLText;
