import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, defaultHoverTransition } from 'styles/theme';
import { Link as ChakraLink, SimpleGrid, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SpecLogo from 'assets/images/SpecLogo';
import Text from 'components/Text';
import Button from 'components/Button';

const FixedJoinCTA = styled(Button)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 5;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    right: 50%;
    transform: translateX(50%);
  }
`;

const TermsFooter = ({ p = `${theme.padding.lg} ${theme.padding.sm}`, showLogo = true, props, showFixedCreateCTA, currentCompanySlug }) => {
  const [showFixedCreateCTAScroll, setShowFixedCreateCTAScroll] = useState(false);

  // Only show the "Create your own page like this" after they have scrolled at least halfway
  useEffect(() => {
    if (showFixedCreateCTA) window.addEventListener('scroll', listenToScroll);
    return () => {
      if (showFixedCreateCTA) window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const listenToScroll = () => {
    const pageHeight = document.body.clientHeight;
    const amountScrolled = document.body.scrollTop || document.documentElement.scrollTop;
    if (amountScrolled > pageHeight / 2 && !showFixedCreateCTAScroll) {
      setShowFixedCreateCTAScroll(true);
      window.removeEventListener('scroll', listenToScroll);
    }
  };

  return (
    <>
      {showFixedCreateCTA &&
        showFixedCreateCTAScroll && (
          <Link to={`/org/${currentCompanySlug}/auth`}>
            <FixedJoinCTA colorScheme="blue" variant="solid" leftIcon={<SpecLogo variant="logo_mark" size="14px" mr="3px" />} as="div">
              Create your own page like this
            </FixedJoinCTA>
          </Link>
        )}

      <Flex direction="column" align="center" justify="center" p={p} id="terms-footer">
        <Flex align="center" justify="center" gap="12px" {...props}>
          <ChakraLink
            href="https://welcome.spec.me"
            isExternal
            color={theme.colors.lightAccentText}
            fontSize="13px"
            fontFamily={theme.fonts.semiBold}>
            Home
          </ChakraLink>
          <ChakraLink
            href="https://welcome.spec.me/contact"
            isExternal
            color={theme.colors.lightAccentText}
            fontSize="13px"
            fontFamily={theme.fonts.semiBold}>
            Help
          </ChakraLink>
          {showLogo && (
            <ChakraLink href="https://welcome.spec.me" isExternal>
              <SpecLogo variant="full_gray" />
            </ChakraLink>
          )}
          <ChakraLink
            href="https://welcome.spec.me/legal/terms"
            isExternal
            color={theme.colors.lightAccentText}
            fontSize="13px"
            fontFamily={theme.fonts.semiBold}>
            Terms
          </ChakraLink>
          <ChakraLink
            href="https://welcome.spec.me/legal/privacy"
            isExternal
            color={theme.colors.lightAccentText}
            fontSize="13px"
            fontFamily={theme.fonts.semiBold}>
            Privacy
          </ChakraLink>
        </Flex>
        <Text color={theme.colors.lightAccentText} fontSize="13px" fontFamily={theme.fonts.semiBold} mt="12px">
          Made with ♥ in NYC
        </Text>
      </Flex>
    </>
  );
};

export default TermsFooter;
