import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { TRAITS_INFO } from 'data/bigFiveData';
import Accordion from 'components/Accordion';
import Flex from 'components/Flex';
import Button from 'components/Button';

const StyledFlex = styled(Flex)`
  strong {
    font-weight: normal;
    font-family: ${theme.fonts.semiBold};
    display: block;
    margin-top: 20px;
  }
`;

const LiveOutline = ({ answers, isEditMode, onDelete }) => {
  return (
    <StyledFlex gap="16px" direction="column" align="flex-start">
      <Accordion width="100%" flexGrow={1}>
        {answers.map((answer, answerIndex) => {
          return (
            <Accordion.Item key={answer.option}>
              <Accordion.Button text={`${answer.option} – ${answer.answer}%`} />
              <Accordion.Panel>
                {TRAITS_INFO[answerIndex].description}
                <strong>High {answer.option} Score</strong>
                {TRAITS_INFO[answerIndex].high_description}
                <strong>Low {answer.option} Score</strong>
                {TRAITS_INFO[answerIndex].low_description}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {isEditMode && <Button onClick={onDelete}>Reset Answers</Button>}
    </StyledFlex>
  );
};

export default LiveOutline;
