import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api } from 'helpers/utils';
import { isUsernameValid, isValidURL } from 'helpers/authUtils';
import { getCurrent } from 'reduxx/current';
import update from 'immutability-helper';
import Section from 'components/Section';
import ImageUpload from 'containers/ImageUpload';
import InputGroup from 'components/InputGroup';
import Loading from 'components/Loading';
import Flex from 'components/Flex';
import Button from 'components/Button';

const Styled = styled.div``;

class CompanyInfo extends React.Component {
  state = {
    company: {
      name: '',
      logo: '',
      slug: '',
      domain: '',
    },
    wereEditsMade: false,
    isSlugAvailable: true,
  };

  componentDidMount() {
    this.getCompanyInfo();
  }

  getCompanyInfo() {
    Api.get({ url: `/api/v1/admin/companies/get_company_info` }).then(data => {
      if (data.success) {
        this.setState({ company: data.company, currentSlug: data.company.slug, didLoad: true });
      } else {
        this.props.triggerToast({ title: data.error, status: 'error' });
      }
    });
  }

  onCompanyChange = (field, value) => {
    if (field === 'slug') {
      if (!isUsernameValid(value)) return;
      this.isCompanySlugAvailable(value);
      value = value.replace('.', '');
      value = value.toLowerCase();
    }

    this.setState({
      company: update(this.state.company, { [field]: { $set: value } }),
      wereEditsMade: true,
    });
  };

  isCompanySlugAvailable(companySlug) {
    if (!companySlug || companySlug.length < 2) {
      this.setState({ isSlugAvailable: true });
    } else {
      Api.post({
        url: `/api/v1/admin/companies/is_slug_available`,
        body: { company_slug: companySlug },
      }).then(data => {
        if (data.is_available || companySlug === this.state.currentSlug) {
          this.setState({ isSlugAvailable: true });
        } else {
          this.setState({ isSlugAvailable: false });
        }
      });
    }
  }

  returnValidation() {
    const { company, isSlugAvailable } = this.state;
    if (!company.name || company.name.length < 2) return { success: false, error: "Please enter your company's name" };
    if (!company.slug || company.slug.length < 2 || !isSlugAvailable)
      return { success: false, error: 'Please enter a valid directory URL' };
    if (!!company.domain) {
      if (company.domain.includes('http')) return { success: false, error: "Please don't include http in your email domain" };
      if (company.domain.includes('www.')) return { success: false, error: "Please don't include www. in your email domain" };
      if (!isValidURL(`https://${company.domain}`)) return { success: false, error: 'Please enter a valid email domain' };
    }
    return { success: true };
  }

  onSave = () => {
    const { company, isSlugAvailable, currentSlug } = this.state;
    const { triggerToast, dispatch } = this.props;
    const validation = this.returnValidation();
    if (validation.success) {
      this.setState({ isSaving: true });
      Api.post({
        url: `/api/v1/admin/companies/update_company_info`,
        body: { company: company },
      }).then(data => {
        if (data.success) {
          if (currentSlug !== data.company.slug) {
            window.location.href = window.location.href.replace(currentSlug, data.company.slug);
          } else {
            this.setState({
              wereEditsMade: false,
              isSaving: false,
              currentSlug: data.company.slug,
            });
            triggerToast({ title: 'Company info has been saved' });
            dispatch(getCurrent(false));
          }
        }
      });
    } else {
      triggerToast({ title: validation.error, status: 'error' });
    }
  };

  render() {
    const { company, didLoad, isSlugAvailable, wereEditsMade, isSaving } = this.state;
    if (!didLoad) return <Loading />;
    return (
      <Section>
        <Section.Header title="Company Info" />
        <Section.Body>
          <Flex direction="column" align="stretch" gap="16px">
            <InputGroup
              label="Logo"
              field="logo"
              customComponent={
                <ImageUpload
                  value={company.logo}
                  onUpload={this.onCompanyChange}
                  field="logo"
                  uploadPreset="company_logo"
                  folderName="company_logos"
                  uploadBoxHeight="50px"
                  uploadBoxWidth="50px"
                  uploadBoxBorderRadius={theme.radii.md}
                  buttonVariant="link"
                  buttonText="Choose File"
                />
              }
            />
            <Flex gap="16px">
              <InputGroup
                label="Name"
                type="input"
                value={company.name}
                field="name"
                placeholder="Acme Co"
                onChange={this.onCompanyChange}
              />
              <InputGroup
                label="Signup Email Domain"
                type="input"
                value={company.domain}
                field="domain"
                placeholder="example.com"
                onChange={this.onCompanyChange}
              />
            </Flex>
            <InputGroup
              label="Directory URL"
              type="input"
              value={company.slug}
              placeholder="username"
              field="slug"
              onChange={this.onCompanyChange}
              inputLeftAddon="spec.me/org/"
              errorLabel={
                !isSlugAvailable && (
                  <span>
                    URL <em>{company.slug}</em> is not available, try another!
                  </span>
                )
              }
            />
          </Flex>
        </Section.Body>
        <Section.Footer>
          <Button
            colorScheme="brand"
            variant="solid"
            isDisabled={!wereEditsMade || isSaving || !isSlugAvailable}
            isLoading={isSaving}
            onClick={this.onSave}>
            Save Changes
          </Button>
        </Section.Footer>
      </Section>
    );
  }
}

export default CompanyInfo;
