import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api, returnQuery, setCookie } from 'helpers/utils';
import { urls } from 'data/constants';
import { createStandaloneToast } from '@chakra-ui/toast';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import OnboardingFlow from 'componentsCustom/OnboardingFlow';
import Button from 'components/Button';
import Flex from 'components/Flex';
const { ToastContainer, toast } = createStandaloneToast({ theme: theme });

const EnterCode = ({ email, onEnterCodeEmailEdit, successAction }) => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [loginAccessCode, setLoginAccessCode] = useState('');
  const [recaptchaCode, setRecaptchaCode] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const title = `Enter 4-digit access code sent to ${email}`;
  let subtitle = 'Or click the access link within the email.';

  useEffect(() => {
    let successRedirect;
    const parsed = returnQuery();
    if (parsed.redirect) successRedirect = parsed.redirect;
    Api.post({
      url: `/api/v1/auth/send_login_access_code`,
      body: { email: email, success_redirect: successRedirect },
    }).then(data => {
      if (!data.success) {
        triggerToast(data.error);
      }
    });
  }, []);

  const onSubmit = () => {
    if (loginAccessCode.length !== 4) {
      setIsSubmitLoading(false);
      triggerToast('Please enter complete 4-digit code');
    } else if (!recaptchaCode) {
      setIsSubmitLoading(false);
      triggerToast(`Please confirm you're not a robot!`);
    } else {
      setIsSubmitLoading(true);
      Api.post({
        url: `/api/v1/auth/submit_login_access_code`,
        body: { email: email, login_access_code: loginAccessCode, recaptcha_code: recaptchaCode },
      }).then(data => {
        if (data.success) {
          setCookie('login_token', data.login_token);
          let redirectURL;
          const parsed = returnQuery();
          if (successAction === 'create_spec') {
            redirectURL = `${urls.routeDomain}/${data.user.slug}?action=create-spec`;
          } else if (successAction === 'account_login') {
            redirectURL = `${urls.routeDomain}/${data.user.slug}`;
          }
          if (parsed.redirect) redirectURL = parsed.redirect;
          setTimeout(() => {
            window.location.href = redirectURL;
          }, 50);
        } else {
          setIsSubmitLoading(false);
          triggerToast(data.error);
        }
      });
    }
  };

  const triggerToast = title => {
    toast({
      title: title,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <OnboardingFlow.Inner>
      <ToastContainer />
      <OnboardingFlow.HeaderEmoji emoji="📩" />
      <OnboardingFlow.Header title={title} subtitle={subtitle} />

      <OnboardingFlow.Body>
        <Flex direction="column" gap="16px">
          <Flex gap="10px" width="100%">
            <PinInput
              otp
              value={loginAccessCode}
              onChange={value => setLoginAccessCode(value)}
              isDisabled={isSubmitLoading}
              autoFocus
              focusBorderColor={theme.colors.lightAccentText}
              variant="filled"
              id="input-element">
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </Flex>
          {loginAccessCode.length === 4 && (
            <Flex align="flex-start" width="100%">
              <ReCAPTCHA sitekey="6Lejf3ogAAAAAClC2IhL-g0zP0xDXtMHgSvS6LrR" onChange={code => setRecaptchaCode(code)} />
            </Flex>
          )}
        </Flex>
      </OnboardingFlow.Body>

      <OnboardingFlow.Footer>
        <Button
          variant="solid"
          colorScheme="brand"
          isLoading={isSubmitLoading}
          isDisabled={isSubmitLoading}
          type="button"
          onClick={onSubmit}>
          Submit
        </Button>
      </OnboardingFlow.Footer>

      <OnboardingFlow.PostText onClick={onEnterCodeEmailEdit}>
        <div>
          Enter the wrong email? <span>Edit email</span>
        </div>
      </OnboardingFlow.PostText>
    </OnboardingFlow.Inner>
  );
};

export default EnterCode;
