import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Api } from 'helpers/utils';
import { GoogleIcon } from 'assets/icons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import Button from 'components/Button';

// https://github.com/MomenSherif/react-oauth
const GoogleAuth = props => {
  return (
    <GoogleOAuthProvider clientId="173430426217-65v6j0nefucfpu5hjaf0utr0quk8f6h0.apps.googleusercontent.com">
      <ButtonComponent {...props} />
    </GoogleOAuthProvider>
  );
};

const ButtonComponent = ({ isLoading, domain = '', authMethod = 'login', onSuccess }) => {
  const login = useGoogleLogin({
    onSuccess: tokenResponse => handleSuccess(tokenResponse),
    hosted_domain: domain,
  });

  const handleSuccess = tokenResponse => {
    const accessToken = tokenResponse.access_token;
    if (authMethod === 'get_user_google_info') {
      Api.post({
        url: `/api/v1/auth/get_user_google_info`,
        body: { access_token: accessToken },
      }).then(user => {
        onSuccess(user);
      });
    }
  };

  return (
    <Button isLoading={isLoading} isDisabled={isLoading} type="button" leftIcon={<GoogleIcon />} onClick={login}>
      Continue with Google
    </Button>
  );
};

export default GoogleAuth;
