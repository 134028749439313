import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { Link } from '@chakra-ui/react';
import SpecLogo from 'assets/images/SpecLogo';
import Flex from 'components/Flex';

const Styled = styled(Flex)`
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100vw;
  background: ${theme.colors.primary};
  color: white;
  padding: 16px;
  z-index: 50;
  margin-bottom: ${theme.margin.xxl};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 0px;
  }
`;

const NotSignedInNavigation = () => {
  return (
    <Styled>
      <Link href="https://welcome.spec.me">
        <SpecLogo variant="full_white" logoWidth="106" logoHeight="36" />
      </Link>
    </Styled>
  );
};

export default NotSignedInNavigation;
