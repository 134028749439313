import React from 'react';
import { Flex } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/layout/flex
const FlexComponent = ({ align = 'center', ...props }) => {
  return (
    <Flex align={align} {...props}>
      {props.children}
    </Flex>
  );
};

export default FlexComponent;
