import React from 'react';
import { Button } from '@chakra-ui/react';

// https://chakra-ui.com/docs/components/form/button
const ButtonComponent = React.forwardRef(({ variant = 'outline', colorScheme = 'gray', size = 'md', ...props }, ref) => (
  <Button ref={ref} {...props} variant={variant} colorScheme={colorScheme} size={size}>
    {props.children}
  </Button>
));

export default ButtonComponent;
