import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'styles/theme';
import { CloseIcon } from 'assets/icons';
import { Box } from '@chakra-ui/react';
import { Api } from 'helpers/utils';
import InputGroup from 'components/InputGroup';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Flex from 'components/Flex';
import SearchResultTile from 'componentsCustom/SearchResultTile';
const movieDBPosterPath = 'https://image.tmdb.org/t/p/w185/';

const OpenButton = styled(Box)`
  width: 100%;
  text-align: left;
`;

class ApiSearchModal extends React.Component {
  state = {
    searchTerm: '',
    selectedResults: [],
    searchResults: [],
    isOpen: false,
  };

  componentDidMount() {
    const { selectedResults = [] } = this.props;
    this.setState({ selectedResults: selectedResults });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedResults !== this.props.selectedResults) {
      this.setState({ selectedResults: this.props.selectedResults });
    }
  }

  onSearchTermChange = searchTerm => {
    this.setState({ searchTerm: searchTerm });
    const { apiToSearch } = this.props;
    if (searchTerm.length < 2) {
      this.setState({ searchResults: [] });
      return;
    }
    Api.post({
      url: `/api/v1/search/external_api`,
      body: {
        search_term: searchTerm,
        api_to_search: apiToSearch,
      },
    })
      .then(data => {
        if (!data.errors) {
          let searchResults = [];
          if (['movies', 'tv'].includes(apiToSearch)) {
            searchResults = data.results.map(result => {
              return {
                title: apiToSearch === 'tv' ? result.name : result.title,
                subtitle: result.release_date,
                image: `${movieDBPosterPath}${result.poster_path}`,
              };
            });
          } else if (apiToSearch === 'books') {
            searchResults = data.items.map(item => {
              if (item.volumeInfo) {
                const volumeInfo = item.volumeInfo;
                if (volumeInfo && volumeInfo.title && volumeInfo.imageLinks.thumbnail) {
                  return {
                    title: volumeInfo.title,
                    subtitle: volumeInfo.authors.join(', '),
                    image: volumeInfo.imageLinks.thumbnail.replace('http:', 'https:'),
                  };
                }
              }
            });
          } else if (apiToSearch === 'cities') {
            searchResults = data.predictions.map(prediction => {
              return {
                title: prediction.description.replace(', USA', ''),
                extraData: prediction.place_id,
              };
            });
          }
          this.setState({ searchResults: searchResults });
        }
      })
      .catch(error => console.error('There was an error!', error));
  };

  onToggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onClearSearch = () => {
    this.setState({ searchTerm: '', searchResults: [] });
  };

  onSelectResult = (valueOne, valueTwo) => {
    const { onSelectResult, shouldCloseOnSelect = true } = this.props;
    onSelectResult(valueOne, valueTwo);
    if (shouldCloseOnSelect) {
      this.onToggleModal();
      this.onClearSearch();
    }
  };

  render() {
    const { isOpen, searchTerm, selectedResults, searchResults } = this.state;
    const { onClose, searchPlaceholder = 'Search', isCentered, children, displayAsInput = false, inputProps } = this.props;
    return (
      <>
        {displayAsInput ? (
          <InputGroup onFocus={this.onToggleModal} {...inputProps} />
        ) : (
          <OpenButton onClick={this.onToggleModal} as="button" className={this.props.buttonClassName}>
            {children}
          </OpenButton>
        )}
        <Modal
          isOpen={isOpen}
          onClose={this.onToggleModal}
          isCentered={isCentered}
          size="sm"
          returnFocusOnClose={false}
          constrainWidthMobile>
          <Modal.Body>
            <Flex direction="column" gap="8px">
              <InputGroup
                type="input"
                value={searchTerm}
                placeholder={searchPlaceholder}
                onChange={(field, value) => this.onSearchTermChange(value)}
                autoFocus
                autoComplete="do-not-autofill"
                elementPointerEvents="auto"
                inputRightElement={
                  searchTerm && (
                    <button onClick={() => this.onClearSearch()}>
                      <CloseIcon color={theme.colors.accentText} />
                    </button>
                  )
                }
              />

              <Flex direction="column" width="100%" className={searchResults.length === 0 && 'hidden'}>
                {searchResults.map((result, index) => {
                  const isSelected = selectedResults.includes(result.title);
                  if (!result.title) return null;
                  return (
                    <SearchResultTile
                      key={index}
                      result={result}
                      image={result.image}
                      title={result.title}
                      subtitle={result.subtitle}
                      onSelectResult={result => this.onSelectResult(result.title, result.extraData || result.image)}
                      imageType="poster"
                      isSelected={isSelected}
                    />
                  );
                })}
              </Flex>
            </Flex>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ApiSearchModal;
