import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, editTileStyle } from 'styles/theme';
import { Tooltip } from '@chakra-ui/react';
import { isEqual } from 'helpers/utils';
import Flex from 'components/Flex';
import TextEditor from 'components/TextEditor';
import InputGroup from 'components/InputGroup';
import AnswerHandleGroup from 'componentsCustom/AnswerHandleGroup';

const Styled = styled.div`
  ${editTileStyle};
`;

const EditTile = ({ answer, onSectionAnswerChange, sectionIndex, answerIndex, sortableHandleClassName, answerElementID }) => {
  return (
    <Styled id={answerElementID}>
      <Tooltip
        label="This question is part of a template, so the title can't be edited."
        placement="top-start"
        openDelay={400}
        isDisabled
        // isDisabled={answer.is_custom}
        shouldWrapChildren>
        <Flex borderBottom={theme.layout.border}>
          <InputGroup
            type="textarea"
            value={answer.option}
            placeholder="Question title"
            field="option"
            onChange={(field, value) => onSectionAnswerChange(sectionIndex, answerIndex, field, value)}
            variant="unstyled"
            minRows="1"
            disabled={false && !answer.is_custom}
          />
          <AnswerHandleGroup
            onDeleteClick={() => onSectionAnswerChange(sectionIndex, answerIndex, 'is_deleted', true)}
            dragHandleClassName={sortableHandleClassName}
          />
        </Flex>
      </Tooltip>
      <TextEditor
        placeholder="Response"
        allowHeaders={false}
        value={answer.node_answer}
        editorID={`editor-${sectionIndex}-${answerIndex}`}
        onHTMLChange={value => onSectionAnswerChange(sectionIndex, answerIndex, 'answer', value)}
        onNodeChange={value => onSectionAnswerChange(sectionIndex, answerIndex, 'node_answer', value)}
      />
    </Styled>
  );
};

export default React.memo(EditTile, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.answer, nextProps.answer) &&
    prevProps.sectionIndex === nextProps.sectionIndex &&
    prevProps.answerIndex === nextProps.answerIndex
  );
});
